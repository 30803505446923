import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  APIResponse,
  menu_words,
  QuestionerAudioQuestion,
  QuestionerAvatar,
  QueueType,
  School,
  SchoolData,
  Student,
  YoutubeSearchResult,
} from './../constant.service';
import { AnalyticsService } from './../analytics.service';
import { cloneDeep, isEqual } from 'lodash-es';
import { decompress } from 'compress-json';
import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';

// import { AngularFireAuth } from '@angular/fire/auth';
// import * as firebase from 'firebase/app';
// import 'firebase/auth';
import { ToastrService, ActiveToast } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject, from } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  map,
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
  filter,
} from 'rxjs/operators';
import { Base64 } from 'js-base64';
import * as uuid from 'uuid';
import * as moment from 'moment';
import {
  LearningData,
  SelectionType,
  Lesson,
  Category,
  User,
  YlistTargetVideo,
  Video,
} from '../constant.service';
import * as Sentry from '@sentry/angular';
declare var gapi: any; // Add this line to use the gapi variable
declare var google: any; // Add this line to use the gapi variable

declare var require;
const jsonpack = require('jsonpack/main');
const Swal = require('sweetalert2');
import * as pako from 'pako';
import { LocalIndexedDBStorageService } from '../local-indexeddb-storage.service';
import { KvService } from './../kv.service';
import { R2Service } from './../r2.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnInit {
  public accessToken: string = '';
  public refreshToken: string = '';
  public userData: User;
  public schoolData: SchoolData;
  public schoolDataToCloneToSetLanguage: SchoolData;
  public clonedSchoolData: SchoolData;
  public selectedSchool: School;
  // public students: Student[] = [];
  // public user: User;
  public showLoader: boolean = false;
  public isAuthenticationCallback: boolean = false;
  public receivedFirstCallbackFromFirebase = false;
  public categoryMap: object = {};
  public categoryMapFlipped: object = {};
  public observerData = new Subject<LearningData>();
  public youtubeSearchTerm = new Subject<string>();
  public observerAuthentication = new Subject<any>();

  public currentLanguageCode: string;
  public currentDialectCode: string;
  public translations: {};
  public translation_data: any = null;
  public clonedLessons: Lesson[];
  public clonedCategories: Category[];
  public clonedQuestionerAvatars: QuestionerAvatar[];
  public clonedQuestionerQuestions: QuestionerAudioQuestion[];
  private listDisplayTypeEnum = SelectionType;
  public unsetCategoryLabel: string = 'Unset Category';

  public defaultUserData = {
    email: null,
    uid: null,
    myDataURL: '',
    schools: [],
    setting: {
      youtubeApiKey: '',
      youtubePlaylist: '',
      disableFullscreen: false,
      recordingVolume: 10,
    },
    purchasedDate: 0,
    expiredDate: 0,
    deviceId: 0,
  } as User;

  public defaultPremiumSchoolData: SchoolData = null;

  public defaultPremiumSchool: School = {
    schoolId: 'rbbxGM8Tp5X6afuMg4dSboDUmP33',
    schoolName: 'speaknplay premium',
    isMultiLanguage: true,
  };
  public isPasswordUser = false;

  constructor(
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private cookieService: CookieService,
    private http: HttpClient,
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private kvService: KvService,
    private r2Service: R2Service
  ) {}

  async checkToLoadLatestVersion() {
    let variables = await this.kvService.read('variables', 'global');
    if (variables.app_version != environment.version) {
      Swal.fire({
        title: this.translate.instant('New version is available'),
        text: this.translate.instant(
          'Do you want to reload the app to get the latest version?'
        ),
        type: 'warning',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('No'),
        confirmButtonText: this.translate.instant('Yes'),
      }).then((result) => {
        if (result.value) {
          let scripts = document.getElementsByTagName('script');
          let links = document.getElementsByTagName('link');
          let files = [location.origin + '/index.html'];
          for (let si = 0; si < scripts.length; si++) {
            try {
              if (scripts[si].src.includes(location.host)) {
                files.push(scripts[si].src);
              }
            } catch (e) {
              console.log(e);
            }
          }
          for (let li = 0; li < links.length; li++) {
            try {
              if (links[li].href.includes(location.host)) {
                files.push(links[li].href);
              }
            } catch (e) {
              console.log(e);
            }
          }
          window['hard_reload_files'] = files.length;
          let cb = () => {
            window['hard_reload_files']--;
            if (window['hard_reload_files'] == 0) {
              window.location.reload();
            }
          };
          for (let file of files) {
            this.hardReloadFile(file, cb);
          }
        }
      });
    } else {
      if (
        variables.message_html &&
        variables.message_id &&
        localStorage.getItem('message_id') != variables.message_id
      ) {
        Swal.fire({
          title: '',
          html: variables.message_html,
        });
        localStorage.setItem('message_id', variables.message_id);
      }
    }
  }

  hardReloadFile(filePath: string, cb) {
    var oAjax = new XMLHttpRequest();
    oAjax.open('post', filePath);
    oAjax.setRequestHeader('Pragma', 'no-cache');
    oAjax.setRequestHeader('Expires', '-1');
    oAjax.setRequestHeader('Cache-Control', 'no-cache');
    oAjax.send();
    oAjax.onreadystatechange = () => {
      if (oAjax.readyState === 4) {
        cb();
      }
    };
  }

  getCategories(includeUnset = false) {
    let list = this.clonedSchoolData.data.categories
      ? this.clonedSchoolData.data.categories
      : [];
    if (includeUnset) {
      const unsetCategory: Category = {
        cid: 10000,
        name: this.translate.instant(this.unsetCategoryLabel),
      };
      list.push(unsetCategory);
    }
    return list;
  }

  async setCurrentLearningLanguage() {
    this.currentLanguageCode = localStorage.getItem('learninglanguage') || 'en';
    this.currentDialectCode = localStorage.getItem('dialect') || 'en-US';
    this.clonedSchoolData = cloneDeep(this.schoolDataToCloneToSetLanguage);
    if (
      this.schoolData.uid == this.defaultPremiumSchool.schoolId &&
      this.userData.uid != this.defaultPremiumSchool.schoolId
    ) {
      const translation_output =
        'lessons/' +
        this.schoolData.uid +
        '/' +
        (this.currentDialectCode == 'vi-VNS'
          ? 'vi-VN'
          : this.currentDialectCode) +
        '/translations_output.json';
      this.translation_data = await this.r2Service.getFile(translation_output);
      this.translation_data = JSON.parse(this.translation_data.data);
      const translationName = this.translation_data.name;
      const translationAudio = this.translation_data.audio;
      const translationAnswer = this.translation_data.answer;
      const translationQuestion = this.translation_data.question;

      this.clonedSchoolData.questionerAudioQuestions =
        this.clonedSchoolData.questionerAudioQuestions.map((item) => {
          let filename = this.formatTranslationKey(item.name);
          if (this.currentDialectCode == 'vi-VNS') {
            filename = filename + '_vi-VNS';
          }
          let audioFileName = translationAudio[filename];
          if (!audioFileName) {
            audioFileName = Object.keys(translationQuestion).find(
              (k) =>
                this.formatTranslationKey(translationQuestion[k]) == filename
            );
            audioFileName = translationAudio[audioFileName];
          }
          item.audioURL =
            environment.r2BaseURL +
            '/lessons/' +
            this.defaultPremiumSchool.schoolId +
            '/' +
            (this.currentDialectCode == 'vi-VNS'
              ? 'vi-VN'
              : this.currentDialectCode) +
            '/' +
            audioFileName;
          item.name =
            translationQuestion[this.formatTranslationKey(item.name)] ||
            item.name;
          if (item.audioURL.includes('undefined')) {
            debugger;
          }
          return item;
        });

      if (!this.userData.type || this.userData.type == 'free') {
        // remove those Lesson that isFree is false or not exist
        this.clonedSchoolData.data.lessons =
          this.clonedSchoolData.data.lessons.filter((item) => {
            return item.isFree;
          });
      }
      this.clonedSchoolData.data.lessons =
        this.clonedSchoolData.data.lessons.map((item) => {
          item.name =
            translationName[this.formatTranslationKey(item.name)] || item.name;
          item.ylist = item.ylist.map((ylist) => {
            ylist.questionAnswers = ylist.questionAnswers.map((qa) => {
              qa.answer =
                translationAnswer[this.formatTranslationKey(qa.answer)] ||
                qa.answer;
              return qa;
            });
            return ylist;
          });
          return item;
        });
      this.clonedSchoolData.data.categories =
        this.clonedSchoolData.data.categories.map((item) => {
          item.name =
            translationName[this.formatTranslationKey(item.name)] || item.name;
          return item;
        });

      // user can't save the changes, then schoolData is the clonedVersion so that if user clone it, it'll be in the same language as the cloned version
      if (this.userData.uid != this.schoolData.uid) {
        // this version is for user to save the changes, this.schoolDataToCloneToSetLanguage is the original version for user to set current language because the keys are in in vietnamese
        this.schoolData = cloneDeep(this.clonedSchoolData);
      }
    }

    this.clonedLessons = this.clonedSchoolData.data.lessons
      ? this.clonedSchoolData.data.lessons
      : [];
    this.clonedCategories = this.getClonedCategories(true);
    this.clonedQuestionerAvatars = this.clonedSchoolData.questionerAvatars
      ? this.clonedSchoolData.questionerAvatars
      : [];
    this.clonedQuestionerQuestions = this.clonedSchoolData
      .questionerAudioQuestions
      ? this.clonedSchoolData.questionerAudioQuestions
      : [];
  }

  getClonedCategories(includeUnset = false) {
    let list = this.clonedSchoolData.data.categories
      ? this.clonedSchoolData.data.categories
      : [];
    if (includeUnset) {
      const unsetCategory: Category = {
        cid: 10000,
        name: this.translate.instant(this.unsetCategoryLabel),
      };
      list.push(unsetCategory);
    }
    return list;
  }

  formatTranslationKey(key) {
    return typeof key === 'string'
      ? key.toLowerCase().trim()
      : 'not-exist-translate-key';
  }

  getAudioURL(audioQuestionId) {
    let url = null;
    try {
      url = this.clonedSchoolData.questionerAudioQuestions.find(
        (q) => q.id == audioQuestionId
      ).audioURL;
    } catch (e) {
      url = null;
    }
    if (url.includes('undefined')) {
      debugger;
    }
    return url;
  }

  getQuestionerAudioQuestion(audioQuestionId) {
    let questionerAudioQuestion = null;
    try {
      questionerAudioQuestion =
        this.clonedSchoolData.questionerAudioQuestions.find(
          (q) => q.id == audioQuestionId
        );
    } catch (e) {
      questionerAudioQuestion = null;
    }
    return questionerAudioQuestion;
  }

  getQuestionerAvatar(questionerAvatarId) {
    let questionerAvatar = null;
    try {
      questionerAvatar = this.clonedSchoolData.questionerAvatars.find(
        (q) => q.id == questionerAvatarId
      );
    } catch (e) {
      questionerAvatar = null;
    }
    return questionerAvatar;
  }

  getJpgURL(questionerAvatarId) {
    let url = null;
    try {
      url = this.clonedSchoolData.questionerAvatars.find(
        (q) => q.id == questionerAvatarId
      ).jpgURL;
    } catch (e) {
      url = null;
    }
    return url;
  }

  getAvatarName(questionerAvatarId) {
    let url = null;
    try {
      url = this.clonedSchoolData.questionerAvatars.find(
        (q) => q.id == questionerAvatarId
      ).name;
    } catch (e) {
      url = null;
    }
    return url;
  }

  getAudioName(audioQuestionId) {
    let url = null;
    try {
      url = this.schoolData.questionerAudioQuestions.find(
        (q) => q.id == audioQuestionId
      ).name;
    } catch (e) {
      url = null;
    }
    return url;
  }

  ngOnInit(): void {}

  checkToContinuePendingPurchase() {
    const pending_purchase_type = localStorage.getItem('pending_purchase_type');
    if (localStorage.getItem('pending_purchase_type')) {
      localStorage.removeItem('pending_purchase_type');
      this.stripeCheckout(pending_purchase_type);
      return true;
    }
    return false;
  }

  isValidPaidUser() {
    return (
      this.userData.expiredDate &&
      this.userData.expiredDate > parseInt(moment().format('X'))
    );
  }

  async validateUserAndDevice(fromAuthProcess = false) {
    // return;
    if (this.isValidPaidUser()) {
      let remoteDeviceIds = this.userData.hasOwnProperty('devices')
        ? this.userData.devices
        : [];
      let concurrentDevices: { device_id: number; last_time: number }[] =
        remoteDeviceIds.filter((device) => {
          // There is an interval 300 seconds to update last time check, here it checks if a device has last time check >= 360 seconds
          // any device that has last time check < 360 seconds are considered as concurrent devices.
          return device.last_time >= parseInt(moment().format('X')) - 360;
        });
      let localDeviceId = this.getDeviceId();
      let existsLocalDevice = concurrentDevices.find((d) => {
        return d.device_id == localDeviceId;
      });
      // Keep the latest device
      if (!existsLocalDevice) {
        concurrentDevices.push({
          device_id: localDeviceId,
          last_time: parseInt(moment().format('X')),
        });
      }

      if (existsLocalDevice && fromAuthProcess) {
        concurrentDevices = concurrentDevices.map((d) => {
          if (d.device_id == localDeviceId) {
            d.last_time = parseInt(moment().format('X'));
          }
          return d;
        });
      }
      // sort by last time check asc, last device at the top will be signed out if more than 1 device are concurrently signed in.
      concurrentDevices = concurrentDevices.sort((d1, d2) => {
        return d1.last_time - d2.last_time;
      });
      let shouldSignOut = false;
      if (concurrentDevices.length > 1) {
        for (let i = 0; i < concurrentDevices.length; i++) {
          if (
            concurrentDevices[i].device_id == localDeviceId &&
            i != concurrentDevices.length - 1
          ) {
            shouldSignOut = true;
          }
        }
      }
      if (shouldSignOut) {
        concurrentDevices = concurrentDevices.filter((d) => {
          return d.device_id != localDeviceId;
        });
        await this.kvService.write(
          this.userData.email,
          JSON.stringify(this.userData),
          'users'
        );
        await this.kvService.updateProperty(
          this.userData.email,
          'devices',
          JSON.stringify(concurrentDevices),
          'users'
        );
        Swal.fire({
          title: this.translate.instant('Warning'),
          html: this.translate.instant(
            'You have signed in on another device. Please sign in again.'
          ),
          timer: 10000,
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.SignOut();
          }
        });
        // this.toster.warning(this.translate.instant('You have signed in on another device. Please sign in again.'));
      } else {
        this.userData.devices = concurrentDevices;
        await this.kvService.write(
          this.userData.email,
          JSON.stringify(this.userData),
          'users'
        );
      }

      // if (shouldSignOut) {
      // this.SignOut();
      // Latest sign in will be kept
      // if (fromAuthProcess) {
      //   deviceRef.set(deviceInfo);
      // } else {
      //   // Other devices will be sign out
      //   this.SignOut();
      // }
      // }

      // Expiry check
      // if (this.userData.expiredDate < parseInt(moment().format('X'))) {
      //   // if not default user
      //   if (!isEqual(this.schoolData.data, this.defaultFreeSchoolData.data)) {
      //     location.href = location.href;
      //   }
      // }
    }
  }

  // async getStudentsFromSchool(uid) {
  //   const studentPath = 'lessons/' + uid + '/students.json';
  //   const rawStudentsData = await this.r2Service.getFile(studentPath);
  //   if (rawStudentsData.data) {
  //     this.students = this.decompressUserData(rawStudentsData.data);
  //   }
  //   return this.students;
  // }

  // async getSchoolsFromStudent(studentId: string) {
  //   const schoolPath = 'lessons/' + studentId + '/schools.json';
  //   let schools: School[] = [];
  //   const rawStudentsData = await this.r2Service.getFile(schoolPath);
  //   if (rawStudentsData.data) {
  //     schools = rawStudentsData.data
  //       ? this.decompressUserData(rawStudentsData.data)
  //       : [];
  //   }
  //   if (
  //     schools.findIndex(
  //       (s) => s.schoolId == this.defaultPremiumSchool.schoolId
  //     ) == -1
  //   ) {
  //     schools.push(this.defaultPremiumSchool);
  //   }
  //   if (
  //     schools.findIndex((s) => s.schoolId == this.defaultFreeSchool.schoolId) ==
  //     -1
  //   ) {
  //     schools.push(this.defaultFreeSchool);
  //   }
  //   return schools;
  // }

  // async initDefaultPremiumSchoolData() {
  //   const schoolPath =
  //     'lessons/' + this.defaultPremiumSchool.schoolId + '/data.json';
  //   const localSchoolCacheKey = btoa(schoolPath);
  //   const result = await LocalIndexedDBStorageService.getItem(
  //     localSchoolCacheKey
  //   );
  //   if (result) {
  //     this.defaultPremiumSchoolData = this.decompressUserData(result);
  //   } else {
  //     const rawSchoolData = await this.r2Service.getFile(schoolPath);
  //     await LocalIndexedDBStorageService.setItem(
  //       localSchoolCacheKey,
  //       rawSchoolData.data
  //     );
  //     this.defaultPremiumSchoolData = this.decompressUserData(
  //       rawSchoolData.data
  //     );
  //   }
  //   if (!this.defaultPremiumSchoolData) {
  //     this.defaultPremiumSchoolData = {
  //       uid: this.defaultPremiumSchool.schoolId,
  //       data: {
  //         categories: [],
  //         lessons: [],
  //       },
  //       questionerAvatars: [],
  //       questionerAudioQuestions: [],
  //       lastUpdated: 0,
  //     };
  //   }
  // }

  async handleNewUser(user: User) {
    this.isAuthenticationCallback = true;
    // console.log(user);
    this.schoolData = {
      uid: user.uid,
      data: {
        categories: [],
        lessons: [],
      },
      questionerAvatars: [],
      questionerAudioQuestions: [],
      lastUpdated: 0,
    };
    const compressedSchoolData = this.compressUserData(this.schoolData);
    const filename = 'lessons/' + user.uid + '/data.json';
    const downloadURL = environment.r2BaseURL + '/' + filename;
    await this.r2Service.uploadFile(compressedSchoolData, filename);
    this.userData = {
      email: user.email,
      uid: user.uid,
      myDataURL: downloadURL,
      schools: [this.defaultPremiumSchool],
      students: [],
      // data: {
      //   lessons: [],
      //   categories: [],
      // },
      setting: {
        youtubeApiKey: '',
        youtubePlaylist: '',
        disableFullscreen: false,
        recordingVolume: 10,
      },
      purchasedDate: 0,
      expiredDate: 0,
      type: 'free',
      deviceId: this.getDeviceId(),
      // questionerAvatars: [],
      // questionerAudioQuestions: [],
    };

    // const compressedUserData = this.compressUserData(this.userData);
    // console.log(this.userData);
    //{merge: true}
    this.kvService
      .write(user.email, JSON.stringify(this.userData), 'users')
      .then(() => {
        this.isAuthenticationCallback = false;
        this.receivedFirstCallbackFromFirebase = true;
        this.observerAuthentication.next(true);
      });
    this.authenticationNext();
  }

  async setSchoolData(schoolId: string) {
    let fileName = 'lessons/' + schoolId + '/data.json';
    // for those viewing the premium school data, they can't save the changes
    if (
      schoolId == this.defaultPremiumSchool.schoolId &&
      this.userData.uid != this.defaultPremiumSchool.schoolId
    ) {
      fileName = 'lessons/' + schoolId + '/data_production.json';
    }

    const localSchoolCacheKey = btoa(fileName);
    const result = await LocalIndexedDBStorageService.getItem(
      localSchoolCacheKey
    );
    if (result && false) {
      this.schoolData = this.decompressUserData(result);
    } else {
      const rawSchoolData = await this.r2Service.getFile(fileName);
      await LocalIndexedDBStorageService.setItem(
        localSchoolCacheKey,
        rawSchoolData.data
      );
      if (!rawSchoolData.data) {
        this.schoolData = {
          uid: this.userData.uid,
          data: {
            categories: [],
            lessons: [],
          },
          questionerAvatars: [],
          questionerAudioQuestions: [],
          lastUpdated: 0,
        };
      } else {
        this.schoolData = this.decompressUserData(rawSchoolData.data);
      }
    }
    await LocalIndexedDBStorageService.setItem('defaultSchoolId', schoolId);
    this.selectedSchool = this.userData.schools.find(
      (school) => school.schoolId === schoolId
    );
    this.schoolDataToCloneToSetLanguage = cloneDeep(this.schoolData);
  }

  async handleExistUser(remoteUserData: User) {
    let decompressed: any;
    // @todo: check this Do not allow overwrite from remote if there is working version in local
    if (!this.userData || this.isDefaultData()) {
      const lastUpdated = remoteUserData.lastUpdated
        ? remoteUserData.lastUpdated
        : 0;
      await LocalIndexedDBStorageService.setItem(
        'lastUserDataUpdated',
        lastUpdated
      );
      this.userData = remoteUserData;
      this.userData.schools = this.userData.schools || [];
      this.userData.setting = this.userData.setting || {
        youtubeApiKey: '',
        youtubePlaylist: '',
        disableFullscreen: false,
        recordingVolume: 10,
      };
      // console.log(JSON.stringify(this.userData));
    }
    let defaultSchoolId = await LocalIndexedDBStorageService.getItem(
      'defaultSchoolId'
    );
    this.userData.schools = this.userData.schools || [];
    this.userData.students = this.userData.students || [];
    this.userData.schools.push(this.defaultPremiumSchool);
    // let schools = await this.getSchoolsFromStudent(this.userData.uid);
    // this.userData.schools = await this.getSchoolsFromStudent(
    //   this.userData.uid
    // );

    if (this.userData.type == 'student') {
      if (!defaultSchoolId) {
        defaultSchoolId = this.defaultPremiumSchool.schoolId;
      }
    } else if (this.userData.type == 'teacher') {
      if (
        this.userData.schools.findIndex(
          (s) => s.schoolId == this.userData.uid
        ) == -1
      ) {
        this.userData.schools.unshift({
          schoolId: this.userData.uid,
          schoolName: this.translate.instant('My school'),
          isMultiLanguage: false,
        });
      }
      // if exist schoolId = this.userData.uid set it isMultiLanguage = false
      this.userData.schools = this.userData.schools.map((s) => {
        if (s.schoolId == this.userData.uid) {
          s.isMultiLanguage = false;
        }
        return s;
      });
      if (!defaultSchoolId) {
        defaultSchoolId = this.userData.uid;
      }
    }
    // remove duplicate schools
    this.userData.schools = this.userData.schools.filter(
      (s, index, self) =>
        index === self.findIndex((t) => t.schoolId === s.schoolId) ||
        s.schoolId == 'c3BlYWtucGxheWZyZWVAZ21haWwuY29t'
    );
    // if not exist defaultSchoolID, set the premium school as default if exist, otherwise set the first school as default
    if (
      this.userData.schools.findIndex((s) => s.schoolId == defaultSchoolId) ==
      -1
    ) {
      if (
        this.userData.schools.findIndex(
          (s) => s.schoolId == this.defaultPremiumSchool.schoolId
        ) != -1
      ) {
        defaultSchoolId = this.defaultPremiumSchool.schoolId;
      } else {
        defaultSchoolId = this.userData.schools[0].schoolId;
      }
    }

    await this.setSchoolData(defaultSchoolId);
    await this.setCurrentLearningLanguage();
    // read school data from firebase storage
    this.isAuthenticationCallback = false;
    this.receivedFirstCallbackFromFirebase = true;
    this.observerAuthentication.next(true);
    await this.handleExpiredUser();
    this.authenticationNext();
  }

  async handleNoUserReturn() {
    this.userData = null;
    this.userData = null;
    await LocalIndexedDBStorageService.setItem('user', null);
    this.userData = this.defaultUserData;
    await this.setSchoolData(this.defaultPremiumSchool.schoolId);
    await this.setCurrentLearningLanguage();
    this.generateCategoryMap();
    this.observerData.next(this.schoolData.data);
    this.receivedFirstCallbackFromFirebase = true;
    this.isAuthenticationCallback = false;
    this.observerAuthentication.next(true);
    Swal.close();
  }

  async handleExpiredUser() {
    let isExpired =
      this.userData.expiredDate &&
      this.userData.expiredDate < parseInt(moment().format('X'));
    const isExpiredIn7Days =
      this.userData.expiredDate &&
      this.userData.expiredDate < parseInt(moment().add(7, 'days').format('X'));
    if (isExpired || isExpiredIn7Days) {
      Swal.fire({
        title: this.translate.instant('Warning'),
        html:
          this.translate.instant(
            isExpired
              ? 'Your subscription has expired'
              : 'Your subscription will expired at ' +
                  moment
                    .unix(this.userData.expiredDate)
                    .format('YYYY-MM-DD HH:mm')
          ) +
          '. ' +
          this.translate.instant(
            'Please renew your subscription to continue using premium lessons.'
          ),
        timer: 10000,
        allowOutsideClick: true,
        showCancelButton: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {});
    }
  }

  async initAppInsight(user: User) {
    // if (this.analyticsService.analytics) {
    //   this.analyticsService.analytics.setUserId(user.uid);
    //   Sentry.configureScope((scope) => {
    //     scope.setUser({ id: user.uid });
    //   });
    // }
    Sentry.configureScope((scope) => {
      scope.setUser({ id: user.uid });
    });
  }

  async authenticationNext() {
    try {
      this.isAuthenticationCallback = false;
      this.receivedFirstCallbackFromFirebase = true;
      this.observerAuthentication.next(true);
      await this.setCurrentLearningLanguage();
      this.generateCategoryMap();
      this.observerData.next(this.schoolData.data);

      this.updateStorage();
      this.ngZone.run(() => {
        this.preventOtherAccess();
        if (!this.checkToContinuePendingPurchase()) {
          const urlsToCheck = [
            'auth/login',
            'auth/register',
            'auth/reset-password',
          ];
          if (urlsToCheck.some((url) => this.router.url.includes(url))) {
            this.router.navigate([
              this.mobileAndTabletcheck() ? 'library' : '/',
            ]);
          }
        }
        // location.reload()
      });
    } catch (e) {
      console.log(e);
      this.ngZone.run(() => {
        this.router.navigate(['login']);
      });
    }
  }

  async authenticationCallback(user) {
    if (!user) {
      this.handleNoUserReturn();
      return;
    }

    this.userData = user;
    console.log(this.userData.uid);
    this.initAppInsight(user);
    this.isPasswordUser = true;
    // let userData = await this.kvService.read(user.email, 'users');
    Swal.close();
    // If not already callback
    if (!this.isAuthenticationCallback) {
      if (!user.type) {
        this.handleNewUser(user);
      } else {
        this.handleExistUser(user);
      }
    }
  }

  updateStorage() {
    LocalIndexedDBStorageService.setItem(
      'user',
      this.compressUserData(this.userData)
    );
    LocalIndexedDBStorageService.setItem(
      'school_' + this.schoolData.uid,
      this.compressUserData(this.schoolData)
    );
    localStorage.setItem(
      'youtube_api_key',
      this.userData.setting.youtubeApiKey
    );
  }

  isDefaultData() {
    return this.userData && !this.userData.schools;
  }

  getDeviceId(): number {
    let deviceId = localStorage.getItem('device_id');
    if (!deviceId || deviceId == 'undefined') {
      deviceId = moment().format('X');
      localStorage.setItem('device_id', deviceId);
    }
    return parseInt(deviceId);
  }

  preventOtherAccess() {
    // let accessKey = localStorage.getItem('access_key');
    // if (accessKey != '123456') {
    //   this.user = null;
    //   this.userData = null;
    //   localStorage.setItem('user', null);
    //   firebase.auth().signOut().then(function () {
    //     console.log('out');
    //   }, function (error) {
    //     // An error happened.
    //     console.log(error);
    //   });
    //   this.SignOut();
    // }
  }

  isLocalAccessTokenExpired() {
    const tokenExpiry = Number(localStorage.getItem('access_token_expiry'));
    console.log(
      'Expires at: ' + moment(tokenExpiry).format('YYYY-MM-DD HH:mm:ss')
    );
    // return true if tokenExpiry is expired in 5 mins
    const isExpired = tokenExpiry < Date.now() + 5 * 60 * 1000;
    return isExpired;
  }

  storeLocalAccessToken(accessToken) {
    this.accessToken = accessToken;
    localStorage.setItem('access_token', accessToken);
    const tokenExpiry = Date.now() + 7 * 60 * 1000 + '';
    console.log(
      'Expires at: ' + moment(tokenExpiry).format('YYYY-MM-DD HH:mm:ss')
    );
    localStorage.setItem('access_token_expiry', tokenExpiry);
  }

  //Set user
  SetUserData(user) {
    // return userData;
  }

  // Make sure all data url upload to firebase storage and get the url
  async prePushUserDataChanges() {
    const playItemPromises = this.schoolData.data.lessons.map(
      async (playItem) => {
        const yItemPromises = playItem.ylist.map(async (yItem) => {
          if (
            yItem.customThumbnailUrl &&
            !yItem.customThumbnailUrl.includes('https://')
          ) {
            const customThumbnailUrl = await this.asyncUploadDataURL(
              yItem.customThumbnailUrl,
              'images',
              'jpg'
            );
            console.log(
              'Found unuploaded customThumbnailURL in : ' + playItem.name
            );
            yItem.customThumbnailUrl = customThumbnailUrl;
          }
        });
        await Promise.all(yItemPromises);
      }
    );

    const avatarPromises = this.schoolData.questionerAvatars.map(
      async (avatar) => {
        if (avatar.jpgURL && !avatar.jpgURL.includes('https://')) {
          const jpgURL = await this.asyncUploadDataURL(
            avatar.jpgURL,
            'images',
            'jpg'
          );
          console.log('Found unuploaded questionerAvatar : ' + avatar.name);
          avatar.jpgURL = jpgURL;
        }
      }
    );
    await Promise.all(avatarPromises);

    const audioPromises = this.schoolData.questionerAudioQuestions.map(
      async (audio) => {
        if (audio.audioURL && !audio.audioURL.includes('https://')) {
          const audioURL = await this.asyncUploadDataURL(
            audio.audioURL,
            'images',
            'jpg'
          );
          console.log(
            'Found unuploaded questionerAudioQuestion : ' + audio.name
          );
          audio.audioURL = audioURL;
        }
      }
    );
    await Promise.all(audioPromises);

    await Promise.all(playItemPromises);
  }

  canCloneCurrentSchool() {
    let uid = this.userData.uid;
    if (uid == 'c3BlYWt0b3BsYXl2aWRlb0BnbWFpbC5jb20=') {
      uid = 'rbbxGM8Tp5X6afuMg4dSboDUmP33';
    }
    return this.schoolData.uid != this.userData.uid;
  }

  async cloneCurrentSchool() {
    let clonedSchoolData = cloneDeep(this.clonedSchoolData);
    clonedSchoolData.uid = this.userData.uid;

    const compressedSchoolData = this.compressUserData(clonedSchoolData);
    await this.r2Service.uploadFile(
      compressedSchoolData,
      'lessons/' + this.userData.uid + '/data.json'
    );
    await this.PushUserDataChanges();
    await this.setSchoolData(this.userData.uid);
    await this.setCurrentLearningLanguage();
    // this.reloadPage();
  }

  async PushUserDataChanges() {
    await this.prePushUserDataChanges();

    // userRef.delete().then(function () {
    // }).catch(function (error) {
    // });
    // return userRef.set(this.userData, {
    //   merge: true
    // });

    // upload school data to firebase storage
    const isMySchoolData = this.schoolData.uid == this.userData.uid;
    if (isMySchoolData) {
      const filename = 'lessons/' + this.userData.uid + '/data.json';
      const compressedSchoolData = this.compressUserData(this.schoolData);
      await this.r2Service.uploadFile(compressedSchoolData, filename);
      await LocalIndexedDBStorageService.removeItem(
        btoa(this.userData.myDataURL)
      );
      this.userData.myDataURL = filename;
      this.userData.lastUpdated = +new Date();
      await LocalIndexedDBStorageService.setItem(
        'lastUserDataUpdated',
        this.userData.lastUpdated
      );
      // const compressedUserData = this.compressUserData(this.userData);
      // LocalIndexedDBStorageService.setItem('user', compressedUserData);
      LocalIndexedDBStorageService.setItem(
        btoa(filename),
        compressedSchoolData
      );
      await this.setCurrentLearningLanguage();
      // return userRef.set({ userData: compressedUserData.join(',') });
      return this.kvService.write(
        this.userData.email,
        JSON.stringify(this.userData),
        'users'
      );
    }
  }

  compressUserData(userData) {
    const jsonPackedUserData = jsonpack.pack(userData);
    const pakoDeflatedUserData = pako.deflate(jsonPackedUserData);
    const hexString = Array.from(pakoDeflatedUserData, (byte) =>
      (byte as number).toString(16).padStart(2, '0')
    ).join('');
    // return Array.from(pakoDeflatedUserData);
    return hexString;
  }

  decompressUserData(userData) {
    try {
      const byteArray = new Uint8Array(
        userData.match(/[\da-f]{2}/gi).map((hex) => parseInt(hex, 16))
      );
      return jsonpack.unpack(pako.inflate(byteArray, { to: 'string' }));
      // return jsonpack.unpack(pako.inflate(userData.split(','), { to: 'string' }));
    } catch (e) {
      try {
        return jsonpack.unpack(pako.inflate(userData, { to: 'string' }));
      } catch (e1) {
        return null;
      }
    }
  }

  youtubeSignIn() {
    const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${encodeURIComponent(
      environment.googleClientId
    )}&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.readonly&redirect_uri=${encodeURIComponent(
      window.location.origin
    )}&prompt=consent&access_type=offline`;

    const width = 600;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    const options = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`;
    const popup = window.open(url, 'OAuth2 Consent', options);
    const checkPopupInterval = setInterval(async () => {
      if (popup.closed) {
        clearInterval(checkPopupInterval);
        return;
      }

      try {
        const popupUrl = new URL(popup.location.href);

        if (popupUrl.origin === window.location.origin) {
          const code = popupUrl.searchParams.get('code');
          const error = popupUrl.searchParams.get('error');

          if (code || error) {
            clearInterval(checkPopupInterval);
            popup.close();

            if (error) {
              console.error(`Error during YouTube sign-in: ${error}`);
            } else {
              await this.requestGoogleTokens(code);
            }
          }
        }
      } catch (error) {
        // Ignore DOMException error when trying to access popup.location.href
        // due to same-origin policy.
      }
    }, 100);
  }

  async requestGoogleTokens(authorizationCode) {
    const tokenEndpoint = 'https://oauth2.googleapis.com/token';
    const requestBody = new URLSearchParams();
    requestBody.append('grant_type', 'authorization_code');
    requestBody.append('client_id', environment.googleClientId);
    requestBody.append('client_secret', environment.googleClientSecret);
    requestBody.append('code', authorizationCode);
    requestBody.append('redirect_uri', location.origin);

    try {
      const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: requestBody,
      });

      if (!response.ok) {
        const responseBody = await response.json(); // Read the response body
        console.error('Response body:', responseBody); // Log the response body for debugging
      }
      const data = await response.json();
      this.storeLocalAccessToken(data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
      this.refreshToken = data.refresh_token;
    } catch (error) {
      console.error('Error obtaining tokens:', error);
      throw error;
    }
  }

  async refreshAccessToken(refreshToken) {
    const tokenEndpoint = 'https://oauth2.googleapis.com/token';

    const requestBody = new URLSearchParams();
    requestBody.append('grant_type', 'refresh_token');
    requestBody.append('client_id', environment.googleClientId);
    requestBody.append('client_secret', environment.googleClientSecret);
    requestBody.append('refresh_token', refreshToken);

    try {
      const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: requestBody,
      });

      if (!response.ok) {
        const responseBody = await response.json(); // Read the response body
        console.error('Response body:', responseBody); // Log the response body for debugging
        throw new Error(
          `Error refreshing access token: ${response.statusText}`
        );
      }

      const data = await response.json();
      this.storeLocalAccessToken(data.access_token);
    } catch (error) {
      console.error('Error refreshing access token:', error);
      throw error;
    }
    // using below to refresh the access token
    /*
    if (this.authService.refreshToken) {
      this.hasYoutubeAPIKey = true;
      setInterval(() => {
        if (this.authService.isLocalAccessTokenExpired) {
          this.authService.refreshAccessToken(this.authService.refreshToken);
        }
      }, 1 * 60 * 1000);
    }
    */
  }

  // Sign out
  async SignOut(redirectToLogin = true, redirectToResetPassword = false) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    try {
      this.modalService.dismissAll();
    } catch (e) {}
    localStorage.removeItem('session_token');
    this.showLoader = false;
    // localStorage.removeItem('device_id');
    // await LocalIndexedDBStorageService.removeItem('user');
    if (redirectToLogin) {
      this.router.navigate(['login']);
    }
    if (redirectToResetPassword) {
      this.router.navigate(['reset-password']);
    }
    setTimeout(() => {
      location.reload();
    });
  }

  isLoggedIn(): boolean {
    // const user = await LocalIndexedDBStorageService.getItem('user');
    // && user.emailVerified != false
    return this.userData && this.userData.email !== null;
  }

  progressToast(
    toasterService: ToastrService,
    message: string,
    title: string,
    progressFn: () => number
  ): ActiveToast<any> {
    let toast: ActiveToast<any> = toasterService.info(message, title, {
      extendedTimeOut: 100000000,
      timeOut: 100000000, // we need to set a timeout otherwise ngx-toastr won't display the progressBar
      enableHtml: true,
      tapToDismiss: false,
      progressBar: true,
      progressAnimation: 'increasing',
    });

    // A bit "hacky", the ngx-toastr progress bar only works with its own progress method, based on the specified timeout, and cannot be controlled manually
    // That's why we have to specify a big timeout in the options
    // We overload the default progress method to use the one we want, this way, we can have a manual control of the progress bar
    (<any>toast).toastRef.componentInstance.updateProgress = () => {
      (<any>toast).toastRef.componentInstance.width = progressFn();
    };
    return toast;
  }

  generateCategoryMap() {
    if (this.schoolData.data.categories) {
      for (let category of this.schoolData.data.categories) {
        this.categoryMap[category.cid] = category.name;
      }
    }
    this.categoryMap[10000] = this.translate.instant('Unset Category');
    this.categoryMapFlipped = Object.entries(this.categoryMap).reduce(
      (obj, [key, value]) => ({ ...obj, [value]: key }),
      {}
    );
  }
  getCategoryLabel(cid) {
    return this.categoryMap[cid];
  }

  addNewCategoryToLocal(newCategoryName: string) {
    let currentCategories = [...(this.schoolData.data.categories || [])];
    newCategoryName = newCategoryName.trim();
    let newCId = null;

    if (!newCategoryName) {
      return false;
    }

    let exists = currentCategories.find((item) => {
      return item.name == newCategoryName;
    });

    if (exists) {
      return false;
    } else {
      let maxCId =
        currentCategories.length > 0
          ? Math.max.apply(
              Math,
              currentCategories.map(function (o) {
                return o.cid;
              })
            )
          : 0;
      newCId = maxCId + 1;
      currentCategories.push({
        cid: newCId,
        name: newCategoryName,
        // lang: forceLang ? forceLang : newCatLang,
        disabled: false,
      });
    }

    this.schoolData.data.categories = currentCategories;
    return newCId;
  }

  addNewVideoToLocal(
    name: string,
    cid: number[],
    ylist: Video[],
    display?: any,
    ageGroup?: number
  ) {
    const videos = this.schoolData.data.lessons || [];
    let exists = videos.find((item) => {
      return (
        item.name == name &&
        item.ageGroup == ageGroup &&
        item.cid.some((c) => cid.includes(c))
      );
    });
    if (exists) {
      return false;
    } else {
      let maxVId =
        videos.length > 0
          ? Math.max.apply(
              Math,
              videos.map(function (o) {
                return o.vid;
              })
            )
          : 0;
      let newItem = {
        vid: maxVId + 1,
        name: name,
        cid: cid,
        ylist: ylist,
        // lang: forceLang ? forceLang : newVideoLang,
        ageGroup: ageGroup,
      };
      if (display) {
        newItem['display'] = display;
      }
      videos.push(newItem);
      this.schoolData.data.lessons = videos;
    }
  }

  deleteThumbnail(thumbnail: string) {
    if (
      thumbnail &&
      thumbnail.includes('firebasestorage') &&
      thumbnail.includes(this.userData.uid)
    ) {
      this.deleteFromURL(thumbnail);
    }
  }

  async deleteFromURL(url: string, cb = null) {
    if (url.includes('https://')) {
      url = url.replace(environment.r2BaseURL + '/', '');
    }
    // dont delete if not from current user
    if (!url.includes(this.userData.uid)) {
      return;
    }
    await this.r2Service.deleteFile(url);
    cb();
  }

  deleteFromUrls(urls: string[], cb = null) {
    urls = urls.filter((url) => {
      return url && url.includes('https://');
    });
    let deletedCount = 0;
    let totalFiles = urls.length;
    if (totalFiles == 0) {
      cb();
    } else {
      urls.forEach((url) => {
        this.deleteFromURL(url, () => {
          deletedCount++;
          if (deletedCount == totalFiles && cb) {
            cb();
          }
        });
      });
    }
  }

  getSystemWordsKeysValues() {
    const currentLanguageWords = [].concat(
      ...menu_words[this.currentLanguageCode]
    );
    let englishLanguageWords = [].concat(...menu_words['en']);
    let result = {};
    englishLanguageWords.forEach((key, i) => {
      result[key] = currentLanguageWords[i];
    });
    return result;
  }

  getDisplayWords(retrieveKey: number): string[] {
    const shapeKey: number = this.listDisplayTypeEnum.CircleSquareTriangle;
    const colorKey: number =
      this.listDisplayTypeEnum
        .redBlueGreenYellowOrangeBrownPinkGreyBlackWhitePurple;
    const leftRightKey: number = this.listDisplayTypeEnum.leftRight;
    const leftCenterRightKey: number = this.listDisplayTypeEnum.leftCenterRight;
    const topBottomKey: number = this.listDisplayTypeEnum.topBottom;
    const topCenterBottomKey: number = this.listDisplayTypeEnum.topCenterBottom;
    const smallBigKey: number = this.listDisplayTypeEnum.smallBig;
    const definition = menu_words[this.currentLanguageCode];
    let result = [];
    retrieveKey = Number(retrieveKey);
    let englishLanguageWords = [];
    switch (retrieveKey as number) {
      case colorKey:
        englishLanguageWords = menu_words['en'][0];
        result = definition[0];
        break;
      case leftRightKey:
        englishLanguageWords = menu_words['en'][1];
        result = definition[1];
        break;
      case leftCenterRightKey:
        englishLanguageWords = [
          menu_words['en'][1][0],
          menu_words['en'][2][0],
          menu_words['en'][1][1],
        ];
        result = [definition[1][0], definition[2][1], definition[1][1]];
        break;
      case topBottomKey:
        englishLanguageWords = [menu_words['en'][2][1], menu_words['en'][2][2]];
        result = [definition[2][1], definition[2][2]];
        break;
      case topCenterBottomKey:
        englishLanguageWords = [
          menu_words['en'][2][1],
          menu_words['en'][2][0],
          menu_words['en'][2][2],
        ];
        result = [definition[2][1], definition[2][0], definition[2][2]];
        break;
      case smallBigKey:
        englishLanguageWords = menu_words['en'][3];
        result = definition[3];
        break;
      case shapeKey:
        englishLanguageWords = menu_words['en'][4];
        result = definition[4];
        break;
    }
    // User custom wording
    let resultKeyValue = {};
    englishLanguageWords.forEach((value, index) => {
      resultKeyValue[value] = result[index];
    });
    let defaultSystemRecognizedWords =
      this.userData.setting?.defaultSystemRecognizedWords || {};
    if (
      !defaultSystemRecognizedWords.hasOwnProperty(this.currentLanguageCode)
    ) {
      defaultSystemRecognizedWords[this.currentLanguageCode] =
        this.getSystemWordsKeysValues();
    }
    for (let key in resultKeyValue) {
      resultKeyValue[key] =
        defaultSystemRecognizedWords[this.currentLanguageCode][key];
    }
    //end
    return Object.values(resultKeyValue);
  }

  getDisplayTypeLabels(retrieveKey: number): string {
    let displayTypes = this.getDisplayWords(retrieveKey).map((item) => {
      return item.replace(',', '-');
    });
    let displayWords = displayTypes.join(', ');
    return displayWords;
  }

  generateYListMatchWords(ylist: Video[], display: SelectionType) {
    let ylistTargetVideos: YlistTargetVideo[] = [];
    const displayWords = this.getDisplayWords(display);
    let index = 0;
    for (let youtubeVideo of ylist) {
      if (youtubeVideo.disabled) {
        continue;
      }
      let term = displayWords[index];
      ylistTargetVideos.push({ term: term, youtubeVideo: youtubeVideo });
      index++;
    }

    return ylistTargetVideos;
  }
  mobileAndTabletcheck() {
    let isMobile = false; //initiate as false
    // device detection
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      isMobile = true;
    }
    return isMobile;
  }
  // TODO: migrate to cloudflare worker and use paypal
  stripeCheckout(purchaseType) {
    // this.fns.functions.useFunctionsEmulator(
    //   "http://127.0.0.1:5001/speaknplay/us-central1/createStripeCheckout"
    // );
    // publishable key
    // const stripe = new window['Stripe'](environment.stripePublishableKey, {
    //   apiVersion: '2020-08-27',
    // });
    // const createStripeCheckout = this.fns.httpsCallable('createStripeCheckout');
    // createStripeCheckout({
    //   type: type,
    //   production: environment.production,
    //   ref: btoa(this.userData.uid + '||' + type),
    //   hostname: location.hostname,
    // })
    //   .toPromise()
    //   .then((response) => {
    //     // Swal.close();
    //     const sessionId = response.id;
    //     stripe.redirectToCheckout({ sessionId: sessionId });
    //   });

    const stripe = new window['Stripe'](environment.stripePublishableKey, {
      apiVersion: '2020-08-27',
    });
    const apiEndpoint = environment.apiEndpoint;
    const url = `${apiEndpoint}/create-stripe-checkout`;
    Swal.showLoading();
    this.http
      .post(url, {
        // purchaseType: purchaseType,
        // production: environment.production,
        // stripe has maximum 200 characters for ref
        ref: btoa(
          JSON.stringify({
            email: this.userData.email,
            type: purchaseType,
            production: environment.production,
            // hostname: location.hostname,
          })
        ),
        // hostname: location.hostname,
      })
      .subscribe({
        next: (apiResponse: APIResponse) => {
          Swal.close();
          const sessionId = apiResponse.data.id;
          stripe.redirectToCheckout({ sessionId: sessionId });
        },
        error: (apiResponse: APIResponse) => {
          Swal.close();
          this.toster.error(apiResponse.message);
        },
      });
  }

  updateTranslations() {
    // this.fns.functions.useFunctionsEmulator(
    //   'http://localhost:5001/speaknplay/us-central1/updateTranslations'
    // );
    // const updateTranslations = this.fns.httpsCallable('updateTranslations');
    // return updateTranslations({
    //   uid: this.userData.uid,
    // })
    //   .toPromise()
    //   .then((response) => {
    //     console.log(response);
    //   });
  }

  // TODO: migrate to cloudflare queue
  createQueueJob(type, data, cb) {
    // this.fns.functions.useFunctionsEmulator('http://localhost:5001/speaknplay/us-central1/createQueueJob');
    // if (
    //   type == QueueType.UpdateTran &&
    //   location.hostname.includes('localhost')
    // ) {
    //   // this.updateTranslations();
    // } else {
    //   const createQueueJob = this.fns.httpsCallable('createQueueJob');
    //   return createQueueJob({
    //     production: environment.production,
    //     uid: this.userData.uid,
    //     type: type,
    //     data: data,
    //   })
    //     .toPromise()
    //     .then((response) => {
    //       console.log(response);
    //       cb();
    //     });
    // }
    // var ref = firebase.database().ref('queue/tasks');
    // data['uid'] = this.user.uid;
    // data['type'] = type;
    // ref.push(data).then(() => {
    //   cb();
    // });
  }

  setTranslationLanguage(lang: string, isDefault: boolean = false) {
    if (isDefault) {
      this.translate.setDefaultLang(lang);
    }
    this.translate.use(lang);
  }

  reloadPage(target = null) {
    const goToUrl = target ? target : this.router.url;
    // this.router.navigateByUrl('reload', { skipLocationChange: true }).then(() => {
    //   this.router.navigate([goToUrl]);
    // });
    location.href = location.origin + goToUrl;
  }

  getOriginalYID(currentYID: string) {
    return currentYID.split('__')[0];
  }

  deleteResources(deletedItems: Lesson[], callback) {
    let deletingAudioURLs = [];
    for (let deletedItem of deletedItems) {
      for (let youtubeVideo of deletedItem.ylist) {
        if (youtubeVideo.questionAnswers) {
          for (let questionAnswer of youtubeVideo.questionAnswers) {
            let question = questionAnswer.question;
            let audioUrl = this.getAudioURL(question);
            if (audioUrl.includes('.mp3')) {
              deletingAudioURLs.push(audioUrl);
            }
          }
        }
      }
    }
    let totalDeletingCount = deletingAudioURLs.length;
    let deletedCount = 0;
    let cb = () => {
      deletedCount++;
      if (deletedCount == totalDeletingCount) {
        callback();
      }
    };
    if (deletingAudioURLs.length > 0) {
      for (let deletingAudioURL of deletingAudioURLs) {
        this.deleteFromURL(deletingAudioURL, cb);
      }
    } else {
      callback();
    }
  }

  timeOutAudioError = null;
  // Initialize an empty object to store audio elements and their states
  audioElements = {};
  playPauseClick(
    elementClass,
    questionerAudioQuestionId,
    onEndedCallback = null
  ) {
    let playPauseElement = document.querySelector('.' + elementClass);

    // Check if the audio element exists for this questionerAudioQuestionId
    if (!this.audioElements[questionerAudioQuestionId]) {
      // Create a new audio element and store it in the audioElements object
      let audio = new Audio();
      audio.autoplay = false;
      audio.id = 'audio_' + questionerAudioQuestionId;
      this.audioElements[questionerAudioQuestionId] = {
        audio: audio,
        isPlaying: false,
      };
    }

    let audioData = this.audioElements[questionerAudioQuestionId];
    let audio = audioData.audio;

    if (audioData.isPlaying) {
      audioData.isPlaying = false;
      playPauseElement.classList.remove('pause');
      playPauseElement.classList.add('play');
      audio.pause();
    } else {
      audioData.isPlaying = true;
      playPauseElement.classList.remove('play');
      playPauseElement.classList.add('pause');
      const questionerAudioQuestionURL =
        this.schoolData.questionerAudioQuestions.find(
          (x) => x.id == questionerAudioQuestionId
        ).audioURL;
      audio.src = questionerAudioQuestionURL;
      audio.onended = () => {
        audioData.isPlaying = false;
        playPauseElement.classList.remove('pause');
        playPauseElement.classList.add('play');
        if (onEndedCallback) {
          onEndedCallback();
        }
      };
      audio.addEventListener('error', () => {
        audioData.isPlaying = false;
        playPauseElement.classList.remove('pause');
        playPauseElement.classList.add('play');
        clearTimeout(this.timeOutAudioError);
        this.timeOutAudioError = setTimeout(() => {
          let errorMessage = 'Error playing audio';
          switch (audio.error.code) {
            case audio.error.MEDIA_ERR_ABORTED:
              errorMessage += ': Playback was aborted.';
              break;
            case audio.error.MEDIA_ERR_NETWORK:
              errorMessage += ': Network error occurred.';
              break;
            case audio.error.MEDIA_ERR_DECODE:
              errorMessage += ': Decoding error occurred.';
              break;
            case audio.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
              errorMessage += this.translate.instant(
                ': Media source not supported, it could be because the file is no longer available.'
              );
              break;
            default:
              errorMessage += ': An unknown error occurred.';
          }
          alert(errorMessage);
        }, 500);
      });
      audio.play();
    }
  }

  async uploadDataURL(base64: string, folder, extension, deleteURL, callback) {
    let uploadFunc = async () => {
      let lastImgPath =
        folder + '/' + this.userData.uid + '/' + Date.now() + '.' + extension;
      // var metadata = {
      //   cacheControl: 'public, max-age=31536000', // Cache for one year
      // };
      const fileURL = await this.r2Service.uploadFile(base64, lastImgPath, 1);
      // var uploadLastImageTask = storageRef
      //   .child(folder + '/' + lastImgPath)
      //   .putString(base64, 'data_url', metadata);
      // uploadLastImageTask.on(
      //   firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      //   (snapshot) => {
      //     // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      //   },
      //   (error) => {
      //     console.log('error: ', error);
      //   },
      //   () => {
      //     // Upload completed successfully, now we can get the download URL
      //     uploadLastImageTask.snapshot.ref
      //       .getDownloadURL()
      //       .then((downloadURL) => {
      //         callback(downloadURL);
      //       });
      //   }
      // );
      callback(fileURL);
    };
    if (deleteURL) {
      await this.deleteFromURL(deleteURL, uploadFunc);
    } else {
      await uploadFunc();
    }
  }

  async asyncUploadDataURL(base64: string, folder, extension): Promise<string> {
    return new Promise(async (resolve, reject) => {
      // const storageRef = firebase.storage().ref();
      const path =
        folder + '/' + this.userData.uid + '/' + Date.now() + '.' + extension;
      const fileURL = await this.r2Service.uploadFile(base64, path, 1);
      return resolve(fileURL);
    });
  }

  toastSubmit() {
    let progressionToast = this.toster.warning(
      this.translate.instant('Submitting...'),
      '',
      {
        extendedTimeOut: 100000000,
        timeOut: 100000000, // The progress bar is displayed only if there is a timeout, so I set a very large timeout to close it manually
        enableHtml: true,
        tapToDismiss: false,
        progressBar: true,
        progressAnimation: 'increasing',
      }
    );
    return progressionToast;
  }
  toastSaved(saveButtonRef?: { label: string }) {
    if (saveButtonRef) {
      saveButtonRef.label = 'Saved';
    }

    this.toster.clear();
    this.toster.success(this.translate.instant('Done'), '', {
      extendedTimeOut: 3000,
      timeOut: 5000,
      enableHtml: true,
      tapToDismiss: false,
    });
    setTimeout(() => {
      if (saveButtonRef) {
        saveButtonRef.label = 'Save';
      }
    }, 5000);
  }

  swagConfirm(
    title,
    text,
    confirmButtonText,
    cancelButtonText,
    confirmedCallback,
    cancelCallback
  ) {
    Swal.fire({
      title: title ? this.translate.instant(title) : null,
      text: text ? this.translate.instant(text) : null,
      type: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText
        ? this.translate.instant(confirmButtonText)
        : null,
      cancelButtonText: cancelButtonText
        ? this.translate.instant(cancelButtonText)
        : null,
    }).then((result) => {
      if (result.value) {
        confirmedCallback();
      } else {
        cancelCallback();
      }
    });
  }

  isNotTeacher() {
    return (
      !this.userData ||
      this.userData.type != 'teacher' ||
      this.userData.uid != this.schoolData.uid
    );
  }
  teacherOnlyAlert(redirectRouterLink = null) {
    if (this.isNotTeacher()) {
      Swal.fire({
        title: this.translate.instant('No permission'),
        text: this.translate.instant('Only school owner can access this page'),
        type: 'warning',
      }).then(() => {
        if (redirectRouterLink) {
          this.router.navigate(redirectRouterLink);
        }
      });
      return true;
    }
    return false;
  }
  goToTeacherRoute(routerLink) {
    if (!this.teacherOnlyAlert()) {
      this.router.navigate(routerLink);
    }
  }

  async encryptText(input: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    const byteArray = new Uint8Array(hashBuffer);
    let binaryString = '';
    for (let i = 0; i < byteArray.byteLength; i++) {
      binaryString += String.fromCharCode(byteArray[i]);
    }
    const base64Hash = btoa(binaryString); // base64 encoding
    return base64Hash.substring(0, 10); // Truncate to 16 characters
  }

  playAnyKey(key: string) {
    let audioKey = null;
    if (
      this.schoolData.uid == this.defaultPremiumSchool.schoolId &&
      this.userData.uid != this.defaultPremiumSchool.schoolId
    ) {
      const translationAudio = this.translation_data.audio;
      const translationName = this.translation_data.name;
      const translationAnswer = this.translation_data.answer;
      if (translationAudio[key]) {
        audioKey = translationAudio[key];
      } else {
        // translationName is object with property key and property value, the property value is the key, so we need to get the key from the property value
        let keyFromValue = Object.keys(translationName).find(
          (k) => translationName[k] === key
        );
        if (keyFromValue && translationAudio[keyFromValue]) {
          audioKey = translationAudio[keyFromValue];
        } else {
          // translationAnswer is object with property key and property value, the property value is the key, so we need to get the key from the property value
          keyFromValue = Object.keys(translationAnswer).find(
            (k) => translationAnswer[k] === key
          );
          if (keyFromValue && translationAudio[keyFromValue]) {
            audioKey = translationAudio[keyFromValue];
          }
        }
      }
    }
    if (audioKey) {
      const audioURL =
        environment.r2BaseURL +
        '/lessons/' +
        this.defaultPremiumSchool.schoolId +
        '/' +
        (this.currentDialectCode == 'vi-VNS'
          ? 'vi-VN'
          : this.currentDialectCode) +
        '/' +
        audioKey;
      let sound: any = document.getElementById('audio_anykey');
      sound.src = audioURL;
      sound.play();
    }
  }
}

window['decompressUserData'] = AuthService.prototype.decompressUserData;
window['encryptText'] = AuthService.prototype.encryptText;
