import { NavService } from '../../services/nav.service';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// import 'firebase/storage';
// import 'firebase/firestore'
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash-es';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { DragulaService } from 'ng2-dragula';
import {
  Category,
  Lesson,
  YoutubeSearchResult,
  Video,
  InputPreviewVideo,
  SelectionType,
  LessonAgeGroups,
  QueueType,
} from 'src/app/services/constant.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { YoutubeService } from 'src/app/services/youtube.service';
declare var require: any;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-lesson',
  templateUrl: './edit-lesson.component.html',
  styleUrls: ['./edit-lesson.component.scss'],
})
export class EditLessonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() usedVideo: YoutubeSearchResult = null;
  @Input() type: 'add' | 'edit' = null;
  @Input() editId: number = null;
  @Input() isCopy: boolean = false;
  @Output() eventChange = new EventEmitter();
  public modal: NgbModalRef;
  public mediaForm: FormGroup;
  public sidebaron: any;
  public categories: Category[];
  public lessonAgeGroups: number[];
  public videos: Lesson[];
  public ylist: Video[] = [];
  public selectedLanguageCode: string;

  public previewVideo: Video;
  public previewVideoIndex: number = null;

  public modalSearchVisible: boolean = true;

  public selectedItem: Lesson;
  public ListPlayTypeEnum = SelectionType;
  // public originalLangCodeBeforeCopy: string = null;
  public currentSelectedCategoryIds: number[] = [];
  public deletedCustomThumbnailUrls: string[] = [];
  public saveButtonRef = { label: 'Save' };
  public isUpdating = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public authService: AuthService,
    private modalService: NgbModal,
    private dragulaService: DragulaService,
    private cd: ChangeDetectorRef,
    public navService: NavService,
    public translate: TranslateService,
    private youtubeService: YoutubeService
  ) {}

  ngOnChanges(changes) {
    console.log(changes);
  }

  intervalWaitToDestroy = null;
  maxWaitToDestroy = 20;
  ngOnDestroy(): void {
    this.intervalWaitToDestroy = setInterval(() => {
      this.maxWaitToDestroy--;
      if (this.maxWaitToDestroy <= 0) {
        this.isUpdating = false;
      }
      this.checkToDestroy();
    }, 1000);
  }

  checkToDestroy() {
    if (!this.isUpdating) {
      clearInterval(this.intervalWaitToDestroy);
      console.log('destroy');
      this.ylist = [];
      this.currentSelectedCategoryIds = [];
      this.usedVideo = null;
      this.selectedItem = null;
    }
  }

  ngOnInit() {
    if (!this.authService.teacherOnlyAlert(['/library'])) {
      this.lessonAgeGroups = Object.assign([], LessonAgeGroups);
      this.categories = cloneDeep(this.authService.schoolData.data.categories);
      this.videos = cloneDeep(this.authService.schoolData.data.lessons);
      // Add new
      if (this.type == 'add') {
        this.mediaForm = this.fb.group({
          name: ['', Validators.required],
          cid: [[], Validators.required],
          display: ['', Validators.required],
          ageGroup: ['', Validators.required],
        });
        this.selectLesson(this.usedVideo);
      } else {
        // Edit
        if (this.editId) {
          this.initEdit(this.editId);
        } else {
          this.route.params.subscribe((params) => {
            console.log(params['id']);
            this.initEdit(params['id']);
          });
        }
      }
    }

    // const existGroup = this.dragulaService.find('HANDLES');
    // if (!existGroup) {
    //   this.dragulaService.createGroup("HANDLES", {
    //     moves: (el, container, handle) => {
    //       return handle.className === 'handle';
    //     }
    //   });
    //   this.dragulaService.createGroup("HANDLES", {
    //     removeOnSpill: true
    //   });
    // }
  }

  handleThumbnailChanged(event: {
    type: string;
    url: string;
    origin?: string;
  }) {
    if (event.type == 'DELETE') {
      if (
        event.url &&
        !event.url.includes('data:image') &&
        this.deletedCustomThumbnailUrls.indexOf(event.url) == -1
      ) {
        this.deletedCustomThumbnailUrls.push(event.url);
      }
    }
  }

  initEdit(
    id: number,
    video: Lesson = null,
    redirectedFromAddDueToExistingItemName: boolean = false
  ) {
    if (!video) {
      video = this.videos.find((item) => {
        return item.vid == id;
      });
    }
    if (video) {
      video.cid = Array.isArray(video.cid)
        ? video.cid.map((c) => Number(c))
        : [Number(video.cid)];
      this.currentSelectedCategoryIds = video.cid;
      this.selectedItem = cloneDeep(video);
      this.selectedItem.cid = this.selectedItem.cid.map((c) => Number(c));
      this.selectedItem.cid = video.cid.filter((c) => c != 10000);
      if (this.usedVideo) {
        if (this.selectedItem.ylist.length >= 12) {
          this.eventChange.next(
            this.translate.instant('This item already have maximum 12 videos')
          );
          return;
        } else {
          // already pushed
          const existYlistItem = this.selectedItem.ylist.find(
            (item) => item.yid == this.usedVideo.id
          );
          if (existYlistItem) {
            const currentTime = new Date().getTime();
            this.usedVideo.id = this.usedVideo.id + '__' + currentTime;
          }
          if (!redirectedFromAddDueToExistingItemName || !existYlistItem) {
            this.selectedItem.ylist.push(this.formNewYVideo(this.usedVideo));
          }

          this.ylist = this.selectedItem.ylist;
          this.createUpdateForm();
        }
      } else {
        this.ylist = this.selectedItem.ylist;
        this.createUpdateForm();
      }
    } else {
      this.toastr.error('Not found media');
      this.router.navigate(['/library']);
    }
  }

  createUpdateForm() {
    this.mediaForm = this.fb.group({
      name: [this.selectedItem.name, Validators.required],
      cid: [this.selectedItem.cid, Validators.required],
      display: [this.selectedItem.display || '', Validators.required],
      ageGroup: [this.selectedItem.ageGroup || '', Validators.required],
    });
  }

  add(value: Lesson) {
    const sameAgeGroupVideos = this.videos.filter(
      (video) => video.ageGroup == value.ageGroup
    );
    let existLesson = sameAgeGroupVideos.find((item) => {
      // when comparing category ids, its already include lang check
      let isSameName =
        item.name.toLowerCase().includes(value.name.toLowerCase()) ||
        value.name.toLowerCase().includes(item.name.toLowerCase());
      let existInSameCategory = item.cid.some((c) => {
        return value.cid.includes(c);
      });
      return (
        isSameName && existInSameCategory && item.ageGroup == value.ageGroup
      );
    });
    if (existLesson) {
      const confirmedCb = () => {
        this.type = 'edit';
        this.editId = existLesson.vid;
        this.isCopy = false;
        this.initEdit(this.editId, existLesson, true);
      };
      this.authService.swagConfirm(
        'Duplicated item by name and category',
        'Do you want add this video to the exist item?',
        'Yes',
        'No',
        confirmedCb,
        () => {}
      );
      // this.toastr.error('Duplicated name, category, and language. You will be switched to edit form');
    } else {
      this.toastr.clear();
      this.isUpdating = true;
      let progressionToast = this.authService.toastSubmit();
      const submitForm = () => {
        let callback = () => {
          this.deletedCustomThumbnailUrls = [];
          this.authService.addNewVideoToLocal(
            value.name,
            value.cid,
            this.ylist,
            value.display,
            value.ageGroup
          );
          // this.authService.schoolData.data.lessons = this.videos;
          (<any>progressionToast).toastRef.componentInstance.updateProgress =
            () => {
              (<any>progressionToast).toastRef.componentInstance.width = 70;
            };
          this.authService.PushUserDataChanges().then(() => {
            if (!this.usedVideo && !this.isCopy) {
              this.router.navigate(['/library']);
            }

            (<any>progressionToast).toastRef.componentInstance.updateProgress =
              () => {
                (<any>progressionToast).toastRef.componentInstance.width = 100;
              };
            this.eventChange.next('');
            this.isUpdating = false;
            this.authService.toastSaved(this.saveButtonRef);
            if (this.isCopy) {
              location.href = location.href;
            }
            // return this.authService.createQueueJob(
            //   QueueType.UpdateTran,
            //   {},
            //   () => {
            //     console.log("QueueType.UpdateTran");
            //   }
            // );
          });
        };
        if (this.deletedCustomThumbnailUrls.length > 0) {
          this.authService.deleteFromUrls(
            this.deletedCustomThumbnailUrls,
            callback
          );
        } else {
          callback();
        }
      };
      submitForm();
    }
  }

  update(value: Lesson) {
    this.toastr.clear();
    this.isUpdating = true;
    this.authService.toastSubmit();
    let submitFunc = () => {
      let callback = () => {
        this.deletedCustomThumbnailUrls = [];
        this.videos.map((media) => {
          if (media.vid === this.selectedItem.vid) {
            media.name = value.name;
            media.cid = value.cid;
            media.ylist = this.ylist;
            media.ageGroup = value.ageGroup;
            if (value.display) {
              media.display = value.display;
            }
          }
          return media;
        });
        this.authService.schoolData.data.lessons = this.videos;
        this.authService.PushUserDataChanges().then(() => {
          this.isUpdating = false;
          this.authService.toastSaved(this.saveButtonRef);
          // setTimeout(() => {
          //   this.toastr.clear();
          // }, 1000);
          this.eventChange.next('');
          return this.authService.createQueueJob(
            QueueType.UpdateTran,
            {},
            () => {
              console.log('QueueType.UpdateTran');
            }
          );
        });
      };
      if (this.deletedCustomThumbnailUrls.length > 0) {
        this.authService.deleteFromUrls(
          this.deletedCustomThumbnailUrls,
          callback
        );
      } else {
        callback();
      }
    };
    submitFunc();
  }

  openLg(content) {
    this.modal = this.modalService.open(content, {
      size: 'xl',
      scrollable: true,
      windowClass: 'modal-fullscreen',
    });
  }

  openSm(content) {
    this.modal = this.modalService.open(content, { size: 'sm' });
  }

  formNewYVideo(youtubeSearchResult: YoutubeSearchResult) {
    let newYVideo = {
      yid: youtubeSearchResult.id,
      repeatTime: 0,
      title: youtubeSearchResult.title,
      channelId: youtubeSearchResult.channelTitle,
      channelTitle: youtubeSearchResult.channelTitle,
    };
    if (typeof youtubeSearchResult['startSeconds'] !== 'undefined') {
      newYVideo['startSeconds'] = youtubeSearchResult['startSeconds'];
    }
    if (typeof youtubeSearchResult['endSeconds'] !== 'undefined') {
      newYVideo['endSeconds'] = youtubeSearchResult['endSeconds'];
    }
    if (typeof youtubeSearchResult['questionAnswers'] !== 'undefined') {
      newYVideo['questionAnswers'] = youtubeSearchResult['questionAnswers'];
    }
    if (typeof youtubeSearchResult['durationSkipBarsStatus'] !== 'undefined') {
      newYVideo['durationSkipBarsStatus'] =
        youtubeSearchResult['durationSkipBarsStatus'];
    }
    if (typeof youtubeSearchResult['customThumbnailUrl'] !== 'undefined') {
      newYVideo['customThumbnailUrl'] =
        youtubeSearchResult['customThumbnailUrl'];
    }
    if (typeof youtubeSearchResult['mute'] !== 'undefined') {
      newYVideo['mute'] = youtubeSearchResult['mute'];
    }
    if (typeof youtubeSearchResult['volume'] !== 'undefined') {
      newYVideo['volume'] = youtubeSearchResult['volume'];
    }
    if (youtubeSearchResult.channelTitle.toLowerCase().includes('speaknplay')) {
      const originalDetails = this.extractOriginalVideoInfo(
        youtubeSearchResult.desc
      );
      newYVideo['originalTitle'] = originalDetails.videoTitle;
      newYVideo['originalChannelTitle'] = originalDetails.channelTitle;
      newYVideo['originalYid'] = originalDetails.videoId;
    }
    return newYVideo;
  }

  extractOriginalVideoInfo(description) {
    const videoTitlePattern = /"(.+?)" by/;
    const channelTitlePattern = /by "(.+?)",/;
    const videoLinkPattern = /at (.+)$/;

    const videoTitleMatch = description.match(videoTitlePattern);
    const channelTitleMatch = description.match(channelTitlePattern);
    const videoLinkMatch = description.match(videoLinkPattern);

    if (videoTitleMatch && channelTitleMatch && videoLinkMatch) {
      const videoTitle = videoTitleMatch[1];
      const channelTitle = channelTitleMatch[1];
      const videoLink = videoLinkMatch[1];

      const videoIdPattern = /v=(.+)$/;
      const videoIdMatch = videoLink.match(videoIdPattern);

      const videoId = videoIdMatch ? videoIdMatch[1] : null;

      return {
        videoTitle: videoTitle,
        channelTitle: channelTitle,
        videoId: videoId,
      };
    } else {
      return null;
    }
  }

  selectLesson(youtubeSearchResult: YoutubeSearchResult) {
    console.log(youtubeSearchResult.id);
    this.ylist.push(this.formNewYVideo(youtubeSearchResult));

    // change array reference to trigger ngChange from menu-generator
    this.ylist = [...this.ylist];
    if (!this.usedVideo) {
      this.modal.close();
    }
  }

  toggleSearch() {
    let toggleSearchEl = document.getElementById('toggleSearchIcon');
    if (toggleSearchEl.classList.contains('fa-angle-up')) {
      toggleSearchEl.classList.remove('fa-angle-up');
      toggleSearchEl.classList.add('fa-angle-down');
      this.modalSearchVisible = false;
    } else {
      toggleSearchEl.classList.remove('fa-angle-down');
      toggleSearchEl.classList.add('fa-angle-up');
      this.modalSearchVisible = true;
    }
  }

  cancel() {
    if (!this.usedVideo && !this.isCopy) {
      this.router.navigate(['/library']);
    }
    this.eventChange.next('');
  }

  deleteYVideo(yid: string, index) {
    Swal.fire({
      title: this.translate.instant('Are you sure?'),
      // text: this.translate.instant("You won't be able to revert this!"),
      type: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',
      confirmButtonText: this.translate.instant('Yes, delete it!'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.value) {
        let youtubeVideo: Video = this.ylist.find((ylistVideo, ylistIndex) => {
          return ylistVideo.yid == yid && ylistIndex == index;
        });
        if (youtubeVideo) {
          if (
            youtubeVideo.customThumbnailUrl &&
            this.deletedCustomThumbnailUrls.indexOf(
              youtubeVideo.customThumbnailUrl
            ) == -1
          ) {
            this.deletedCustomThumbnailUrls.push(
              youtubeVideo.customThumbnailUrl
            );
          }
        }
        this.ylist = this.ylist.filter((youtubeVideo: Video, ylistIndex) => {
          return youtubeVideo.yid !== yid || index != ylistIndex;
        });
      }
    });
  }

  rowUpdate(key: string, newValue: any, yid: string, index) {
    this.ylist = this.ylist.map((youtubeVideo, ylistIndex) => {
      if (youtubeVideo.yid == yid && ylistIndex == index) {
        youtubeVideo[key] = newValue;
      }
      console.log(youtubeVideo);
      return youtubeVideo;
    });
  }

  playLesson(content, youtubeVideo: Video, ylistIndex) {
    this.previewVideo = youtubeVideo;
    this.previewVideoIndex = ylistIndex;
    this.modal = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'preview-video-modal',
    });
  }

  previewVideoSelectedChange($event: InputPreviewVideo) {
    if ($event) {
      let submitForm = () => {
        this.ylist = this.ylist.map((youtubeVideo, index) => {
          if (
            this.previewVideo.yid == youtubeVideo.yid &&
            this.previewVideoIndex == index
          ) {
            youtubeVideo = this.previewVideo;
          }
          return youtubeVideo;
        });

        if (this.type == 'add') {
          this.isUpdating = false;
          this.authService.toastSaved(this.saveButtonRef);
          this.modal.close();
        } else {
          this.update(this.mediaForm.value);
        }
      };
      if (typeof $event['startSeconds'] !== 'undefined') {
        this.previewVideo.startSeconds = $event['startSeconds'];
      }
      if (typeof $event['endSeconds'] !== 'undefined') {
        this.previewVideo.endSeconds = $event['endSeconds'];
      }
      if (typeof $event['durationSkipBarsStatus'] !== 'undefined') {
        this.previewVideo.durationSkipBarsStatus =
          $event['durationSkipBarsStatus'];
      }
      if (typeof $event['questionAnswers'] !== 'undefined') {
        this.previewVideo.questionAnswers = $event['questionAnswers'];
      }
      if (typeof $event['mute'] !== 'undefined') {
        this.previewVideo.mute = $event['mute'];
      }
      if (typeof $event['volume'] !== 'undefined') {
        this.previewVideo.volume = $event['volume'];
      }
      if (
        $event['customThumbnailUrl'] &&
        $event['customThumbnailUrl'].includes('data:image')
      ) {
        this.authService.uploadDataURL(
          $event['customThumbnailUrl'],
          'images',
          'jpg',
          null,
          (customThumbnailUrl) => {
            // trick to trigger ngChange from preview-video component to update the customThumbnailUrl from base64 to real url
            this.previewVideo = {
              ...this.previewVideo,
              customThumbnailUrl: customThumbnailUrl,
            };
            submitForm();
          }
        );
      } else {
        this.previewVideo.customThumbnailUrl =
          this.deletedCustomThumbnailUrls.includes($event['customThumbnailUrl'])
            ? null
            : $event['customThumbnailUrl'];
        submitForm();
      }
    } else {
      // close clicked
      if (this.modal) {
        this.modal.close();
      }
    }
  }

  selectedCategoryChange(cats: Category[]) {
    let currentAgeGroup = this.mediaForm.get('ageGroup').value;
    let cids = cats.map((c) => c.cid);
    // this.videos is the current user videos list, each video belongs to one or more categories
    let lessonsInSameCategory = this.videos.filter((media) => {
      return cids.some((c) => {
        media.cid = Array.isArray(media.cid)
          ? media.cid.map((c) => Number(c))
          : [Number(media.cid)];
        return media.cid.includes(c) && media.ageGroup == currentAgeGroup;
      });
    });

    let categoryCounts = {};

    lessonsInSameCategory.forEach((item) => {
      item.cid = Array.isArray(item.cid) ? item.cid : [item.cid]; // todo: remove this line
      item.cid.forEach((c) => {
        if (Number(c) !== 10000) {
          // Ignore category with c = 10000
          categoryCounts[c] = (categoryCounts[c] || 0) + 1;
        }
      });
    });

    let overfilledCategory = Object.entries(categoryCounts).find(
      ([key, value]) => Number(value) > 42
    );

    if (overfilledCategory) {
      // event.target.value = this.currentSelectedCategoryIds;
      this.toastr.error(
        this.translate.instant(
          'One category cannot have more than 42 lessons, category '
        ) +
          overfilledCategory[0] +
          this.translate.instant(' already has ') +
          overfilledCategory[1] +
          this.translate.instant(' items')
      );
    } else {
      this.currentSelectedCategoryIds = cids;
    }
  }

  selectedAgeGroupChange(ageGroup: number) {
    this.mediaForm.get('ageGroup').setValue(ageGroup);
  }

  // call when new category added or exist category edited
  categoryEventChange(cid: number) {
    if (cid) {
      this.categories = this.authService.schoolData.data.categories;
      this.currentSelectedCategoryIds = this.currentSelectedCategoryIds.filter(
        (c) => c !== cid && c !== 10000
      );
      this.mediaForm
        .get('cid')
        .setValue([...this.currentSelectedCategoryIds, cid]);
    }

    this.modal.close();
  }

  checkIfVideoID(value) {
    console.log(value);
    this.validVideoId(this.youtubeService.youtube_parser(value));
  }

  validVideoId(id) {
    var img = new Image();
    img.src = 'http://img.youtube.com/vi/' + id + '/mqdefault.jpg';
    img.onload = () => {
      if (img['width'] === 320) {
        this.ylist.push({ yid: id });
      }
    };
  }
}
