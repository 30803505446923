import { AuthService } from 'src/app/services/firebase/auth.service';
import {
  DurationSkipBarStatus,
  Lesson,
  QuestionAnswer,
  QuestionerAudioQuestion,
  QuestionerAvatar,
  Video,
} from './../../services/constant.service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
  OnChanges,
  SimpleChange,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import {
  InputPreviewVideo,
  YoutubeSearchResult,
} from 'src/app/services/constant.service';
import { NavService } from 'src/app/services/nav.service';
import { YoutubeService } from 'src/app/services/youtube.service';
import { cloneDeep, isBoolean, isEqual } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-preview-video',
  templateUrl: './preview-video.component.html',
  styleUrls: ['./preview-video.component.scss'],
})
export class PreviewVideoComponent implements OnInit, OnDestroy, OnChanges {
  @Output() selectedChange = new EventEmitter();
  @Output() thumbnailChanged = new EventEmitter<{
    type: string;
    url: string;
    origin?: string;
  }>();
  public player: YT.Player;
  @Input() input: InputPreviewVideo;
  @Input() inputIndex: number; // order of the video in the list
  @Input() inputLesson: Lesson;
  public localInput: InputPreviewVideo;
  @Input() isPreviewForAdding = false;
  @Input() showToolbar = true;
  @Input() readonly = false;

  @ViewChild('avatarShotForm') avatarShotForm: TemplateRef<any>;
  @ViewChild('formContent') formContent: TemplateRef<any>;
  public emptyStartlabel = 'Set video start time';
  public emptyEndLabel = 'Set video end time';
  public hasStartLabel = 'Video start';
  public hasEndLabel = 'Video end';
  public askQuestion = 'Ask question';
  public form: FormGroup;
  public questionAnswersForm: FormGroup;
  public modal: NgbModalRef;
  public intendedPauseInt = null;
  public isRecording = false;
  public isPlayingRecord = false;
  public hasRecordAudio = false;
  public questionerAudioQuestion: QuestionerAudioQuestion;
  public editQuestionAnswer: QuestionAnswer = null;
  public editQuestionAnswerIndex: number = null;
  public localQuestionnerQuestions: QuestionerAudioQuestion[] = [];
  public totalBars: number;
  public barWidth: number;
  public videoDuration: number;
  public currentPlayingBar: number = 0;
  public currentPlayTime: number = 0;
  public resetSelectedThumbnailFile: boolean = false;
  public localDurationSkipBarsStatus: DurationSkipBarStatus[] = [];

  constructor(
    private toastService: ToastrService,
    public youtubeService: YoutubeService,
    public navService: NavService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public authService: AuthService,
    public cd: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  private mouseDown: boolean = false;
  private lastToggledIndex: number = null;

  expectingDisabledBar = false;
  startingBarIndex = null;

  onMouseDown(index, event) {
    if (this.readonly) {
      return;
    }
    this.mouseDown = true;
    this.currentPlayingBar = index;
    // Toggle disable status
    this.expectingDisabledBar =
      !this.localDurationSkipBarsStatus[index].disabled;
    this.player.seekTo(this.videoDuration * (index / this.totalBars), true);
    this.localDurationSkipBarsStatus[index].disabled =
      this.expectingDisabledBar;
    this.startingBarIndex = index;
  }

  onMouseUp(index, event) {
    if (this.readonly) {
      return;
    }

    this.mouseDown = false;
    this.currentPlayingBar = index;
    if (this.currentPlayingBar && this.startingBarIndex !== null) {
      // console.log('Index: ' + index + ', isParentmouseout: ' + (isParentMouseOut ? 'true' : 'false') + ', startingbarindex: ' + this.startingBarIndex);
      this.player.seekTo(this.videoDuration * (index / this.totalBars), true);
      let loopStart =
        index >= this.startingBarIndex ? this.startingBarIndex : index;
      let loopEnd =
        index >= this.startingBarIndex ? index : this.startingBarIndex;
      if (loopStart != loopEnd) {
        for (let i = loopStart; i <= loopEnd; i++) {
          this.localDurationSkipBarsStatus[i].disabled =
            this.expectingDisabledBar;
        }
        setTimeout(() => {
          this.currentPlayingBar = 0;
          this.startingBarIndex = null;
        }, 1000);
      }
    }
  }

  onMouseMove(index, event) {
    if (this.readonly) {
      return;
    }
    if (this.mouseDown && index !== this.lastToggledIndex) {
      this.lastToggledIndex = index;
      this.currentPlayingBar = index;
      this.player.seekTo(this.videoDuration * (index / this.totalBars), true);
      this.localDurationSkipBarsStatus[index].disabled =
        this.expectingDisabledBar;
    }
  }

  onPanStart(event: HammerInput | any) {
    if (this.readonly) {
      return;
    }
    if (event.pointerType === 'mouse') {
      return;
    }
    const point = event.center;
    const targetElement = document.elementFromPoint(point.x, point.y);

    if (targetElement) {
      const targetIdx = this.getBarIndex(targetElement);
      if (targetIdx !== null) {
        this.onMouseDown(targetIdx, event);
      }
    }
  }

  onPanMove(event: HammerInput | any) {
    if (this.readonly) {
      return;
    }
    if (event.pointerType === 'mouse') {
      return;
    }
    const point = event.center;
    const targetElement = document.elementFromPoint(point.x, point.y);

    if (targetElement) {
      const targetIdx = this.getBarIndex(targetElement);
      if (targetIdx !== null) {
        this.onMouseMove(targetIdx, event);
      }
    }
  }

  onPanEnd(event: HammerInput | any) {
    if (this.readonly) {
      return;
    }
    if (event.pointerType === 'mouse') {
      return;
    }
    const point = event.center;
    const targetElement = document.elementFromPoint(point.x, point.y);

    if (targetElement) {
      const targetIdx = this.getBarIndex(targetElement);
      if (targetIdx !== null) {
        this.onMouseUp(targetIdx, event);
      }
    }
  }

  getBarIndex(element: Element): number | null {
    const classList = Array.from(element.classList);
    const barClass = classList.find((className) =>
      className.startsWith('bar-')
    );
    if (barClass) {
      return parseInt(barClass.replace('bar-', ''), 10);
    }
    return null;
  }

  handleThumbnailChanged(event: { type: string; url: string }) {
    if (
      this.localInput.customThumbnailUrl &&
      !this.localInput.customThumbnailUrl.includes('data:image')
    ) {
      this.thumbnailChanged.emit({
        type: 'DELETE',
        url: this.localInput.customThumbnailUrl,
        origin: 'preview-video',
      });
    }
    if (event.type == 'CROP') {
      this.localInput.customThumbnailUrl = event.url;
    }
  }

  updatePageData() {
    this.localQuestionnerQuestions = cloneDeep(
      this.authService.schoolData.questionerAudioQuestions
    );
    this.localQuestionnerQuestions.map((question) => {
      const avatarName = this.authService.getAvatarName(
        question.questionerAvatarId
      );
      question.name =
        question.name + (avatarName ? ' (' + avatarName + ')' : ' (No Avatar)');
    });
  }

  ngOnChanges(event) {
    // after hit save in preview video, the thumbnail will be uploaded from edit-media component, then it triggers the changes here
    // so we need to update the editCustomThumbnailUrl so that it uses the real url to prevent hitting save again will trigger reupload
    // also we need to set customThumbnailBase64 to null so that it will not be uploaded again
    if (
      this.localInput &&
      event.input &&
      event.input.currentValue &&
      event.input.currentValue.customThumbnailUrl
    ) {
      this.localInput.customThumbnailUrl =
        event.input.currentValue.customThumbnailUrl;
    }
  }

  ngOnInit(): void {
    this.localInput = cloneDeep(this.input);
    this.emptyStartlabel = this.translate.instant('Set video start time');
    this.emptyEndLabel = this.translate.instant('Set video end time');
    this.hasStartLabel = this.translate.instant('Video start');
    this.hasEndLabel = this.translate.instant('Video end');
    this.askQuestion = this.translate.instant('Ask question');
    this.updatePageData();
    // probably from youtube search
    if (this.localInput.id) {
      this.localInput.yid = this.localInput.id;
    }
    if (this.navService.screenWidth < 700) {
      this.emptyStartlabel = this.translate.instant('Start');
      this.emptyEndLabel = this.translate.instant('End');
      this.hasStartLabel = this.translate.instant('Start');
      this.hasEndLabel = this.translate.instant('End');
      this.askQuestion = this.translate.instant('Ask');
    }
    if (this.showToolbar) {
      if (!this.localInput.questionAnswers) {
        this.localInput.questionAnswers = [];
      }
      this.form = this.fb.group({
        question: ['', [Validators.required, Validators.minLength(1)]],
        answer: ['', [Validators.required, Validators.minLength(1)]],
      });
      this.questionAnswersForm = this.fb.group({
        questionAnswers: this.fb.array([] as FormGroup[]),
      });
      for (let questionAnswer of this.localInput.questionAnswers) {
        const questionAnswerGroup = this.fb.group({
          id: [questionAnswer.id, Validators.required],
          question: [questionAnswer.question, Validators.required],
          answer: [questionAnswer.answer, Validators.required],
          at: [questionAnswer.at, Validators.required],
        });

        this.questionAnswersFormArray.push(questionAnswerGroup);
      }
    }

    $(document).on('mouseup', (e) => {
      if (this.mouseDown) {
        console.log('mouseup: ' + this.currentPlayingBar);
        this.onMouseUp(this.currentPlayingBar, null);
      }
    });
  }

  get questionAnswersFormArray() {
    return this.questionAnswersForm.controls['questionAnswers'] as FormArray;
  }

  getQuestionAnswer(questionAnswer: any) {
    return questionAnswer as FormGroup;
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalPlayerMonitor);
    clearInterval(this.intPauseInt);
    $(document).off('mouseup');
  }

  openSm(content) {
    this.modal = this.modalService.open(content, { size: 'sm' });
  }

  validateForm(formValue) {
    if (!this.form.valid) {
      let errorMessages = [];
      if (!formValue.question || formValue.question.length == 0) {
        errorMessages.push(this.translate.instant('Add your audio question'));
      }
      if (!formValue.answer || formValue.answer.trim().length == 0) {
        errorMessages.push(this.translate.instant('Enter Expecting Answer'));
      }
      let errorMessage =
        this.translate.instant('Please') +
        errorMessages.join(this.translate.instant(' and '));
      this.navService.showInfo(errorMessage);
      return false;
    }
    return true;
  }

  ignorePlayQuestionAnswerId = null;

  add(formValue) {
    if (!this.validateForm(formValue)) {
      return;
    }
    this.pausePlayer();
    const currentTime = Number(this.player.getCurrentTime().toFixed(3));
    // this.cloneInput.questionAnswers.push({ id: this.cloneInput.questionAnswers.length + 1, question: formValue.question, answer: formValue.answer, at: currentTime });
    let maxId = 1;
    for (let formControl of this.questionAnswersFormArray.controls) {
      let tmpId = formControl.value.id;
      if (tmpId > maxId) {
        maxId = tmpId;
      }
    }
    const questionAnswer = this.fb.group({
      id: [maxId + 1, Validators.required],
      question: [formValue.question, Validators.required],
      answer: [formValue.answer, Validators.required],
      at: [currentTime, Validators.required],
    });

    this.questionAnswersFormArray.push(questionAnswer);
    let formArrayValue = this.questionAnswersFormArray.value;
    formArrayValue = formArrayValue.sort(
      (qa1: QuestionAnswer, qa2: QuestionAnswer) => {
        if (qa1.at == qa2.at) {
          return qa1.id - qa2.id;
        } else {
          return qa1.at - qa2.at;
        }
      }
    );
    this.ignorePlayQuestionAnswerId = questionAnswer.value.id;
    this.questionAnswersFormArray.patchValue(formArrayValue);
    this.resetAskQuestionForm();
    // paused, stop monitoring
    clearInterval(this.intervalPlayerMonitor);
    clearInterval(this.intPauseInt);
  }

  editQuestion(index: number) {
    this.editQuestionAnswerIndex = index;
    this.editQuestionAnswer =
      this.questionAnswersFormArray.controls[index].value;
    this.openQuestionAnswerForm(this.formContent);
    this.form.controls['question'].setValue(this.editQuestionAnswer.question);
    this.form.controls['answer'].setValue(this.editQuestionAnswer.answer);
    this.hasRecordAudio = true;
  }

  edit(formValue) {
    this.validateForm(formValue);
    this.pausePlayer();
    this.editQuestionAnswer.question = formValue.question;
    this.editQuestionAnswer.answer = formValue.answer;
    this.questionAnswersFormArray.controls[
      this.editQuestionAnswerIndex
    ].setValue(this.editQuestionAnswer);
    let formArrayValue = this.questionAnswersFormArray.value;
    formArrayValue = formArrayValue.sort(
      (qa1: QuestionAnswer, qa2: QuestionAnswer) => {
        if (qa1.at == qa2.at) {
          return qa1.id - qa2.id;
        } else {
          return qa1.at - qa2.at;
        }
      }
    );
    this.ignorePlayQuestionAnswerId = formValue.question;
    this.questionAnswersFormArray.patchValue(formArrayValue);
    this.resetAskQuestionForm();
    // paused, stop monitoring
    clearInterval(this.intervalPlayerMonitor);
    clearInterval(this.intPauseInt);
  }

  resetAskQuestionForm(closeModal = true) {
    this.form.reset();
    if (closeModal) {
      this.modal.close();
    }
    this.hasRecordAudio = false;
    this.editQuestionAnswerIndex = null;
    this.editQuestionAnswer = null;
  }

  test() {
    this.form.controls['answer'].setValue('test');
  }

  deleteQuestionAnswer(index: number) {
    this.questionAnswersFormArray.removeAt(index);
    // this.cloneInput.questionAnswers = this.cloneInput.questionAnswers.filter((questionAnswer: QuestionAnswer) => {
    //   return questionAnswer.id !== id;
    // });
  }

  pausePlayer() {
    this.player.pauseVideo();
    // let currentPlayTime = this.player.getCurrentTime();
    // this.intendedPauseInt = setInterval(() => {
    //   this.player.seekTo(currentPlayTime, true);
    // }, 10);
  }

  cancel() {
    if (this.modal) {
      this.modal.close();
    }
  }

  isDisabledQuestionAnswer(questionAnswerIndex: number) {
    if (
      !this.localDurationSkipBarsStatus ||
      this.localDurationSkipBarsStatus.length == 0
    ) {
      return false;
    }
    const questionAnswer =
      this.questionAnswersFormArray.controls[questionAnswerIndex].value;
    let isDisabled = false;
    for (let bar of this.localDurationSkipBarsStatus) {
      questionAnswer.at = Number(questionAnswer.at);
      if (
        bar.disabled &&
        questionAnswer.at >= bar.from &&
        questionAnswer.at <= bar.to
      ) {
        isDisabled = true;
        break;
      }
    }
    return isDisabled;
  }

  save(closeModal = false) {
    if (!this.isPreviewForAdding) {
      this.toastService.clear();
      let progressionToast = this.toastService.warning(
        this.translate.instant('Submitting...'),
        '',
        {
          extendedTimeOut: 100000000,
          timeOut: 100000000, // The progress bar is displayed only if there is a timeout, so I set a very large timeout to close it manually
          enableHtml: true,
          tapToDismiss: false,
          progressBar: true,
          progressAnimation: 'increasing',
        }
      );
    }
    // calculate start and end seconds
    let startSeconds = null;
    let endSeconds = this.player.getDuration();
    let trimDurationSkipBarsStatus: DurationSkipBarStatus[] = [];
    for (let bar of this.localDurationSkipBarsStatus) {
      if (!bar.disabled && startSeconds === null) {
        startSeconds = bar.from;
      }
      if (!bar.disabled) {
        endSeconds = bar.to;
      }
      if (bar.disabled) {
        trimDurationSkipBarsStatus.push(bar);
      }
    }
    this.localInput.durationSkipBarsStatus = trimDurationSkipBarsStatus;
    this.localInput.startSeconds = startSeconds;
    this.localInput.endSeconds = endSeconds;
    this.localInput.questionAnswers =
      this.questionAnswersFormArray.getRawValue();
    this.selectedChange.emit(this.localInput); // after emitting, immediately the data will be saved to firebase
    console.log(this.localInput);
    if (closeModal) {
      this.closeIt();
    } else {
      this.resetSelectedThumbnailFile = true;
      setTimeout(() => {
        this.resetSelectedThumbnailFile = false;
      }, 2000);
    }
  }

  closeIt() {
    this.selectedChange.emit(null);
  }

  setStartSeconds() {
    this.pausePlayer();
    const currentTime = Number(this.player.getCurrentTime().toFixed(3));
    if (
      !this.localInput.endSeconds ||
      currentTime < this.localInput.endSeconds
    ) {
      this.localInput.startSeconds = currentTime;
    } else {
      this.toastService.error(
        this.translate.instant('Start time must before end time')
      );
    }
  }

  setEndSeconds() {
    this.pausePlayer();
    const currentTime = Number(this.player.getCurrentTime().toFixed(3));
    if (
      !this.localInput.startSeconds ||
      currentTime > this.localInput.startSeconds
    ) {
      this.localInput.endSeconds = currentTime;
    } else {
      this.toastService.error(
        this.translate.instant('End time must after start time')
      );
    }
  }

  intervalPlayerMonitor = null;
  lastQuestions: { questionAnswers: QuestionAnswer[]; at: number } = {
    questionAnswers: [],
    at: 0,
  };
  intPauseInt = null;
  playerReady(playerEvent: YT.PlayerEvent) {
    this.lastQuestions = { questionAnswers: [], at: 0 };
    this.player = playerEvent.target;
    this.videoDuration = this.player.getDuration();
    this.totalBars =
      this.videoDuration > 100 ? 100 : Math.floor(this.videoDuration);
    for (let i = 0; i < this.totalBars; i++) {
      let existIndex = -1;
      if (this.localInput.durationSkipBarsStatus) {
        existIndex = this.localInput.durationSkipBarsStatus.findIndex(
          (value) => value.index == i
        );
      }
      if (this.localInput.durationSkipBarsStatus && existIndex > -1) {
        this.localDurationSkipBarsStatus[i] =
          this.localInput.durationSkipBarsStatus[existIndex];
      } else {
        this.localDurationSkipBarsStatus[i] = {
          index: i,
          disabled: false,
          from: parseFloat(
            (i * (this.videoDuration / this.totalBars)).toFixed(10)
          ),
          to: parseFloat(
            ((i + 1) * (this.videoDuration / this.totalBars)).toFixed(10)
          ),
        };
      }
    }
    this.barWidth = parseFloat((100 / this.totalBars).toFixed(10));
    // this.localInput.durationSkipBarsStatus = this.localInput.durationSkipBarsStatus ? this.localInput.durationSkipBarsStatus : Array(this.totalBars).fill(false).map((value, index) => {
    //   return {
    //     index: index,
    //     disabled: false,
    //     from: parseFloat((index * (this.videoDuration / this.totalBars)).toFixed(10)),
    //     to: parseFloat(((index + 1) * (this.videoDuration / this.totalBars)).toFixed(10))
    //   }
    // });
    if (
      typeof this.localInput.volume === 'number' &&
      typeof this.localInput.mute === 'boolean'
    ) {
      this.player.setVolume(this.localInput.volume);
      if (this.localInput.mute) {
        this.player.mute();
      } else {
        this.player.unMute();
      }
    } else {
      this.localInput.mute = this.player.isMuted();
      this.localInput.volume = this.player.getVolume();
    }

    console.log('player ready');
    // this.monitorPlayer();
    const mediaWrapper = document.getElementById('mediaWrapper');
    const modalBody = document.querySelector('.preview-video-modal #modalBody');
    const styles = getComputedStyle(modalBody);
    const containerWidth =
      modalBody.clientWidth -
      parseInt(styles.paddingLeft) -
      parseInt(styles.paddingRight);
    const playerHeight = Math.floor((containerWidth * 9) / 16);
    // const playerSize = this.authService.calculatePlayerSize(containerWidth, mediaWrapper.offsetHeight);
    document.querySelector('#mediaWrapper')['style'].width =
      containerWidth + 'px';
    document.querySelector('#mediaWrapper iframe')['style'].height =
      playerHeight + 'px';
    document.querySelector('#mediaWrapper iframe')['style'].width =
      containerWidth + 'px';
    mediaWrapper.classList.remove('invisible');
  }

  monitorPlayer() {
    clearInterval(this.intervalPlayerMonitor);
    clearInterval(this.intPauseInt);
    this.intervalPlayerMonitor = setInterval(() => {
      this.currentPlayingBar = Math.round(
        (this.player.getCurrentTime() / this.videoDuration) * this.totalBars
      );
      this.currentPlayTime = this.player.getCurrentTime();
      const muted = this.player.isMuted();
      this.localInput.mute = muted; //prevent blinking between yes and no
      this.localInput.volume = this.player.getVolume();
      if (
        this.localDurationSkipBarsStatus &&
        this.localDurationSkipBarsStatus.length > 0
      ) {
        for (let i = 0; i < this.localDurationSkipBarsStatus.length; i++) {
          const bar = this.localDurationSkipBarsStatus[i];
          if (
            bar.from <= this.currentPlayTime &&
            this.currentPlayTime < bar.to &&
            bar.disabled
          ) {
            this.currentPlayTime = bar.to;
            this.player.seekTo(this.currentPlayTime, true);
          }
        }
      }
      this.lastQuestions.questionAnswers =
        this.lastQuestions.questionAnswers.filter(
          (lastQuestionAnswer: QuestionAnswer) => {
            return Math.abs(this.currentPlayTime - lastQuestionAnswer.at) < 3;
          }
        );
      for (let i = 0; i < this.questionAnswersFormArray.controls.length; i++) {
        const questionAnswer = this.questionAnswersFormArray.controls[i];
        if (this.ignorePlayQuestionAnswerId == questionAnswer.value.id) {
          setTimeout(() => {
            this.ignorePlayQuestionAnswerId = null;
          }, 2000);
          continue;
        }
        let diff = Math.abs(questionAnswer.value.at - this.currentPlayTime);
        let isInLastQuestionAnswers = this.lastQuestions.questionAnswers.find(
          (lastQuestionAnswer: QuestionAnswer) => {
            return isEqual(questionAnswer.value, lastQuestionAnswer);
          }
        );
        if (diff <= 0.1 && !isInLastQuestionAnswers) {
          this.player.mute();
          this.intPauseInt = setInterval(() => {
            this.player.seekTo(this.currentPlayTime, true);
          }, 10);
          // document.querySelector('.question' + i).scrollIntoView({ behavior: 'smooth', block: 'center' });
          document
            .querySelector('.question' + i)
            .closest('tr').style.backgroundColor = '#b0c4de';
          this.playAudioQuestion(
            'question' + i,
            questionAnswer.value.question,
            () => {
              clearInterval(this.intPauseInt);
              this.player.unMute();
              this.player.playVideo();
              this.player.seekTo(this.currentPlayTime, true);
              document
                .querySelector('.question' + i)
                .closest('tr').style.backgroundColor = '';
            }
          );
          this.lastQuestions.questionAnswers.push(questionAnswer.value);
          this.lastQuestions.at = this.currentPlayTime;
        }
      }
    }, 100);
  }

  onPlayerStateChange(event) {
    if (event.data == YT.PlayerState.PLAYING) {
      // this.player.unMute();
      // clearInterval(this.intendedPauseInt);
      this.monitorPlayer();
    }
    if (event.data == YT.PlayerState.ENDED) {
      this.player.playVideo();
    }
    if (event.data == YT.PlayerState.BUFFERING) {
      clearInterval(this.intervalPlayerMonitor);
      clearInterval(this.intPauseInt);
    }
  }

  openQuestionAnswerForm(formContent, isAdding = false) {
    this.form.reset();
    this.player.pauseVideo();
    this.isPlayingRecord = false;
    this.openSm(formContent);
    if (isAdding) {
      this.hasRecordAudio = false;
      this.resetAskQuestionForm(false);
    }
  }

  explainAtInfo(at: any) {
    let formatTime = this.youtubeService.formatTime(at);
    return (
      this.translate.instant('At time ') +
      formatTime +
      this.translate.instant(' (hour:minute:second format)')
    );
  }
  questionerAudioQuestionModal = null;

  pickedQuestionerAudioQuestion(questionerAudioQuestion: any) {
    this.questionerAudioQuestion = questionerAudioQuestion;
  }

  questionnerAudioQuestionUpdate(
    questionerAudioQuestion: QuestionerAudioQuestion
  ) {
    this.updatePageData();
    if (questionerAudioQuestion) {
      this.form.controls['question'].setValue(questionerAudioQuestion.id);
    }
    if (this.questionerAudioQuestionModal) {
      this.questionerAudioQuestionModal.close();
    }
  }

  openQuestionerAudioQuestionModal(content) {
    this.questionerAudioQuestionModal = this.modalService.open(content, {
      size: 'md',
    });
  }

  playAudioQuestion(
    audioQuestionClassSelector,
    audioQuestionId,
    onEndedCallback = null
  ) {
    this.authService.playPauseClick(
      audioQuestionClassSelector,
      audioQuestionId,
      onEndedCallback
    );
  }
}
