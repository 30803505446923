<!-- footer start-->
<div class="container-fluid" style="min-height: 30px">
  <div class="row">
    <div class="col-md-6 footer-copyright">
      <p class="mb-0">
        {{ "Copyright" | translate }} 2023 © speaknplay
        {{ "All rights reserved" | translate }}.
      </p>
    </div>
    <div class="col-md-6">
      <p class="pull-right mb-0">
        Hand crafted & made with <i class="fa fa-heart"></i>
      </p>
    </div>
  </div>
</div>
