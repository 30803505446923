import { Voice, YoutubeSearchResult } from './constant.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base64 } from 'js-base64';
import { Observable, of, Subject, from } from 'rxjs';
import {
  map,
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AuthService } from './firebase/auth.service';
import * as moment from 'moment';
import { LocalIndexedDBStorageService } from './local-indexeddb-storage.service';
import { R2Service } from './r2.service';
import { environment } from 'src/environments/environment';
import { truncate } from 'lodash-es';
// import * as firebase from 'firebase/app';
// import 'firebase/storage'
// import 'firebase/firestore'

@Injectable({
  providedIn: 'root',
})
export class YoutubeService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private r2Service: R2Service
  ) {}

  public static getYoutubeApiKey() {
    return localStorage.getItem('youtube_api_key');
  }

  public static async getWithExpiry(key) {
    const itemStr = await LocalIndexedDBStorageService.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      await LocalIndexedDBStorageService.removeItem(key);
      return null;
    }
    return item.value;
  }

  public static async setWithExpiry(key, value, ttl) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    await LocalIndexedDBStorageService.setItem(key, JSON.stringify(item));
  }

  async getLessons(
    videoIds: string[],
    includeSnippet: boolean = false
  ): Promise<Observable<any>> {
    const ids = videoIds.join(',');
    let part = 'snippet,status,statistics,contentDetails';
    if (videoIds.length > 1 && !includeSnippet) {
      part = 'statistics,contentDetails';
    }
    let params: any = [
      `key=${YoutubeService.getYoutubeApiKey()}`,
      `part=${part}`,
      `id=${ids}`,
    ];
    if (this.authService.accessToken) {
      params.push(`access_token=` + this.authService.accessToken);
    }
    const cacheKey = Base64.encode('video_id_' + ids);
    let cacheSearch = await YoutubeService.getWithExpiry(cacheKey);
    if (cacheSearch) {
      return of(JSON.parse(cacheSearch));
    } else {
      const queryUrl =
        `https://www.googleapis.com/youtube/v3/videos?` + params.join('&');
      console.log(queryUrl);
      return this.http.get(queryUrl).pipe(
        map((response) => {
          cacheSearch = JSON.stringify(response);
          YoutubeService.setWithExpiry(cacheKey, cacheSearch, 86400 * 30);
          return <any>response;
        })
      );
    }
  }

  async getYoutubeChannels(channelIds: string[]): Promise<Observable<any>> {
    const ids = channelIds.join(',');
    let part = 'snippet,statistics,contentDetails';
    if (channelIds.length > 1) {
      part = 'statistics,contentDetails';
    }
    let params: any = [
      `key=${YoutubeService.getYoutubeApiKey()}`,

      `part=${part}`,
      `id=${ids}`,
    ];
    if (this.authService.accessToken) {
      params.push(`access_token=` + this.authService.accessToken);
    }
    const cacheKey = Base64.encode('channel_id_' + ids);
    let cacheSearch = await YoutubeService.getWithExpiry(cacheKey);
    if (cacheSearch) {
      return of(JSON.parse(cacheSearch));
    } else {
      const queryUrl =
        `https://www.googleapis.com/youtube/v3/channels?` + params.join('&');
      console.log(queryUrl);
      return this.http.get(queryUrl).pipe(
        map((response) => {
          cacheSearch = JSON.stringify(response);
          YoutubeService.setWithExpiry(cacheKey, cacheSearch, 86400 * 30);
          return <any>response;
        })
      );
    }
  }

  async getYoutubePlaylists(playlistIds: string[]): Promise<Observable<any>> {
    const ids = playlistIds.join(',');
    let part = 'snippet,contentDetails';
    if (playlistIds.length > 1) {
      part = 'contentDetails';
    }
    let params: any = [
      `key=${YoutubeService.getYoutubeApiKey()}`,

      `part=${part}`,
      `id=${ids}`,
    ];
    if (this.authService.accessToken) {
      params.push(`access_token=` + this.authService.accessToken);
    }
    const cacheKey = Base64.encode('playlist_id_' + ids);
    let cacheSearch = await YoutubeService.getWithExpiry(cacheKey);
    if (cacheSearch) {
      return of(JSON.parse(cacheSearch));
    } else {
      const queryUrl =
        `https://www.googleapis.com/youtube/v3/playlists?` + params.join('&');
      console.log(queryUrl);
      return this.http.get(queryUrl).pipe(
        map((response) => {
          cacheSearch = JSON.stringify(response);
          YoutubeService.setWithExpiry(cacheKey, cacheSearch, 86400 * 30);
          return <any>response;
        })
      );
    }
  }

  searchYoutube(observalText: Observable<string>): Observable<any> {
    return observalText.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap((term) => {
        const cacheKey = Base64.encode('suggest_' + term);
        return from(YoutubeService.getWithExpiry(cacheKey)).pipe(
          switchMap((cacheValue) => {
            if (cacheValue) {
              return of(JSON.parse(cacheValue));
            } else {
              return from(
                $.ajax({
                  url:
                    'https://suggestqueries.google.com/complete/search?hl=en&ds=yt&client=youtube&hjson=t&cp=1&q=' +
                    term +
                    '&key=' +
                    YoutubeService.getYoutubeApiKey() +
                    '&format=5&alt=json&callback=?',
                  dataType: 'jsonp',
                }).promise()
              ).map((data) => {
                console.log(data);
                let returnMap = <any>data[1].map((item) => {
                  return item[0];
                });
                YoutubeService.setWithExpiry(
                  cacheKey,
                  JSON.stringify(returnMap),
                  86400
                );
                return returnMap;
              });
            }
          })
        );
        // console.log(this);
        // const cacheValue = await YoutubeService.getWithExpiry(cacheKey);
      })
      // tap(() => this.searching = false)
    );
  }

  async youtubeAutoComplete(query: string): Promise<Observable<any>> {
    let queryUrl = `/complete/search?hl=en&ds=yt&client=youtube&hjson=t&cp=1&q=${query}&key=${YoutubeService.getYoutubeApiKey()}&format=5&alt=json&callback=?`;
    if (!window.location.host.includes('localhost')) {
      queryUrl = 'https://suggestqueries.google.com' + queryUrl;
    }
    return this.http
      .get(queryUrl, {
        headers: null,
        withCredentials: true,
      })
      .pipe(
        map((response) => {
          console.log(response);
          return <any>response[1].map((item) => {
            return item[0];
          });
        })
      );
  }

  async search(
    query: string,
    relatedToVideoId: string = '',
    channelId: string = '',
    playlistId: string = '',
    publishedAfter: string = '',
    publishedBefore: string = '',
    type: string = '',
    videoType: string = '',
    videoDuration: string = '',
    order: string = '',
    caption: boolean = false,
    nextPageToken: string = '',
    searchCount: number = 0
  ): Promise<Observable<any>> {
    let queryEndpoint = 'https://www.googleapis.com/youtube/v3/search';
    let params: any;
    let cacheKey: string;
    if (playlistId) {
      params = [
        `key=${YoutubeService.getYoutubeApiKey()}`,

        `part=snippet`,
        `maxResults=20`,
        `safeSearch=strict`,
        `playlistId=${playlistId}`,
      ];
      queryEndpoint = 'https://www.googleapis.com/youtube/v3/playlistItems';
      if (nextPageToken) {
        params.push(`pageToken=${nextPageToken}`);
      }
      cacheKey = Base64.encode(playlistId + '_' + nextPageToken);
    } else {
      params = [
        `key=${YoutubeService.getYoutubeApiKey()}`,

        `part=snippet`,
        `maxResults=20`,
        `safeSearch=strict`,
        // `onBehalfOfContentOwner=true`,
        // `access_token=`
        // `videoSyndicated=true`
      ];
      if (query) {
        params.push(`q=${query}`);
      }
      if (channelId) {
        params.push(`channelId=${channelId}`);
      }
      if (relatedToVideoId) {
        params.push(`relatedToVideoId=` + relatedToVideoId);
      }
      if (publishedAfter && publishedBefore) {
        params.push(`publishedAfter=` + publishedAfter);
        params.push(`publishedBefore=` + publishedBefore);
      }
      let isVideoType = false;
      if (videoType) {
        params.push(`videoType=` + videoType);
        params.push(`type=video`);
        isVideoType = true;
      } else if (type) {
        params.push(`type=` + type);
        isVideoType = type == 'video';
      } else {
        params.push(`type=video`);
        isVideoType = true;
      }
      if (videoDuration) {
        params.push(`videoDuration=` + videoDuration);
      }
      if (order) {
        params.push(`order=` + order);
      }
      if (nextPageToken) {
        params.push(`pageToken=${nextPageToken}`);
      }
      // if (this.accessToken) {
      //   params.push(`access_token=` + this.accessToken)
      //   // params.push(`onBehalfOfContentOwner=true`)
      // }
      let videoCaption = '';
      if (caption && isVideoType) {
        params.push(`videoCaption=closedCaption`);
        videoCaption = 'closedCaption';
      }
      cacheKey = Base64.encode(
        query +
          '_' +
          relatedToVideoId +
          '_' +
          channelId +
          '_' +
          publishedAfter +
          '_' +
          publishedBefore +
          '_' +
          type +
          '_' +
          videoType +
          '_' +
          videoDuration +
          '_' +
          order +
          '_' +
          videoCaption +
          '_' +
          nextPageToken +
          '_' +
          searchCount
      );
    }
    if (this.authService.accessToken) {
      params.push(`access_token=` + this.authService.accessToken);
    }
    if (!['playlist', 'channel'].includes(type)) {
      params.push(`videoEmbeddable=true`);
      params.push(`videoLicense=creativeCommon`);
      params.push(`videoSyndicated=true`);
    }
    let cacheSearch = await YoutubeService.getWithExpiry(cacheKey);
    if (cacheSearch) {
      return of(JSON.parse(cacheSearch));
    } else {
      const queryUrl = `${queryEndpoint}?` + params.join('&');
      console.log(queryUrl);
      return this.http.get(queryUrl).pipe(
        map((response) => {
          cacheSearch = JSON.stringify(response);
          YoutubeService.setWithExpiry(cacheKey, cacheSearch, 86400 * 7);
          return <any>response;
        })
      );
    }
  }

  calculateQuestionerAvatarSize(
    isSquareImage: boolean = false,
    isHomeModeBeginner = false
  ): { width: number; height: number; top: number; right: number } {
    let originHeight = 225;
    if (isSquareImage) {
      originHeight = 300;
    }
    let originWidth = 300;
    let newHeight = null;
    let newWidth = null;
    let positionRight = null;
    let positionTop = null;
    let windowInnerWidth = window.innerWidth;
    const playerHeight = document.getElementById('videoWrapper').offsetHeight;
    const playerWidth = Math.round((playerHeight * 16) / 9);
    let freeRightSpace = Math.floor((windowInnerWidth / 100) * 30);
    if (playerWidth < windowInnerWidth - 2) {
      // wide screen -> player width is smaller than window width
      freeRightSpace = Math.floor((windowInnerWidth / 100) * 30);
      let freeLeftRightSpace = Math.round(
        (Math.floor((windowInnerWidth / 100) * 70) -
          Math.floor((playerWidth / 100) * 70)) /
          2
      );
      freeRightSpace = freeLeftRightSpace + freeRightSpace;
    }
    newWidth = Math.floor((playerWidth / 100) * 26); // padding left 2%, right 2%;
    newHeight = (newWidth * originHeight) / originWidth;
    positionRight = Math.round((freeRightSpace - newWidth) / 2);
    positionTop = Math.round((playerHeight - newHeight) / 2);
    positionTop = positionTop > 0 ? positionTop : 0;
    positionRight = positionRight > 0 ? positionRight : 0;

    return {
      width: newWidth,
      height: newHeight,
      top: positionTop,
      right: positionRight,
    };
  }

  calculatePlayerSize(
    containerWidth: number,
    containerHeight: number
  ): { width: number; height: number } {
    let videoHeight, videoWidth: number;
    // if (isQuestionAnswerOn) {
    //   let gifTalkSize = this.calculateQuestionerAvatarSize();
    //   if (window.innerHeight < window.innerWidth) {
    //     containerHeight = containerHeight - gifTalkSize.height;
    //   } else {
    //     containerWidth = containerWidth - gifTalkSize.width;
    //   }
    // }

    for (let i = containerHeight; i > 0; i--) {
      const temp = Math.round((i * 16) / 9);
      if (temp <= containerWidth) {
        videoHeight = i;
        videoWidth = temp;
        break;
      }
    }
    return { width: videoWidth, height: videoHeight };
  }

  formatTime(seconds: any) {
    if (seconds && seconds > 0) {
      // if (seconds < 60) {
      //   if (seconds < 10) {
      //     seconds = '0' + seconds;
      //   }
      //   return '0:' + seconds;
      // } else {
      //   const mins = Math.floor(seconds / 60);
      //   let leftSeconds: any = seconds - (mins * 60);
      //   if (leftSeconds < 10) {
      //     leftSeconds = '0' + leftSeconds;
      //   }
      //   return mins + ':' + leftSeconds;
      // }
      const duration = moment.duration(seconds, 'seconds');
      const momentObj = moment.utc(duration.asMilliseconds());
      const formattedTime = momentObj.format('HH:mm:ss');
      // const duration = moment.duration(seconds, 'seconds');
      // const durationAsSeconds = duration.asSeconds();
      // const formattedTime = moment.duration(durationAsSeconds, 'seconds').humanize(true);

      return formattedTime;
    } else {
      return '';
    }
  }

  youtube_parser(url) {
    if (url.length == 11) {
      return url;
    }
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : '';
  }

  // https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=PLTxGZcMi2_K1Rxy9C12dFwr9QigjatH4y&key=AIzaSyClRdANCWg14aDZERY0kO20SYuabNu9Iqk
  async getPlaylistVideos(playlistId: string) {
    const cacheKey = 'suggestion_videos_' + playlistId;
    let cacheSuggestionVideos = await YoutubeService.getWithExpiry(cacheKey);
    if (cacheSuggestionVideos) {
      cacheSuggestionVideos = JSON.parse(cacheSuggestionVideos);
      return cacheSuggestionVideos;
    } else {
      // random key from array environment.youtubeAPIKeys
      const keys = environment.youtubeAPIKeys;
      const apiKey = keys[Math.floor(Math.random() * keys.length)];
      let searchResult: YoutubeSearchResult[] = [];
      const queryEndpoint =
        'https://www.googleapis.com/youtube/v3/playlistItems';
      const mainParams = [
        `key=${apiKey}`,
        `part=snippet`,
        `maxResults=50`,
        `playlistId=${playlistId}`,
      ];
      let nextPageToken: string | null = null;

      for (let i = 0; i < 20; i++) {
        let params = [...mainParams];
        if (nextPageToken) {
          params.push(`pageToken=${nextPageToken}`);
        }
        const queryUrl = `${queryEndpoint}?${params.join('&')}`;

        try {
          const response: any = await this.http.get(queryUrl).toPromise();
          const items = response.items;

          const videoDetails: YoutubeSearchResult[] = items.map(
            (videoDetail) => ({
              title: videoDetail.snippet.title,
              desc: truncate(videoDetail.snippet.description, {
                length: 60,
              }),
              id: videoDetail.snippet.resourceId.videoId,
              type: 'video',
              thumbnailUrl: videoDetail.snippet.thumbnails?.high?.url ?? '',
              publishedAt: videoDetail.snippet.publishedAt,
              channelId: videoDetail.snippet.channelId,
              channelTitle: videoDetail.snippet.channelTitle,
              playlistId: videoDetail.snippet.playlistId,
            })
          );

          if (videoDetails.length > 0) {
            searchResult = searchResult.concat(videoDetails);
          }

          nextPageToken = response.nextPageToken || null;
          if (!nextPageToken) {
            break;
          }
        } catch (error) {
          console.error('Error fetching playlist videos:', error);
          break;
        }
      }
      const filename =
        this.authService.userData.uid + '_suggestion_videos.json';
      await this.r2Service.uploadFile(JSON.stringify(searchResult), filename);
      YoutubeService.setWithExpiry(
        cacheKey,
        JSON.stringify(searchResult),
        60 * 60 * 1000
      );
      return searchResult;
    }
  }
}
