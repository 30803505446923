<div class="dark-body-only" id="outer-container">
  <!-- page-wrapper Start-->
  <div class="page-wrapper" id="canvas-bookmark">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper">
      <div class="page-body">
        <div
          class="container"
          style="background-color: #fff; margin-top: 80px; padding: 3rem 2rem"
        >
          <div class="col-xs-12">
            <h2>{{ "Privacy Policy" | translate }}</h2>
            <br />
            <p>
              {{
                "Thank you for visiting this page to read our privacy policy."
                  | translate
              }}<br />
              {{
                "This page provides the information that you need to check about the things we have applied to protect the user right and the privacy of our dear users."
                  | translate
              }}<br /><br />
              {{
                "By visiting this web application you agree to be bound by the terms and conditions of this terms of service. If you do not agree, please do not use or access our web application."
                  | translate
              }}<br /><br />
              <img
                src="https://storage.speaknplay.app/internal%2Fprivacy_policy.jpg?alt=media&token=9db22e8f-7766-4a43-a44b-b562f7928e29"
              />
            </p>
            <p>
              {{
                "You will find out that our privacy policy is short but very straightforward and easy to understand. Please check below:"
                  | translate
              }}
            </p>
            <ol>
              <li>
                {{ "We are using Google Firebase Authentication" | translate }}
                <a
                  href="https://firebase.google.com/products/auth"
                  target="_blank"
                  >https://firebase.google.com/products/auth</a
                >
                {{ "for your sign-up/sign-in." | translate }} <br />
                {{
                  "Your login details are being protected in high-security by Google. From our side we can only see your email address, and we protect your email address very seriously, it will never be shared with anyone else."
                    | translate
                }}
              </li>
              <li>
                {{
                  "All your data including: Video URL(s), Lesson name(s), Categories, Audio records, Images are all private. Only you have the ability to access your data and share it (via export file)."
                    | translate
                }}
              </li>
              <li>
                {{
                  "If you wish to delete your account and your data, it is very much possible and easy, but you will have to know that it can not be reverted and you will lose everything including your"
                    | translate
                }}
                <span class="explorer-account">Explorer</span>
                {{ "account membership (if you are)." | translate }}<br />
                {{ "Please read more here:" | translate }}
                <a href="https://speaknplay.app/data-deletion" target="_blank"
                  >www.speaknplay.app/data-deletion</a
                >
              </li>
            </ol>
            <p>
              {{
                "Thank you for reading this, We are all ear to hear from you, if you have any questions please do not hesitate to send us an email to"
                  | translate
              }}<a href="mailto:support@speaknplay.app">
                support@speaknplay.app</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Page Body End-->
  </div>
  <!-- page-wrapper End-->
</div>
<footer class="footer">
  <app-footer></app-footer>
</footer>
