<div class="dark-body-only" id="outer-container">
    <!-- page-wrapper Start-->
    <div class="page-wrapper" id="canvas-bookmark">
        <div class="page-main-header" id="page-main-header">
            <div class="main-header-right row">
                <div class="main-header-left">
                    <ul class="nav-menus" style="font-size: 1.2rem; margin-left:20px">
                        <li>
                          <a href="javascript:void(0)">
                            <img src="assets/images/kidvoice.png" alt="">
                          </a>
                        </li>
                      </ul>
                </div>
            </div>
        </div>
        <div class="page-body-wrapper">
            <div class="page-body">
                <div class="container" style="background-color: #FFF; margin-top:80px;padding:3rem 2rem">
                    <div class="col-xs-12">
                        <h3>{{ 'Device Not Supported' | translate }}</h3>
                        <br>
                        <p>
                            {{ 'This web app is not available on mobile and tablet devices. Please visit our site on a desktop computer.' | translate }}
                        </p>
                    </div>

                </div>
            </div>
        </div>
        <!-- Page Body End-->
    </div>
    <!-- page-wrapper End-->
</div>
<footer class="footer">
    <app-footer></app-footer>
</footer>