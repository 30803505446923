<div class="form-group row">
  <ng-container *ngIf="!isPickingLearningLanguage">
    <label
      class="col-sm-3 col-md-2 col-form-label text-left m-b-10"
      *ngIf="showLabel"
      >{{ "UI Language" | translate }}
      <app-info
        name="ui-language"
        [color]="showLabel ? 'white' : 'black'"
      ></app-info
    ></label>
    <div class="col-sm-9 col-md-4 text-left m-b-10">
      <select
        class="form-control"
        (change)="changeUILanguage($event.target['value'])"
        [(ngModel)]="selectedLanguageIndex"
      >
        <option *ngFor="let language of languages; let i = index" [value]="i">
          {{ language.name }}
        </option>
      </select>
    </div>
  </ng-container>
  <ng-container *ngIf="isPickingLearningLanguage">
    <label
      class="col-sm-3 col-md-2 col-form-label text-left m-b-10"
      *ngIf="showLabel"
      >{{ "Learning Language" | translate }}
      <app-info
        name="learning-language"
        [color]="showLabel ? 'white' : 'black'"
      ></app-info
    ></label>
    <div class="col-sm-9 col-md-4 text-left m-b-10">
      <select
        class="form-control"
        (change)="changeLearningLanguage($event.target['value'])"
        [(ngModel)]="selectedLanguageIndex"
      >
        <option *ngFor="let language of languages; let i = index" [value]="i">
          {{ language.name }}
        </option>
      </select>
    </div>
  </ng-container>

  <ng-container *ngIf="isPickingLearningLanguage">
    <label
      class="col-sm-3 col-md-2 col-form-label text-left m-b-10"
      *ngIf="dialectCount > 1"
      >{{ "version" | translate | capitalizeFirst }}
      <app-info
        name="dialect"
        [color]="isPickingLearningLanguage ? 'white' : 'black'"
      ></app-info
    ></label>
    <div class="col-sm-9 col-md-4 text-left m-b-10" *ngIf="dialectCount > 1">
      <select
        class="form-control"
        (change)="changeDialect($event.target['value'])"
        [(ngModel)]="selectedDialectIndex"
      >
        <option
          *ngFor="let dialect of availableDialect; let i = index"
          [value]="i"
        >
          {{ dialect[1] }}
        </option>
      </select>
    </div>
  </ng-container>
</div>
