<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body" [formGroup]="settingForm">
          <ng-container
            *ngIf="
              authService.userData &&
              authService.userData.expiredDate &&
              authService.userData.expiredDate > currentUnixtime
            "
          >
            <div class="form-group">
              <ng-container *ngIf="authService.isLoggedIn()">
                <!-- <label class="col-form-label"
                  >{{ "Your account ID" | translate }}:&nbsp;</label
                >
                <strong
                  ><mark>{{ authService.userData.uid }}</mark></strong
                ><br /> -->
                <label class="col-form-label"
                  >{{ "Your account name" | translate }}:&nbsp;</label
                >
                <strong>{{ authService.userData.email }}</strong
                ><br />
                <label class="col-form-label"
                  >{{ "Your account type" | translate }}:&nbsp;</label
                >
                <strong>{{
                  authService.userData.type.toUpperCase() | translate
                }}</strong
                ><br />
              </ng-container>
              <label class="col-form-label"
                >{{ "Expiry date:" | translate }}&nbsp;</label
              >
              <strong>{{ expiryDate }}</strong>
              <br />
              <label class="col-form-label"
                ><strong>{{ "Note" | translate }}</strong
                >:
                {{
                  "Each account allows only one device to be logged in at a time. When a new device signs in, the previous device will be automatically signed out."
                    | translate
                }}
              </label>
            </div>
            <div class="form-group">
              <label class="col-form-label"
                >{{ "School" | translate }} ({{
                  "Teachers can add students to their schools" | translate
                }})</label
              >
              <ng-select
                [placeholder]="'School' | translate"
                [multiple]="false"
                [virtualScroll]="true"
                [items]="schools"
                bindLabel="schoolName"
                bindValue="schoolId"
                (change)="changeSchool($event)"
                [(ngModel)]="selectedSchoolId"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-select>
            </div>
            <!-- <div class="form-group" [class.d-none]="authService.isNotTeacher()">
              <label class="col-form-label"
                >Youtube API Key (<a
                  [routerLink]="['/how-to-get-youtube-api-key']"
                  target="blank"
                  >{{ "Instruction" | translate }}</a
                >)
                <app-info name="youtube_api_key"></app-info>
              </label>
              <input
                class="form-control"
                type="text"
                formControlName="youtubeApiKey"
                maxlength="500"
              />
            </div> -->
            <div class="form-group" [class.d-none]="authService.isNotTeacher()">
              <label class="col-form-label"
                >Youtube Playlists
                <app-info name="youtube_playlists"></app-info>
              </label>
              <input
                class="form-control"
                type="text"
                formControlName="youtubePlaylist"
                placeholder="Example: https://www.youtube.com/playlist?list=PLvzOwE5lWqhQJzVZLz2J2Y1Y1QZgZjJol"
                maxlength="500"
              />
            </div>
          </ng-container>
          <div class="form-group">
            <div class="row">
              <div class="col-6">
                <label class="col-form-label">{{
                  "Update system labels" | translate
                }}</label>
                <!-- <select
                  class="form-control"
                  (change)="selectedSystemWordOptionChange($event)"
                >
                  <option value="">
                    {{ "Select word to update" | translate }}
                  </option>
                  <ng-container
                    *ngFor="let key of englishSystemRecognizedWords"
                  >
                    <option
                      [value]="
                        key +
                        '|' +
                        defaultSystemRecognizedWords[
                          authService.currentLanguageCode
                        ][key]
                      "
                    >
                      {{
                        defaultSystemRecognizedWords[
                          authService.currentLanguageCode
                        ][key]
                      }}
                    </option>
                  </ng-container>
                </select> -->
                <ng-select
                  [items]="items"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ 'Select word to update' | translate }}"
                  [(ngModel)]="selectedItem"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="selectedSystemWordOptionChange($event)"
                ></ng-select>
              </div>
              <div class="col-6">
                <label class="col-form-label">{{
                  "Change to" | translate
                }}</label>
                <input
                  class="form-control"
                  type="text"
                  value="{{ currentSystemRecognizedWord }}"
                  (change)="changeSystemWord($event.target['value'])"
                  maxlength="50"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-form-label">{{
              "Voice recording volume" | translate
            }}</label>
            <input
              class="form-control"
              type="number"
              formControlName="recordingVolume"
              min="1"
              max="20"
              style="width: 150px"
            />
          </div>

          <div class="form-group">
            <div class="checkbox checkbox-dark m-squar">
              <input
                id="inline-sqr-1"
                type="checkbox"
                formControlName="disableFullscreen"
              />
              <label for="inline-sqr-1" class="mt-0">{{
                "Disable fullscreen mode" | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-primary mr-1" (click)="updateSetting()">
            {{ "Save" | translate }}
          </button>
          <button class="btn btn-danger mr-1" (click)="logOut()">
            {{ "Logout" | translate }}
          </button>
          <button
            class="btn btn-danger mr-1"
            (click)="resetPassword()"
            *ngIf="authService.isPasswordUser"
          >
            {{ "Reset Password" | translate }}
          </button>
          <button class="btn btn-danger" (click)="deleteConfirmation()">
            {{ "Delete account" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
