<div class="dark-body-only" id="outer-container">
  <!-- page-wrapper Start-->
  <div class="page-wrapper" id="canvas-bookmark">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper">
      <div class="page-body">
        <div
          class="container"
          style="background-color: #fff; margin-top: 80px; padding: 3rem 2rem"
        >
          <div class="col-xs-12">
            <h2>Terms of service</h2>
            <br />
            <p>
              {{
                "Thank you for visiting this page to read our terms of service."
                  | translate
              }}<br />
              {{
                "By visiting this web application you agree to be bound by the terms and conditions of this terms of service. If you do not agree, please do not use or access our web application."
                  | translate
              }}<br /><br />
              <img
                src="https://storage.speaknplay.app/internal%2Fterms_of_service.JPG?alt=media&token=bd583a5f-c6f5-4a2d-90fd-45021c54cd4b"
              />
            </p>
            <p>
              {{
                "You will find out that our terms of service is short but very straightforward and easy to understand. Please check below:"
                  | translate
              }}
            </p>
            <ol>
              <li>
                {{
                  "The data that you stored in our system are own by you, it can be deleted at anytime by you and it is you that responsible for your data"
                    | translate
                }}<br />
                {{
                  "We do not take legal responsibility for your data, so please make sure the data you store in our system are legally from the country/state where you are living."
                    | translate
                }}
              </li>
              <li>
                {{
                  "We created this web application with main focus is to help children to improve the speaking ability. But we do not encourage parents to only depend on this web application. Babies, toddlers need to play in the real world with YOU, nothing can replace that."
                    | translate
                }}
              </li>
              <li>
                {{
                  "Beside those videos that we already added content for teaching"
                    | translate
                }}, <span class="explorer-account">Explorer</span>
                {{
                  "As we do not have vision on how you do it, there is a chance that your content does not serve well or it even cause some negative effects for learners especially kids."
                    | translate
                }}<br />
                {{
                  "Unfortunately, we cannot take responsibility for this as those content are created and owned by you."
                    | translate
                }}<br />
                {{
                  "When you select videos, please choose them carefully and avoid fight scenes or abnormal objects, or those actions that look unsafe, etc."
                    | translate
                }}
              </li>
              <li>
                {{ "Each" | translate }}
                <span class="explorer-account">Explorer</span>
                {{
                  "Each account allows only one device to be logged in at a time. When a new device signs in, the previous device will be automatically signed out."
                    | translate
                }}
              </li>
              <li>
                {{
                  "If you wish to delete your account and your data, it is very much possible and easy, but you should know that it cannot be reverted, and you will lose everything, including your account membership (if you have one)."
                    | translate
                }}
                <span class="explorer-account">Explorer</span>
                {{ "account membership (if you are)." | translate }}<br />
                {{ "Please read more here:" | translate }}
                <a href="https://speaknplay.app/data-deletion" target="_blank"
                  >www.speaknplay.app/data-deletion</a
                >
              </li>
            </ol>
            <p>
              {{
                "Thank you for reading this, We are all ears to hear from you. If you have any questions, please do not hesitate to send us an email to "
                  | translate
              }}
              <a href="mailto:support@speaknplay.app">support@speaknplay.app</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Page Body End-->
  </div>
  <!-- page-wrapper End-->
</div>
<footer class="footer">
  <app-footer></app-footer>
</footer>
