<div class="card mt-4">
    <div class="card-body">
        <form class="theme-form" [formGroup]="form">
            <div class="form-group">
                <label class="col-form-label pt-0">{{ 'Record your audio question' | translate }}</label>
                <div class="row">
                    <div class="col">
                        <div class="record-button" id="record-button" (click)="recordButtonClick()">
                            <div class="inner"></div>
                        </div>
                    </div>
                    <div class="col" [style.visibility]="hasRecordAudio ? 'inherit' : 'hidden'">
                        <div class="player-container">
                            <div class="play play-pause form-play-pause" (click)="playPauseClick('form-play-pause')">
                                Play</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="col-form-label pt-0">{{ 'Question Name' | translate }} <app-info
                        name="questioner_audio_name"></app-info></label>
                <input class="form-control" formControlName="name" type="text" maxlength="200">
                <!-- <div *ngIf="form.controls.name.touched && form.controls.name.errors?.required"
                    class="text text-danger mt-1">
                    Question Name is required
                </div>
                <div *ngIf="form.controls.name.touched && form.controls.name.errors?.name"
                    class="text text-danger mt-1">
                    Invalid question Name
                </div> -->
            </div>
            <div class="form-group">
                <label class="col-form-label pt-0">{{ 'Pick an avatar for your audio question.' | translate }}</label>
                <button class="btn btn-danger btn-xs float-right active" type="button" placement="top"
                    (click)="openAvatarShotModal(avatarShotForm)">{{ 'Create' | translate }}</button>

                <select class="form-control clearfix" formControlName="questionerAvatar"
                    (change)="pickedQuestionerAvatar($event.target['value'])">
                    <option value="999" selected>{{ 'Default (A question mark)' | translate }}</option>
                    <ng-container *ngFor="let questionerAvatar of authService.schoolData.questionerAvatars">
                        <option [value]="questionerAvatar.id">{{questionerAvatar.name}}</option>
                    </ng-container>
                </select>
            </div>
            <div class="form-group mt-3">
                <button class="btn btn-primary mr-2" type="button" placement="top" type="button" (click)="useIt()"
                    [disabled]="!dataURL || !form.value.name || form.value.name.length == 0">{{!questionerAudioQuestion
                    ? ('Add'| translate) : ('Update'|translate)}}</button>

                <button class="btn btn-light" type="button" placement="top" (click)="closeIt()" title="">
                    {{ 'Close' | translate }}</button>
            </div>
        </form>
    </div>
</div>

<audio id="testQuestionerAudioQuestion" autoplay="false" #playPause></audio>
<ng-template #avatarShotForm>
    <div class="modal-body">
        <!-- <app-questioner-avatar-management (avatarChange)="questionnerAvatarUpdate($event)">
        </app-questioner-avatar-management> -->
        <app-avatarshot (questionerAvatarCreated)="questionnerAvatarUpdate($event)"></app-avatarshot>
    </div>
</ng-template>