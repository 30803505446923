<div class="container-fluid">
    <div class="">
        <div class="authentication-box media-profile-form" style="padding:0;margin:0 auto;width: 600px;max-width: 90vw;">
            <div class="row">
                <!-- <app-breadcrumb></app-breadcrumb> -->
            </div>
        </div>
        <div class="authentication-box media-profile-form" style="width: 600px;max-width: 90vw;">
            <div class="card mt-4">
                <div class="card-body">
                    <div class="form-group mb-0">
                        <input class="form-control" type="text" [(ngModel)]="searchTerm" name="textSearch"
                            placeholder="{{'Filter by question name' | translate}}..." (keyup)="searchTermChanged($event)" maxlength="50">
                    </div>
                    <hr class="clearfix" />
                    <div class="form-group">
                        <div *ngIf="authService.schoolData.questionerAudioQuestions.length == 0">{{ 'No audio question.' |
                            translate }}</div>
                        <div class='clearfix ylist' *ngIf="itemsInSelectedLang.length>0">
                            <div class="row p-t-5 p-b-5 mb-2">
                                <div class="col-2">
                                    <h6 style="font-weight:bold">{{ 'Play' | translate }}</h6>
                                </div>
                                <div class="col-6" style="padding-left:0">
                                    <h6 style="font-weight:bold">{{'Question Name' | translate }}</h6>
                                </div>
                                <div class="col-2 text-center" style="padding-left:0;padding-right:0">
                                    <h6 style="font-weight:bold">{{ 'Enable' | translate }}</h6>
                                </div>
                                <div class="col-2 text-right" style="font-size:20px">
                                    
                                </div>
                            </div>
                            <div class="row p-t-5 p-b-5" *ngFor="let audioQuestion of searchResults; let i = index">
                                <div class="col-2">
                                    <div class="player-container" style="margin-top:-16px;">
                                        <div class="play play-pause question-list-item question{{i}}"
                                            (click)="authService.playPauseClick('question'+i, audioQuestion.id)">
                                            Play</div>
                                    </div>
                                </div>
                                <div class="col-6" style="padding-left:0">
                                    <h6 class="task-label" id="label_{{audioQuestion.id}}">
                                        {{audioQuestion.name}}</h6>
                                </div>
                                <div class="col-2 text-right" style="display:flex; justify-content: center;align-items: center;">
                                    <!-- checkbox -->
                                    <input type="checkbox" class="checkbox" [checked]="!audioQuestion.disabled"
                                    style="width:16px;height:16px" (click)="toggleDisable(audioQuestion.id)"/>
                                </div>

                                <div class="col-2 text-right" style="font-size:20px">
                                    <i class="fa fa-edit m-r-5" (click)="editAudioQuestion(audioQuestion)"
                                        style="cursor: pointer;"></i>
                                    <span class="action-box large delete-btn m-r-5" title="Delete"
                                        (click)="withConfirmation(audioQuestion.id)" style="cursor: pointer;"><i
                                            class="icon"><i class="icon-trash"></i></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group form-row mt-3">
                        <button class="btn btn-{{saveButtonRef.label == 'Save' ? 'primary' : 'success'}} mr-2 pl-3 pr-3" type="button" placement="top" type="button"
                            (click)="save()">{{ saveButtonRef.label | translate }}</button>
                        <button class="btn btn-danger mr-2 pl-3 pr-3" type="button" placement="top" type="button"
                            (click)="addAudioQuestion()">{{ 'New' | translate }}</button>
                        <button class="btn btn-light  mr-2 pl-3 pr-3" type="button" placement="top"
                            (click)="goToAvatar()" title="">
                            {{ 'Audio Question Avatars' | translate }}</button>

                        <button class="btn btn-light pl-3 pr-3" type="button" placement="top" (click)="cancel()"
                            title="">
                            {{ 'Back' | translate }}</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>



<ng-template #audioQuestionForm>
    <div class="modal-body">
        <app-questioner-audio-question (questionerAudioQuestionUpdate)="audioQuestionSelectedChanged($event)"
            [questionerAudioQuestion]="selectedEditAudioQuestion"></app-questioner-audio-question>
    </div>
</ng-template>
<audio id="testaudio" autoplay="false" #playPause></audio>