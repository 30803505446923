<ul
  class="nav-menus"
  [style.fontSize]="navService.screenWidth >= 600 ? '1.2rem' : '14px'"
  [style.marginLeft]="navService.screenWidth >= 600 ? '20px' : '5px'"
>
  <li *ngIf="navService.screenWidth >= 600">
    <a [routerLink]="['/']" style="position: relative; top: -5px">
      <img src="assets/images/kidvoice.png" alt="" />
    </a>
  </li>
  <ng-container *ngIf="showBreadcrumb">
    <li
      class="breadcrumb-item"
      style="margin-top: 10px; margin-left: 20px"
      *ngIf="navService.screenWidth >= 600 && title != 'Library'"
    >
      <a [routerLink]="'/library'">
        {{ "Library" | translate }}
      </a>
    </li>
    <li *ngIf="navService.screenWidth < 600" class="breadcrumb-item">
      <a [routerLink]="['/']">
        <i
          class="icofont icofont-sound-wave"
          style="font-size: 1.3rem; font-weight: bold; color: black"
        ></i>
      </a>
    </li>
    <li
      *ngIf="navService.screenWidth < 600 && title != 'Library'"
      class="breadcrumb-item"
    >
      <a [routerLink]="['/library']">
        <i class="fa fa-briefcase"></i>
      </a>
    </li>
    <li
      class="breadcrumb-item"
      *ngIf="
        breadcrumbs?.parentBreadcrumb &&
        title != 'Library' &&
        navService.screenWidth >= 600
      "
      style="margin-top: 10px"
    >
      {{ breadcrumbs?.parentBreadcrumb | translate }}
    </li>
    <li
      class="breadcrumb-item active"
      *ngIf="
        breadcrumbs?.childBreadcrumb &&
        title != 'Library' &&
        navService.screenWidth >= 600
      "
      style="margin-top: 10px"
    >
      {{ breadcrumbs?.childBreadcrumb | translate }}
    </li>
  </ng-container>
</ul>
