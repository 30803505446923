<div class="row" *ngIf="!action_type">
  <div class="col text-center">
    <button (click)="action_type='UPLOAD'" class="btn btn-primary">{{ 'Upload picture' | translate }}</button>
  </div>
  <div class="col text-center">
    <button (click)="initToTakePicture()" class="btn btn-primary">{{ 'Take picture' | translate }}</button>
  </div>
</div>
<ng-container *ngIf="action_type=='UPLOAD'">
  <div class="row mb-5">
    <div class="col text-center">
      <app-upload-image (thumbnailChanged)="handleThumbnailChanged($event)"
        [showPreview]="false" [resizeToWidth]="300" [aspectRatio]="4/3"></app-upload-image>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="action_type=='TAKE'">
  <div class="row mb-5">
    <div class="col">
      <div>
        <video id="video"></video>
      </div>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col text-center">
      <button id="startbutton" (click)="takepicture()" class="btn btn-primary">{{ 'Take picture' | translate
        }}</button><br />
    </div>
  </div>
</ng-container>


<div class="row">
  <div class="col text-center">
    <label id="gif_preview_label" class="preview-label"><strong>{{ 'Preview' | translate }}</strong></label><br />
    <img id="photo" />
  </div>
</div>
<div class="row" *ngIf="action_type=='TAKE'">
  <div class="col text-center mt-2">
    <canvas id="canvas" class="invisible" style="width:1x;height:1px"></canvas>
  </div>
</div>

<div class="row mt-2">
  <div class="col">
    <div class="form-group">
      <label class="col-form-label pt-0">{{ 'Give this questioner avatar a name' | translate }}
        <i class="fa fa-question-circle d-inline-block" style="cursor: pointer;"
          (click)="navService.showInfo(translate.instant('You can have up to 5 questioner avatars, a name will help to differentiate each of them'))"></i></label>
      <input class="form-control" type="text" [(ngModel)]="questionerAvatarName" maxlength="50">
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <button (click)="useIt()" class="btn btn-primary mr-1" [disabled]="!pictureDataURL || !questionerAvatarName">{{ 'Save' | translate }}</button>
    <button (click)="cancel()" class="btn btn-light mr-1">{{ 'Close' | translate }}</button>
  </div>
</div>