declare var require;

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBzg5SQO8zdEW4E0Z-TjAuRJTsgLIKO4Kg',
    authDomain: 'speaknplay.firebaseapp.com',
    // databaseURL: "https://speaknplay-default-rtdb.firebaseio.com",
    projectId: 'speaknplay',
    storageBucket: 'speaknplay.appspot.com',
    messagingSenderId: '1033586389925',
    appId: '1:1033586389925:web:a6a2ebb02e39b94076ee4c',
    measurementId: 'G-1X339HFHD6',

    // apiKey: "AIzaSyBp_nThfPB9sytMppaOYwTO9zHf3SHdHSE",
    // authDomain: "kidvoice-66d87.firebaseapp.com",
    // databaseURL: "https://kidvoice-66d87.firebaseio.com",
    // projectId: "kidvoice-66d87",
    // storageBucket: "kidvoice-66d87.appspot.com",
    // messagingSenderId: "530376000931",
    // appId: "1:530376000931:web:c3a5e810d2c30ad977c616",
    // measurementId: "G-DDNWFRZ24L",
    YOUTUBE_API_URL: 'https://www.googleapis.com/youtube/v3/search',
  },
  googleClientSecret: 'zOWP7xWnhCIzxYboCJRLUJwa',
  googleClientId:
    '530376000931-l2nn0q7md7eeohu21c4cfs2bort5ktej.apps.googleusercontent.com',
  stripePublishableKey: 'pk_live_KcnRERMIfOGVu0S1Ud7ZdsSP',
  version: require('../../package.json').version,
  apiEndpoint: 'https://api.speaknplay.app',
  r2BaseURL: 'https://storage.speaknplay.app',
  youtubeAPIKeys: ['AIzaSyClRdANCWg14aDZERY0kO20SYuabNu9Iqk'],
};
