<ng-container *ngIf="thumbnailFile && !readonly">
  <image-cropper
    [format]="'jpeg'"
    (imageCroppedBase64)="imageCroppedBase64($event)"
    [imageFileChanged]="thumbnailFile"
    [aspectRatio]="aspectRatio"
    [resizeToWidth]="resizeToWidth"
  ></image-cropper>
</ng-container>
<input
  type="file"
  class="btn btn-outline-light"
  (change)="onFileSelected($event)"
  [(ngModel)]="file"
  *ngIf="!readonly"
/>
<ng-container *ngIf="customThumbnailUrl && showPreview">
  <div class="row mt-3">
    <div class="d-flex" style="width: 320px; margin-left: 15px">
      <img [src]="customThumbnailUrl" style="width: 320px; height: 180px" />
    </div>
    <div
      class="d-flex"
      style="align-items: center; margin-left: 5px"
      *ngIf="!readonly"
    >
      <a
        href="javascript:void(0)"
        (click)="removeThumbnail()"
        style="color: #000"
      >
        <i class="fa fa-remove fa-2x"></i>
      </a>
    </div>
  </div>
</ng-container>
