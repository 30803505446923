<!-- Container-fluid starts-->
<div class="container-fluid" [class.sidebaron]="sidebaron">
  <div class="">
    <div
      class="authentication-box media-profile-form"
      style="padding: 0; margin: 0 auto; width: 600px; max-width: 90vw"
    >
      <div class="row">
        <!-- <app-breadcrumb></app-breadcrumb> -->
      </div>
    </div>
    <div
      class="authentication-box media-profile-form"
      *ngIf="!!mediaForm"
      style="width: 600px; max-width: 90vw"
    >
      <div class="card mt-4">
        <div class="card-body">
          <form class="theme-form" [formGroup]="mediaForm">
            <div class="form-group">
              <label class="col-form-label pt-0"
                >{{ "Lesson name" | translate }}
                <app-info name="add_video_name"></app-info
              ></label>
              <input
                class="form-control"
                formControlName="name"
                type="text"
                required=""
                placeholder="{{ 'Example: hippo, hippopotamus' | translate }}"
                (change)="checkIfVideoID($event.target['value'])"
                style="max-width: 300px"
                maxlength="50"
              />
              <div
                *ngIf="
                  mediaForm.controls.name.touched &&
                  mediaForm.controls.name.errors?.required
                "
                class="text text-danger mt-1"
              >
                {{ "Name is required" | translate }}
              </div>
              <div
                *ngIf="
                  mediaForm.controls.name.touched &&
                  mediaForm.controls.name.errors?.name
                "
                class="text text-danger mt-1"
              >
                {{ "Invalid name" | translate }}
              </div>
            </div>
            <!-- <div class="form-group" *ngIf="isCopy">
              <label class="col-form-label pt-0">{{
                "Language" | translate
              }}</label>
              <app-languages
                (selectedLanguageChange)="changeLanguage($event)"
                [isPickingLearningLanguage]="false"
                [originalLangCodeBeforeCopy]="originalLangCodeBeforeCopy"
              >
              </app-languages>
            </div> -->
            <div class="form-group">
              <label class="col-form-label pt-0">{{
                "Category" | translate
              }}</label>
              <button
                class="btn btn-light btn-xs float-right active"
                type="button"
                placement="top"
                (click)="openSm(newCategory)"
              >
                {{ "New category" | translate }}
              </button>

              <ng-select
                formControlName="cid"
                (change)="selectedCategoryChange($event)"
                [placeholder]="'Select a course' | translate"
                [multiple]="true"
                [virtualScroll]="true"
                [items]="categories"
                bindLabel="name"
                bindValue="cid"
              >
              </ng-select>
              <div
                *ngIf="
                  mediaForm.controls.cid.touched &&
                  mediaForm.controls.cid.errors?.required
                "
                class="text text-danger mt-1"
              >
                {{ "Category is required" | translate }}
              </div>
              <div
                *ngIf="
                  mediaForm.controls.cid.touched &&
                  mediaForm.controls.cid.errors?.cid
                "
                class="text text-danger mt-1"
              >
                {{ "Invalid Category" | translate }}
              </div>
            </div>
            <div class="form-group">
              <label class="col-form-label pt-0">{{
                "Age group" | translate
              }}</label>
              <select
                class="form-control"
                formControlName="ageGroup"
                (change)="selectedAgeGroupChange($event.target['value'])"
              >
                <option
                  *ngFor="let ageGroup of lessonAgeGroups"
                  [value]="ageGroup"
                >
                  {{ ageGroup }}
                </option>
              </select>
              <div
                *ngIf="
                  mediaForm.controls.ageGroup.touched &&
                  mediaForm.controls.ageGroup.errors?.required
                "
                class="text text-danger mt-1"
              >
                {{ "Age group are required" | translate }}
              </div>
            </div>
            <div class="form-group">
              <label class="col-form-label pt-0"
                >{{ "Video list" | translate }}
                <app-info name="youtube_video_list"></app-info
              ></label>
              <button
                class="btn btn-danger btn-sm float-right active"
                type="button"
                placement="top"
                (click)="openLg(content)"
                *ngIf="!usedVideo && ylist.length < 12"
              >
                {{ "Add video" | translate }}
              </button>
              <label class="float-right text-danger" *ngIf="ylist.length >= 12">
                {{ "Maximum 12 videos reached" | translate }}</label
              >
              <div class="ylist clearfix m-t-15">
                <div class="row font-weight-bold text-center">
                  <div class="col" style="width: 100px">
                    {{ "Avatar" | translate }}
                  </div>
                  <div class="col" style="width: 80px">
                    {{ "Start seconds" | translate }}
                  </div>
                  <div class="col" style="width: 80px">
                    {{ "End seconds" | translate }}
                  </div>
                  <div class="col" style="width: 80px">
                    {{ "Repeat" | translate }}
                  </div>
                  <div class="col" style="width: 20px">
                    {{ "Enable" | translate }}
                  </div>
                  <div class="col" style="width: 20px" *ngIf="ylist.length > 1">
                    &nbsp;
                  </div>
                </div>
              </div>
              <div
                class="clearfix ylist"
                dragula="DRAGULA_FACTS"
                [(dragulaModel)]="ylist"
              >
                <div
                  class="row p-t-5 p-b-5"
                  *ngFor="let youtubeVideo of ylist; let i = index"
                >
                  <div
                    class="col d-flex align-items-center justify-content-center"
                    style="width: 100px; cursor: pointer"
                  >
                    <div
                      class="position-relative"
                      (click)="playLesson(previewContent, youtubeVideo, i)"
                    >
                      <img
                        src="{{
                          youtubeVideo.customThumbnailUrl
                            ? youtubeVideo.customThumbnailUrl
                            : 'https://img.youtube.com/vi/' +
                              authService.getOriginalYID(youtubeVideo.yid) +
                              '/mqdefault.jpg'
                        }}"
                        alt="avatar-img"
                        style="max-width: 80px; cursor: pointer"
                      />
                      <span class="edit-text">Edit</span>
                    </div>
                  </div>

                  <div class="col" style="width: 80px">
                    <input
                      class="form-control"
                      type="number"
                      [value]="youtubeVideo.startSeconds"
                      min="0"
                      (change)="
                        rowUpdate(
                          'startSeconds',
                          $event.target['value'],
                          youtubeVideo.yid,
                          i
                        )
                      "
                    />
                  </div>
                  <div class="col" style="width: 80px">
                    <input
                      class="form-control"
                      type="number"
                      [value]="youtubeVideo.endSeconds"
                      min="0"
                      (change)="
                        rowUpdate(
                          'endSeconds',
                          $event.target['value'],
                          youtubeVideo.yid,
                          i
                        )
                      "
                    />
                  </div>
                  <div class="col" style="width: 80px">
                    <input
                      class="form-control"
                      type="number"
                      [value]="youtubeVideo.repeatTime"
                      min="0"
                      max="5"
                      (change)="
                        rowUpdate(
                          'repeatTime',
                          $event.target['value'],
                          youtubeVideo.yid,
                          i
                        )
                      "
                    />
                  </div>
                  <div class="col disable-box" style="width: 20px">
                    <input
                      type="checkbox"
                      class="form-control"
                      [checked]="!youtubeVideo.disabled"
                      (change)="
                        rowUpdate(
                          'disabled',
                          !$event.target['checked'],
                          youtubeVideo.yid,
                          i
                        )
                      "
                    />
                  </div>
                  <div
                    class="col"
                    style="width: 20px; font-size: 20px"
                    *ngIf="ylist.length > 1"
                  >
                    <i
                      class="fa fa-times m-r-15"
                      style="cursor: pointer"
                      (click)="deleteYVideo(youtubeVideo.yid, i)"
                    ></i>
                    <i
                      class="icofont icofont-drag"
                      style="cursor: move"
                      title="Drag this item to different position"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="ylist.length > 1">
              <label class="col-form-label pt-0"
                >{{ "Selection Type" | translate }}
                <app-info name="video_display_type"></app-info
              ></label>
              <select class="form-control clearfix" formControlName="display">
                <option value="">{{ "Select one" | translate }}</option>
                <option
                  value="{{ ListPlayTypeEnum.leftRight }}"
                  *ngIf="ylist.length == 2"
                >
                  {{
                    authService.getDisplayTypeLabels(ListPlayTypeEnum.leftRight)
                  }}
                </option>
                <option
                  value="{{ ListPlayTypeEnum.leftCenterRight }}"
                  *ngIf="ylist.length == 3"
                >
                  {{
                    authService.getDisplayTypeLabels(
                      ListPlayTypeEnum.leftCenterRight
                    )
                  }}
                </option>
                <option
                  value="{{ ListPlayTypeEnum.topBottom }}"
                  *ngIf="ylist.length == 2"
                >
                  {{
                    authService.getDisplayTypeLabels(ListPlayTypeEnum.topBottom)
                  }}
                </option>
                <option
                  value="{{ ListPlayTypeEnum.topCenterBottom }}"
                  *ngIf="ylist.length == 3"
                >
                  {{
                    authService.getDisplayTypeLabels(
                      ListPlayTypeEnum.topCenterBottom
                    )
                  }}
                </option>
                <option
                  value="{{ ListPlayTypeEnum.smallBig }}"
                  *ngIf="ylist.length == 2"
                >
                  {{
                    authService.getDisplayTypeLabels(ListPlayTypeEnum.smallBig)
                  }}
                </option>
                <!-- <option value="{{ListPlayTypeEnum.shortLong}}">Size: short, long</option> -->
                <option
                  value="{{
                    ListPlayTypeEnum.redBlueGreenYellowOrangeBrownPinkGreyBlackWhitePurple
                  }}"
                >
                  {{
                    authService.getDisplayTypeLabels(
                      ListPlayTypeEnum.redBlueGreenYellowOrangeBrownPinkGreyBlackWhitePurple
                    )
                  }}
                </option>
                <option
                  value="{{ ListPlayTypeEnum.CircleSquareTriangle }}"
                  *ngIf="ylist.length == 3"
                >
                  {{
                    authService.getDisplayTypeLabels(
                      ListPlayTypeEnum.CircleSquareTriangle
                    )
                  }}
                </option>
              </select>
              <div class="clearfix m-t-10">
                <app-menu-generator
                  *ngIf="mediaForm.value.display"
                  [yList]="ylist"
                  [listDisplayType]="mediaForm.value.display"
                  [isInPopUp]="false"
                >
                </app-menu-generator>
              </div>
            </div>
            <div class="form-group form-row mt-3 mb-0">
              <button
                class="btn btn-{{
                  saveButtonRef.label == 'Save' ? 'primary' : 'success'
                }} mr-2"
                type="button"
                placement="top"
                (click)="update(mediaForm.value)"
                type="submit"
                *ngIf="(!usedVideo || type == 'edit') && !isCopy"
                [disabled]="
                  !mediaForm.value.name ||
                  !mediaForm.value.cid ||
                  mediaForm.value.cid.length == 0 ||
                  !mediaForm.value.ageGroup ||
                  ylist.length == 0 ||
                  (ylist.length > 1 && !mediaForm.value.display)
                "
              >
                {{ saveButtonRef.label | translate }}
              </button>
              <button
                class="btn btn-primary mr-2"
                type="button"
                placement="top"
                (click)="add(mediaForm.value)"
                type="submit"
                *ngIf="
                  (usedVideo && type !== 'edit') || type == 'add' || isCopy
                "
                [disabled]="
                  !mediaForm.value.name ||
                  !mediaForm.value.cid ||
                  mediaForm.value.cid.length == 0 ||
                  !mediaForm.value.ageGroup ||
                  ylist.length == 0 ||
                  (ylist.length > 1 && !mediaForm.value.display)
                "
              >
                {{ "Add" | translate }}
              </button>
              <button
                class="btn btn-light"
                type="button"
                placement="top"
                (click)="cancel()"
                title=""
                *ngIf="!usedVideo"
              >
                {{ "Back" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- youtube search -->
<ng-template #content let-modal>
  <div class="modal-header text-center" style="justify-content: center">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click'); modalSearchVisible = true"
      style="
        top: 4px;
        left: 0;
        position: absolute;
        font-size: 35px;
        outline: none;
      "
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ "Youtube Search" | translate }}</h4>
    <button
      class="form-control"
      style="
        width: 40px;
        position: absolute;
        top: 2px;
        right: 2px;
        outline: none;
      "
      (click)="toggleSearch()"
    >
      <i class="fa fa-angle-up" id="toggleSearchIcon"></i>
    </button>
  </div>
  <div class="modal-body" style="min-height: 500px; overflow: hidden">
    <app-create-lesson-from-youtube-video
      (selectedChange)="selectLesson($event)"
      [showSearch]="modalSearchVisible"
      [newOnPopup]="false"
    >
      <!-- [ylist]="ylist" -->
    </app-create-lesson-from-youtube-video>
  </div>
</ng-template>

<ng-template #previewContent let-modal>
  <div class="modal-body" id="modalBody">
    <app-preview-video
      [input]="previewVideo"
      (selectedChange)="previewVideoSelectedChange($event)"
      (thumbnailChanged)="handleThumbnailChanged($event)"
      [inputLesson]="selectedItem"
      [isPreviewForAdding]="type == 'add'"
    >
    </app-preview-video>
  </div>
</ng-template>

<!-- add new category -->
<ng-template #newCategory let-modal>
  <div class="modal-body" id="modalBody">
    <app-edit-category
      (eventChange)="categoryEventChange($event)"
      [type]="'add'"
    >
    </app-edit-category>
  </div>
</ng-template>
