import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { FooterComponent } from '../footer/footer.component';
import { LanguagesComponent } from '../languages/languages.component';
import { HeaderComponent } from '../header/header.component';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { NavService } from 'src/app/services/nav.service';
import { environment } from 'src/environments/environment';
import { APIResponse } from 'src/app/services/constant.service';

type UserFields = 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  public message: string = '';
  public token: string = '';
  public changePasswordForm: FormGroup;
  public formErrors: FormErrors = {
    password: '',
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    public navService: NavService,
    private http: HttpClient,
    private fb: FormBuilder
  ) {
    this.changePasswordForm = this.fb.group({
      password: ['', Validators.required],
    });
    this.route.params.subscribe((params) => {
      if (typeof params['ref'] != 'undefined') {
        console.log(params['ref']);
        this.token = params['ref'];
      }
    });
  }

  ngOnInit() {}

  changePassword() {
    if (this.token) {
      const apiEndpoint = environment.apiEndpoint;
      const url = `${apiEndpoint}/reset-password`;
      this.http
        .post(url, {
          password: this.changePasswordForm.value['password'],
          token: this.token,
        })
        .subscribe({
          next: (apiResponse: APIResponse) => {
            if (apiResponse.success) {
              this.toastr.success(apiResponse.message);
              this.router.navigate(['/login']);
            } else {
              this.toastr.error(apiResponse.message);
            }
          },
          error: (err) => {
            this.toastr.error(err.message);
          },
        });
    } else {
      this.message = 'Invalid token';
    }
  }
}
