import { AnalyticsService } from './../../services/analytics.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
declare var require;
const Swal = require('sweetalert2');
const jsonpack = require('jsonpack/main');
import * as pako from 'pako';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { KvService } from 'src/app/services/kv.service';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
})
export class PurchaseComponent implements OnInit {
  @Output() purchaseClickEvent = new EventEmitter();
  public isExtend = false;
  public purchasedStatus: string;
  public isStripeReady: boolean = false;
  public stripeInt = 0;
  public successInt = 0;
  public ref = null;
  public isSuccess = false;
  public buttonLabel = 'Purchase';
  public expiryDate = null;
  public currentUnixtime;
  public userType = 'free';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private toastr: ToastrService,
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
    private toaster: ToastrService,
    private kvService: KvService
  ) {
    try {
      this.purchasedStatus = this.route.snapshot.data['title'].includes(
        'success'
      )
        ? 'success'
        : 'cancel';
      if (this.purchasedStatus == 'cancel') {
        this.toastr.info(
          'Payment canceled, let come back later when you are ready.'
        );
        this.router.navigateByUrl('/');
      }
      console.log(this.purchasedStatus);
    } catch (e) {}
  }

  ngOnInit(): void {
    this.currentUnixtime = moment().format('X');
    // let title = this.route.snapshot.data['title'];
    this.isStripeReady = typeof window['Stripe'] !== 'undefined';
    if (!this.isStripeReady) {
      Swal.showLoading();
      this.stripeInt = setInterval(() => {
        console.log('stripe is not ready');
        this.isStripeReady = typeof window['Stripe'] !== 'undefined';
        if (this.isStripeReady) {
          clearInterval(this.stripeInt);
          Swal.close();
        }
      }, 1000);
    }

    this.route.params.subscribe((params) => {
      if (typeof params['ref'] != 'undefined') {
        console.log(params['ref']);
        this.ref = params['ref'];
        if (this.purchasedStatus == 'success') {
          this.afterPaymentSuccessful(this.ref);
        }
      }
    });
    this.buttonLabel = this.isExtend
      ? this.translate.instant('Extend')
      : this.translate.instant('Purchase');
    if (
      this.authService.isLoggedIn() &&
      this.authService.userData.expiredDate > 0
    ) {
      this.expiryDate = moment
        .unix(this.authService.userData.expiredDate)
        .format('YYYY/MM/DD HH:mm:ss');
    }
    // this.analyticsService.analytics.logEvent('page_view', {
    //   page_title: this.translate.instant('Purchase'),
    // });
    if (this.authService.isLoggedIn()) {
      this.userType = this.authService.userData.type;
      this.isExtend = this.authService.userData.type != 'free';
    }
  }

  stripeCheckout(type) {
    // this.analyticsService.analytics.logEvent('purchase_checkout_clicked');
    this.purchaseClickEvent.next();
    if (!this.authService.isLoggedIn()) {
      localStorage.setItem('pending_purchase_type', type);
      this.router.navigate(['login']);
    } else {
      // this.analyticsService.analytics.logEvent('purchase_navigate_stripe');
      Swal.showLoading();
      this.authService.stripeCheckout(type);
    }
  }

  afterPaymentSuccessful(ref: string) {
    // this.analyticsService.analytics.logEvent('purchase_payment_successful');
    // verify ref
    Swal.showLoading();
    const refData = JSON.parse(atob(ref));
    // const checkoutToken = refData.checkout_token;
    const purchaseType = refData.type;
    const apiEndpoint = environment.apiEndpoint;
    const url = `${apiEndpoint}/verify-checkout-token`;
    Swal.showLoading();
    this.userType =
      purchaseType.indexOf('student') > -1 ? 'student' : 'teacher';
    this.successInt = setInterval(async () => {
      let remoteUserData = await this.kvService.read(
        this.authService.userData.email,
        'users'
      );
      if (
        remoteUserData.purchasedDate &&
        Math.abs(remoteUserData.purchasedDate - this.currentUnixtime) < 3600
      ) {
        // this.analyticsService.analytics.logEvent(
        //   'purchase_payment_successful_confirmed'
        // );
        this.authService.handleExistUser(remoteUserData);
        // this.authService.userData.purchasedDate =
        //   remoteUserData.purchasedDate;
        // this.authService.userData.expiredDate = remoteUserData.expiredDate;
        this.isSuccess = true;
        clearInterval(this.successInt);
        Swal.close();
      }
    }, 2000);
    // const arr = ref.split('||');
    // if (arr.length != 2) {
    //   this.toastr.error('Invalid ref');
    //   return;
    // }
    // const uid = arr[0];
    // const purchaseType = arr[1];
    // const uid = refData.uid;
    // const purchaseType = refData.purchaseType;

    // this.userType =
    //   purchaseType.indexOf('student') > -1 ? 'student' : 'teacher';
    // this.successInt = setInterval(() => {
    //   userRef.get().subscribe(async (result) => {
    //     let remoteUserData = this.authService.decompressUserData(
    //       result.data().userData
    //     );
    //     // if purchase date is within 1 hour
    //     if (
    //       Math.abs(remoteUserData.purchasedDate - this.currentUnixtime) < 3600
    //     ) {
    //       // this.analyticsService.analytics.logEvent(
    //       //   'purchase_payment_successful_confirmed'
    //       // );
    //       this.authService.handleExistUser(this.authService.userData, result);
    //       // this.authService.userData.purchasedDate =
    //       //   remoteUserData.purchasedDate;
    //       // this.authService.userData.expiredDate = remoteUserData.expiredDate;
    //       this.isSuccess = true;
    //       clearInterval(this.successInt);
    //       Swal.close();
    //     }
    //   });
    // }, 2000);
  }
}
