<div class="container-fluid">
  <div class="">
    <div
      class="authentication-box media-profile-form"
      style="padding: 0; margin: 0 auto"
    >
      <div class="row">
        <!-- <app-breadcrumb></app-breadcrumb> -->
      </div>
    </div>
    <div class="authentication-box media-profile-form">
      <div class="card mt-4">
        <div class="card-body">
          <div class="form-group">
            <div *ngIf="authService.schoolData.questionerAvatars.length == 0">
              {{
                "No questioner avatar, click add to create new one." | translate
              }}
            </div>
            <div class="clearfix ylist" *ngIf="items.length > 0">
              <div
                class="row p-t-5 p-b-5"
                *ngFor="let avatar of items; let i = index"
              >
                <div class="col-4" style="padding-top: 15px">
                  <h6 class="task-label" id="label_{{ avatar.id }}">
                    {{ avatar.name }}
                  </h6>
                </div>
                <div class="col-4">
                  <img
                    [src]="avatar.jpgURL"
                    style="height: 50px; border-radius: 3px"
                  />
                </div>
                <div
                  class="col-4 text-right"
                  style="font-size: 20px; padding-top: 15px"
                >
                  <i
                    class="fa fa-edit m-r-5"
                    (click)="editAvatar(avatar)"
                    style="cursor: pointer"
                  ></i>
                  <span
                    class="action-box large delete-btn m-r-5"
                    title="Delete"
                    (click)="withConfirmation(avatar.id)"
                    style="cursor: pointer"
                    ><i class="icon"><i class="icon-trash"></i></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group form-row mt-3">
            <!-- <button class="btn btn-{{saveButtonRef.label == 'Save' ? 'primary' : 'success'}} mr-2 pl-3 pr-3" type="button" placement="top" type="button"
                        (click)="save()">{{ saveButtonRef.label | translate }}</button> -->
            <button
              class="btn btn-danger mr-2"
              type="button"
              placement="top"
              type="button"
              (click)="addAvatar()"
            >
              {{ "New" | translate }}
            </button>

            <button
              class="btn btn-light"
              type="button"
              placement="top"
              (click)="cancel()"
              title=""
            >
              {{ "Back" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #avatarShotForm>
  <div class="modal-body">
    <app-avatarshot
      (questionerAvatarCreated)="avatarSelectedChange($event)"
      [questionerAvatar]="selectedEditAvatar"
    ></app-avatarshot>
  </div>
</ng-template>
