import { ReloadComponent } from './components/reload/reload.component';
import { NoSupportedDeviceComponent } from './components/no-supported-device/no-supported-device.component';
import { CreateYoutubeAPIKeyComponent } from './components/create-youtube-apikey/create-youtube-apikey.component';
import { DataDeletionComponent } from './components/data-deletion/data-deletion.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RegisterComponent } from './components/register/register.component';
import { DeleteUserComponent } from './components/delete-user/delete-user.component';
import { TermOfServiceComponent } from './components/term-of-service/term-of-service.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { PurchaseComponent } from './components/purchase/purchase.component';
// import { TestComponent } from "./components/test/test.component";
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { FullLayoutComponent } from './components/layout/full-layout/full-layout.component';
import { AdminGuard } from './guard/admin.guard';
import { HomeComponent } from './components/home/home.component';
import { CreateLessonFromYoutubeVideoComponent } from './components/create-lesson-from-youtube-video/create-lesson-from-youtube-video.component';
import { EditLessonComponent } from './components/edit-lesson/edit-lesson.component';
import { LibraryComponent } from './components/library/library.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { QuestionerAvatarManagementComponent } from './components/questioner-avatar-management/questioner-avatar-management.component';
import { QuestionerAudioQuestionManagementComponent } from './components/questioner-audio-question-management/questioner-audio-question-management.component';
import { StudentsComponent } from './components/students/students.component';
import { EditStudentComponent } from './components/edit-student/edit-student.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  // {
  //   path: "test",
  //   component: TestComponent,
  // },
  {
    path: 'verify-email/:ref',
    component: VerifyEmailComponent,
  },
  {
    path: 'how-to-get-youtube-api-key',
    component: CreateYoutubeAPIKeyComponent,
  },
  {
    path: 'data-deletion',
    component: DataDeletionComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-of-service',
    component: TermOfServiceComponent,
  },
  {
    path: 'no-supported-device',
    component: NoSupportedDeviceComponent,
  },
  {
    path: 'reload',
    component: ReloadComponent,
  },
  {
    path: 'delete-user',
    component: DeleteUserComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'change-password/:ref',
    component: ChangePasswordComponent,
  },
  {
    path: 'purchase',
    component: ContentLayoutComponent,
    children: [
      {
        path: 'success/:ref',
        component: PurchaseComponent,
        data: {
          title: 'Purchase success',
          breadcrumb: 'Purchase success',
        },
      },
      {
        path: 'cancel',
        component: PurchaseComponent,
        data: {
          title: 'Purchase canceled',
          breadcrumb: 'Purchase canceled',
        },
      },
    ],
  },
  {
    path: 'library',
    component: ContentLayoutComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: '',
        component: LibraryComponent,
        data: {
          title: 'Library',
          breadcrumb: 'Library',
        },
      },
      {
        path: 'edit-category/:id',
        component: EditLessonComponent,
        data: {
          title: 'Edit Category',
          breadcrumb: 'Edit Category',
        },
      },
      {
        path: 'edit-student/:id',
        component: EditStudentComponent,
        data: {
          title: 'Edit Student',
          breadcrumb: 'Edit Student',
        },
      },
      {
        path: 'categories',
        component: CategoriesComponent,
        data: {
          title: 'Categories',
          breadcrumb: 'Categories',
        },
      },
      {
        path: 'students',
        component: StudentsComponent,
        data: {
          title: 'Students',
          breadcrumb: 'Students',
        },
      },
      {
        path: 'avatar',
        component: QuestionerAvatarManagementComponent,
        data: {
          title: 'Avatar',
          breadcrumb: 'Avatar',
        },
      },
      {
        path: 'audio-question',
        component: QuestionerAudioQuestionManagementComponent,
        data: {
          title: 'Audio Question',
          breadcrumb: 'Audio Question',
        },
      },
      {
        path: 'create-lesson-from-youtube-video',
        component: CreateLessonFromYoutubeVideoComponent,
        data: {
          title: 'Create Lesson',
          breadcrumb: 'Create Lesson',
        },
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
