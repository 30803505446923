import { NavService } from 'src/app/services/nav.service';
import { AuthService } from './../../services/firebase/auth.service';
import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from '@angular/core';
import { languages, supportedDialects } from '../../services/constant.service';
import { KvService } from 'src/app/services/kv.service';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit, OnDestroy {
  @Output() selectedLanguageChange = new EventEmitter<string>();

  selectedLanguageIndex: number = 8; //en
  selectedDialectIndex: number = 3; // en-us
  @Input() isPickingLearningLanguage: boolean = false;
  @Input() showLabel: boolean = true;
  // @Input() originalLangCodeBeforeCopy: string = null;
  public availableDialect = [];
  public dialectCount = 0;
  public languages: any;

  constructor(
    public translate: TranslateService,
    private authService: AuthService,
    public navService: NavService,
    public kvService: KvService
  ) {}
  ngOnDestroy(): void {
    // When copying a media is changed cause the whole pages changes, after done copying the language should be changed back to original language
    // if (this.originalLangCodeBeforeCopy) {
    //   this.changeLanguage(
    //     this.getSelectedLanguageIndex(this.originalLangCodeBeforeCopy)
    //   );
    //   // sleep 1 second
    // }
  }

  ngOnInit(): void {
    let dialect = localStorage.getItem('dialect');
    let uilanguage = localStorage.getItem('uilanguage');
    let learningLanguage = localStorage.getItem('learningLanguage');
    // loop through languages and add language if it is in supportedDialects
    let supportedLanguages = [];
    for (let i = 0; i < languages.length; i++) {
      let dialects = languages[i].dialect;
      let supportedDialectsForLanguage = [];
      for (let j = 0; j < dialects.length; j++) {
        if (supportedDialects.includes(dialects[j][0])) {
          supportedDialectsForLanguage.push(dialects[j]);
        }
      }
      if (supportedDialectsForLanguage.length > 0) {
        supportedLanguages.push({
          lang: languages[i].lang,
          name: languages[i].name,
          dialect: supportedDialectsForLanguage,
        });
      }
    }
    this.languages = supportedLanguages;

    if (this.isPickingLearningLanguage) {
      // if both language and dialect available, make sure dialect belongs to language, because in media page: when changing the language, dialect kept as previous
      if (learningLanguage && dialect) {
        this.selectedLanguageIndex =
          this.getSelectedLanguageIndex(learningLanguage);
        this.availableDialect =
          this.languages[this.selectedLanguageIndex].dialect;
        let isExistDialect = this.availableDialect.find((item) => {
          return item.includes(dialect);
        });
        if (!isExistDialect) {
          dialect = this.availableDialect[0][0];
        }
      } else {
        if (!dialect) {
          dialect = navigator.language;
        }
        this.availableDialect =
          this.languages[this.selectedLanguageIndex].dialect;
      }
      this.selectedLanguageIndex =
        this.getSelectedLanguageIndexByDialect(dialect);
      localStorage.setItem(
        'learningLanguage',
        this.languages[this.selectedLanguageIndex].lang
      );
      this.dialectCount = this.availableDialect.length;
      this.changeDialect(this.selectedDialectIndex);
    } else {
      if (!uilanguage) {
        let dialect = navigator.language;
        this.selectedLanguageIndex =
          this.getSelectedLanguageIndexByDialect(dialect);
      } else {
        this.selectedLanguageIndex = this.getSelectedLanguageIndex(uilanguage);
      }
      this.changeUILanguage(this.selectedLanguageIndex);
    }
  }

  changeUILanguage(index) {
    this.selectedLanguageIndex = index;
    let lang = this.languages[index].lang;
    localStorage.setItem('uilanguage', lang);
    console.log(localStorage.getItem('uilanguage'));
    let selectedLanguage = lang.split('-')[0];
    localStorage.setItem('uilanguage', selectedLanguage);
    this.authService.setTranslationLanguage(selectedLanguage);
    this.selectedLanguageChange.next(lang);
  }

  changeLearningLanguage(index) {
    this.selectedLanguageIndex = index;
    let lang = this.languages[index].lang;
    localStorage.setItem('learningLanguage', lang);
    console.log(localStorage.getItem('learningLanguage'));
    let selectedLanguage = lang.split('-')[0];
    localStorage.setItem('learningLanguage', selectedLanguage);

    this.availableDialect = this.languages[index].dialect;
    this.dialectCount = this.availableDialect.length;
    this.changeDialect(0);
  }

  async changeDialect(index) {
    this.selectedDialectIndex = index;
    localStorage.setItem('dialect', this.availableDialect[index][0]);
    console.log(localStorage.getItem('dialect'));
    // const selectedLanguageIndex = this.getSelectedLanguageIndexByDialect(
    //   this.availableDialect[index][0]
    // );
    // let lang = this.languages[selectedLanguageIndex].lang;
    // this.authService.setTranslationLanguage(lang.split('-')[0]);
    // localStorage.setItem('language', lang);
    // console.log(localStorage.getItem('language'));
    this.authService.currentDialectCode = this.availableDialect[index][0];
    this.selectedLanguageChange.next(this.authService.currentDialectCode);
  }

  getSelectedLanguageIndexByDialect(inputDialect: string) {
    if (inputDialect.includes('-')) {
      let languageSet = this.languages.findIndex((item) => {
        let dialects = item.dialect;
        const matchDialect = dialects.findIndex((dialect) => {
          return dialect[0] === inputDialect;
        });
        if (matchDialect >= 0) {
          this.selectedDialectIndex = matchDialect;
        }
        return matchDialect >= 0;
      });
      return languageSet;
    } else {
      return this.getSelectedLanguageIndex(inputDialect);
    }
  }

  getSelectedLanguageIndex(lang: string) {
    let languageSet = this.languages.findIndex((item) => {
      return item.lang == lang;
    });
    return languageSet;
  }
}

export interface LanguagePayload {
  languageIndex: number;
  dialectIndex: number;
  language: string;
}
