<div class="menuGeneratorWrapper" id="menuGeneratorWrapper">
    <ng-container *ngIf="ylistTargetVideos.length > 0">
        <ng-container *ngIf="listDisplayType == ListPlayTypeEnum.CircleSquareTriangle && countItems > 2">
            <div class="row">
                <div class="col col-shape">
                    <div class="position-relative c{{ylistTargetVideos[0].youtubeVideo.yid}} term-circle">
                        <img [src]="ylistTargetVideos[0].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[0].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[0].youtubeVideo.yid) + '/mqdefault.jpg'"
                            class="col-circle" />&nbsp;&nbsp;
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[0].term}}</span></div>
                    </div>

                </div>
                <div class="col col-shape">
                    <div class="position-relative c{{ylistTargetVideos[1].youtubeVideo.yid}} term-square">
                        <img [src]="ylistTargetVideos[1].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[1].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[1].youtubeVideo.yid) + '/mqdefault.jpg'"
                            class="col-square" />&nbsp;&nbsp;
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[1].term}}</span></div>
                    </div>

                </div>
                <div class="col col-shape" *ngIf="ylistTargetVideos[2].youtubeVideo">
                    <div class="position-relative c{{ylistTargetVideos[2].youtubeVideo.yid}} term-triangle">
                        <img [src]="ylistTargetVideos[2].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[2].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[2].youtubeVideo.yid) + '/mqdefault.jpg'"
                            class="col-triangle" />&nbsp;&nbsp;
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[2].term}}</span></div>
                    </div>

                </div>
            </div>
        </ng-container>
        <ng-container *ngIf=" listDisplayType==ListPlayTypeEnum.leftRight  && countItems > 1">
            <div class="row left-right">
                <div class="col c{{ylistTargetVideos[0].youtubeVideo.yid}} term-left">
                    <div class="position-relative">
                        <img
                            [src]="ylistTargetVideos[0].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[0].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[0].youtubeVideo.yid) + '/mqdefault.jpg'" />
                        <div class="position-absolute video-name">
                            <span>
                                <i class="icofont icofont-arrow-left"></i>
                                <span class="position">{{ylistTargetVideos[0].term}}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col c{{ylistTargetVideos[1].youtubeVideo.yid}} term-right">
                    <div class="position-relative">
                        <img
                            [src]="ylistTargetVideos[1].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[1].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[1].youtubeVideo.yid) + '/mqdefault.jpg'" />
                        <div class="position-absolute video-name">
                            <span>
                                <i class="icofont icofont-arrow-right"></i>
                                <span class="position">{{ylistTargetVideos[1].term}}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="listDisplayType == ListPlayTypeEnum.leftCenterRight && countItems > 2">
            <div class="row left-center-right">
                <div class="col c{{ylistTargetVideos[0].youtubeVideo.yid}} term-left">
                    <div class="position-relative">
                        <img
                            [src]="ylistTargetVideos[0].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[0].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[0].youtubeVideo.yid) + '/mqdefault.jpg'" />
                        <div class="position-absolute video-name">
                            <span>
                                <i class="icofont icofont-arrow-left"></i>
                                <span class="position">{{ylistTargetVideos[0].term}}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col c{{ylistTargetVideos[1].youtubeVideo.yid}} term-center">
                    <div class="position-relative">
                        <img
                            [src]="ylistTargetVideos[1].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[1].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[1].youtubeVideo.yid) + '/mqdefault.jpg'" />
                        <div class="position-absolute video-name">
                            <span>
                                <i class="icofont icofont-dart"></i>
                                <span class="position">{{ylistTargetVideos[1].term}}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col c{{ylistTargetVideos[2].youtubeVideo.yid}} term-right">
                    <div class="position-relative">
                        <img
                            [src]="ylistTargetVideos[2].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[2].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[2].youtubeVideo.yid) + '/mqdefault.jpg'" />
                        <div class="position-absolute video-name">
                            <span>
                                <i class="icofont icofont-arrow-right"></i>
                                <span class="position">{{ylistTargetVideos[2].term}}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="listDisplayType == ListPlayTypeEnum.topBottom && countItems > 1">
            <div class="row m-b-10 top-bottom">
                <div class="col c{{ylistTargetVideos[0].youtubeVideo.yid}} term-top">
                    <div class="position-relative">
                        <img
                            [src]="ylistTargetVideos[0].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[0].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[0].youtubeVideo.yid) + '/mqdefault.jpg'" />
                        <div class="position-absolute video-name">
                            <span>
                                <i class="icofont icofont-arrow-up"></i>
                                <span class="position">{{ylistTargetVideos[0].term}}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col top-bottom c{{ylistTargetVideos[1].youtubeVideo.yid}} term-bottom">
                    <div class="position-relative">
                        <img
                            [src]="ylistTargetVideos[1].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[1].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[1].youtubeVideo.yid) + '/mqdefault.jpg'" />
                        <div class="position-absolute video-name">
                            <span>
                                <i class="icofont icofont-arrow-down"></i>
                                <span class="position">{{ylistTargetVideos[1].term}}</span>
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="listDisplayType == ListPlayTypeEnum.topCenterBottom && countItems > 2">
            <div class="row m-b-10 top-center-bottom">
                <div class="col c{{ylistTargetVideos[0].youtubeVideo.yid}} term-top">
                    <div class="position-relative">
                        <img
                            [src]="ylistTargetVideos[0].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[0].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[0].youtubeVideo.yid) + '/mqdefault.jpg'" />
                        <div class="position-absolute video-name">
                            <span>
                                <i class="icofont icofont-arrow-up"></i>
                                <span class="position">{{ylistTargetVideos[0].term}}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-b-10 top-center-bottom">
                <div class="col c{{ylistTargetVideos[1].youtubeVideo.yid}} term-center">
                    <div class="position-relative">
                        <img
                            [src]="ylistTargetVideos[1].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[1].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[1].youtubeVideo.yid) + '/mqdefault.jpg'" />
                        <div class="position-absolute video-name">
                            <span>
                                <i class="icofont icofont-dart"></i>
                                <span class="position">{{ylistTargetVideos[1].term}}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-b-10 top-center-bottom">
                <div class="col c{{ylistTargetVideos[2].youtubeVideo.yid}} term-bottom">
                    <div class="position-relative">
                        <img
                            [src]="ylistTargetVideos[2].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[2].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[2].youtubeVideo.yid) + '/mqdefault.jpg'" />
                        <div class="position-absolute video-name">
                            <span>
                                <i class="icofont icofont-arrow-down"></i>
                                <span class="position">{{ylistTargetVideos[2].term}}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="listDisplayType == ListPlayTypeEnum.smallBig && countItems > 1">
            <div class="row small-big">
                <div class="col-4 c{{ylistTargetVideos[0].youtubeVideo.yid}} term-small">
                    <div class="position-relative">
                        <img [src]="ylistTargetVideos[0].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[0].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[0].youtubeVideo.yid) + '/mqdefault.jpg'"
                            style="width:100%" />
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[0].term}}</span></div>
                    </div>
                </div>
                <div class="col-8 c{{ylistTargetVideos[1].youtubeVideo.yid}} term-big">
                    <div class="position-relative">
                        <img [src]="ylistTargetVideos[1].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[1].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[1].youtubeVideo.yid) + '/mqdefault.jpg'"
                            style="width:100%" id="bigImage" />
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[1].term}}</span></div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="listDisplayType == ListPlayTypeEnum.redBlueGreenYellowOrangeBrownPinkGreyBlackWhitePurple">
            <div class="row color">
                <!-- class="animated pulse infinite" -->
                <div *ngIf="countItems >= 1"
                    class="c{{ylistTargetVideos[0].youtubeVideo.yid}} {{colColorClasses}} term-red">
                    <div class="position-relative">
                        <img [src]="ylistTargetVideos[0].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[0].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[0].youtubeVideo.yid) + '/mqdefault.jpg'"
                            style="border-color: red" [style.borderWidth]="this.borderWidth+'px'" />
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[0].term}}</span></div>
                    </div>
                </div>
                <div class="c{{ylistTargetVideos[1].youtubeVideo.yid}} {{colColorClasses}} term-blue"
                    *ngIf="countItems >= 2">
                    <div class="position-relative">
                        <img [src]="ylistTargetVideos[1].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[1].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[1].youtubeVideo.yid) + '/mqdefault.jpg'"
                            style="border-color: blue" [style.borderWidth]="this.borderWidth+'px'" />
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[1].term}}</span></div>
                    </div>
                </div>
                <div class="c{{ylistTargetVideos[2].youtubeVideo.yid}} {{colColorClasses}} term-green"
                    *ngIf="countItems >= 3">
                    <div class="position-relative">
                        <img [src]="ylistTargetVideos[2].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[2].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[2].youtubeVideo.yid) + '/mqdefault.jpg'"
                            style="border-color: green" [style.borderWidth]="this.borderWidth+'px'" />
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[2].term}}</span></div>
                    </div>
                </div>
                <div class="c{{ylistTargetVideos[3].youtubeVideo.yid}} {{colColorClasses}} term-yellow"
                    *ngIf="countItems >= 4">
                    <div class="position-relative">
                        <img [src]="ylistTargetVideos[3].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[3].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[3].youtubeVideo.yid) + '/mqdefault.jpg'"
                            style="border-color: yellow" [style.borderWidth]="this.borderWidth+'px'" />
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[3].term}}</span></div>
                    </div>
                </div>
                <div class="c{{ylistTargetVideos[4].youtubeVideo.yid}} {{colColorClasses}} term-orange"
                    *ngIf="countItems >= 5">
                    <div class="position-relative">
                        <img [src]="ylistTargetVideos[4].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[4].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[4].youtubeVideo.yid) + '/mqdefault.jpg'"
                            style="border-color: #F76300" [style.borderWidth]="this.borderWidth+'px'" />
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[4].term}}</span></div>
                    </div>
                </div>
                <div class="c{{ylistTargetVideos[5].youtubeVideo.yid}} {{colColorClasses}} term-brown"
                    *ngIf="countItems >= 6">
                    <div class="position-relative">
                        <img [src]="ylistTargetVideos[5].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[5].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[5].youtubeVideo.yid) + '/mqdefault.jpg'"
                            style="border-color: #593E31" [style.borderWidth]="this.borderWidth+'px'" />
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[5].term}}</span></div>
                    </div>
                </div>
                <div class="c{{ylistTargetVideos[6].youtubeVideo.yid}} {{colColorClasses}} term-pink"
                    *ngIf="countItems >= 7">
                    <div class="position-relative">
                        <img [src]="ylistTargetVideos[6].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[6].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[6].youtubeVideo.yid) + '/mqdefault.jpg'"
                            style="border-color: pink" [style.borderWidth]="this.borderWidth+'px'" />
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[6].term}}</span></div>
                    </div>
                </div>
                <div class="c{{ylistTargetVideos[7].youtubeVideo.yid}} {{colColorClasses}} term-grey term-gray"
                    *ngIf="countItems >= 8">
                    <div class="position-relative">
                        <img [src]="ylistTargetVideos[7].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[7].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[7].youtubeVideo.yid) + '/mqdefault.jpg'"
                            style="border-color: grey" [style.borderWidth]="this.borderWidth+'px'" />
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[7].term}}</span></div>
                    </div>
                </div>
                <div class="c{{ylistTargetVideos[8].youtubeVideo.yid}} {{colColorClasses}} term-black"
                    *ngIf="countItems >= 9">
                    <div class="position-relative">
                        <img [src]="ylistTargetVideos[8].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[8].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[8].youtubeVideo.yid) + '/mqdefault.jpg'"
                            style="border-color: black;outline: solid 1px #2D2D2D;"
                            [style.borderWidth]="this.borderWidth+'px'" />
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[8].term}}</span></div>
                    </div>
                </div>
                <div class="c{{ylistTargetVideos[9].youtubeVideo.yid}} {{colColorClasses}} term-white"
                    *ngIf="countItems >= 10">
                    <div class="position-relative">
                        <img [src]="ylistTargetVideos[9].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[9].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[9].youtubeVideo.yid) + '/mqdefault.jpg'"
                            style="border-color: white" [style.borderWidth]="this.borderWidth+'px'" />
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[9].term}}</span></div>
                    </div>
                </div>
                <div class="c{{ylistTargetVideos[10].youtubeVideo.yid}} {{colColorClasses}} term-purple"
                    *ngIf="countItems >= 11">
                    <div class="position-relative">
                        <img [src]="ylistTargetVideos[10].youtubeVideo.customThumbnailUrl ? ylistTargetVideos[10].youtubeVideo.customThumbnailUrl : 'https://img.youtube.com/vi/' + authService.getOriginalYID(ylistTargetVideos[10].youtubeVideo.yid) + '/mqdefault.jpg'"
                            style="border-color: purple" [style.borderWidth]="this.borderWidth+'px'" />
                        <div class="position-absolute video-name"><span>{{ ylistTargetVideos[10].term}}</span></div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>