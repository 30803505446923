import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Student, Lesson } from 'src/app/services/constant.service';
import { cloneDeep } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
// import * as firebase from 'firebase';
import { HttpClient } from '@angular/common/http';
import { R2Service } from 'src/app/services/r2.service';
import { environment } from 'src/environments/environment';
import { KvService } from 'src/app/services/kv.service';
declare var require;
const Swal = require('sweetalert2');

export interface Task {
  text: string;
  completed: boolean;
}
@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss'],
})
export class StudentsComponent implements OnInit {
  public items: Student[] = [];
  public editStudentId: string;
  public visible: boolean;
  public modal: NgbModalRef;
  public actionType: 'add' | 'edit' = null;
  searchTerm: string = '';
  searchResults: Student[] = [];
  public saveButtonRef = { label: 'Save' };

  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private modalService: NgbModal,
    private http: HttpClient,
    private r2Service: R2Service,
    private kvService: KvService
  ) {}

  // A warning message, with a function attached to the "Confirm"-button...
  withConfirmation(emailAddress) {
    Swal.fire({
      title: this.translate.instant('Confirmation'),
      showCancelButton: true,
      confirmButtonText: this.translate.instant(
        'Delete this student from accessing my school?'
      ),
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteStudent(emailAddress);
      }
    });
  }

  searchTermChanged(event) {
    this.searchResults = this.items.filter((item) => {
      return (
        item.studentName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >
        -1
      );
    });
  }

  //For deleting a task
  async deleteStudent(emailAddress) {
    this.authService.toastSubmit();
    const studentId = await this.authService.encryptText(emailAddress);
    this.items = this.items.filter((item) => {
      return item.studentId != studentId;
    });
    // this.items = [];
    this.authService.toastSubmit();
    this.authService.userData.students = this.items;
    this.searchTermChanged(null);
    await this.saveStudentSchools(emailAddress, 'remove');
    await this.authService.PushUserDataChanges();
    this.authService.toastSaved(this.saveButtonRef);
  }

  async ngOnInit() {
    this.items = await this.authService.userData.students;
    this.searchResults = cloneDeep(this.items);
  }

  cancel() {
    this.router.navigate(['/library']);
  }

  // async saveSchoolStudents() {
  //   // const studentPath =
  //   //   'lessons/' + this.authService.userData.uid + '/students.json';
  //   // await this.r2Service.uploadFile(
  //   //   this.authService.compressUserData(this.items),
  //   //   studentPath
  //   // );
  //   this.authService.userData.students = this.items;
  //   this.searchTermChanged(null);
  // }

  async saveStudentSchools(emailAddress: string, actionType: 'add' | 'remove') {
    // const studentId = await this.authService.encryptText(emailAddress);
    await this.kvService.updateProperty(
      emailAddress,
      'schools',
      JSON.stringify({
        school: {
          schoolId: this.authService.userData.uid,
          schoolName: this.authService.userData.email,
        },
        type: actionType,
      }),
      'users'
    );
    // let schools = await this.authService.getSchoolsFromStudent(studentId);
    // if (actionType == 'add') {
    //   this.authService.userData.schools.push({
    //     schoolId: this.authService.userData.uid,
    //     schoolName: this.authService.userData.email,
    //   });
    //   // schools.push({
    //   //   schoolId: this.authService.userData.uid,
    //   //   schoolName: this.authService.userData.email,
    //   // });
    // } else {
    //   // schools = schools.filter((item) => {
    //   //   return item.schoolId != this.authService.userData.uid;
    //   // });
    //   this.authService.userData.schools = this.authService.userData.schools.filter(
    //     (item) => {
    //       return item.schoolId != this.authService.userData.uid;
    //     }
    //   );
    // }
    // const path = 'lessons/' + studentId + '/schools.json';
    // await this.r2Service.uploadFile(
    //   this.authService.compressUserData(schools),
    //   path,
    //   1
    // );
  }

  edit(studentId: any, content) {
    this.editStudentId = studentId;
    this.actionType = 'edit';
    this.modal = this.modalService.open(content, { size: 'sm' });
  }

  add(content) {
    this.editStudentId = null;
    this.actionType = 'add';
    this.modal = this.modalService.open(content, { size: 'sm' });
  }
  async eventChange($event) {
    if ($event) {
      this.authService.toastSubmit();
      this.authService.userData.students = this.items;
      this.searchTermChanged(null);
      const studentId = await this.authService.encryptText($event.emailAddress);
      if ($event.actionType == 'add') {
        await this.saveStudentSchools($event.emailAddress, 'add');
      } else {
        this.items = this.items.map((student) => {
          if (student.studentId == studentId) {
            student.studentName = $event.studentName;
          }
          return student;
        });
      }

      this.items = this.authService.userData.students;
      this.searchResults = cloneDeep(this.items);
      await this.authService.PushUserDataChanges();
      this.authService.toastSaved(this.saveButtonRef);
    }
    this.modal.close();
  }
}
