<div class="dark-body-only" id="outer-container">
  <!-- page-wrapper Start-->
  <div class="page-wrapper" id="canvas-bookmark">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper">
      <div class="page-body">
        <div
          class="container"
          style="background-color: #fff; margin-top: 80px; padding: 3rem 2rem"
        >
          <div class="col-xs-12">
            <h2>{{ "Account Removal Request" | translate }}</h2>
            <br />
            <p>
              {{
                "In any cases that you wish to remove/delete your account and the account data from our system, we take it seriously. All you need to do is login and go to https://speaknplay.app/library and Click setting in the top right page and click delete account. There is no delay in your deletion, so please make sure your decision as we do not have your back up data. If you wish to backup your data before deleting, please click export."
                  | translate
              }}
            </p>
          </div>
        </div>
      </div>
      <!-- footer start-->

      <!-- footer End-->
    </div>
    <!-- Page Body End-->
  </div>
  <!-- page-wrapper End-->
</div>
<footer class="footer">
  <app-footer></app-footer>
</footer>
