import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss'],
})
export class SocialLoginComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {}

  loginGoogle() {
    // this.analyticsService.analytics.logEvent('login_page_clicked_google_login');
    Swal.showLoading();
    // this.authService.GoogleAuth();
  }

  loginYoutube() {
    // this.analyticsService.analytics.logEvent(
    //   'login_page_clicked_youtube_login'
    // );
    Swal.showLoading();
    // this.authService.youtubeSignIn();
  }

  // Login With Twitter
  loginTwitter(): void {
    // this.analyticsService.analytics.logEvent(
    //   'login_page_clicked_twitter_login'
    // );
    Swal.showLoading();
    // this.authService.signInTwitter();
  }

  // Login With Facebook
  loginFacebook() {
    // this.analyticsService.analytics.logEvent(
    //   'login_page_clicked_facebook_login'
    // );
    Swal.showLoading();
    // this.authService.signInFacebok();
  }
}
