import { AnalyticsService } from './services/analytics.service';
import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  Injectable,
  ErrorHandler,
  APP_INITIALIZER,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { AuthService } from './services/firebase/auth.service';
import { YoutubeService } from './services/youtube.service';
import { LocalIndexedDBStorageService } from './services/local-indexeddb-storage.service';
import { AdminGuard } from './guard/admin.guard';
import { SecureInnerPagesGuard } from './guard/SecureInnerPagesGuard.guard';
import { CookieService } from 'ngx-cookie-service';
// import { AngularFireModule } from "@angular/fire";
// import { AngularFireAuthModule } from "@angular/fire/auth";

import { environment } from '../environments/environment';
// import { AngularFirestoreModule } from "@angular/fire/firestore";
// import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { YouTubePlayerModule } from '@angular/youtube-player';
import { ServiceWorkerModule } from '@angular/service-worker';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { FullLayoutComponent } from './components/layout/full-layout/full-layout.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { DragulaModule } from 'ng2-dragula';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import 'hammerjs';
import 'mousetrap';
import {
  HammerModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
// services
import { NavService } from './services/nav.service';
import { CustomizerService } from './services/customizer.service';
// Directives
import { LanguagesComponent } from './components/languages/languages.component';
import { NumberSuffixPipe } from './pipe/number-suffix.pipe';
import { SettingComponent } from './components/setting/setting.component';
import { MenuGeneratorComponent } from './components/menu-generator/menu-generator.component';
import { CreateLessonFromYoutubeVideoComponent } from './components/create-lesson-from-youtube-video/create-lesson-from-youtube-video.component';
import { PreviewVideoComponent } from './components/preview-video/preview-video.component';
import { HomeComponent } from './components/home/home.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LibraryComponent } from './components/library/library.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { EditLessonComponent } from './components/edit-lesson/edit-lesson.component';
import { EditCategoryComponent } from './components/edit-category/edit-category.component';
import { ImageCropperComponent } from './components/image-cropper/component/image-cropper.component';
import { HttpClientModule } from '@angular/common/http';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { LoginComponent } from './components/login/login.component';
import { PurchaseComponent } from './components/purchase/purchase.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermOfServiceComponent } from './components/term-of-service/term-of-service.component';
import { DeleteUserComponent } from './components/delete-user/delete-user.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { QuestionerAvatarShotComponent } from './components/questioner-avatar-shot/questioner-avatar-shot.component';
import { QuestionerAvatarManagementComponent } from './components/questioner-avatar-management/questioner-avatar-management.component';
import { DataDeletionComponent } from './components/data-deletion/data-deletion.component';
import { CreateYoutubeAPIKeyComponent } from './components/create-youtube-apikey/create-youtube-apikey.component';
import { InfoComponent } from './components/info/info.component';
import { NoSupportedDeviceComponent } from './components/no-supported-device/no-supported-device.component';
import { ReloadComponent } from './components/reload/reload.component';
import { SocialLoginComponent } from './components/social-login/social-login.component';
import { QuestionerAudioQuestionManagementComponent } from './components/questioner-audio-question-management/questioner-audio-question-management.component';
import { NewQuestionerAudioQuestionComponent } from './components/questioner-audio-question/questioner-audio-question.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { EditStudentComponent } from './components/edit-student/edit-student.component';
import { StudentsComponent } from './components/students/students.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CapitalizeFirstPipe } from './pipe/capitalize-first.pipe';
declare var require;
if (environment.production) {
  Sentry.init({
    dsn: 'https://7e0840c9563e473a8e1696895693c957@o952196.ingest.sentry.io/5901537',
    release: require('../../package.json').version,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost:4200', /^\//],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    ContentLayoutComponent,
    FullLayoutComponent,
    FeatherIconsComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    LanguagesComponent,
    NumberSuffixPipe,
    SettingComponent,
    MenuGeneratorComponent,
    CreateLessonFromYoutubeVideoComponent,
    PreviewVideoComponent,
    HomeComponent,
    LibraryComponent,
    EditLessonComponent,
    CategoriesComponent,
    EditCategoryComponent,
    ImageCropperComponent,
    LoginComponent,
    PurchaseComponent,
    PrivacyPolicyComponent,
    TermOfServiceComponent,
    DeleteUserComponent,
    RegisterComponent,
    ResetPasswordComponent,
    QuestionerAvatarShotComponent,
    QuestionerAvatarManagementComponent,
    DataDeletionComponent,
    CreateYoutubeAPIKeyComponent,
    InfoComponent,
    NoSupportedDeviceComponent,
    ReloadComponent,
    SocialLoginComponent,
    QuestionerAudioQuestionManagementComponent,
    NewQuestionerAudioQuestionComponent,
    UploadImageComponent,
    EditStudentComponent,
    StudentsComponent,
    VerifyEmailComponent,
    ChangePasswordComponent,
    CapitalizeFirstPipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAuthModule,
    // AngularFirestoreModule,
    // AngularFireFunctionsModule,
    YouTubePlayerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),

    CommonModule,
    RouterModule,
    // TranslateModule,
    DragulaModule.forRoot(),
    NgbModule,
    NgSelectModule,
    InfiniteScrollModule,

    HttpClientModule,
    Ng2SearchPipeModule,
    HammerModule,
  ],
  exports: [
    LoaderComponent,
    FeatherIconsComponent,
    TranslateModule,
    LanguagesComponent,
    SettingComponent,
    BreadcrumbComponent,
    NumberSuffixPipe,
    MenuGeneratorComponent,
    CreateLessonFromYoutubeVideoComponent,
    PreviewVideoComponent,
  ],
  providers: [
    AuthService,
    AdminGuard,
    SecureInnerPagesGuard,
    CookieService,
    NavService,
    CustomizerService,
    YoutubeService,
    AnalyticsService,
    LocalIndexedDBStorageService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
