import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Lesson,
  Video,
  QuestionAnswer,
  QuestionerAudioQuestion,
} from '../../services/constant.service';
import { AuthService } from '../../services/firebase/auth.service';
import {
  Component,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-questioner-audio-question-management',
  templateUrl: './questioner-audio-question-management.component.html',
  styleUrls: ['./questioner-audio-question-management.component.scss'],
})
export class QuestionerAudioQuestionManagementComponent implements OnInit {
  itemsInSelectedLang: QuestionerAudioQuestion[] = [];
  searchResults: QuestionerAudioQuestion[] = [];
  audioQuestionModal = null;
  selectedEditAudioQuestion: QuestionerAudioQuestion = null;
  @ViewChild('audioQuestionForm') audioQuestionForm: TemplateRef<any>;
  @Output() audioQuestionChange = new EventEmitter();
  searchTerm: string = '';
  public saveButtonRef = { label: 'Save' };

  constructor(
    public authService: AuthService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.updatePageData();
  }

  updatePageData() {
    this.itemsInSelectedLang = cloneDeep(
      this.authService.schoolData.questionerAudioQuestions
    );
    this.searchResults = cloneDeep(this.itemsInSelectedLang);
  }

  toggleDisable(audioQuestionId: number) {
    this.itemsInSelectedLang = this.itemsInSelectedLang.map((item) => {
      if (item.id == audioQuestionId) {
        item.disabled = !item.disabled;
      }
      return item;
    });
  }

  searchTermChanged(event) {
    this.searchResults = this.itemsInSelectedLang.filter((item) => {
      return (
        item.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
      );
    });
  }

  audioQuestionSelectedChanged(
    questionerAudioQuestion: QuestionerAudioQuestion
  ) {
    this.updatePageData();
    this.selectedEditAudioQuestion = null;
    if (questionerAudioQuestion) {
      this.authService.toastSaved(this.saveButtonRef);
    }
    if (this.audioQuestionModal) {
      this.audioQuestionModal.close();
    }
  }

  addAudioQuestion() {
    this.audioQuestionModal = this.modalService.open(this.audioQuestionForm, {
      size: 'md',
    });
  }

  editAudioQuestion(avatar: QuestionerAudioQuestion) {
    this.selectedEditAudioQuestion = avatar;
    this.audioQuestionModal = this.modalService.open(this.audioQuestionForm, {
      size: 'md',
    });
  }

  withConfirmation(questionerAudioQuestionId) {
    Swal.fire({
      title: this.translate.instant('Are you sure?'),
      text: this.translate.instant(
        "You won't be able to revert this! All the questions that use this audio question will be deleted too."
      ),
      type: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',
      confirmButtonText: this.translate.instant('Yes, delete it!'),
    }).then((result) => {
      if (result.value) {
        this.deleteAudioQuestion(questionerAudioQuestionId);
      }
    });
  }

  async deleteAudioQuestion(questionerAudioQuestionId) {
    this.authService.toastSubmit();
    let deletingAudioQuestion =
      this.authService.schoolData.questionerAudioQuestions.find((item) => {
        return item.id == questionerAudioQuestionId;
      });
    this.itemsInSelectedLang = this.itemsInSelectedLang.filter((item) => {
      return item.id != questionerAudioQuestionId;
    });
    this.authService.schoolData.questionerAudioQuestions =
      this.authService.schoolData.questionerAudioQuestions.filter((item) => {
        return item.id != questionerAudioQuestionId;
      });
    let submitted = false;
    let submit = () => {
      if (!submitted) {
        submitted = true;
        this.authService.schoolData.data.lessons =
          this.authService.schoolData.data.lessons.map((playItem: Lesson) => {
            playItem.ylist = playItem.ylist.map((youtubeVideo: Video) => {
              if (
                youtubeVideo.hasOwnProperty('questionAnswers') &&
                youtubeVideo.questionAnswers &&
                youtubeVideo.questionAnswers.length > 0
              ) {
                youtubeVideo.questionAnswers =
                  youtubeVideo.questionAnswers.filter(
                    (questionAnswer: QuestionAnswer) => {
                      return (
                        questionAnswer.question &&
                        questionAnswer.question != questionerAudioQuestionId
                      );
                    }
                  );
              }
              return youtubeVideo;
            });
            return playItem;
          });

        this.authService.PushUserDataChanges().then(() => {
          this.updatePageData();
          this.authService.toastSaved(this.saveButtonRef);
        });
      }
    };
    if (
      deletingAudioQuestion &&
      deletingAudioQuestion.audioURL.includes(this.authService.userData.uid)
    ) {
      await this.authService.deleteFromURL(
        deletingAudioQuestion.audioURL,
        submit
      );
    } else {
      submit();
    }
  }

  cancel() {
    this.router.navigate(['/library']);
  }

  goToAvatar() {
    this.router.navigate(['/library/avatar']);
  }

  useIt(avatar: QuestionerAudioQuestion) {
    this.audioQuestionChange.emit(avatar);
  }

  save() {
    this.authService.toastSubmit();
    this.authService.schoolData.questionerAudioQuestions =
      this.authService.schoolData.questionerAudioQuestions.map((item) => {
        let localItem = this.itemsInSelectedLang.find((localItem) => {
          return localItem.id == item.id;
        });
        if (localItem) {
          item.disabled = localItem.disabled;
        }
        return item;
      });
    this.authService.PushUserDataChanges().then(() => {
      this.updatePageData();
      this.authService.toastSaved(this.saveButtonRef);
    });
  }
}
