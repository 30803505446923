import { AnalyticsService } from './../../services/analytics.service';
import { SpeechService } from './../../services/speech.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../services/firebase/auth.service';
import { APIResponse } from 'src/app/services/constant.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };
declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public newUser = false;
  public user: any;
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    email: '',
    password: '',
  };
  public errorMessage: any;
  public browserSupport = true;

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    private toster: ToastrService,
    private translate: TranslateService,
    public speechService: SpeechService,
    private fb: FormBuilder // , // private analyticsService: AnalyticsService
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.browserSupport = !this.speechService.useCloudSpeech;
    if (this.authService.userData) {
      this.authService.SignOut(false);
    }
    // this.analyticsService.analytics.logEvent('page_view', {
    //   page_title: 'register',
    // });
  }

  signup() {
    const apiEndpoint = environment.apiEndpoint;
    const url = `${apiEndpoint}/register`;
    Swal.showLoading();
    this.http
      .post(url, {
        email: this.loginForm.value['email'],
        password: this.loginForm.value['password'],
      })
      .subscribe({
        next: (apiResponse: APIResponse) => {
          if (apiResponse.success) {
            this.toster.success(
              this.translate.instant(
                'We have sent you a verification email. Please check your inbox and click on the link to verify your email address.'
              ),
              '',
              {
                timeOut: 60000,
              }
            );
          } else {
            this.toster.error(
              this.translate.instant(
                'Something went wrong: ' + apiResponse.message
              ),
              '',
              {
                timeOut: 60000,
              }
            );
          }
          Swal.close();
        },
        error: (apiResponse: APIResponse) => {
          Swal.close();
          this.toster.error(apiResponse.message);
        },
      });
    // this.analyticsService.analytics.logEvent('register_page_clicked_register');
  }
}
