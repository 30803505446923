import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import 'rxjs/add/operator/toPromise';
import { CommonService } from './common.service';
import { HttpHeaders } from '@angular/common/http';
import { APIResponse } from './constant.service';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class KvService {
  errorMessage: string | undefined;
  constructor(public http: HttpClient, private commonService: CommonService) {}

  async read(key: string, index: string): Promise<any> {
    const apiEndpoint = environment.apiEndpoint;
    const url = apiEndpoint + '/kv'; // Replace with your Worker's URL
    const data = JSON.stringify({ key: key, operation: 'read', index: index });

    try {
      const response: any = await this.http
        .post(url, data, {
          headers: this.commonService.appendAuthenticationHeader(
            new HttpHeaders().set('Content-Type', 'application/json')
          ),
          observe: 'response',
          responseType: 'json',
        })
        .toPromise();

      if (response.body.success) {
        return JSON.parse(response.body.data);
      } else {
        this.handleError(response.body.message);
      }
    } catch (error) {
      this.handleError(error.message);
    }
  }

  async translate(textArray: string[], index: string, language): Promise<any> {
    const apiEndpoint = environment.apiEndpoint;
    const url = apiEndpoint + '/kv'; // Replace with your Worker's URL
    const data = JSON.stringify({
      key: language,
      value: JSON.stringify(textArray),
      operation: 'translate',
      index: index,
    });

    try {
      const response: any = await this.http
        .post(url, data, {
          headers: this.commonService.appendAuthenticationHeader(
            new HttpHeaders().set('Content-Type', 'application/json')
          ),
          observe: 'response',
          responseType: 'json',
        })
        .toPromise();

      if (response.body.success) {
        return JSON.parse(response.body.data);
      } else {
        this.handleError(response.body.message);
      }
    } catch (error) {
      this.handleError(error.message);
    }
  }

  async delete(key: string, index: string, operation = 'delete'): Promise<any> {
    const apiEndpoint = environment.apiEndpoint;
    const url = apiEndpoint + '/kv';
    const data = JSON.stringify({
      key: key,
      operation: operation,
      index: index,
    });

    try {
      const response: any = await this.http
        .post(url, data, {
          observe: 'response',
          headers: this.commonService.appendAuthenticationHeader(
            new HttpHeaders().set('Content-Type', 'application/json')
          ),
          responseType: 'json',
        })
        .toPromise();

      if (response.body.success) {
        return response.body.data;
      } else if (response.status === 404) {
        this.errorMessage = 'Key not found.';
        this.handleError(this.errorMessage);
      } else {
        this.handleError(response.statusText);
        this.handleError(response.statusText);
      }
    } catch (error) {
      this.handleError(error.message);
    }
  }

  async write(key: string, value: string, index: string): Promise<void> {
    const apiEndpoint = environment.apiEndpoint;
    const url = apiEndpoint + '/kv'; // Replace with your Worker's URL
    const data = JSON.stringify({
      key: key,
      value: value,
      operation: 'write',
      index: index,
    });

    try {
      const response: any = await this.http
        .post(url, data, {
          observe: 'response',
          headers: this.commonService.appendAuthenticationHeader(
            new HttpHeaders().set('Content-Type', 'application/json')
          ),
          responseType: 'json',
        })
        .toPromise();

      if (response.body.success) {
        return response.body.data;
      } else if (response.status === 404) {
        this.errorMessage = 'Key not found.';
        this.handleError(this.errorMessage);
      } else {
        this.handleError(response.statusText);
        this.handleError(response.statusText);
      }
    } catch (error) {
      this.handleError(error.message);
    }
  }

  async updateProperty(
    key: string,
    propertyKey: string,
    propertyValue: string,
    index: string
  ): Promise<void> {
    const apiEndpoint = environment.apiEndpoint;
    const url = apiEndpoint + '/kv'; // Replace with your Worker's URL
    const data = JSON.stringify({
      key: key,
      propertyKey: propertyKey,
      propertyValue: propertyValue,
      operation: 'update',
      index: index,
    });

    try {
      const response: any = await this.http
        .post(url, data, {
          observe: 'response',
          headers: this.commonService.appendAuthenticationHeader(
            new HttpHeaders().set('Content-Type', 'application/json')
          ),
          responseType: 'json',
        })
        .toPromise();

      if (response.body.success) {
        return response.body.data;
      } else if (response.status === 404) {
        this.errorMessage = 'Key not found.';
        this.handleError(this.errorMessage);
      } else {
        this.handleError(response.statusText);
        this.handleError(response.statusText);
      }
    } catch (error) {
      this.handleError(error.message);
    }
  }

  downloadFile(url: string, filename: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  }

  private handleError(error: any) {
    if (typeof error === 'string') {
      error = new Error(error);
    }
    Sentry.captureException(error);
  }
}

interface FileData {
  content: string; // For upload
  url?: string; // For get
}
