import { NavService } from './../../services/nav.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  DingAudioDataURL,
  LessonAgeGroups,
  QuestionAnswer,
  QuestionerAvatar,
} from './../../services/constant.service';
import { AnalyticsService } from './../../services/analytics.service';
import { SpeechService } from './../../services/speech.service';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep, isEqual } from 'lodash-es';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/firebase/auth.service';
import * as moment from 'moment';
import {
  LearningData,
  Lesson,
  Category,
  Video,
  YlistTargetVideo,
} from 'src/app/services/constant.service';
import { YoutubeService } from 'src/app/services/youtube.service';

declare var require;
const stringSimilarity = require('string-similarity');
const Swal = require('sweetalert2');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  public items: Lesson[];
  public categories: Category[];
  public targetYoutube: YT.Player;
  public currentVideoId: string = '';
  public currentVideo: Video = null;
  public currentLesson: Lesson = null;
  public isVideoPlaying = false;
  public selectedCategoryItems: Lesson[];
  public currentSelectedCategory: number;
  private defaultSpeechText = 'Speak to pick a lesson, category';
  public currentSpeech: string;
  private demandList = ['continue', 'pause'];
  public modal: NgbModalRef;
  public purchaseModal: NgbModalRef;
  public settingModal: NgbModalRef;
  public ylistMenuModal: NgbModalRef;
  private processQuestionAnswersInt = null;
  public currentQuestionAnswer: QuestionAnswer;
  public purchaseHeader = 'Upgrade';
  public expiredAt = '';

  @ViewChild('myModal')
  private myModal: TemplateRef<any>;

  @ViewChild('ylistModal')
  private ylistModal: TemplateRef<any>;
  private currentPlayTime = 0;
  // app stop is microphone is not on and video is not playing, example: modal opened
  private isAppStop = true;
  public devMode = false;

  private acceptKeys = ['Control', ' ']; // space, esc
  private hasInit = false;
  public ytReady = false;
  private youtubeVideoRepeatCount = 0;
  private maxTimeOnMicroPhone = 120;
  private microPhoneTimeCount = 0;
  private intMicroPhone = 0;
  public currentOrigin: string;
  public selectedDialect: string;
  public console = console;
  public thumbnailContainerWidth: any = 'auto';
  private photoContainerHeight;
  private isModalOpened = false;
  private ytCheck: number;
  private btnFullScreenShowing: boolean = false;
  private animalIcons = [
    'bat',
    'bear',
    'bear-tracks',
    'bird',
    'bird-alt',
    'bone',
    'bull',
    'camel',
    'camel-alt',
    'camel-head',
    'cat',
    'cat-alt-1',
    'cat-alt-2',
    'cat-alt-3',
    'cat-alt-4',
    'cat-with-dog',
    'cow',
    'cow-head',
    'crab',
    'crocodile',
    'deer-head',
    'dog',
    'dog-alt',
    'dog-barking',
    'dolphin',
    'duck-tracks',
    'eagle-head',
    'eaten-fish',
    'elephant',
    'elephant-alt',
    'elephant-head',
    'elephant-head-alt',
    'elk',
    'fish',
    'fish-alt-1',
    'fish-alt-2',
    'fish-alt-3',
    'fish-alt-4',
    'fox',
    'fox-alt',
    'frog',
    'frog-tracks',
    'froggy',
    'giraffe',
    'giraffe-alt',
    'goat-head',
    'goat-head-alt-1',
    'goat-head-alt-2',
    'gorilla',
    'hen-tracks',
    'horse-head',
    'horse-head-alt-1',
    'horse-head-alt-2',
    'horse-tracks',
    'jellyfish',
    'kangaroo',
    'lemur',
    'lion',
    'lion-alt',
    'lion-head',
    'lion-head-alt',
    'monkey',
    'monkey-alt-1',
    'monkey-alt-2',
    'monkey-alt-3',
    'octopus',
    'octopus-alt',
    'owl',
    'panda',
    'panda-alt',
    'panther',
    'parrot',
    'parrot-lip',
    'paw',
    'pelican',
    'penguin',
    'pig',
    'pig-alt',
    'pigeon',
    'pigeon-alt',
    'pigeons',
    'rabbit-running',
    'rat-alt',
    'rhino',
    'rhino-head',
    'rooster',
    'seahorse',
    'seal',
    'shrimp',
    'snail',
    'snail-alt-1',
    'snail-alt-2',
    'snake',
    'squid',
    'squirrel',
    'tiger',
    'tiger-alt',
    'turtle',
    'whale',
    'woodpecker',
    'zebra',
  ];
  public randomIcon = 'fish';
  private liveTracking;
  private processedTranscript: string[] = [];
  private currentSpeechResults: any[] = [];
  private processResultInt = 0;
  public version: string;
  public ylistTargetVideos: YlistTargetVideo[] = [];
  private timeOutSelectYListVideoInt = 0;
  public matchedMenuVideo: YlistTargetVideo;
  private closeMatchEnable = false;

  private videoCoverClickLock = false;
  private intendedPause = false;
  private fullScreenChange; // store enonymous function on event fullScreenChange, this is for purpose of later removing it from listen list
  private resizeChange;
  public enableFloatingSwitch = 0;
  public homeMode: HomeMode = HomeMode.beginner;
  public selectedLessonAgeGroup: number = 2;
  public isHomeModeBeginner = true;
  public currentUnixtime: any;
  public isPortrait = true;
  public isQuestionAnswerOn = false;
  public isQuestionAnswerOnAndListening = false;
  public dingAudioDataURL: any;
  public lessonAgeGroups: number[];

  constructor(
    public authService: AuthService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    public router: Router,
    public ngZone: NgZone,
    private speechService: SpeechService,
    private youtubeService: YoutubeService,
    private analyticsService: AnalyticsService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private http: HttpClient,
    public navService: NavService
  ) {
    this.currentOrigin = window.location.origin;
    this.dingAudioDataURL =
      this.sanitizer.bypassSecurityTrustResourceUrl(DingAudioDataURL);
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy();
  }
  destroy() {
    $(document).off('keyup');
    $(document).off('keydown');
    $(document).off('mousemove');

    // window.onkeydown = null;
    // window.onkeyup = null;
    window.removeEventListener('fullscreenchange', this.fullScreenChange, true);
    window.removeEventListener('resize', this.resizeChange, true);
    clearInterval(this.ytCheck);
    clearInterval(this.intMicroPhone);
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('background-color');
    clearInterval(this.liveTracking);
    clearInterval(this.processQuestionAnswersInt);
    clearInterval(this.intervalCheckToSkipPartOfVideo);
    this.stopRecognition();
    setTimeout(() => {
      try {
        this.stopRecognition();
      } catch (ex) {}
    });
  }

  ngOnInit(): void {
    this.lessonAgeGroups = Object.assign([], LessonAgeGroups);
    if (this.authService.mobileAndTabletcheck()) {
      this.router.navigate(['no-supported-device']);
    } else {
      // trigger this load for later usage, other wise it'll return empty string
      this.speechService.initSpeechRecognition();
      this.currentUnixtime = moment().format('X');
      if (this.speechService.useCloudSpeech) {
        this.authService.SignOut();
        return;
      }
      try {
        let lastselectedLessonAgeGroup = localStorage.getItem(
          'selected_lesson_age_group'
        );
        if (lastselectedLessonAgeGroup) {
          this.selectedLessonAgeGroup = parseInt(lastselectedLessonAgeGroup);
        } else {
          this.selectedLessonAgeGroup = 2;
        }
      } catch (e) {
        this.enableFloatingSwitch = 0;
      }
      this.selectedQuestionerAvatar = this.defaultQuestionerAvatar;
      try {
        this.enableFloatingSwitch =
          parseInt(localStorage.getItem('enableFloatingSwitch')) || 0;
      } catch (e) {
        this.enableFloatingSwitch = 0;
      }
      this.translateDefaultSpeechText();

      try {
        this.homeMode =
          (localStorage.getItem('homeMode') as HomeMode) || HomeMode.advance;
        this.isHomeModeBeginner = this.homeMode === HomeMode.beginner;
      } catch (e) {
        this.homeMode = HomeMode.beginner;
        this.isHomeModeBeginner = true;
      }
      this.version = environment.version;
      this.currentSpeech = this.defaultSpeechText;
      window.YT['ready'](() => {
        this.ytReady = true;
      });
      document.body.style.overflow = 'hidden';
      document.body.style.backgroundColor = '#000000';
      this.authService.observerData.subscribe((schoolData: LearningData) => {
        if (!!this.targetYoutube) {
          this.initFunc();
        }
      });
      this.devMode = location.hostname.includes('localhost');
      // this.devMode = false;
      window['devMode'] = this.devMode;
      // let matches = stringSimilarity.findBestMatch('healed', ['edward', 'sealed', 'theatre']);
      // this.writeDevLog(matches);
      if (window.innerHeight < window.innerWidth) {
        this.isPortrait = false;
      }
      // this.analyticsService.analytics.logEvent('page_view', {
      //   page_title: 'home',
      // });
    }
  }

  setDevMode() {
    if (this.devMode) {
      // this.maxTimeOnMicroPhone = 300;
      if (!this.authService.mobileAndTabletcheck()) {
        Mousetrap.bind(['ctrl+m'], () => {
          this.resetToFirstTimeVisit(false);
        });
      }
    }
    if (!this.authService.mobileAndTabletcheck()) {
      Mousetrap.bind(['ctrl+e', 'ctrl+e'], () => {
        this.currentPlayTime = this.targetYoutube.getDuration() - 5;
        this.localSeekTo(this.currentPlayTime, true);
      });
      Mousetrap.bind(['ctrl+q', 'ctrl+q'], () => {
        this.currentPlayTime = this.targetYoutube.getCurrentTime() + 10;
        this.localSeekTo(this.currentPlayTime, true);
      });
      Mousetrap.bind(['ctrl+y', 'ctrl+y'], () => {
        this.intendedPause = true;
        this.targetYoutube.pauseVideo();
      });
      Mousetrap.bind(['ctrl+b', 'ctrl+b'], () => {
        this.youtubeService.calculateQuestionerAvatarSize(
          true,
          this.isHomeModeBeginner
        );
      });
    }
  }

  /**
   * Process matching speeched text and return isMatched immedately, all actions: rendering menu, play video, handle demand will be run after returning isMatched
   * so that it can stop recognition instantly to prevent more result sending from onResult event.
   */
  matchText(speechToText: string) {
    let isMatched = false;
    this.currentSpeech = speechToText;
    this.writeDevLog(speechToText);

    speechToText = speechToText.toLowerCase().trim();
    let matchCategory = null;
    let matchDemand = null;
    if (this.ylistTargetVideos.length == 0) {
      matchCategory = this.categories.find((category) => {
        if (speechToText.includes(' ')) {
          return speechToText.includes(category.name.toLowerCase());
        } else {
          return speechToText === category.name.toLowerCase();
        }
      });
      matchDemand = this.demandList.find((demand) => {
        return speechToText.includes(demand.toLowerCase());
      });
    }
    if (matchCategory && this.currentSelectedCategory !== matchCategory.cid) {
      clearInterval(this.intMicroPhone);
      this.microPhoneTimeCount = 0;
      this.currentSelectedCategory = matchCategory.cid;
      if (!this.speechService.useCloudSpeech) {
        this.speechService.recognition.interimResults = true;
      }
      this.stopRecognition(false);
      setTimeout(() => {
        this.renderMenuItems();
        // this.currentSpeech = matchCategory.name;
        this.highlightRecognizedText(matchCategory.name);
      });

      isMatched = true;
    } else if (matchDemand) {
      isMatched = true;
      clearInterval(this.intMicroPhone);
      this.microPhoneTimeCount = 0;
      setTimeout(() => {
        this.handleDemand(matchDemand);
        this.stopRecognition(false);
        this.highlightRecognizedText(matchDemand);
        this.currentSpeech = this.defaultSpeechText;
      });
    } else {
      isMatched = this.playVideoIfMatch(speechToText);
    }
    return isMatched;
  }

  findBestMatch(text: string, terms: string[]) {
    return null;
    let bestMatch: { target: string; rating: number };
    let similarityMatch = stringSimilarity.findBestMatch(text, terms);
    if (similarityMatch) {
      bestMatch = similarityMatch['bestMatch'];
      if (bestMatch['rating'] < 0.1) {
        return null;
      }
    }
    this.writeDevLog('best match: ' + bestMatch['target']);
    return bestMatch['target'];
  }

  processSpeechResult() {
    let isMatched = false;
    while (this.currentSpeechResults.length) {
      let result = this.currentSpeechResults.shift();
      for (let i = 0; i < result.length; i++) {
        const singleMatch = result[i];
        const transcript = singleMatch.transcript;
        let speechToText = transcript
          .toLowerCase()
          .split(' ')
          .slice(-10)
          .join(' ');
        if (!this.processedTranscript.includes(speechToText)) {
          this.writeDevLog('Processing transcript: ' + speechToText);
          this.writeDevLog(speechToText);
          this.processedTranscript.push(speechToText);
          isMatched = this.matchText(speechToText);
          if (isMatched) {
            break;
          }
        } else {
          this.writeDevLog('Already processed: ' + speechToText);
        }
      }
      if (isMatched) {
        this.writeDevLog('Found match: ' + this.currentSpeech);
        this.processedTranscript = [];
        this.currentSpeechResults = [];
        this.writeDevLog(this.currentSpeechResults);
        // clear last batch sent
        clearTimeout(this.processResultInt);
        // stop recognition so that no more result from onresult event sending to process
        this.stopRecognition();
        break;
      }
    }
    if (!isMatched && this.closeMatchEnable) {
      this.writeDevLog('Not match: ' + this.currentSpeech);
      const not_found_text = '<span class="bg-warning">Không tìm thấy</span>: ';
      this.currentSpeech = this.currentSpeech.replace(not_found_text, '');
      this.currentSpeech = not_found_text + this.currentSpeech;
      // process similarity matches
      let similarityMatches: {
        target: string;
        bestMatch: string;
        rating: number;
      }[] = [];
      let terms = [];
      for (let item of this.selectedCategoryItems) {
        terms = terms.concat(item.name.split(','));
      }
      let categoryTerms = this.categories.map((item) => {
        return item.name;
      });
      terms = terms.concat(categoryTerms);
      this.writeDevLog(this.processedTranscript);
      for (let transcript of this.processedTranscript) {
        let similarityMatch = stringSimilarity.findBestMatch(transcript, terms);
        similarityMatches.push({
          target: transcript,
          bestMatch: similarityMatch.bestMatch.target,
          rating: similarityMatch.bestMatch.rating,
        });
      }
      let uniqueSimilarityMatches: {
        target: string;
        bestMatch: string;
        rating: number;
      }[] = [];

      for (let similarityMatch of similarityMatches) {
        let exist = false;
        for (let uniqueSimilarityMatch of uniqueSimilarityMatches) {
          if (uniqueSimilarityMatch.bestMatch === similarityMatch.bestMatch) {
            exist = true;
            uniqueSimilarityMatch.rating =
              uniqueSimilarityMatch.rating + similarityMatch.rating;
            break;
          }
        }
        if (!exist) {
          uniqueSimilarityMatches.push(similarityMatch);
        }
      }
      uniqueSimilarityMatches.sort((a, b) => {
        return b.rating - a.rating;
      });
      this.writeDevLog(uniqueSimilarityMatches);
      if (uniqueSimilarityMatches[0].rating > 1) {
        this.processedTranscript = [];
        this.currentSpeechResults = [];
        let isMatched = this.matchText(uniqueSimilarityMatches[0].bestMatch);
        if (isMatched) {
          this.stopRecognition();
        }
      }
    }
  }

  dictate(writeLog: boolean = true) {
    if (this.router.url.includes('media')) {
      this.destroy();
    }
    // console.log('dictate');
    const repeatDictate = () => {
      if (
        (!this.isVideoPlaying && !this.isAppStop) ||
        this.isQuestionAnswerOnAndListening
      ) {
        // recognition end cause youtube automatically play, this is for prevent this weird issue happens
        if (this.authService.mobileAndTabletcheck()) {
          this.targetYoutube.pauseVideo();
        }
        this.dictate();
      }
    };
    try {
      if (!this.speechService.useCloudSpeech) {
        this.speechService.recognition.onresult = (event) => {
          Array.from(event.results).map((result) => {
            clearTimeout(this.processResultInt);
            this.processResultInt = setTimeout(() => {
              if (
                !this.isVideoPlaying ||
                this.isHomeModeBeginner ||
                this.isQuestionAnswerOnAndListening
              ) {
                this.currentSpeechResults.push(result);
                this.processSpeechResult();
              }
            }, 500);
          });

          // speechToText = speechToText.toLowerCase().split(' ').slice(-10).join(' ');
        };
        // this.speechService.recognition.onstart = (event) => {
        //   this.writeDevLog('Recognition Start Event');
        // }
        this.speechService.recognition.onspeechend = (event) => {
          // this.writeDevLog('Speech end', event);
          repeatDictate();
        };
        // this.speechService.recognition.onnomatch = (event) => {
        //   this.writeDevLog('No match', event);
        // }
        // this.speechService.recognition.onsoundstart = (event) => {
        //   this.writeDevLog('Sound start', event);
        // }
        this.speechService.recognition.onsoundend = (event) => {
          // this.writeDevLog('Sound end', event);
          repeatDictate();
        };
        // this.speechService.recognition.onspeechstart = (event) => {
        //   this.writeDevLog('Speech start', event);
        // }
        this.speechService.recognition.onerror = (event) => {
          // this.writeDevLog('Error', event);
          repeatDictate();
        };
        // this.speechService.recognition.onaudiostart = (event) => {
        //   this.writeDevLog('Audio start', event);
        // }
        this.speechService.recognition.onaudioend = (event) => {
          // this.writeDevLog('Audio end', event);
          repeatDictate();
        };

        this.speechService.recognition.onend = (event) => {
          // this.writeDevLog('onend', event);
          repeatDictate();
        };
      } else {
        this.speechService.setCloudSpeechCallback((result) => {
          console.log(result.speechResult);
          clearTimeout(this.processResultInt);
          this.processResultInt = setTimeout(() => {
            if (
              !this.isVideoPlaying ||
              this.isHomeModeBeginner ||
              this.isQuestionAnswerOnAndListening
            ) {
              this.currentSpeechResults.push([result]);
              this.processSpeechResult();
              if (!this.isVideoPlaying && !this.isAppStop) {
                // recognition end cause youtube automatically play, this is for prevent this weird issue happens
                if (this.authService.mobileAndTabletcheck()) {
                  this.targetYoutube.pauseVideo();
                }
                this.speechService.startListening();
              }
            }
          }, 500);
        });
      }
      this.speechService.startListening();
      // if speech  recognition is off, it can be started and next lines from here will be run. If speach recognition is on, exception will be thrown and next
      // lines will not reach
      this.playRecordingStart();
      document.querySelector('#recordIconContainer .icofont-mic')['style'][
        'visibility'
      ] = 'visible';
      document.querySelector('#recordIconContainer .icofont-mic-mute')['style'][
        'visibility'
      ] = 'hidden';
      // this.microPhoneTimeCount = 0;
      // clearInterval(this.intMicroPhone);
      if (!this.intMicroPhone && this.homeMode != HomeMode.beginner) {
        this.intMicroPhone = setInterval(() => {
          this.microPhoneTimeCount++;
          if (
            this.microPhoneTimeCount > 0 &&
            this.microPhoneTimeCount % this.maxTimeOnMicroPhone == 0
          ) {
            this.writeDevLog('mic count: ' + this.microPhoneTimeCount);
            clearInterval(this.intMicroPhone);
            this.microPhoneTimeCount = 0;
            this.intMicroPhone = null;
            this.resetToFirstTimeVisit();
          }
        }, 1000);
      }
    } catch (err) {
      if (writeLog) {
        this.writeDevLog('Error');
        this.writeDevLog(err.message);
      }
      return err.message;
    }
    return 'ok';
  }

  updateGrammar() {
    // grammar is not yet supported by Chrome browser
    return;
    let terms = [];
    for (let item of this.items) {
      terms = terms.concat(item.name.split(','));
    }
    for (let item of this.categories) {
      terms.push(item.name);
    }
    this.writeDevLog(terms);
    if (!this.speechService.useCloudSpeech) {
      let SpeechGrammarList =
        window['SpeechGrammarList'] || window['webkitSpeechGrammarList'];
      let grammar =
        '#JSGF V1.0; grammar terms; public <term> = ' +
        terms.join(' | ') +
        ' ;';
      let speechRecognitionList = new SpeechGrammarList();
      speechRecognitionList.addFromString(grammar, 1);
      this.speechService.recognition.grammars = speechRecognitionList;
    } else {
      this.speechService.setSpeechContext(terms);
    }
  }

  initFunc() {
    if (
      this.hasInit ||
      !this.authService.schoolData ||
      (!this.authService.schoolData.data && !this.targetYoutube)
    ) {
      return;
    }
    // reset in case home mode change, beginner mode not allow key up/key down
    this.authService.preventOtherAccess();
    this.hasInit = true;
    this.items = cloneDeep(this.authService.clonedLessons) || [];
    this.categories = cloneDeep(this.authService.clonedCategories) || [];
    this.categories = this.categories.filter((item) => {
      return !item.disabled;
    });

    if (this.categories.length > 0 && this.items.length > 0) {
      this.currentSelectedCategory = this.categories[0].cid;
    } else {
      // if (this.authService.isValidPaidUser()) {
      //   alert(
      //     this.translate.instant(
      //       "No lesson found, you'll be redirect to library page"
      //     )
      //   );
      //   this.router.navigate(["/library"]);
      //   return;
      // }
    }
    const nowUnix = parseInt(moment().format('X'));
    const oneWeekLaterUnix = parseInt(moment().add('1', 'week').format('X'));
    if (this.authService.userData && this.authService.userData.expiredDate) {
      const expiredDateUnix = this.authService.userData.expiredDate;
      if (expiredDateUnix <= oneWeekLaterUnix && expiredDateUnix > nowUnix) {
        this.purchaseHeader = 'Expire date:';
        this.expiredAt = moment
          .unix(expiredDateUnix)
          .format('YYYY/MM/DD HH:mm:ss');
      } else if (expiredDateUnix > nowUnix) {
        this.purchaseHeader = 'Renew subscription';
      }
    } else {
      this.purchaseHeader = 'Upgrade';
    }

    this.updateGrammar();
    if (!this.speechService.useCloudSpeech) {
      this.speechService.recognition.interimResults = true;
      this.speechService.recognition.continuous = true;
      this.speechService.recognition.lang = this.selectedDialect;
      this.speechService.recognition.maxAlternatives = 100;
    }
    if (!this.authService.mobileAndTabletcheck()) {
      // $(document).on('mousemove', (e) => {
      //   if (this.isVideoPlaying) {
      //     this.showYoutubeCredit();
      //   }
      // });
      $(document).on('keydown', (e) => {
        this.writeDevLog(e.key);
        e = e || window.event; //normalize the evebnt for IE
        var target = e.srcElement || e.target; //Get the element that event was triggered on
        var tagName = target.tagName; //get the tag name of the element [could also just compare elements]
        if (e.key === ' ') {
          this.spaceOrClickKeyDownEvent(e);
        }
        if (e.key === 'Escape' || e.keyCode === 27) {
          console.log('Escape key pressed.');
          // Perform your desired action here
          this.resetToFirstTimeVisit(false);
        }
        return !(tagName === 'BODY' && this.acceptKeys.includes(e.key)); //see if it was body and space
      });
      $(document).on('keyup', (e) => {
        e = e || window.event;
        if (e.key === ' ') {
          this.spaceOrClickKeyUpEvent(e);
        }
        // if arrow left or right, then back or forward video
        if (
          (e.key === 'ArrowLeft' || e.key === 'ArrowRight') &&
          !this.isAppStop &&
          this.isVideoPlaying &&
          !this.intendedPause &&
          !this.isHomeModeBeginner
        ) {
          this.setCurrentPlayTime();
          if (e.key === 'ArrowLeft') {
            this.currentPlayTime = this.currentPlayTime - 1;
            this.rewindCount--;
          } else {
            this.currentPlayTime = this.currentPlayTime + 1;
            this.rewindCount++;
          }

          if (this.currentPlayTime < this.currentVideo.startSeconds) {
            this.currentPlayTime = this.currentVideo.startSeconds;
          }
          if (
            this.currentVideo.endSeconds &&
            this.currentPlayTime > this.currentVideo.endSeconds
          ) {
            this.currentPlayTime = this.currentVideo.endSeconds;
          }
          clearTimeout(this.rewindInt);
          this.rewindInt = setTimeout(() => {
            this.rewindCount = 0;
          }, 1500);
          this.localSeekTo(this.currentPlayTime, true);
          // this is to prevent while rewinding menu go up and down continuously that make it looks messy
          clearInterval(this.processQuestionAnswersInt);
          this.processQuestionAnswersInt = null;
          // if (this.rewindCount !== 0) {
          // document.getElementById("spaceCount").innerHTML =
          //   this.rewindCount.toString();
          // document.getElementById("spaceCount").style.display = "flex";
          // }
          document.getElementById('spaceCount').innerHTML =
            e.key === 'ArrowLeft' ? '<<' : '>>';
          document.getElementById('spaceCount').style.display = 'flex';
          clearTimeout(this.timeOutHideSpaceCount);
          this.timeOutHideSpaceCount = setTimeout(() => {
            document.getElementById('spaceCount').style.display = 'none';
          }, 2000);

          this.targetYoutube.playVideo();
          this.localSeekTo(this.currentPlayTime, true);
          clearTimeout(this.timeoutHandleDemandContinue);
          // after 1500 remind count will be 0 and allow to continue process question answers
          this.timeoutHandleDemandContinue = setTimeout(() => {
            this.processQuestionAnswers();
          }, 1600);
        }
      });
    }
    setTimeout(() => {
      this.renderMenuItems();
      this.openModal();
      this.cd.detectChanges();
    });
  }

  private intendedPausedStartTime = 0;
  private intendedPauseInt = 0;
  private ignoreDuplicateKeyDownCall = false;
  spaceOrClickKeyDownEvent(event) {
    if (
      this.isHomeModeBeginner ||
      this.purchaseModal ||
      this.settingModal ||
      this.isQuestionAnswerOn
    ) {
      return;
    }
    if (
      !this.detectLeftButtonOrSpace(event) &&
      !this.authService.mobileAndTabletcheck()
    ) {
      return;
    }
    if (!this.isAppStop && this.isVideoPlaying && !this.intendedPause) {
      this.intendedPause = true;
      console.log('line 787: ', this.intendedPause);
      this.intendedPausedStartTime = new Date().getTime();
      this.setCurrentPlayTime();
      // we don't use this.targetYoutube.pauseVideo(); as this cause related video show
      this.targetYoutube.mute();
      clearInterval(this.intendedPauseInt);
      this.intendedPauseInt = setInterval(() => {
        this.isLongPause = true;
        this.targetYoutube.playVideo();
        this.localSeekTo(this.currentPlayTime, true);
      }, 10);
    }
    setTimeout(() => {
      this.ignoreDuplicateKeyDownCall = false;
    }, 500);
  }

  setCurrentPlayTime() {
    console.log('set current play time');
    // because press keydown can call multiple time
    this.currentPlayTime = this.targetYoutube.getCurrentTime();
  }
  private rewindCount = 0;
  private rewindInt = 0;
  private isLongPause = false;
  private timeOutHideSpaceCount = null;
  spaceOrClickKeyUpEvent(event) {
    if (this.ignoreDuplicateKeyDownCall) {
      return;
    }
    this.ignoreDuplicateKeyDownCall = true;
    setTimeout(() => {
      this.ignoreDuplicateKeyDownCall = false;
    }, 500);
    if (
      this.isHomeModeBeginner ||
      this.purchaseModal ||
      this.settingModal ||
      this.isQuestionAnswerOn
    ) {
      if (this.isQuestionAnswerOn) {
        setTimeout(() => {
          this.playAudioQuestion(this.currentQuestionAnswer);
        });
      }
      return;
    }
    if (
      !this.detectLeftButtonOrSpace(event) &&
      !this.authService.mobileAndTabletcheck()
    ) {
      return;
    }
    this.isLongPause = false;
    if (!this.isAppStop) {
      if (this.ylistTargetVideos.length > 0) {
        clearTimeout(this.timeOutSelectYListVideoInt);
        this.processedTranscript = [];
        this.currentSpeechResults = [];
        if (this.ylistMenuModal) {
          this.ylistMenuModal.close();
        }
        this.ylistTargetVideos = [];
        this.matchedMenuVideo = null;
        this.isVideoPlaying = false;
      } else {
        const currentTime = new Date().getTime();
        this.writeDevLog(currentTime - this.intendedPausedStartTime);
        this.writeDevLog(this.isVideoPlaying);
        if (
          this.intendedPausedStartTime > 0 &&
          currentTime - this.intendedPausedStartTime > 251 &&
          this.isVideoPlaying
        ) {
          // long pause
          this.setCurrentPlayTime();
          this.targetYoutube.playVideo();
          this.localSeekTo(this.currentPlayTime, true);
          this.processQuestionAnswers();
        } else {
          // preventing keydown and handleDemand overwrite currentPlayTime
          // because we hacking the pause, but the video current time still can change 10-100 miliseconds, so if hold-and-press keydown, it will continuously change the
          // current playtime
          console.log('current play TIME A:' + this.currentPlayTime);
          this.currentPlayTime =
            this.currentPlayTime > 1 ? this.currentPlayTime - 1 : 0;
          if (
            this.currentVideo &&
            this.currentPlayTime < this.currentVideo.startSeconds
          ) {
            this.currentPlayTime = this.currentVideo.startSeconds;
          }
          // const isStartOfVideo =
          // this.currentPlayTime <= this.currentVideo.startSeconds + 0.5;
          // clearTimeout(this.rewindInt);
          // this.rewindInt = setTimeout(() => {
          //   this.rewindCount = 0;
          // }, 1500);
          console.log('current play TIME B:' + this.currentPlayTime);
          // first key up will pause the video
          if (this.isVideoPlaying) {
            this.ignoreYoutubePlayerStateChanged = true;
            setTimeout(() => {
              this.ignoreYoutubePlayerStateChanged = false;
            }, 3000);
            this.intendedPause = true;
            this.handleDemand('pause', true);
          } else {
            this.intendedPause = false;
            this.handleDemand('continue', true);
          }
          // if (this.rewindCount == 0 || isStartOfVideo) {

          // } else if (this.rewindCount > 1) {
          //   // if user press/click continuously, then from 2nd up it will resume the video
          //   // this is to prevent while rewinding menu go up and down continuously that make it looks messy
          //   clearInterval(this.processQuestionAnswersInt);
          //   this.processQuestionAnswersInt = null;
          //   this.handleDemand("continue", true);
          // }
          // if (this.rewindCount > 0) {
          //   document.getElementById("spaceCount").innerHTML =
          //     this.rewindCount.toString();
          //   document.getElementById("spaceCount").style.display = "flex";
          // }
          // clearTimeout(this.timeOutHideSpaceCount);
          // this.timeOutHideSpaceCount = setTimeout(() => {
          //   document.getElementById("spaceCount").style.display = "none";
          // }, 2000);
          // if (!isStartOfVideo) {
          //   this.rewindCount++;
          // }
        }
      }
    } else if (this.isAppStop) {
      this.openFullscreen();
    }
    // this.intendedPause = false;
    // reset
    setTimeout(() => {
      this.intendedPause = false;
    }, 5000);
    console.log('line 909: ', this.intendedPause);
    clearInterval(this.intendedPauseInt);
    setTimeout(() => {
      clearInterval(this.intendedPauseInt);
    }, 600);
    this.intendedPausedStartTime = 0;
    if (this.currentVideo && this.targetYoutube.isMuted()) {
      this.targetYoutube.unMute();
    }
  }

  localSeekTo(seconds: number, allowSeekAhead: boolean) {
    // ignore if seeking to same as current time
    // if (Math.ceil(this.targetYoutube.getCurrentTime()) == Math.ceil(seconds)) {
    //   return;
    // }
    // Preventing multiple calling seeking. Example, when calling seek, then it will trigger Player Pause event, then we also have seek calling
    // temporary disable due to the  hack pause by calling seekto every 10/1000 second
    this.targetYoutube.seekTo(seconds, allowSeekAhead);
  }

  timeoutHandleDemandContinue = null;
  handleDemand(demand_text, fromKeyboard = false) {
    // this.writeDevLog("handle demand: " + demand_text);
    switch (demand_text) {
      case 'continue':
        this.youtubeVideoRepeatCount = 0;
        if (this.currentVideoId) {
          this.stopRecognition();
          try {
            // this.currentPlayTime = this.currentPlayTime > 5 ? this.currentPlayTime - 5 : 0;
            // if (this.currentPlayTime < this.currentVideo.startSeconds) {
            //   this.currentPlayTime = this.currentVideo.startSeconds;
            // }
            this.targetYoutube.playVideo();
            this.writeDevLog('current play time: ' + this.currentPlayTime);
            this.localSeekTo(this.currentPlayTime, true);
            this.showYoutubeCredit();
            this.processQuestionAnswers();
            clearTimeout(this.timeoutHandleDemandContinue);
            // after 1500 remind count will be 0 and allow to continue process question answers
            this.timeoutHandleDemandContinue = setTimeout(() => {
              this.processQuestionAnswers();
            }, 1600);
          } catch (e) {
            console.log(e);
          }

          this.isVideoPlaying = true;
          if (!fromKeyboard || !this.isHomeModeBeginner) {
            this.resetVideoWrapper();
          }
        } else {
          this.writeDevLog('No current video');
        }
        break;
      case 'pause':
        // preventing kids tend to multiple touching the screen or multiple click on the screen
        if (!this.videoCoverClickLock) {
          this.videoCoverClickLock = true;
          // document.body.style.overflow = 'inherit';
          this.setCurrentPlayTime();
          this.isVideoPlaying = false;
          this.writeDevLog('Pause, Current play time: ' + this.currentPlayTime);
          try {
            this.targetYoutube.pauseVideo();
            setTimeout(() => {
              this.targetYoutube.pauseVideo();
            }, 1000);
          } catch (e) {
            console.log(e);
          }
          this.dictate();
          this.isVideoPlaying = false;
          this.stopProcessQuestionAnswers();
          this.currentSpeech = this.defaultSpeechText;
          if (!fromKeyboard || !this.isHomeModeBeginner) {
            this.resetVideoWrapper();
          }
          this.writeDevLog('Current play time: ' + this.currentPlayTime);
          setTimeout(() => {
            this.videoCoverClickLock = false;
          }, 1000);
        }

        break;
    }
  }

  detectLeftButtonOrSpace(event) {
    return event.button === 0 || event.key === ' ';
  }

  translateDefaultSpeechText() {
    if (window.innerWidth < 500) {
      this.defaultSpeechText = this.translate.instant('Say something');
    } else {
      this.defaultSpeechText = this.translate.instant(
        'Speak to pick a lesson, category'
      );
    }
  }
  changeLanguage(selectedDialect: string) {
    setTimeout(async () => {
      this.selectedDialect = selectedDialect;
      this.writeDevLog('selected language: ' + selectedDialect);
      this.speechService.setLanguage(selectedDialect);
      // auto get selected lang from localStorage
      await this.authService.setCurrentLearningLanguage();
      this.items = cloneDeep(this.authService.clonedLessons);
      this.categories = cloneDeep(this.authService.clonedCategories);
      this.categories = this.categories.filter((c) => !c.disabled);
      if (this.categories.length > 0) {
        this.currentSelectedCategory = this.categories[0].cid;
      }
      this.translateDefaultSpeechText();
      this.updateGrammar();
      this.renderMenuItems();
    });
  }

  onRightClick(event) {
    try {
      event.preventDefault();
      event.stopPropagation();
    } catch (e) {}
  }

  resetToFirstTimeVisit(shortModal = true) {
    try {
      window.removeEventListener(
        'fullscreenchange',
        this.fullScreenChange,
        true
      );
      window.removeEventListener('resize', this.resizeChange, true);
      clearInterval(this.liveTracking);
      clearInterval(this.ytCheck);
      clearInterval(this.intMicroPhone);
      clearInterval(this.processQuestionAnswersInt);
      clearInterval(this.intervalCheckToSkipPartOfVideo);
      this.openModal(shortModal);
      if (this.ylistMenuModal) {
        this.ylistMenuModal.close();
      }
      // this is cause mic on
      this.targetYoutube.pauseVideo();
      this.currentPlayTime = 0;
      this.microPhoneTimeCount = 0;
      this.isVideoPlaying = false;
      this.resetVideoWrapper();
      this.isAppStop = true;
      this.writeDevLog('is app stopped');
      this.closeFullscreen();
      this.stopRecognition();
      clearTimeout(this.timeOutSelectYListVideoInt);
      this.ylistTargetVideos = [];
      this.matchedMenuVideo = null;
      this.videoCoverClickLock = false;
      this.intendedPause = false;
      // image gif
      let questionerAvatarEl = document.getElementById('imagecontainer');
      questionerAvatarEl.style.display = 'none';
      // this.resetVideoWrapper();
      document.getElementById('videoWrapper').classList.remove('playerZoomOut');
      document.getElementById('videoWrapper').classList.remove('playerZoomIn');
      this.isQuestionAnswerOn = false;
      this.isQuestionAnswerOnAndListening = false;
      try {
        if (this.currentVideo && this.targetYoutube.isMuted()) {
          this.targetYoutube.unMute();
        }
        clearInterval(this.intendedPauseInt);
      } catch (e) {}
    } catch (e) {
      console.log(e);
    }
  }

  openModal(shortModal: boolean = false) {
    // When user first visit home page, and modal is opening, then user change home mode (beginner <-> advanced)
    // It will trigger initfunc and call this function, in that case this function should not run as modal is opening
    // Running will cause ExpressionChangedAfterItHasBeenCheckedError
    if (this.isModalOpened) {
      return;
    }
    if (shortModal) {
      this.pickRandomIcon();
      document.getElementById('btnFullScreen').style.display = 'inherit';
      this.btnFullScreenShowing = true;
    } else {
      if (this.modal) {
        this.modal.close();
      }
      this.modal = this.modalService.open(this.myModal, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        windowClass: 'dark-modal home-form-modal',
        backdrop: 'static',
        keyboard: false,
      });
      setTimeout(() => {
        document.getElementById('startButton').focus();
      });
    }
    this.targetYoutube.pauseVideo();
    this.setCurrentPlayTime();
    this.isVideoPlaying = false;
    this.resetVideoWrapper();
    this.stopRecognition();
    this.isModalOpened = true;
    this.isAppStop = true;
    this.writeDevLog('is app stopped');
  }

  private increaseRepeatInt = null;
  onPlayerStateChange(event) {
    if (this.ignoreYoutubePlayerStateChanged) {
      return;
    }
    if (event.data == YT.PlayerState.ENDED) {
      const currentIndex = this.getCurrentPlayVideoIndex();
      const youtubeVideo = this.currentLesson.ylist[currentIndex];
      // check max video repeat count
      if (this.youtubeVideoRepeatCount >= youtubeVideo.repeatTime) {
        this.youtubeVideoRepeatCount = 0;
        this.handleDemand('pause');
        if (youtubeVideo.repeatTime > 0) {
          const firstPart = this.translate.instant('Video has reached maximum');
          this.currentSpeech = `${firstPart} ${
            youtubeVideo.repeatTime
          } ${this.translate.instant('time repeat(s)')}`;
        }
      } else {
        // play same video
        this.youtubePlay(this.currentLesson.ylist[currentIndex]);
        clearTimeout(this.increaseRepeatInt);
        this.increaseRepeatInt = setTimeout(() => {
          this.youtubeVideoRepeatCount++;
        }, 2000);
      }
    } else {
      // UNSTARTED = -1,
      // ENDED = 0,
      // PLAYING = 1,
      // PAUSED = 2,
      // BUFFERING = 3,
      // CUED = 5
      // this.writeDevLog(event);
      // this.writeDevLog('onPlayerStateChange: ' + event.data);
      // Sometimes player got pause while in video playing mode due to user keyboards
      // we force to play again
      console.log('is video playing: ' + this.isVideoPlaying);
      console.log('is intended pause: ' + this.intendedPause);
      if (
        event.data === YT.PlayerState.PAUSED &&
        this.isVideoPlaying &&
        !this.intendedPause
      ) {
        try {
          // This is for preventing case after max repeat time reached, player stopped, then click on screen will go here and stop the player (not play)
          if (this.targetYoutube.getDuration() - this.currentPlayTime < 2) {
            this.currentPlayTime = 0;
          }
          // when video started playing it trigger this one time, we need to ignore it
          if (!this.lockYoutubePlay) {
            this.targetYoutube.playVideo();
            this.localSeekTo(this.currentPlayTime, true);
          }

          this.processQuestionAnswers();
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  closeFullscreen() {
    try {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document['mozCancelFullScreen']) {
          document['mozCancelFullScreen']();
        } else if (document['webkitExitFullscreen']) {
          document['webkitExitFullscreen']();
        } else if (document['msExitFullscreen']) {
          document['msExitFullscreen']();
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  onPlayerReady(event) {
    this.targetYoutube = event.target;
    this.initFunc();
  }

  getCurrentPlayVideoIndex() {
    return this.currentLesson.ylist.findIndex((youtubeVideo) => {
      return youtubeVideo.yid == this.currentVideoId;
    });
  }

  getNextPlayVideoIndex() {
    let currentIndex = this.currentLesson.ylist.findIndex((youtubeVideo) => {
      return youtubeVideo.yid === this.currentVideoId;
    });
    currentIndex = currentIndex + 2;
    let remainder = currentIndex % this.currentLesson.ylist.length;
    const nextIndex =
      remainder === 0 ? this.currentLesson.ylist.length - 1 : remainder - 1;
    return nextIndex;
  }

  playVideoIfMatch(speechToText: string) {
    this.youtubeVideoRepeatCount = 0;
    const sort_by_length = (a, b) => {
      // TODO: check case array if correct
      return a.name.length >= b.name.length ? -1 : 1;
    };
    if (this.isQuestionAnswerOnAndListening) {
      return this.checkMatchQuestionAnswer(speechToText);
    } else if (this.ylistTargetVideos.length > 0) {
      // check if in popup menu mode (multiple videos in a play item)
      let terms = [];
      let matchVideo = this.ylistTargetVideos.find((ylistTargetVideo) => {
        // menu terms can have more than one, example: dương,lam,nước biển
        let menu_terms = ylistTargetVideo.term.split(',').map((item) => {
          return item.trim();
        });
        let found = menu_terms.find((item) => {
          return speechToText.includes(item);
        });
        terms.push(ylistTargetVideo.term);
        return found ? true : false;
      });
      if (!matchVideo) {
        let match = this.findBestMatch(speechToText, terms);
        if (match) {
          matchVideo = this.ylistTargetVideos.find((ylistTargetVideo) => {
            return ylistTargetVideo.term == match;
          });
        }
      }
      if (matchVideo) {
        this.matchedMenuVideo = cloneDeep(matchVideo);
        clearTimeout(this.timeOutSelectYListVideoInt);
        setTimeout(() => {
          this.ylistTargetVideos = [];
          this.matchedMenuVideo = null;
          if (this.ylistMenuModal) {
            this.ylistMenuModal.close();
          }
          this.youtubePlay(matchVideo.youtubeVideo, 0);
        }, 3000);
        return true;
      }
    } else {
      let found_items = this.selectedCategoryItems.filter((item) => {
        let names: any;
        try {
          names = item.name.split(',');
        } catch (e) {
          console.log(item);
          console.log(e);
        }

        const match = names.find((name) => {
          return speechToText.includes(name.toLowerCase().trim());
        });
        return !!match;
      });

      found_items.sort(sort_by_length);
      let longestMatch = found_items[0];
      if (longestMatch) {
        this.currentSpeech = speechToText;
        this.currentLesson = longestMatch;
        const playVideo = longestMatch.ylist[0];
        setTimeout(() => {
          // Note: In beginner mode if a Lesson has more than one video in it, it will play first one instead of showing YListMenu
          if (
            longestMatch.ylist.length > 1 &&
            longestMatch.display &&
            !this.isHomeModeBeginner
          ) {
            this.openYlistMenu();
          } else {
            // Although this function will return true to stop the recognition from continue sending speechtext, but sometimes it still send
            // this is to prevent while video playing the speech recognition still recognize previous match text and cause refresh the video
            clearTimeout(this.processResultInt);
            this.animateSelectedLesson(playVideo.yid, longestMatch.vid);
            // Single video in ylist or beginner mode
            setTimeout(() => {
              this.youtubePlay(playVideo);
            }, 3000);
          }
        });
        return true;
      } else if (speechToText.length > 0) {
        return false;
      }
    }
  }

  showYoutubeCredit(timeout = 15000) {
    var youtubeCredit = document.getElementById('youtubeCredit');
    if (youtubeCredit) {
      youtubeCredit.style.display = 'inherit';
      setTimeout(() => {
        youtubeCredit.style.display = 'none';
      }, timeout);
    }
  }

  ignoreYoutubePlayerStateChanged = false;
  timeoutIgnoreStateChange = null;
  lockYoutubePlay = false;
  timeOutLockYoutubePlay = null;
  intervalCheckToSkipPartOfVideo = null;

  youtubePlay(youtubeVideo: Video, timeout: number = 800) {
    if (this.lockYoutubePlay && this.currentVideoId == youtubeVideo.yid) {
      return;
    }
    this.lockYoutubePlay = true;
    this.timeOutLockYoutubePlay = setTimeout(() => {
      this.lockYoutubePlay = false;
    }, 5000);
    this.lastQuestions = { questionAnswers: [], at: 0 };
    this.currentVideoId = youtubeVideo.yid;
    this.currentVideo = youtubeVideo;
    let playItemObject = {
      videoId: this.authService.getOriginalYID(youtubeVideo.yid),
    };
    if (Object.keys(youtubeVideo).includes('startSeconds')) {
      playItemObject = Object.assign(playItemObject, {
        startSeconds: youtubeVideo['startSeconds'],
      });
    }
    if (Object.keys(youtubeVideo).includes('endSeconds')) {
      playItemObject = Object.assign(playItemObject, {
        endSeconds: youtubeVideo['endSeconds'],
      });
    }
    this.isVideoPlaying = true;
    this.animateSelectedLesson(null);
    this.stopRecognition();
    clearInterval(this.intMicroPhone);
    this.microPhoneTimeCount = 0;
    this.highlightRecognizedText(this.currentLesson.name);
    setTimeout(() => {
      this.ignoreYoutubePlayerStateChanged = true;
      clearTimeout(this.timeoutIgnoreStateChange);
      if (typeof this.currentVideo.volume === 'number') {
        this.targetYoutube.setVolume(this.currentVideo.volume);
      }
      if (this.targetYoutube.isMuted()) {
        this.targetYoutube.unMute();
      }
      this.targetYoutube.loadVideoById(playItemObject);
      setTimeout(() => {
        this.showYoutubeCredit();
      });
      clearInterval(this.intervalCheckToSkipPartOfVideo);
      this.timeoutIgnoreStateChange = setTimeout(() => {
        this.ignoreYoutubePlayerStateChanged = false;
        this.intervalCheckToSkipPartOfVideo = setInterval(() => {
          let currentPlayingTime = this.targetYoutube.getCurrentTime();
          if (
            this.currentVideo.durationSkipBarsStatus &&
            this.currentVideo.durationSkipBarsStatus.length > 0
          ) {
            for (let bar of this.currentVideo.durationSkipBarsStatus) {
              if (
                bar.from <= currentPlayingTime &&
                bar.to > currentPlayingTime &&
                bar.disabled
              ) {
                currentPlayingTime = bar.to;
                this.targetYoutube.seekTo(bar.to, true);
              }
            }
          }
        }, 500);
      }, 2000);
      this.processQuestionAnswers();
      this.resetVideoWrapper();
      // this.analyticsService.analytics.logEvent('play', {
      //   id: youtubeVideo.yid,
      // });
    }, timeout);
  }

  playNextVideoInPopUpMenu() {
    this.openYlistMenu();
    this.isVideoPlaying = false;
  }

  animateSelectedLesson(yid: string, vid: number = null) {
    const removeAnimationFunc = () => {
      document.querySelectorAll('.thumbnail-container').forEach((el) => {
        el.classList.remove('animated');
        el.classList.remove('bounce');
        el.classList.remove('infinite');
      });
    };
    removeAnimationFunc();
    if (yid) {
      document.querySelectorAll('.vid' + vid).forEach((el) => {
        el.classList.add('animated');
        el.classList.add('bounce');
        el.classList.add('infinite');
      });
      setTimeout(() => {
        removeAnimationFunc();
      }, 2000);
    }
  }

  highlightRecognizedText(recognizedText: string) {
    this.currentSpeech = this.currentSpeech.replace(
      recognizedText,
      '<mark>' + recognizedText + '</mark>'
    );
    if (this.isHomeModeBeginner) {
      this.processedTranscript = [];
      this.currentSpeechResults = [];
      this.stopRecognition(true, true);
      setTimeout(() => {
        this.currentSpeech = this.defaultSpeechText;
      }, 1000);
    }
  }
  private isVideoWrapperResetting = false;
  resetVideoWrapper() {
    // Make sure this functiong call is ended before calling again
    if (this.isVideoWrapperResetting) {
      return;
    }
    this.isVideoWrapperResetting = true;
    let videoContainer = document.getElementById('videoWrapper');
    let photoContainer = document.getElementById('photoContainer');
    let homeContainer = document.getElementById('homeContainer');
    let spaceCountEl = document.getElementById('spaceCount');
    document
      .getElementById('photoContainer')
      .style.removeProperty('overflow-y');
    let toolbar = document.getElementById('toolbar');
    clearInterval(this.ytCheck);
    if (this.isVideoPlaying) {
      document.getElementById('homeContainer').style.overflow = 'hidden';
      let playerSize = this.youtubeService.calculatePlayerSize(
        window.innerWidth,
        window.innerHeight
      );
      if (this.isHomeModeBeginner) {
        playerSize.height = Math.floor(playerSize.height / 2);
      }
      if (!this.isHomeModeBeginner) {
        videoContainer.style.display = 'none';
      }
      videoContainer.style.height = playerSize.height + 'px';
      // spaceCountEl.style.top = Math.round(playerSize.height / 2) - 75 + "px";
      // spaceCountEl.style.left = Math.round(playerSize.width / 2) - 75 + "px";
      document.querySelector('#videoWrapper iframe')['style'].height =
        playerSize.height + 'px';
      // videoContainer.style.top = Math.floor((window.innerHeight - playerSize.height) / 2) + 'px';

      this.ytCheck = setInterval(() => {
        this.writeDevLog(
          'YT Check status: ' +
            this.targetYoutube.getPlayerState() +
            '-' +
            this.ytCheck
        );
        if (
          this.targetYoutube.getPlayerState() == YT.PlayerState.BUFFERING ||
          this.targetYoutube.getPlayerState() == YT.PlayerState.PLAYING
        ) {
          $('#videoWrapper').slideDown(100);
          clearInterval(this.ytCheck);
          if (!this.isHomeModeBeginner) {
            setTimeout(() => {
              toolbar.classList.add('d-none');
              photoContainer.classList.add('d-none');
            }, 100);
          } else {
            setTimeout(() => {
              this.generateBestFitThumbnails();
            }, 100);
          }
        }
      }, 100);
    } else {
      document.getElementById('homeContainer').style.removeProperty('overflow');
      // document.querySelector('#videoWrapper iframe')['style'].height = '0px';
      if (!this.isHomeModeBeginner) {
        $('#videoWrapper').slideUp(100);
      }
      photoContainer.style.removeProperty('height');
      // document.body.style.removeProperty('overflow');
      document.getElementById('photoContainer').style.overflowY = 'auto';
      toolbar.classList.remove('d-none');
      photoContainer.classList.remove('d-none');
      var youtubeCredit = document.getElementById('youtubeCredit');
      if (youtubeCredit) {
        youtubeCredit.style.display = 'none';
      }
      this.photoContainerHeight =
        window.innerHeight - toolbar.offsetHeight + 'px';
      photoContainer.style.height = this.photoContainerHeight;
      setTimeout(() => {
        this.generateBestFitThumbnails();
      });
    }
    setTimeout(() => {
      this.isVideoWrapperResetting = false;
    }, 100);
  }

  generateBestFitThumbnails() {
    let videoWrapper = document.getElementById('videoWrapper');
    let photoContainer = document.getElementById('photoContainer');
    let photoContainerHeight = photoContainer.clientHeight - 10;
    photoContainerHeight =
      document.body.clientHeight -
      document.getElementById('toolbar').clientHeight -
      10;
    if (
      this.isHomeModeBeginner &&
      getComputedStyle(videoWrapper).display != 'none'
    ) {
      photoContainerHeight =
        photoContainerHeight - parseInt(videoWrapper.style.height);
    }
    const countItems = this.selectedCategoryItems.length;
    this.writeDevLog('Photo container height: ' + photoContainerHeight);
    this.writeDevLog(
      'Photo container scroll height: ' + photoContainer.scrollHeight
    );
    let width = 250;
    let numberOfItemsInRow = 0;
    let numberOfItemsInColumn = 0;
    let maxItemsCount = 0;
    let bestWidth = 250;
    for (let i = 250; i >= 100; i--) {
      bestWidth = i;
      width = i + 20; // padding left + padding right
      const height = Math.ceil((i * 9) / 16) + 20; // padding-top + padding-bottom
      numberOfItemsInRow = Math.floor((window.innerWidth - 6) / width);
      numberOfItemsInColumn = Math.floor(photoContainerHeight / height);
      maxItemsCount = numberOfItemsInRow * numberOfItemsInColumn;
      if (maxItemsCount >= countItems) {
        break;
      }
    }
    this.writeDevLog(
      'item dimension: ' + numberOfItemsInRow + '/' + numberOfItemsInColumn
    );
    this.writeDevLog('max items count: ' + maxItemsCount);
    this.writeDevLog('best width: ' + bestWidth);
    let elems = document.querySelectorAll('.thumbnail-container');
    for (let i = 0; i < countItems; i++) {
      elems[i]['style'].width = bestWidth + 'px';
    }
    if (maxItemsCount < countItems) {
      let elems = document.querySelectorAll('.photo-item');
      for (let i = 0; i < countItems; i++) {
        elems[i]['style'].padding = '5px';
      }
    }
    this.cd.detectChanges();
  }

  stopRecognition(stopOnEndEvent: boolean = true, force = false) {
    if (!this.isHomeModeBeginner || force) {
      // note when video is play and if recording is on the video will be paused
      try {
        if (stopOnEndEvent) {
          if (!this.speechService.useCloudSpeech) {
            this.speechService.recognition.onend = null;
            // when matched text found, onresult still can be trigger after 1-3 seconds and cause the same matched text found and replay the current video and
            // animate the same
            this.speechService.recognition.onresult = null;
          }
        }
        this.speechService.stopListening(false);
      } catch (e) {
        console.log(e);
      }
    }
  }

  renderMenuItems() {
    this.selectedCategoryItems = this.items.filter((item) => {
      const matchCategory = item.cid.includes(this.currentSelectedCategory);
      // if (this.isHomeModeBeginner) {
      //   return matchCategory && item.ylist.length == 1;
      // } else {
      //   return matchCategory;
      // }
      return matchCategory && this.selectedLessonAgeGroup == item.ageGroup;
    });
    if (!this.selectedCategoryItems) {
      return;
    }
    this.selectedCategoryItems = this.selectedCategoryItems.map((item) => {
      item.ylist = item.ylist.filter((yitem) => {
        return !yitem.disabled;
      });
      return item;
    });
    this.selectedCategoryItems.sort((a, b) => {
      return a.vid > b.vid ? 1 : 0;
    });
    this.cd.detectChanges();
    this.generateBestFitThumbnails();
    this.currentSpeech = this.defaultSpeechText;
  }

  writeDevLog(text_log, event = null) {
    // return;
    if (this.devMode) {
      console.log(text_log);
      if (event) {
        console.log(event);
      }
    }
  }

  openFullscreen() {
    if (
      typeof window['nfs_internet_connection'] !== 'undefined' &&
      !window['nfs_internet_connection']
    ) {
      return;
    }
    clearInterval(this.liveTracking);
    this.runHealthCheck();
    this.btnFullScreenShowing = false;
    this.setFullScreen();
    this.resetVideoWrapper();
    this.modal.close();
    this.isModalOpened = false;
    this.isAppStop = false;
    this.fullScreenChange = () => {
      if (document.fullscreenElement === null) {
        this.resetToFirstTimeVisit(false);
      }
    };
    this.resizeChange = () => {
      setTimeout(() => {
        this.resetVideoWrapper();
      }, 200);
    };
    // set it zindex to 1051 here after popup menu close to prevent it showing above the menu
    window.addEventListener('fullscreenchange', this.fullScreenChange, true);
    window.addEventListener('resize', this.resizeChange, true);
    this.handleDemand('pause');
  }

  runHealthCheck() {
    let requestToDictate = () => {
      try {
        const result = this.dictate(false);
        if (result.includes('already started') || result.includes('ok')) {
          document.querySelector('#recordIconContainer .icofont-mic')['style'][
            'visibility'
          ] = 'visible';
          document.querySelector('#recordIconContainer .icofont-mic-mute')[
            'style'
          ]['visibility'] = 'hidden';
          this.writeDevLog('Recognition is on');
          if (document.getElementById('toolbar').classList.contains('d-none')) {
            this.handleDemand('pause');
          }
        } else {
          this.writeDevLog('Recognition is NOT on');
          document.querySelector('#recordIconContainer .icofont-mic')['style'][
            'visibility'
          ] = 'hidden';
          document.querySelector('#recordIconContainer .icofont-mic-mute')[
            'style'
          ]['visibility'] = 'visible';
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    if (!this.isHomeModeBeginner) {
      this.liveTracking = setInterval(() => {
        this.writeDevLog(
          'Recoginition: ' +
            typeof this.speechService.recognition +
            ', Video Playing: ' +
            (this.isVideoPlaying ? 'Yes' : 'No') +
            ', App stopped: ' +
            (this.isAppStop ? 'Yes' : 'No')
        );
        if (!this.btnFullScreenShowing) {
          if (!this.isVideoPlaying && !this.isAppStop) {
            requestToDictate();
          } else {
            this.writeDevLog('Recognition is off');
            try {
              document.querySelector('#recordIconContainer .icofont-mic')[
                'style'
              ]['visibility'] = 'hidden';
              document.querySelector('#recordIconContainer .icofont-mic-mute')[
                'style'
              ]['visibility'] = 'visible';
            } catch (e) {
              // probalby not home page
            }
            // Fix when video not showing when is playing but toolbar/menu is showing
            if (
              this.targetYoutube.getPlayerState() == YT.PlayerState.PLAYING &&
              !document.getElementById('toolbar').classList.contains('d-none')
            ) {
              this.isVideoPlaying = true;
              this.resetVideoWrapper();
            }
            // FIX issue when question/answer is not on but question/anser box showing and video also playing
            let isQuestionAnswerShowing =
              getComputedStyle(document.getElementById('imagecontainer'))
                .display != 'none';
            if (
              this.targetYoutube.getPlayerState() == YT.PlayerState.PLAYING &&
              isQuestionAnswerShowing &&
              !this.isQuestionAnswerOn
            ) {
              this.isVideoPlaying = true;
              this.resetVideoWrapper();
            }
            const videoWrapperComputed = getComputedStyle(
              document.getElementById('videoWrapper')
            );
            // say: orange -> say: whale -> click outside before player play
            if (
              videoWrapperComputed.display == 'none' &&
              this.isVideoPlaying &&
              !document.getElementById('toolbar').classList.contains('d-none')
            ) {
              this.isVideoPlaying = true;
              this.resetVideoWrapper();
            }
          }
          if (!this.isMicOn() && !this.isVideoPlaying) {
            if (
              this.targetYoutube.getPlayerState() == YT.PlayerState.UNSTARTED
            ) {
              this.handleDemand('pause');
            }
            if (
              ![YT.PlayerState.PLAYING, YT.PlayerState.BUFFERING].includes(
                this.targetYoutube.getPlayerState()
              ) &&
              !document.getElementById('toolbar').classList.contains('d-none')
            ) {
              this.handleDemand('pause');
            }
          }

          if (
            !this.isMicOn() &&
            this.isVideoPlaying &&
            this.targetYoutube.getPlayerState() == YT.PlayerState.PAUSED &&
            !this.intendedPause
          ) {
            this.handleDemand('continue');
          }
        }
        try {
          this.devMode = this.devMode || !!window['devMode'];
        } catch (e) {
          this.devMode = false;
        }
        if (document.fullscreenElement == null) {
          this.setFullScreen();
        }
        this.setDevMode();
      }, 1500);
    } else {
      this.liveTracking = setInterval(() => {
        requestToDictate();
        if (
          this.targetYoutube.getPlayerState() == YT.PlayerState.PAUSED &&
          this.isVideoPlaying
        ) {
          this.resetVideoWrapper();
        }
        this.setDevMode();
      }, 1500);
    }
  }

  setFullScreen() {
    if (!this.authService.userData.setting.disableFullscreen) {
      let docEl = document.documentElement;
      document.getElementById('btnFullScreen').style.display = 'none';
      if (docEl.requestFullscreen) {
        docEl.requestFullscreen({ navigationUI: 'hide' });
      } else if (docEl['mozRequestFullScreen']) {
        /* Firefox */
        docEl['mozRequestFullScreen']({ navigationUI: 'hide' });
      } else if (docEl['webkitRequestFullscreen']) {
        /* Chrome, Safari & Opera */
        docEl['webkitRequestFullscreen']({ navigationUI: 'hide' });
      } else if (docEl['msRequestFullscreen']) {
        /* IE/Edge */
        docEl['msRequestFullscreen']({ navigationUI: 'hide' });
      }
    }
  }

  goToMedia() {
    this.modal.close();
    this.ngZone.run(() => {
      this.router.navigate(['/library']);
    });
    if (document.fullscreenElement) {
      this.closeFullscreen();
    }
  }

  pickRandomIcon() {
    const item =
      this.animalIcons[Math.floor(Math.random() * this.animalIcons.length)];
    this.randomIcon = item;
    return item;
  }

  openYlistMenu() {
    this.animateSelectedLesson(null);
    if (this.ylistMenuModal) {
      this.ylistMenuModal.close();
    }
    this.handleDemand('pause');
    this.ylistTargetVideos = this.authService.generateYListMatchWords(
      this.currentLesson.ylist,
      this.currentLesson.display
    );
    this.ylistMenuModal = this.modalService.open(this.ylistModal, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      windowClass: 'modal-transparent',
      // backdrop: 'static',
      backdropClass: 'modal-transparent-backdrop',
      keyboard: true,
      centered: true,
    });
    // listen on click outside modal to close ylist menu
    this.ylistMenuModal.result
      .then(() => {
        this.writeDevLog('ylist closed');
        setTimeout(() => {
          this.closeYlilstMenu();
        });
      })
      .catch((e) => {
        console.log(e);
        setTimeout(() => {
          this.closeYlilstMenu();
        });
      });

    // // for testing menu stay put
    // setTimeout(() => {
    //   this.isVideoPlaying = true;
    //   this.stopRecognition();
    //   clearTimeout(this.timeOutSelectYListVideoInt);
    // })
  }

  /**
   * Context: while ylist menu open
   * Caller:
   * - When click on screen
   * - When press space bar
   * - When speeched text matches the ylist items
   */
  closeYlilstMenu() {
    clearTimeout(this.timeOutSelectYListVideoInt);
    this.matchedMenuVideo = null;
    if (this.ylistMenuModal) {
      this.ylistMenuModal.close();
    }
    if (!this.isVideoPlaying) {
      this.handleDemand('pause');
    }
    this.ylistTargetVideos = [];
  }

  playClickSound() {
    if (!this.isHomeModeBeginner && !this.isQuestionAnswerOn) {
      let sound: any = document.getElementById('audio_click');
      // this is to prevent audio auto play when loaded (autoplay property is not working)
      if (!sound.src) {
        sound.src = 'assets/audio/button-28.mp3';
      }
      sound.play();
    }
  }

  playRecordingStart() {
    if (!this.isHomeModeBeginner && !this.isQuestionAnswerOn) {
      let sound: any = document.getElementById('recording_start');
      // this is to prevent audio auto play when loaded (autoplay property is not working)
      if (!sound.src) {
        sound.src = 'assets/audio/recording_start.mp3';
      }
      sound.play();
    }
  }

  playDingSound() {
    let sound: any = document.getElementById('audio_ding');
    // this is to prevent audio auto play when loaded (autoplay property is not working)
    if (!sound.src) {
      sound.src = DingAudioDataURL;
    }
    sound.play();
  }

  isMicOn() {
    return (
      document.querySelector('#recordIconContainer .icofont-mic')['style'][
        'visibility'
      ] !== 'hidden'
    );
  }

  rememberFloatingSwitchSelected(event) {
    localStorage.setItem('enableFloatingSwitch', event);
    this.enableFloatingSwitch = parseInt(event);
  }
  rememberHomeMode(event) {
    setTimeout(() => {
      localStorage.setItem('homeMode', event);
      // this.homeMode = parseInt(event);
      this.isHomeModeBeginner = this.homeMode === HomeMode.beginner;
      if (this.isHomeModeBeginner) {
        this.rememberFloatingSwitchSelected(0);
      }
      this.hasInit = false;
      this.initFunc();
    });
    // this.renderMenuItems();
  }

  rememberSelectedLessonAgeGroup(event) {
    setTimeout(() => {
      localStorage.setItem('selected_lesson_age_group', event);
      this.selectedLessonAgeGroup = event;
      this.hasInit = false;
      this.initFunc();
    });
  }

  openLg(content) {
    this.modal = this.modalService.open(content, {
      size: 'lg',
      scrollable: true,
    });
  }

  openPurchasePopup(content) {
    this.purchaseModal = this.modalService.open(content, {
      // xlagre
      size: 'xl',
      scrollable: true,
    });
    this.purchaseModal.result
      .then(() => {
        setTimeout(() => {
          this.purchaseModal.close();
          this.purchaseModal = undefined;
        });
      })
      .catch((e) => {
        setTimeout(() => {
          this.purchaseModal.close();
          this.purchaseModal = undefined;
        });
      });
  }
  openSettingPopup(content) {
    this.settingModal = this.modalService.open(content, {
      size: 'lg',
      scrollable: true,
    });
    this.settingModal.result
      .then(() => {
        setTimeout(() => {
          this.settingModal.close();
          this.settingModal = undefined;
        });
      })
      .catch((e) => {
        setTimeout(() => {
          this.settingModal.close();
          this.settingModal = undefined;
        });
      });
  }
  closePopups() {
    if (this.modal) {
      this.modal.close();
    }
    if (this.settingModal) {
      this.settingModal.close();
    }
    if (this.purchaseModal) {
      this.purchaseModal.close();
    }
  }
  closePurchasePopup() {
    this.purchaseModal.close();
  }
  closeSettingPopup(event) {
    this.settingModal.close();
    if (event == 'logout') {
      this.closePopups();
    }
  }

  public defaultQuestionerAvatar: QuestionerAvatar = {
    name: 'STPVDefault',
    jpgURL:
      'https://storage.speaknplay.app/eye-looking.gif?alt=media&token=803be5f8-a2a3-4ca8-be13-8c9a9078a6a9',
    id: 0,
  };
  public selectedQuestionerAvatar: QuestionerAvatar;
  // private isAudioQuestionPlaying = false;
  playAudioQuestion(questionAnswer: QuestionAnswer) {
    this.stopRecognition();
    this.isQuestionAnswerOnAndListening = false;
    this.isQuestionAnswerOn = true;
    let questionerAvatarEl = document.getElementById(
      'questioner_avatar'
    ) as HTMLImageElement;
    let questionerIcoAvatarEl = document.getElementById(
      'question_ico_avatar'
    ) as HTMLImageElement;
    let audioQuestion: HTMLAudioElement = document.getElementById(
      'audio_question'
    ) as HTMLAudioElement;
    // Stop if audio is playing and not finish
    try {
      audioQuestion.pause();
      audioQuestion.currentTime = 0;
    } catch (e) {
      console.log(e);
    }
    // audioQuestion.src = null;
    // audioQuestion.onended = null;
    // audioQuestion.onplay = null;
    // questionerAvatarEl.onload = null;

    // if (this.isAudioQuestionPlaying) {
    //   return;
    // }
    // this.isAudioQuestionPlaying = true;

    const dataURL = this.authService.getAudioURL(questionAnswer.question);
    if (!dataURL) {
      return;
    }
    try {
      setTimeout(() => {
        if (audioQuestion.src != dataURL) {
          audioQuestion.src = dataURL;
        }
        // audioQuestion.loop = false;
        audioQuestion.currentTime = 0;
        audioQuestion.preload = 'auto';
        audioQuestion.muted = false;
        audioQuestion.loop = false;
        audioQuestion.play();
        // audioQuestion.currentTime = 0;
        // audioQuestion.muted = true;
        // audioQuestion.play();
        // audioQuestion.onplay = () => {
        //   console.log('audio onplay');
        // }
        // audioQuestion.onplaying = () => {
        //   console.log('audio onplaying ');
        // }
        // audioQuestion.onwaiting = () => {
        //   console.log('audio onwaiting ');
        // }
        // audioQuestion.ontimeupdate = () => {
        //   console.log('audio timeupdate ');
        // }
        // audioQuestion.onsuspend = () => {
        //   console.log('audio suspend ');
        // }
        // audioQuestion.onpause = () => {
        //   console.log('audio pause ');
        // }
        // audioQuestion.onstalled = () => {
        //   console.log('audio stalled ');
        // }
        // audioQuestion.onerror = () => {
        //   console.log('audio error ');
        // }
        // audioQuestion.onabort = () => {
        //   console.log('audio stalled ');
        // }
        // audioQuestion.oncanplay = () => {
        //   console.log('audio canplay ');
        // }
        // Note that sometimes audio is not ended but got paused by recognition or other activities in mobile/tablet
        audioQuestion.onended = () => {
          console.log('audio onended ');
          // this.isAudioQuestionPlaying = false;
          // audioQuestion.src = null;
          this.isQuestionAnswerOnAndListening = true;
          this.dictate();
        };
      });
      // setTimeout(() => {
      //   this.isQuestionAnswerOnAndListening = true;
      //   this.dictate();
      //   console.log('listening for answer question')
      // }, questionAnswer.questionDuration * 1000);
    } catch (e) {
      console.log(e);
    }

    if (this.selectedQuestionerAvatar.name == 'STPVDefault') {
      questionerIcoAvatarEl.classList.remove('invisible');
    } else {
      // if (!this.isQuestionAnswerOn) {
      //   questionerAvatarEl.classList.add('invisible');
      // }
      questionerAvatarEl.src = this.selectedQuestionerAvatar.jpgURL;
      questionerAvatarEl.classList.remove('invisible');
      questionerAvatarEl.onerror = () => {
        let questionerAvatarSize =
          this.youtubeService.calculateQuestionerAvatarSize(
            this.selectedQuestionerAvatar.name == 'STPVDefault' ? true : false,
            this.isHomeModeBeginner
          );
        let questionerEcoAvatarEl = document.getElementById(
          'question_ico_avatar'
        );
        questionerEcoAvatarEl.style.fontSize =
          questionerAvatarSize.width + 'px';
        document.getElementById('questioner_avatar').classList.add('d-none');
        this.selectedQuestionerAvatar = this.defaultQuestionerAvatar;
        this.playAudioQuestion(questionAnswer);
        return;
      };
      // questionerAvatarEl.onload = () => {
      //   const dataURL = questionAnswer.question;
      //   const duration = questionAnswer.questionDuration;
      //   try {
      //     audioQuestion.src = dataURL;
      //     audioQuestion.preload = "auto";
      //     audioQuestion.muted = false;
      //     audioQuestion.loop = false;
      //     audioQuestion.play();
      //     audioQuestion.onended = () => {
      //       // this.isAudioQuestionPlaying = false;
      //       questionerAvatarEl.onload = null;
      //       questionerAvatarEl.src = this.selectedQuestionerAvatar.jpgURL;
      //       audioQuestion.src = null;
      //       this.isQuestionAnswerOnAndListening = true;
      //       this.dictate();
      //     }
      //   } catch (e) {
      //     console.log(e);
      //   }
      //   // audioQuestion.onplay = () => {
      //   //   setTimeout(() => {
      //   //     if (!this.isQuestionAnswerOn) {
      //   //       questionerAvatarEl.classList.remove('invisible');
      //   //     }
      //   //   });
      //   // }
      // }
    }
  }

  lastQuestions: { questionAnswers: QuestionAnswer[]; at: number } = {
    questionAnswers: [],
    at: 0,
  };

  stopProcessQuestionAnswers() {
    clearInterval(this.processQuestionAnswersInt);
    // so that !this.processQuestionAnswersInt return true
    this.processQuestionAnswersInt = null;
  }

  private preloadedImg = [];
  private preloadedAudio = [];
  preloadQuestionAnswer(
    questionAvatar: QuestionerAvatar,
    questionerAudioQuestionId
  ) {
    if (questionAvatar) {
      let imgSrc = questionAvatar.jpgURL;
      if (imgSrc && !this.preloadedImg.includes(imgSrc)) {
        let questionerAvatarEl = document.getElementById(
          'questioner_avatar'
        ) as HTMLImageElement;
        questionerAvatarEl.src = imgSrc;
        this.preloadedImg.push(imgSrc);
      }
    }
    const audioSrc = this.authService.getAudioURL(questionerAudioQuestionId);
    if (audioSrc && !this.preloadedAudio.includes(audioSrc)) {
      let preloadAudioQuestion: HTMLAudioElement = document.getElementById(
        'audio_question'
      ) as HTMLAudioElement;
      preloadAudioQuestion.onended = null;
      preloadAudioQuestion.onplay = null;
      preloadAudioQuestion.src = audioSrc;
      preloadAudioQuestion.muted = true;
      preloadAudioQuestion.loop = false;
      preloadAudioQuestion.play();
      this.preloadedAudio.push(audioSrc);
    }
  }

  processQuestionAnswers() {
    // this.rewindCount > 1 means only for those remind more than 1 time
    if (
      !this.currentVideo.questionAnswers ||
      this.currentVideo.questionAnswers.length == 0 ||
      this.isQuestionAnswerOn ||
      this.isLongPause ||
      this.rewindCount > 1 ||
      this.isHomeModeBeginner
    ) {
      return;
    }
    if (!this.processQuestionAnswersInt) {
      this.processQuestionAnswersInt = setInterval(() => {
        if (
          this.isQuestionAnswerOn ||
          this.currentVideo.questionAnswers.length == 0
        ) {
          clearInterval(this.processQuestionAnswersInt);
          return;
        }
        let questionAnswers = this.currentVideo.questionAnswers;
        this.currentPlayTime = this.targetYoutube.getCurrentTime();
        // keep track of those within 3 seconds from current play time
        this.lastQuestions.questionAnswers =
          this.lastQuestions.questionAnswers.filter(
            (lastQuestionAnswer: QuestionAnswer) => {
              return Math.abs(this.currentPlayTime - lastQuestionAnswer.at) < 3;
            }
          );
        for (let questionAnswer of questionAnswers) {
          let questionerAudioQuestion =
            this.authService.getQuestionerAudioQuestion(
              questionAnswer.question
            );
          if (questionerAudioQuestion.disabled) {
            continue;
          }
          let diff = Math.abs(questionAnswer.at - this.currentPlayTime);
          let isInLastQuestionAnswers = this.lastQuestions.questionAnswers.find(
            (lastQuestionAnswer: QuestionAnswer) => {
              return isEqual(questionAnswer, lastQuestionAnswer);
            }
          );
          // found questionAnswer within 0.1 second and not in the last 3 seconds Question Answer list
          if (diff <= 0.1 && !isInLastQuestionAnswers) {
            document.getElementById('spaceCount').style.display = 'none';
            this.currentQuestionAnswer = questionAnswer;
            let foundCustomQuestionerAvatar = false;
            if (
              questionerAudioQuestion.questionerAvatarId &&
              questionerAudioQuestion.questionerAvatarId != 999
            ) {
              let selectedQuestionerAvatar =
                this.authService.schoolData.questionerAvatars.find(
                  (questionerAvatar) => {
                    return (
                      questionerAvatar.id ==
                      questionerAudioQuestion.questionerAvatarId
                    );
                  }
                );
              if (selectedQuestionerAvatar) {
                foundCustomQuestionerAvatar = true;
                this.selectedQuestionerAvatar = selectedQuestionerAvatar;
              }
            }

            if (!foundCustomQuestionerAvatar) {
              this.selectedQuestionerAvatar = this.defaultQuestionerAvatar;
            }
            this.currentSpeech = '';
            this.stopProcessQuestionAnswers();
            // preventing zoom in/out in short time when multiple questions asking at the same time
            clearTimeout(this.matchedQuestionAnswerInt);
            // pause
            this.targetYoutube.mute();
            clearInterval(this.intendedPauseInt);
            this.intendedPause = true;
            console.log('line 2307: ', this.intendedPause);
            this.intendedPauseInt = setInterval(() => {
              this.targetYoutube.playVideo();
              this.targetYoutube.seekTo(this.currentQuestionAnswer.at, true);
            }, 10);
            // play gif
            document
              .getElementById('videoWrapper')
              .classList.remove('playerZoomIn');
            document
              .getElementById('videoWrapper')
              .classList.add('playerZoomOut');
            let questionerAvatarEl = document.getElementById('imagecontainer');
            let questionerAvatarSize =
              this.youtubeService.calculateQuestionerAvatarSize(
                this.selectedQuestionerAvatar.name == 'STPVDefault'
                  ? true
                  : false,
                this.isHomeModeBeginner
              );
            questionerAvatarEl.style.width = questionerAvatarSize.width + 'px';
            questionerAvatarEl.style.height =
              questionerAvatarSize.height + 'px';
            questionerAvatarEl.style.display = 'inherit';
            questionerAvatarEl.style.top = questionerAvatarSize.top + 'px';
            questionerAvatarEl.style.right = questionerAvatarSize.right + 'px';

            let questionerEcoAvatarEl = document.getElementById(
              'question_ico_avatar'
            );
            if (this.selectedQuestionerAvatar.name == 'STPVDefault') {
              questionerEcoAvatarEl.style.fontSize =
                questionerAvatarSize.width + 'px';
              document
                .getElementById('questioner_avatar')
                .classList.add('d-none');
            } else {
              document
                .getElementById('questioner_avatar')
                .classList.remove('d-none');
              questionerEcoAvatarEl.style.fontSize = '0';
            }

            // this.resetVideoWrapper();
            setTimeout(() => {
              let currentSecond = Math.round(this.currentPlayTime);
              // if (Math.abs(currentSecond - this.lastQuestions.at) > 3) {

              // }
              this.lastQuestions.questionAnswers.push(questionAnswer);
              this.lastQuestions.at = currentSecond;
              // Stop recognition even on mobile/tablet even on homemodebeginner as there is an issue when recognition is start it pause the audio question
              this.stopRecognition(true, true);
              this.playAudioQuestion(questionAnswer);
            });
            break;
          }

          if (diff <= 5 && questionAnswer.question) {
            let preloadQuestionerAvatar =
              this.authService.schoolData.questionerAvatars.find(
                (questionerAvatar) => {
                  return questionerAvatar.id == questionAnswer.question;
                }
              );
            this.preloadQuestionAnswer(
              preloadQuestionerAvatar,
              questionAnswer.question
            );
          }
        }
        let currentSecond = Math.round(this.currentPlayTime);
        // keep track of those within 3 seconds from current play time
        this.lastQuestions.questionAnswers =
          this.lastQuestions.questionAnswers.filter(
            (lastQuestionAnswer: QuestionAnswer) => {
              return Math.abs(currentSecond - lastQuestionAnswer.at) < 3;
            }
          );
      }, 100);
    }
  }

  matchedQuestionAnswerInt = 0;
  checkMatchQuestionAnswer(speechToText) {
    let answers = this.currentQuestionAnswer.answer.split(',').map((item) => {
      return item.toLowerCase().trim();
    });
    for (let answer of answers) {
      if (speechToText.includes(answer)) {
        this.highlightRecognizedText(answer);
        clearTimeout(this.matchedQuestionAnswerInt);
        this.stopRecognition();
        this.isQuestionAnswerOn = false;
        this.isQuestionAnswerOnAndListening = false;
        this.matchedQuestionAnswerInt = setTimeout(() => {
          let questionerAvatarEl = document.getElementById('imagecontainer');
          questionerAvatarEl.style.display = 'none';
          // this.resetVideoWrapper();
          document
            .getElementById('videoWrapper')
            .classList.remove('playerZoomOut');
          document.getElementById('videoWrapper').classList.add('playerZoomIn');
          if (this.currentVideo && this.targetYoutube.isMuted()) {
            this.targetYoutube.unMute();
          }
          clearInterval(this.intendedPauseInt);
          this.intendedPause = false;
          console.log('line 2415: ', this.intendedPause);
        }, 2000);
        clearInterval(this.intMicroPhone);
        this.microPhoneTimeCount = 0;
        this.playDingSound();
        setTimeout(() => {
          this.processQuestionAnswers();
        }, 1500);
        return true;
      }
    }
  }
}

export enum HomeMode {
  beginner = 'BEGINNER',
  advance = 'ADVANCE',
}
