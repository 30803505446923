import { QuestionerAvatar } from '../../services/constant.service';
import { NavService } from '../../services/nav.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ToastrService } from 'ngx-toastr';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
  ChangeDetectorRef,
  Input,
  OnChanges,
} from '@angular/core';
// import * as firebase from "firebase/app";
// import 'firebase/storage';
// import 'firebase/firestore'
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { R2Service } from 'src/app/services/r2.service';
import { environment } from 'src/environments/environment';
declare var gifshot;
declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-avatarshot',
  templateUrl: './questioner-avatar-shot.component.html',
  styleUrls: ['./questioner-avatar-shot.component.scss'],
})
export class QuestionerAvatarShotComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() questionerAvatar: QuestionerAvatar = null;
  @Output() questionerAvatarCreated = new EventEmitter();
  public action_type: 'TAKE' | 'UPLOAD' = null;

  constructor(
    private toastr: ToastrService,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    public navService: NavService,
    public translate: TranslateService,
    private r2Service: R2Service
  ) {}
  width = 300; // We will scale the photo width to this
  height = 0; // This will be computed based on the input stream

  streaming = false;
  canvas: HTMLCanvasElement;
  photo: HTMLImageElement;
  video: HTMLVideoElement;
  pictureDataURL = null;
  jpgDownloadURL = null;
  questionerAvatarName = null;
  hasTakenPicture = false;
  private currentStream;

  ngOnInit(): void {
    this.photo = document.getElementById('photo') as HTMLImageElement;
    if (this.questionerAvatar) {
      this.jpgDownloadURL = this.questionerAvatar.jpgURL;
      this.pictureDataURL = this.jpgDownloadURL;
      this.photo.setAttribute('src', this.questionerAvatar.jpgURL);
      this.questionerAvatarName = this.questionerAvatar.name;
    }
  }

  initToTakePicture() {
    this.action_type = 'TAKE';
    setTimeout(() => {
      this.canvas = document.getElementById('canvas') as HTMLCanvasElement;
      this.video = document.getElementById('video') as HTMLVideoElement;
      // when camera loaded to video, set width/height
      this.video.addEventListener(
        'canplay',
        (ev) => {
          if (!this.streaming) {
            this.height =
              this.video.videoHeight / (this.video.videoWidth / this.width);

            this.video.setAttribute('width', this.width + '');
            this.video.setAttribute('height', this.height + '');
            this.canvas.setAttribute('width', this.width + '');
            this.canvas.setAttribute('height', this.height + '');
            this.streaming = true;
          }
        },
        false
      );
      // Get access to the camera!
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // Not adding `{ audio: true }` since we only want video now
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((stream) => {
            this.currentStream = stream;
            //video.src = window.URL.createObjectURL(stream);
            this.video.srcObject = stream;
            this.video.style.display = 'inherit';
            this.video.play();
          })
          .catch(function (err) {
            console.log('An error occurred: ' + err);
          });
      }
    });
  }

  ngOnChanges(event) {
    // let tmp = event;
    // if input questionerAvatar changed
    // do an update like below
    /*
    this.jpgDownloadURL = this.questionerAvatar.jpgURL;
      this.pictureDataURL = this.jpgDownloadURL;
      this.photo.setAttribute('src', this.questionerAvatar.jpgURL);
      this.questionerAvatarName = this.questionerAvatar.name;
      */
    //  this.jpgDownloadURL = event.questionerAvatar.currentValue.jpgURL;
    //  this.pictureDataURL = this.jpgDownloadURL;
    //   this.photo.setAttribute('src', event.questionerAvatar.currentValue.jpgURL);
    //   this.questionerAvatarName = event.questionerAvatar.currentValue.name;
  }

  handleThumbnailChanged(event: {
    type: string;
    url: string;
    origin?: string;
  }) {
    this.pictureDataURL = event.url;
    this.photo.setAttribute('src', this.pictureDataURL);
    this.hasTakenPicture = true;
  }

  ngOnDestroy(): void {}

  takepicture() {
    var context = this.canvas.getContext('2d');
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    context.drawImage(this.video, 0, 0, this.width, this.height);

    this.pictureDataURL = this.canvas.toDataURL('image/jpeg', 1);
    this.photo.setAttribute('src', this.pictureDataURL);
    this.hasTakenPicture = true;
  }

  async useIt() {
    if (this.hasTakenPicture) {
      // const blob = await (await fetch(this.gifDataBase64)).blob();
      let progressionToast = this.toastr.warning(
        this.translate.instant('Submitting...'),
        '',
        {
          extendedTimeOut: 100000000,
          timeOut: 100000000, // The progress bar is displayed only if there is a timeout, so I set a very large timeout to close it manually
          enableHtml: true,
          tapToDismiss: false,
          progressBar: true,
          progressAnimation: 'increasing',
        }
      );
      // var storageRef = firebase.storage().ref();
      let progress = 0;
      let lastImgPath =
        'images/' + this.authService.userData.uid + '/' + Date.now() + '.jpg';
      await this.r2Service.uploadFile(this.pictureDataURL, lastImgPath, 1);
      this.jpgDownloadURL = environment.r2BaseURL + '/' + lastImgPath;
      let questionerAvatarId = 0;
      if (this.questionerAvatar) {
        questionerAvatarId = this.questionerAvatar.id;
      } else {
        if (this.authService.schoolData.questionerAvatars.length > 0) {
          questionerAvatarId = Math.max(
            ...this.authService.schoolData.questionerAvatars.map(
              (question) => question.id
            )
          );
        }
        questionerAvatarId++;
      }

      let emitQuestionAvatar = null;
      let deleteURL = null;
      if (this.questionerAvatar) {
        deleteURL = this.questionerAvatar.jpgURL;
        this.questionerAvatar.jpgURL = this.jpgDownloadURL;
        this.questionerAvatar.name = this.questionerAvatarName;
        this.authService.schoolData.questionerAvatars.map((qa) => {
          if (qa.id == this.questionerAvatar.id) {
            qa.jpgURL = this.jpgDownloadURL;
            qa.name = this.questionerAvatarName;
          }
          return qa;
        });
        emitQuestionAvatar = this.questionerAvatar;
      } else {
        let newQuestionerAvatar = {
          id: questionerAvatarId,
          jpgURL: this.jpgDownloadURL,
          name: this.questionerAvatarName,
        };
        emitQuestionAvatar = newQuestionerAvatar;
        this.authService.schoolData.questionerAvatars.push(newQuestionerAvatar);
      }

      this.authService.PushUserDataChanges().then(() => {
        this.questionerAvatarCreated.emit(emitQuestionAvatar);
        this.toastr.clear();
        this.toastr.success(this.translate.instant('Done'), '', {
          extendedTimeOut: 3000,
          timeOut: 5000,
          enableHtml: true,
          tapToDismiss: false,
        });

        if (this.questionerAvatar) {
          this.authService.deleteFromURL(deleteURL, () => {});
        }
      });

      // end migrate
      // var metadata = {
      //   cacheControl: 'public, max-age=31536000', // Cache for one year
      // };
      // var uploadLastImageTask = storageRef
      //   .child('images/' + lastImgPath)
      //   .putString(this.pictureDataURL, 'data_url', metadata);
      // uploadLastImageTask.on(
      //   firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      //   (snapshot) => {
      //     // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      //     progress = Math.floor(
      //       (snapshot.bytesTransferred / snapshot.totalBytes) * 50
      //     );
      //     console.log('Upload is ' + progress + '% done');
      //     if (progress > 0) {
      //       (<any>progressionToast).toastRef.componentInstance.updateProgress =
      //         () => {
      //           (<any>progressionToast).toastRef.componentInstance.width =
      //             progress;
      //         };
      //     }
      //   },
      //   (error) => {
      //     console.log('error: ', error);
      //   },
      //   () => {
      //     // Upload completed successfully, now we can get the download URL
      //     uploadLastImageTask.snapshot.ref
      //       .getDownloadURL()
      //       .then((downloadURL) => {
      //         (<any>(
      //           progressionToast
      //         )).toastRef.componentInstance.updateProgress = () => {
      //           (<any>progressionToast).toastRef.componentInstance.width = 70;
      //         };

      //         this.jpgDownloadURL = downloadURL;
      //         if (this.action_type == 'TAKE') {
      //           this.currentStream.getTracks().forEach((track) => track.stop());
      //         }
      //         let questionerAvatarId = 0;
      //         if (this.questionerAvatar) {
      //           questionerAvatarId = this.questionerAvatar.id;
      //         } else {
      //           if (this.authService.schoolData.questionerAvatars.length > 0) {
      //             questionerAvatarId = Math.max(
      //               ...this.authService.schoolData.questionerAvatars.map(
      //                 (question) => question.id
      //               )
      //             );
      //           }
      //           questionerAvatarId++;
      //         }

      //         let emitQuestionAvatar = null;
      //         let deleteURL = null;
      //         if (this.questionerAvatar) {
      //           deleteURL = this.questionerAvatar.jpgURL;
      //           this.questionerAvatar.jpgURL = downloadURL;
      //           this.questionerAvatar.name = this.questionerAvatarName;
      //           this.authService.schoolData.questionerAvatars.map((qa) => {
      //             if (qa.id == this.questionerAvatar.id) {
      //               qa.jpgURL = downloadURL;
      //               qa.name = this.questionerAvatarName;
      //             }
      //             return qa;
      //           });
      //           emitQuestionAvatar = this.questionerAvatar;
      //         } else {
      //           let newQuestionerAvatar = {
      //             id: questionerAvatarId,
      //             jpgURL: downloadURL,
      //             name: this.questionerAvatarName,
      //           };
      //           emitQuestionAvatar = newQuestionerAvatar;
      //           this.authService.schoolData.questionerAvatars.push(
      //             newQuestionerAvatar
      //           );
      //         }

      //         this.authService.PushUserDataChanges().then(() => {
      //           (<any>(
      //             progressionToast
      //           )).toastRef.componentInstance.updateProgress = () => {
      //             (<any>progressionToast).toastRef.componentInstance.width = 90;
      //           };
      //           let cb = () => {
      //             (<any>(
      //               progressionToast
      //             )).toastRef.componentInstance.updateProgress = () => {
      //               (<any>(
      //                 progressionToast
      //               )).toastRef.componentInstance.width = 100;
      //             };
      //             this.questionerAvatarCreated.emit(emitQuestionAvatar);
      //             this.toastr.clear();
      //             this.toastr.success(this.translate.instant('Done'), '', {
      //               extendedTimeOut: 3000,
      //               timeOut: 5000,
      //               enableHtml: true,
      //               tapToDismiss: false,
      //             });
      //           };
      //           if (this.questionerAvatar) {
      //             this.authService.deleteFromURL(deleteURL, () => {
      //               cb();
      //             });
      //           } else {
      //             cb();
      //           }
      //         });
      //       });
      //   }
      // );
    } else if (this.questionerAvatar) {
      this.questionerAvatar.name = this.questionerAvatarName;
      this.questionerAvatarCreated.emit(this.questionerAvatar);
    }
  }

  cancel() {
    try {
      this.currentStream.getTracks().forEach((track) => track.stop());
    } catch (e) {}

    this.questionerAvatarCreated.emit(null);
  }
}
