<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="">
    <div
      class="authentication-box media-profile-form"
      style="padding: 0; margin: 0 auto"
    >
      <div class="row">
        <!-- <app-breadcrumb></app-breadcrumb> -->
      </div>
    </div>
    <div class="authentication-box media-profile-form">
      <div class="card mt-4">
        <div class="card-body">
          <div class="form-group mb-0">
            <input
              class="form-control"
              type="text"
              [(ngModel)]="searchTerm"
              name="textSearch"
              [placeholder]="'Filter by Category name...' | translate"
              (keyup)="searchTermChanged($event)"
              maxlength="50"
            />
          </div>
          <hr class="clearfix" />
          <div class="form-group">
            <div *ngIf="items.length == 0">
              {{ "No course, click add to create new one." | translate }}
            </div>
            <div class="row p-t-5 p-b-5">
              <div class="col-7">
                <h6 style="font-weight: bold">
                  {{ "Category name" | translate }}
                </h6>
              </div>
              <div
                class="col-2 text-center"
                style="padding-left: 0; padding-right: 0"
              >
                <h6 style="font-weight: bold">{{ "Enable" | translate }}</h6>
              </div>
              <div class="col-3 text-right" style="font-size: 20px"></div>
            </div>
            <div
              class="clearfix ylist"
              dragula="DRAGULA_FACTS"
              [(dragulaModel)]="searchResults"
              *ngIf="searchResults.length > 0"
            >
              <div
                class="row p-t-5 p-b-5"
                *ngFor="let category of searchResults; let i = index"
              >
                <div class="col-7">
                  <h6 class="task-label" id="label_{{ category.cid }}">
                    {{ category.name }}
                  </h6>
                </div>
                <div
                  class="col-2 text-right"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <input
                    type="checkbox"
                    class="checkbox"
                    [checked]="!category.disabled"
                    style="width: 16px; height: 16px"
                    (click)="toggleDisabled(category.cid)"
                  />
                </div>
                <div class="col-3 text-right" style="font-size: 20px">
                  <i
                    class="fa fa-edit m-r-5"
                    (click)="edit(category.cid, content)"
                    style="cursor: pointer"
                    [title]="'Edit' | translate"
                  ></i>
                  <span
                    class="action-box large delete-btn m-r-5"
                    [title]="'Delete Category' | translate"
                    (click)="withConfirmation(category.cid)"
                    style="cursor: pointer"
                    ><i class="icon"><i class="icon-trash"></i></i
                  ></span>
                  <i
                    class="icofont icofont-drag"
                    style="cursor: move"
                    [title]="'Drag this item to different position' | translate"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group form-row mt-3 mb-0 text-right">
            <button
              class="btn btn-{{
                saveButtonRef.label == 'Save' ? 'primary' : 'success'
              }} mr-2"
              type="button"
              placement="top"
              type="button"
              (click)="save()"
            >
              {{ saveButtonRef.label | translate }}
            </button>
            <button
              class="btn btn-danger mr-2"
              type="button"
              placement="top"
              type="button"
              (click)="add(content)"
            >
              {{ "New" | translate }}
            </button>
            <button
              class="btn btn-light"
              type="button"
              placement="top"
              (click)="cancel()"
              [title]="'Back' | translate"
            >
              {{ "Back" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-body" id="modalBody">
    <app-edit-category
      [editId]="editId"
      (eventChange)="eventChange($event)"
      [type]="actionType"
    >
    </app-edit-category>
  </div>
</ng-template>
