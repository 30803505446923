import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/toPromise';
import { CommonService } from './common.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class R2Service {
  errorMessage: string | undefined;
  constructor(public http: HttpClient, private commonService: CommonService) {}

  // async uploadFile(
  //   body: string,
  //   filename: string,
  //   isPublic = 0
  // ): Promise<void> {
  //   const apiEndpoint = environment.apiEndpoint;
  //   const url = apiEndpoint + '/storage';
  //   const data = JSON.stringify({
  //     operation: 'write',
  //     key: filename,
  //     body: body,
  //     isPublic: 1,
  //   });

  //   try {
  //     const response: any = await this.http
  //       .post(url, data, {
  //         headers: this.commonService.appendAuthenticationHeader(
  //           new HttpHeaders().set('Content-Type', 'application/json')
  //         ),
  //         observe: 'response',
  //         responseType: 'json',
  //       })
  //       .toPromise();

  //     if (response.status === 200) {
  //       this.errorMessage = undefined;
  //     } else {
  //       this.handleError(response.statusText);
  //     }
  //   } catch (error) {
  //     this.handleError(error.message);
  //   }
  // }

  async uploadFile(
    body: string,
    filename: string,
    isPublic = 0
  ): Promise<string> {
    const apiEndpoint = environment.apiEndpoint;
    const url = apiEndpoint + '/storage';

    let data;
    let headers = this.commonService.appendAuthenticationHeader(
      new HttpHeaders()
    );

    if (filename.includes('.mp3') || filename.includes('.jpg')) {
      // Extract base64 data without the prefix
      const base64 = body.split(',')[1];

      // Determine the content type
      let contentType = '';
      if (filename.includes('.mp3')) {
        contentType = 'audio/webm; codecs=opus';
      } else if (filename.includes('.jpg')) {
        contentType = 'image/jpeg';
      }

      // Decode base64 data and create a Blob
      const binaryString = atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: contentType });

      // Create a FormData object and append the Blob
      const formData = new FormData();
      formData.append('file', blob, filename);
      formData.append('operation', 'write');
      formData.append('key', filename);
      formData.append('isPublic', isPublic.toString());

      data = formData;
    } else {
      // For other file types, use the original data format
      data = JSON.stringify({
        operation: 'write',
        key: filename,
        body: body,
        isPublic: isPublic,
      });

      headers = headers.set('Content-Type', 'application/json');
    }

    try {
      const response: any = await this.http
        .post(url, data, {
          headers: headers,
          observe: 'response',
          responseType: 'json',
        })
        .toPromise();

      if (response.status === 200) {
        this.errorMessage = undefined;
        return environment.r2BaseURL + '/' + filename;
      } else {
        this.handleError(response.statusText);
        return '';
      }
    } catch (error) {
      this.handleError(error.message);
    }
  }

  async getFile(filename: string, isPublic = 1): Promise<any> {
    const apiEndpoint = environment.apiEndpoint;
    const url = apiEndpoint + '/storage'; // Replace with your Worker's URL

    try {
      const data = JSON.stringify({
        operation: 'read',
        key: filename,
        body: '',
        isPublic: 1,
      });
      const response = await this.http
        .post(url, data, {
          observe: 'response',
          headers: this.commonService.appendAuthenticationHeader(
            new HttpHeaders().set('Content-Type', 'application/json')
          ),
          responseType: 'json',
        })
        .toPromise();

      if (response.status === 200) {
        return response.body;
      } else if (response.status === 404) {
        this.errorMessage = 'File not found.';
        this.handleError(this.errorMessage);
      } else {
        this.handleError(response.statusText);
        this.handleError(response.statusText);
      }
    } catch (error) {
      this.handleError(error.message);
    }
  }

  async deleteFile(filename: string, isPublic = 1): Promise<void> {
    const apiEndpoint = environment.apiEndpoint;
    const url = apiEndpoint + '/storage'; // Replace with your Worker's URL
    const deletedFileName = filename.replace(environment.r2BaseURL + '/', '');
    const data = JSON.stringify({
      operation: 'delete',
      key: deletedFileName,
      body: '',
      isPublic: 1,
    });
    try {
      const response = await this.http
        .post(url, data, {
          observe: 'response',
          headers: this.commonService.appendAuthenticationHeader(
            new HttpHeaders().set('Content-Type', 'application/json')
          ),
          responseType: 'json',
        })
        .toPromise();

      if (response.status === 200) {
        this.errorMessage = undefined;
      } else {
        this.handleError(response.statusText);
      }
    } catch (error) {
      this.handleError(error.message);
    }
  }

  downloadFile(url: string, filename: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  }

  private handleError(message: string) {
    this.errorMessage = message;
  }
}

interface FileData {
  content: string; // For upload
  url?: string; // For get
}
