import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Category, Lesson } from 'src/app/services/constant.service';
import { cloneDeep } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
declare var require;
const Swal = require('sweetalert2');

export interface Task {
  text: string;
  completed: boolean;
}
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  public items: Category[];
  public media: Lesson[];
  public visible: boolean;
  public editId: number = null;
  public modal: NgbModalRef;
  public actionType: 'add' | 'edit' = null;
  searchTerm: string = '';
  searchResults: Category[] = [];
  public saveButtonRef = { label: 'Save' };

  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private modalService: NgbModal
  ) {}

  // A warning message, with a function attached to the "Confirm"-button...
  withConfirmation(deletedCid) {
    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: "You won't be able to revert this!",
    //   type: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Yes, delete it!'
    // }).then((result) => {
    //   if (result.value) {
    //     this.deleteCategory(deletedCid);
    //   }
    // })

    Swal.fire({
      title: this.translate.instant("You won't be able to revert this!"),
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: this.translate.instant(
        'Delete category but keep related lessons'
      ),
      denyButtonText: this.translate.instant(
        'Delete category and related lessons'
      ),
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteCategory(deletedCid);
      } else if (result.isDenied) {
        this.deleteCategory(deletedCid, true);
      }
    });
  }

  searchTermChanged(event) {
    this.searchResults = this.items.filter((item) => {
      return (
        item.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 &&
        item.cid != 10000
      );
    });
  }

  updatePageData() {
    this.items = cloneDeep(this.authService.schoolData.data.categories);
    this.searchResults = cloneDeep(this.items);
    this.searchResults = this.searchResults.filter((item) => {
      return item.cid != 10000;
    });
    ``;
  }

  //For deleting a task
  public deleteCategory(deletedCid, deleteVideo = false) {
    this.authService.toastSubmit();
    deletedCid = Number(deletedCid);
    let callback = () => {
      this.reOrderLessonCategories();
      this.authService.schoolData.data.categories = this.items;
      this.authService.schoolData.data.lessons = this.media;
      this.authService.PushUserDataChanges().then(() => {
        this.updatePageData();
        this.authService.toastSaved(this.saveButtonRef);
      });
    };

    this.items = this.items.filter((item) => {
      return item.cid != deletedCid;
    });
    this.items = this.items.filter((item) => {
      return item.cid != deletedCid;
    });
    if (deleteVideo) {
      let deletedItems = [];
      this.media = this.media.filter((media) => {
        if (media.cid.includes(deletedCid)) {
          deletedItems.push(media);
          return false;
        }
        return true;
      });
      this.authService.deleteResources(deletedItems, callback);
    } else {
      this.media.map((media) => {
        media.cid = Array.isArray(media.cid)
          ? media.cid.map((c) => Number(c))
          : [Number(media.cid)];
        if (media.cid.includes(deletedCid)) {
          // replace deleted category with default category 10000
          if (media.cid.length == 1) {
            media.cid = [10000];
          } else {
            media.cid = media.cid.filter((c) => c != deletedCid);
          }
        }
        return media;
      });
      callback();
    }
  }

  ngOnInit() {
    if (this.authService.schoolData) {
      this.items = this.authService.schoolData.data.categories;
      console.log(this.items);
      this.updatePageData();
      this.media = this.authService.schoolData.data.lessons;
    }
  }

  cancel() {
    this.router.navigate(['/library']);
  }

  reOrderLessonCategories() {
    this.authService.schoolData.data.categories.sort((a, b) => {
      const aIndex = this.searchResults.findIndex((item) => {
        return item.cid == a.cid;
      });
      const bIndex = this.searchResults.findIndex((item) => {
        return item.cid == b.cid;
      });
      if (aIndex > -1 || bIndex > -1) {
        return aIndex - bIndex;
      }
      return 0;
    });
  }

  save() {
    this.authService.toastSubmit();

    this.authService.schoolData.data.categories =
      this.authService.schoolData.data.categories.map((item) => {
        let localItem = this.items.find((localItem) => {
          return localItem.cid == item.cid;
        });
        if (localItem) {
          item.disabled = localItem.disabled;
        }
        return item;
      });

    this.reOrderLessonCategories();
    console.log(this.authService.schoolData.data.categories);
    this.authService.PushUserDataChanges().then(() => {
      this.authService.toastSaved(this.saveButtonRef);
    });
  }

  edit(cid: number, content) {
    this.editId = cid;
    this.actionType = 'edit';
    this.modal = this.modalService.open(content, { size: 'sm' });
  }

  add(content) {
    this.editId = null;
    this.actionType = 'add';
    this.modal = this.modalService.open(content, { size: 'sm' });
  }
  eventChange($event) {
    if ($event) {
      this.items = cloneDeep(this.authService.schoolData.data.categories);
      this.updatePageData();
      this.authService.toastSaved(this.saveButtonRef);
    }
    this.modal.close();
  }

  toggleDisabled(cid: number) {
    this.items = this.items.map((item) => {
      if (item.cid == cid) {
        item.disabled = !item.disabled;
      }
      return item;
    });
  }
}
