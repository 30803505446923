import { NavService } from "src/app/services/nav.service";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";

@Component({
  selector: "app-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.scss"],
})
export class InfoComponent implements OnInit, OnDestroy {
  @Input() name: string;
  @Input() color: "black" | "white" = "black";
  @Input() infoText: string = "";
  constructor(public navService: NavService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  getTranslatedInfoText() {
    return this.navService.getInfoText(this.name);
  }
}
