import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
} from "@angular/core";
import {
  SelectionType,
  Video,
  YlistTargetVideo,
} from "src/app/services/constant.service";
import { AuthService } from "../../services/firebase/auth.service";
import { cloneDeep } from "lodash-es";

@Component({
  selector: "app-menu-generator",
  templateUrl: "./menu-generator.component.html",
  styleUrls: ["./menu-generator.component.scss"],
})
export class MenuGeneratorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() listDisplayType: SelectionType;
  @Input() selected: YlistTargetVideo;
  @Input() selectedTerm: string;
  @Input() calculateSize: boolean = false;
  @Input() isInPopUp: boolean = true;
  public ylistTargetVideos: YlistTargetVideo[];
  @Input() yList: Video[];
  ListPlayTypeEnum = SelectionType;
  private currentSelectedYID: string;
  private currentSelectedTerm: string;
  public borderWidth: number;
  public colColorClasses: string = "col-6 col-xs-4 col-sm-4 col-lg-3 col-xl-3";
  public countItems = 0;
  private systemTerms = {};

  constructor(
    private cd: ChangeDetectorRef,
    public authService: AuthService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    console.log(this.listDisplayType);
    console.log(this.ylistTargetVideos);
    let tmpObj = cloneDeep(this.authService.getSystemWordsKeysValues());
    for (const key in tmpObj) {
      const value = tmpObj[key];
      this.systemTerms[value] = key;
    }
    this.countItems = this.yList.filter((item) => !item.disabled).length;
    if (this.countItems > 0) {
      this.ylistTargetVideos = this.authService.generateYListMatchWords(
        this.yList,
        this.listDisplayType
      );
      if (
        this.calculateSize &&
        this.listDisplayType ==
          this.ListPlayTypeEnum
            .redBlueGreenYellowOrangeBrownPinkGreyBlackWhitePurple
      ) {
      }
      if (this.isInPopUp) {
        let menuGeneratorWrapper = document.getElementById(
          "menuGeneratorWrapper"
        );
        const clientWidth = document.body.clientWidth;
        if (clientWidth > 1000) {
          this.borderWidth = 30;
        } else if (clientWidth > 700) {
          this.borderWidth = 20;
        } else if (clientWidth > 500) {
          this.borderWidth = 15;
        } else {
          this.borderWidth = 5;
        }
        if (this.countItems > 6) {
          this.colColorClasses = "col-3";
        }
        if (clientWidth > 500 && this.countItems <= 3) {
          menuGeneratorWrapper.style.width =
            clientWidth > 900 ? "800px" : "100%";
        } else {
          menuGeneratorWrapper.style.width = "100%";
        }
      } else {
        this.colColorClasses = "col-4";
        this.borderWidth = 10;
      }
    }
  }

  ngOnChanges($event) {
    if (
      $event &&
      $event.selected &&
      $event.selected.currentValue &&
      $event.selected.currentValue.youtubeVideo.yid
    ) {
      this.selected = $event.selected.currentValue;
      if (this.currentSelectedYID) {
        document
          .querySelectorAll(
            ".c" + this.currentSelectedYID + ".term-" + this.currentSelectedTerm
          )
          .forEach((el) => {
            el.classList.remove("animated");
            el.classList.remove("bounce");
            el.classList.remove("infinite");
          });
      }
      this.currentSelectedYID = this.selected.youtubeVideo.yid;
      this.currentSelectedTerm = this.selected.term;
      document
        .querySelectorAll(
          ".c" +
            this.currentSelectedYID +
            ".term-" +
            this.systemTerms[this.currentSelectedTerm]
        )
        .forEach((el) => {
          el.classList.add("animated");
          el.classList.add("bounce");
          el.classList.add("infinite");
        });
      console.log(this.selected);
    }

    if (
      $event &&
      $event.listDisplayType &&
      $event.listDisplayType.currentValue
    ) {
      this.ylistTargetVideos = this.authService.generateYListMatchWords(
        this.yList,
        $event.listDisplayType.currentValue
      );
    }

    if ($event && $event.yList && $event.yList.currentValue) {
      this.ylistTargetVideos = this.authService.generateYListMatchWords(
        $event.yList.currentValue,
        this.listDisplayType
      );
    }
    this.countItems = this.ylistTargetVideos.length;
  }

  ngOnDestroy() {
    this.ylistTargetVideos = [];
    this.listDisplayType = null;
    this.selected = null;
  }
}
