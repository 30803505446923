import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Lesson, Category } from 'src/app/services/constant.service';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss'],
})
export class EditCategoryComponent implements OnInit {
  @Input() type: 'add' | 'edit' = null;
  @Input() editId: number = null;
  @Output() eventChange = new EventEmitter();
  public form: FormGroup;
  public sidebaron: any;
  public categories: Category[];
  public videos: Lesson[];
  public showLanguage: boolean = false;

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private authService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.categories = this.authService.schoolData.data.categories;
    this.videos = this.authService.schoolData.data.lessons;
    // Add new
    if (this.type == 'add') {
      this.form = this.fb.group({
        name: ['', Validators.required],
      });
    } else {
      let category = this.categories.find((item) => {
        return item.cid == this.editId;
      });
      this.form = this.fb.group({
        name: [category.name, Validators.required],
      });
    }
  }

  add(formValue) {
    this.authService.toastSubmit();
    const newCid = this.authService.addNewCategoryToLocal(formValue.name);
    if (!newCid) {
      this.toastrService.error(
        this.translate.instant('Category name already exists or invalid')
      );
    } else {
      this.authService.PushUserDataChanges().then(() => {
        this.categories = this.authService.schoolData.data.categories;
        this.toastrService.clear();
        this.toastrService.success(this.translate.instant('Done'));
        this.eventChange.next(newCid);
      });
    }
  }

  update(formValue) {
    this.authService.toastSubmit();
    this.categories = this.categories.map((category) => {
      if (category.cid == this.editId) {
        category.name = formValue.name;
      }
      return category;
    });
    this.authService.schoolData.data.categories = this.categories;
    this.authService.PushUserDataChanges().then(() => {
      this.toastrService.clear();
      this.toastrService.success(this.translate.instant('Done'));
      this.eventChange.next(this.editId);
    });
  }

  cancel() {
    this.eventChange.next('');
  }
}
