<!-- Container-fluid starts-->
<div class="container-fluid">
  <form class="theme-form" [formGroup]="form">
    <div class="form-group" *ngIf="type == 'add'">
      <label class="col-form-label pt-0">{{
        "Student Email Address" | translate
      }}</label>
      <input
        class="form-control"
        formControlName="emailAddress"
        type="text"
        required=""
        maxlength="50"
      />
      <div
        *ngIf="
          form.controls.emailAddress.touched &&
          form.controls.emailAddress.errors?.name
        "
        class="text text-danger mt-1"
      >
        {{ "Invalid ID" | translate }}
      </div>
    </div>
    <div class="form-group">
      <label class="col-form-label pt-0">{{
        "Student name" | translate
      }}</label>
      <input
        class="form-control"
        formControlName="studentName"
        type="text"
        required=""
        maxlength="50"
      />
      <div
        *ngIf="
          form.controls.studentName.touched &&
          form.controls.studentName.errors?.name
        "
        class="text text-danger mt-1"
      >
        {{ "Invalid name" | translate }}
      </div>
    </div>
    <div class="form-group form-row mt-3 mb-0">
      <button
        class="btn btn-primary mr-2"
        type="button"
        placement="top"
        (click)="update(form.value)"
        type="submit"
        *ngIf="type == 'edit'"
        [disabled]="!form.value.studentName"
      >
        {{ "Update" | translate }}
      </button>
      <button
        class="btn btn-primary mr-2"
        type="button"
        placement="top"
        (click)="add(form.value)"
        type="submit"
        *ngIf="type == 'add'"
        [disabled]="!form.value.studentName || !form.value.emailAddress"
      >
        {{ "Add" | translate }}
      </button>
      <button
        class="btn btn-light"
        type="button"
        placement="top"
        (click)="cancel()"
        title=""
      >
        {{ "Cancel" | translate }}
      </button>
    </div>
  </form>
</div>
