import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './services/analytics.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { LocalIndexedDBStorageService } from './services/local-indexeddb-storage.service';
import { APIResponse, User } from './services/constant.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
declare var require;
const Swal = require('sweetalert2');
declare var gapi: any; // Add this line to use the gapi variable

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Speak to play video';
  public isConnected = false;
  private swagInstance = null;
  constructor(
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
    private http: HttpClient,
    private toster: ToastrService
  ) {
    let lang = localStorage.getItem('uilanguage');
    lang = !lang ? navigator.language : lang;
    lang = lang.split('-')[0];
    this.authService.setTranslationLanguage(lang, true);
    this.analyticsService.initAnalytics();
    window['moment'] = moment;
    // This one sometimes trigger only when there is no internet
    // this.connectionService.monitor().subscribe(isConnected => {
    //   this.isConnected = isConnected;
    //   window['nfs_internet_connection'] = isConnected;
    //   if (!isConnected) {
    //     this.swagInstance = Swal.fire({
    //       type: 'error',
    //       title: 'Oops...',
    //       allowOutsideClick: false,
    //       showCancelButton: false,
    //       showConfirmButton: false,
    //       text: 'No internet connection!',
    //       footer: '<a href="javascript:location.href = location.href">Click to reload the page?</a>'
    //     });

    //   } else {
    //     if (this.swagInstance) {
    //       this.swagInstance.close();
    //       Swal.fire({
    //         title: 'It seems internet connection is back',
    //         html: 'Connecting...',
    //         timer: 2000,
    //         allowOutsideClick: false,
    //         showCancelButton: false,
    //         showConfirmButton: false,
    //         onBeforeOpen: () => {
    //           Swal.showLoading()
    //         }
    //       })
    //     }
    //   }
    // })
  }

  ngOnInit() {
    this.authService.preventOtherAccess();
    const isMobile = this.authService.mobileAndTabletcheck();
    if (isMobile && window.location.pathname !== '/no-supported-device') {
      window.location.href = '/no-supported-device';
    }
    setTimeout(() => {
      window.scrollTo(0, 1);
    });
    // Handle multiple tabs
    setInterval(async () => {
      // Check if user is logged in
      try {
        if (this.authService.userData) {
          let lastUserDataUpdated = await LocalIndexedDBStorageService.getItem(
            'lastUserDataUpdated'
          );
          lastUserDataUpdated = lastUserDataUpdated
            ? parseInt(lastUserDataUpdated)
            : 0;
          if (
            lastUserDataUpdated &&
            this.authService.userData.lastUpdated &&
            this.authService.userData.lastUpdated < lastUserDataUpdated
          ) {
            this.authService.reloadPage();
          }
        }
      } catch (e) {
        console.log(e);
      }
    }, 3000);
    this.tokenLogin();
  }

  tokenLogin() {
    const sessionToken = localStorage.getItem('session_token');
    if (!sessionToken) {
      this.authService.authenticationCallback(null);
      return;
    }
    const apiEndpoint = environment.apiEndpoint;
    const url = `${apiEndpoint}/tokenl`;
    Swal.showLoading();
    this.http
      .post(url, {
        token: sessionToken,
      })
      .subscribe({
        next: (apiResponse: APIResponse) => {
          this.toster.clear();
          if (apiResponse.success) {
            // this.router.navigate(['/']);
            setTimeout(() => {
              this.authService.checkToLoadLatestVersion();
            });
            this.authService.authenticationCallback(apiResponse.user);
          } else {
            localStorage.removeItem('session_token');
          }
          Swal.close();
        },
        error: (apiResponse: APIResponse) => {
          Swal.close();
          localStorage.removeItem('session_token');
        },
      });
  }
}
