import { environment } from './../../environments/environment';
import { AuthService } from './firebase/auth.service';
import { Injectable } from '@angular/core';
import { min, max, mean, chunk, flattenDeep } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class SpeechService {
  public cloudSpeechApiKey: string = '';
  public useCloudSpeech: boolean = false;
  public recognition: any;
  public language: string = 'en-US';
  public isRecording: boolean = false;
  public audioContext: any;
  public processor: any;
  public config = {
    sampleRate: 100,
    bufferLen: 256,
    numChannels: 1,
    mimeType: 'audio/mpeg',
  };
  public tracks;
  public microphone;
  public recordingLength = 0;
  public leftChannel = [];
  public isSpeaking: boolean = false;
  public speakingTimeout: any = 0;
  public blob;
  public buffer;
  public view;
  public sampleRate;
  public bufferSize;
  public length;
  public isAudioProcessStarted: boolean = false;
  public cloudSpeechCallback: any = null;
  public onStopRecordingVoiceCompleteCallback: any = null;

  constructor(public authService: AuthService) {}

  initSpeechRecognition() {
    this.cloudSpeechApiKey = environment.firebase.apiKey;
    var isOpera =
      (!!window['opr'] && !!window['opr'].addons) ||
      !!window['opera'] ||
      navigator.userAgent.indexOf(' OPR/') >= 0;
    const SpeechRecognition = window['webkitSpeechRecognition'];
    if (typeof SpeechRecognition === 'undefined' || isOpera) {
      // check for WebRTC support
      // notice at the release of iOS11, webRTC has not reached WebKit
      // more info: https://stackoverflow.com/questions/45055329/does-webkit-in-ios-11-beta-support-webrtc
      if (this.checkWebRTC()) {
        this.useCloudSpeech = true;
      } else {
        console.error('WebRTC is not supported on this browser.');
        return;
      }
    }

    // for test
    // this.useCloudSpeech = true;
    console.log('Use cloud speech: ' + this.useCloudSpeech);

    if (!this.useCloudSpeech) {
      // Good article on recognition: https://ourcodeworld.com/articles/read/362/getting-started-with-the-speech-recognition-api-in-javascript
      // set up speech recognition
      this.recognition = new (window['SpeechRecognition'] ||
        window['webkitSpeechRecognition'])();
      if (this.language != '')
        this.recognition.lang =
          this.language == 'vi-VNS' ? 'vi-VN' : this.language;
      // weird issue where `interimResults = true` is cutting off speech
      // see comment here: https://developers.google.com/web/updates/2013/01/Voice-Driven-Web-Apps-Introduction-to-the-Web-Speech-API?google_comment_id=z13msdqh0yqpyrpfx23gw5xa2wu0ypzwj04
      const isMobile = this.authService.mobileAndTabletcheck();
      this.recognition.interimResults = !isMobile; // return temporary results, that's a lot due to it's not waiting until speech finish to return
      // Remember this causes recofignition remembers last match speech, example: cow matches 1 video, then you say tiger, it will returns cow tiger
      // to prevent that, it will need to initialize new recognition after each detection
      this.recognition.continuous = false;
      this.recognition.maxAlternatives = 0;

      // add event listeners
      this.recognition.onresult = (event) => {
        var result = {
          speechResult: event.results[0][0].transcript,
          confidence: event.results[0][0].confidence,
          isFinal: event.results[0].isFinal,
          sourceEvent: event,
        };
        console.log(result);
      };
      this.recognition.onspeechend = (e) => {
        console.log('speech end');
        console.log(e);
      };
      this.recognition.onerror = (e) => {
        console.log('speech error');
        console.log(e);
      };
    } else {
      // temporary disable cloud speech due to too much to handle from cloud: connection speed, file size
    }
  }

  public setOnStopRecordingVoiceCompleteCallBack(cb) {
    this.onStopRecordingVoiceCompleteCallback = cb;
  }

  public setBrowserSpeechRecognitionCallback(cb) {
    this.recognition.onresult = cb;
  }

  public setCloudSpeechCallback(cb) {
    this.cloudSpeechCallback = cb;
  }

  public setLanguage(language) {
    this.language = language;
    if (!this.useCloudSpeech) {
      this.recognition.lang = language;
    }
  }

  startListening() {
    if (!this.useCloudSpeech) {
      // start recognition
      this.recognition.continuous = true;
      // console.log('recognition start')
      // console.log(this.recognition);
      this.recognition.start();
    } else {
      this.startRecordingVoice();
    }
  }

  startRecordingVoice() {
    this.isRecording = true;
    this.startRecord();
  }

  startRecord() {
    this.audioContext = new AudioContext();
    /**
     * Create a ScriptProcessorNode
     * */
    if (this.audioContext.createJavaScriptNode) {
      this.processor = this.audioContext.createJavaScriptNode(
        this.config.bufferLen,
        this.config.numChannels,
        this.config.numChannels
      );
    } else if (this.audioContext.createScriptProcessor) {
      this.processor = this.audioContext.createScriptProcessor(
        this.config.bufferLen,
        this.config.numChannels,
        this.config.numChannels
      );
    } else {
      console.log('WebAudio API has no support on this browser.');
    }

    this.processor.connect(this.audioContext.destination);
    /**
     *  ask permission of the user for use this.microphone or camera
     * */
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(this.gotStreamMethod.bind(this))
      .catch((e) => {
        console.log(e);
      });
  }

  gotStreamMethod(stream) {
    // audioElement.src = "";
    this.isRecording = true;

    this.tracks = stream.getTracks();
    /**
     * Create a MediaStreamAudioSourceNode for the this.microphone
     * */
    this.microphone = this.audioContext.createMediaStreamSource(stream);
    /**
     * connect the AudioBufferSourceNode to the gainNode
     * */
    this.microphone.connect(this.processor);
    // encoder = new Mp3LameEncoder(audioContext.sampleRate, 160);
    /**
     * Give the node a function to process audio events
     */
    this.processor.onaudioprocess = (e) => {
      var left = e.inputBuffer.getChannelData(0);
      left = this.silenceRemovalAlgorithm(left);

      this.recordingLength += this.config.bufferLen;
      // we clone the samples
      this.leftChannel.push(new Float32Array(left));
      // Check for pause while speaking
      var MIN_SPEAKING_VOLUME = 0.04;
      var sum = 0.0;
      var i;
      var clipcount = 0;
      for (i = 0; i < left.length; ++i) {
        sum += left[i] * left[i];
        if (Math.abs(left[i]) > 0.99) {
          clipcount += 1;
        }
      }
      var volume = Math.sqrt(sum / left.length);
      if (volume > MIN_SPEAKING_VOLUME) {
        this.isSpeaking = true;
        clearTimeout(this.speakingTimeout);
      } else {
        if (this.isSpeaking) {
          this.isSpeaking = false;
          clearTimeout(this.speakingTimeout);
          this.speakingTimeout = setTimeout(() => {
            this.stopRecord();
          }, 500);
        }
      }
    };

    this.analyzer(this.audioContext);
  }

  stopRecord(processingResult: boolean = true) {
    this.audioContext.close();
    this.processor.disconnect();
    this.tracks.forEach((track) => track.stop());
    if (!processingResult) {
      return;
    }

    this.mergeLeftRightBuffers(
      {
        sampleRate: this.config.sampleRate,
        numberOfAudioChannels: this.config.numChannels,
        internalInterleavedLength: this.recordingLength,
        leftBuffers: this.leftChannel,
        rightBuffers: this.config.numChannels === 1 ? [] : 1,
      },
      (buffer, view) => {
        this.blob = new Blob([view], {
          type: 'audio/mpeg',
        });
        this.buffer = new ArrayBuffer(view.buffer.byteLength);
        this.view = view;
        this.sampleRate = this.config.sampleRate;
        this.bufferSize = this.config.bufferLen;
        this.length = this.recordingLength;

        this.onStopRecordingVoiceComplete(this.blob);
        this.isRecording = false;
        this.clearRecordedData();

        this.isAudioProcessStarted = false;
      }
    );
  }

  clearRecordedData() {
    this.recordingLength = 0;
    this.leftChannel = [];
  }

  stopListening(processingResult = true) {
    if (!this.useCloudSpeech) {
      this.recognition.continuous = true;
      this.recognition.stop();
    } else {
      this.stopRecord(processingResult);
    }
  }

  onStopRecordingVoiceComplete(blob) {
    if (this.onStopRecordingVoiceCompleteCallback) {
      this.onStopRecordingVoiceCompleteCallback(blob);
    } else {
      if (this.isRecording) {
        this.isRecording = false;
        this.postTranscription(blob);
      }
    }
  }
  public speechContextPhrases = '';
  setSpeechContext(phrases) {
    this.speechContextPhrases = JSON.stringify(phrases);
  }

  postTranscription(blob) {
    this.blobToBase64(blob, (data) => {
      console.log('Language: ' + this.language);
      let postBody = `{
          "config": {
            "encoding": "LINEAR16",
            "languageCode": "${this.language}",
            "enableWordTimeOffsets": false,
            "profanityFilter": true,
            "speech_contexts": {"phrases": ${this.speechContextPhrases}},
            "useEnhanced": true
          },
          "audio": {
            "content": "${data}"
          }
        }`;
      let xhr = new XMLHttpRequest();
      xhr.open(
        'POST',
        'https://speech.googleapis.com/v1/speech:recognize?key=' +
          this.cloudSpeechApiKey,
        true
      );
      xhr.onload = (data) => {
        // Request finished. Do processing here.
        // hide the dot loader
        let response = JSON.parse(xhr.responseText);
        if (
          response &&
          response.results &&
          response.results[0] &&
          response.results[0].alternatives &&
          response.results[0].alternatives[0] &&
          response.results[0].alternatives[0].transcript
        ) {
          let text = response.results[0].alternatives[0].transcript || '';
          let event = {
            transcript: text,
            confidence: response.results[0].alternatives[0].confidence,
            isFinal: true,
          };
          if (this.cloudSpeechCallback) {
            this.cloudSpeechCallback(event);
          }
          console.log(response);
          console.log(event);
        }
      };
      xhr.onerror = () => {
        console.error('Error occurred during Cloud Speech AJAX request.');
      };
      xhr.send(postBody);
    });
  }

  analyzer(context) {
    let listener = context.createAnalyser();
    this.microphone.connect(listener);
    listener.fftSize = 256;
    var bufferLength = listener.frequencyBinCount;
    let analyserData = new Uint8Array(bufferLength);
  }

  mergeLeftRightBuffers(config, callback) {
    function mergeAudioBuffers(config, cb) {
      var numberOfAudioChannels = config.numberOfAudioChannels;

      // todo: "slice(0)" --- is it causes loop? Should be removed?
      var leftBuffers = config.leftBuffers.slice(0);
      var rightBuffers = config.rightBuffers.slice(0);
      var sampleRate = config.sampleRate;
      var internalInterleavedLength = config.internalInterleavedLength;
      var desiredSampRate = config.desiredSampRate;

      if (numberOfAudioChannels === 2) {
        leftBuffers = mergeBuffers(leftBuffers, internalInterleavedLength);
        rightBuffers = mergeBuffers(rightBuffers, internalInterleavedLength);
        if (desiredSampRate) {
          leftBuffers = interpolateArray(
            leftBuffers,
            desiredSampRate,
            sampleRate
          );
          rightBuffers = interpolateArray(
            rightBuffers,
            desiredSampRate,
            sampleRate
          );
        }
      }

      if (numberOfAudioChannels === 1) {
        leftBuffers = mergeBuffers(leftBuffers, internalInterleavedLength);
        if (desiredSampRate) {
          leftBuffers = interpolateArray(
            leftBuffers,
            desiredSampRate,
            sampleRate
          );
        }
      }

      // set sample rate as desired sample rate
      if (desiredSampRate) {
        sampleRate = desiredSampRate;
      }

      // for changing the sampling rate, reference:
      // http://stackoverflow.com/a/28977136/552182
      function interpolateArray(data, newSampleRate, oldSampleRate) {
        var fitCount = Math.round(
          data.length * (newSampleRate / oldSampleRate)
        );
        //var newData = new Array();
        var newData = [];
        //var springFactor = new Number((data.length - 1) / (fitCount - 1));
        var springFactor = Number((data.length - 1) / (fitCount - 1));
        newData[0] = data[0]; // for new allocation
        for (var i = 1; i < fitCount - 1; i++) {
          var tmp = i * springFactor;
          //var before = new Number(Math.floor(tmp)).toFixed();
          //var after = new Number(Math.ceil(tmp)).toFixed();
          var before: any = Number(Math.floor(tmp)).toFixed();
          var after = Number(Math.ceil(tmp)).toFixed();
          var atPoint = tmp - before;
          newData[i] = linearInterpolate(data[before], data[after], atPoint);
        }
        newData[fitCount - 1] = data[data.length - 1]; // for new allocation
        return newData;
      }

      function linearInterpolate(before, after, atPoint) {
        return before + (after - before) * atPoint;
      }

      function mergeBuffers(channelBuffer, rLength) {
        var result = new Float64Array(rLength);
        var offset = 0;
        var lng = channelBuffer.length;

        for (var i = 0; i < lng; i++) {
          var buffer = channelBuffer[i];
          result.set(buffer, offset);
          offset += buffer.length;
        }

        return result;
      }

      function interleave(leftChannel, rightChannel) {
        var length = leftChannel.length + rightChannel.length;

        var result = new Float64Array(length);

        var inputIndex = 0;

        for (var index = 0; index < length; ) {
          result[index++] = leftChannel[inputIndex];
          result[index++] = rightChannel[inputIndex];
          inputIndex++;
        }
        return result;
      }

      function writeUTFBytes(view, offset, string) {
        var lng = string.length;
        for (var i = 0; i < lng; i++) {
          view.setUint8(offset + i, string.charCodeAt(i));
        }
      }

      // interleave both channels together
      var interleaved;

      if (numberOfAudioChannels === 2) {
        interleaved = interleave(leftBuffers, rightBuffers);
      }

      if (numberOfAudioChannels === 1) {
        interleaved = leftBuffers;
      }

      var interleavedLength = interleaved.length;
      // create wav file
      var resultingBufferLength = 44 + interleavedLength * 2;
      var buffer = new ArrayBuffer(resultingBufferLength);

      var view = new DataView(buffer);

      // RIFF chunk descriptor/identifier
      writeUTFBytes(view, 0, 'RIFF');

      // RIFF chunk length
      view.setUint32(4, 44 + interleavedLength * 2, true);

      // RIFF type
      writeUTFBytes(view, 8, 'WAVE');

      // format chunk identifier
      // FMT sub-chunk
      writeUTFBytes(view, 12, 'fmt ');

      // format chunk length
      view.setUint32(16, 16, true);

      // sample format (raw)
      view.setUint16(20, 1, true);

      // stereo (2 channels)
      view.setUint16(22, numberOfAudioChannels, true);

      // sample rate
      view.setUint32(24, sampleRate, true);

      // byte rate (sample rate * block align)
      view.setUint32(28, sampleRate * 2, true);

      // block align (channel count * bytes per sample)
      view.setUint16(32, numberOfAudioChannels * 2, true);

      // bits per sample
      view.setUint16(34, 16, true);

      // data sub-chunk
      // data chunk identifier
      writeUTFBytes(view, 36, 'data');

      // data chunk length
      view.setUint32(40, interleavedLength * 2, true);

      // write the PCM samples
      var lng = interleavedLength;
      var index = 44;
      var volume = 1;
      for (var i = 0; i < lng; i++) {
        view.setInt16(index, interleaved[i] * (0x7fff * volume), true);
        index += 2;
      }

      if (cb) {
        return cb({
          buffer: buffer,
          view: view,
        });
      }

      postMessage(
        {
          buffer: buffer,
          view: view,
        },
        null
      );
    }

    var webWorker: any = this.processInWebWorker(mergeAudioBuffers);

    webWorker.onmessage = function (event) {
      callback(event.data.buffer, event.data.view);

      // release memory
      URL.revokeObjectURL(webWorker.workerURL);
    };

    webWorker.postMessage(config);
  }

  processInWebWorker(_function) {
    var workerURL = URL.createObjectURL(
      new Blob(
        [
          _function.toString(),
          ';this.onmessage =  function (e) {' + _function.name + '(e.data);}',
        ],
        {
          type: 'application/javascript',
        }
      )
    );

    var worker: any = new Worker(workerURL);
    worker.workerURL = workerURL;
    return worker;
  }

  checkWebRTC() {
    let audioCtx = window.AudioContext || window['webkitAudioContext'];
    if (
      audioCtx &&
      ((navigator.mediaDevices && navigator.mediaDevices.getUserMedia) ||
        navigator['getUserMedia']) &&
      Worker
    ) {
      return true;
    }
    console.error(
      'Need to provide a cloud-speech-api-key for support on this browser.'
    );
    return false;
  }

  getBuffers(event) {
    var buffers = [];
    for (var ch = 0; ch < 2; ++ch) {
      buffers[ch] = event.inputBuffer.getChannelData(ch);
    }
    return buffers;
  }

  silenceRemovalAlgorithm(channelData: any) {
    //split this into seperate chunks of a certain amount of samples
    const step = 80;
    const threshold = 0.4;
    const output: any = [];
    let _silenceCounter = 0;
    //now chunk channelData into
    chunk(channelData, step).map((frame: any) => {
      //square every value in the frame
      const squaredFrame = frame.map((x: number) => x ** 2);
      const _min: number = min(squaredFrame) || 0;
      const _max: number = max(squaredFrame) || 0;
      const _ptp = _max - _min;
      const _avg = mean(squaredFrame);
      let thd = (_min + _ptp) * threshold;
      // Tinh added this
      thd = thd < threshold ? threshold : thd;
      if (_avg <= thd) {
        _silenceCounter++;
      } else {
        _silenceCounter = 0;
      }
      //if there are 20 or more consecutive 'silent' frames then ignore these frames, do not return
      if (_silenceCounter >= 20) {
        //dont append to the output
      } else {
        //append to the output
        output.push([...frame]);
      }
    });
    console.log('TCL: result -> result', flattenDeep(output).length);
    return flattenDeep(output);
  }

  //**dataURL to blob**
  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  //**blob to dataURL**
  blobToDataURL(blob, callback) {
    let reader = new FileReader();
    reader.onloadend = () => {
      let dataUrl: any = reader.result;
      let base64 = dataUrl.split(',')[1];
      callback(dataUrl);
    };
    reader.onerror = (err) => {
      console.error('Error in reading blob', err);
    };
    reader.readAsDataURL(blob);
  }

  blobToBase64(blob, callback) {
    let reader = new FileReader();
    reader.onloadend = () => {
      let dataUrl: any = reader.result;
      let base64 = dataUrl.split(',')[1];
      callback(base64);
    };
    reader.onerror = (err) => {
      console.error('Error in reading blob', err);
    };
    reader.readAsDataURL(blob);
  }
}

export interface IWindow extends Window {
  webkitSpeechRecognition: any;
}
