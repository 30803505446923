<div class="page-wrapper">
  <app-header></app-header>
  <div class="auth-bg">
    <div class="authentication-box">
      <div class="text-center"></div>
      <div class="card mt-4">
        <div class="card-body">
          <ng-container>
            <div class="text-center">
              <h4>{{ "Enter your new password" | translate }}</h4>
            </div>
            <form class="theme-form" [formGroup]="changePasswordForm">
              <div class="form-group">
                <label class="col-form-label">
                  {{ "New Password" | translate }}
                </label>
                <input
                  class="form-control"
                  type="password"
                  formControlName="password"
                  required=""
                />
                <div
                  *ngIf="
                    changePasswordForm.controls.password.touched &&
                    changePasswordForm.controls.password.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  {{ "Password is required" | translate }}
                </div>
              </div>
              <div class="form-row">
                <div class="col-sm-4">
                  <button
                    class="btn btn-primary btn-block btn-lg"
                    [class.loader--text]="authService.showLoader"
                    [disabled]="
                      !changePasswordForm.valid || authService.showLoader
                    "
                    (click)="changePassword()"
                    type="submit"
                  >
                    <span>{{
                      authService.showLoader ? "" : ("Submit" | translate)
                    }}</span>
                  </button>
                </div>
                <div class="col-sm-8">
                  <div class="text-left mt-2 m-l-10">
                    Maybe?
                    <a
                      class="btn-link text-capitalize"
                      [routerLink]="['/login']"
                      >{{ "Login" | translate }}</a
                    >
                    or
                    <a
                      class="btn-link text-capitalize"
                      [routerLink]="['/register']"
                      >{{ "Register" | translate }}</a
                    >
                  </div>
                </div>
              </div>
              <div class="form-group form-row mt-3 mb-0"></div>
            </form>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="footer">
  <app-footer></app-footer>
</footer>
