<!-- Container-fluid starts-->
<div
  class="container-fluid product-wrapper"
  *ngIf="filteredItems && categories"
  style="position: relative; top: 30px"
>
  <div class="product-grid">
    <div class="card">
      <div class="card-body d-flex justify-content-between">
        <div>
          <h3 class="text-primary">
            <mark>{{ authService.selectedSchool.schoolName }}</mark>
            <button
              class="btn btn-link font-weight-bold text-uppercase"
              type="button"
              (click)="clone()"
              [class.d-none]="!authService.canCloneCurrentSchool()"
            >
              {{ "Clone" | translate }}
            </button>
          </h3>
        </div>
        <div class="text-right">
          <button
            class="btn btn-square btn-warning mr-2 m-b-15"
            type="button"
            placement="top"
            [class.opacity-40]="authService.isNotTeacher()"
            (click)="authService.goToTeacherRoute(['/library/audio-question'])"
          >
            {{ "Questions" | translate }}
          </button>
          <button
            class="btn btn-square btn-danger mr-2 m-b-15"
            [class.opacity-40]="authService.isNotTeacher()"
            type="button"
            placement="top"
            (click)="goToSearch()"
          >
            {{ "Create Lesson" | translate }}
          </button>
          <button
            class="btn btn-square btn-secondary mr-2 m-b-15"
            [class.opacity-40]="authService.isNotTeacher()"
            type="button"
            placement="top"
            (click)="authService.goToTeacherRoute(['/library/categories'])"
          >
            {{ "Categories" | translate }}
          </button>
          <button
            class="btn btn-square btn-info mr-2 m-b-15"
            [class.opacity-40]="authService.isNotTeacher()"
            type="button"
            placement="top"
            (click)="authService.goToTeacherRoute(['/library/students'])"
          >
            {{ "Students" | translate }}
          </button>
          <button
            class="btn btn-square btn-secondary mr-2 m-b-15 d-none"
            type="button"
            placement="top"
            (click)="openLg(content)"
          >
            {{ "Import" | translate }}
          </button>
          <button
            class="btn btn-square btn-success mr-2 m-b-15 hid d-none"
            type="button"
            placement="top"
            (click)="downloadJson()"
          >
            {{ "Export" | translate }}
          </button>
          <ng-template #content let-modal>
            <div class="modal-header">
              <h4 class="modal-title">{{ "Import" | translate }}</h4>
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="min-height: 500px">
              <form class="form theme-form">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">{{
                          "Upload File" | translate
                        }}</label>
                        <div class="col-sm-9">
                          <input
                            type="file"
                            class="form-control"
                            (change)="importFileChanged($event)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="form-group row mb-0">
                        <label class="col-sm-3 col-form-label">
                          {{ "Or paste it here" | translate }}</label
                        >
                        <div class="col-sm-9">
                          <textarea
                            class="form-control"
                            rows="15"
                            #importBox
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-primary ml-2"
                (click)="importJson(importBox.value)"
              >
                {{ "Import" | translate }}
              </button>
              <button
                type="button"
                class="btn btn-light"
                (click)="modal.close('Close click')"
              >
                {{ "Close" | translate }}
              </button>
            </div>
          </ng-template>
          <!-- <ng-template #youtubePlayer let-ytModal>
                    <div class="modal-body" id="modalBody">
                        <div class="mediaWrapper" id="mediaWrapper" style="text-align: center;">
                            <youtube-player [videoId]="clickedVideoId" suggestedQuality="highres"
                                [playerVars]="{autoplay: 1, loop: 1}" (ready)="playerReady($event)"></youtube-player>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="row">
                            <div class="col-12">
                                <button type="button" class="btn btn-light"
                                    (click)="ytModal.close('Close click')">Close</button>
                            </div>
                        </div>
                    </div>
                </ng-template> -->

          <ng-template #youtubePlayer let-ytModal>
            <div class="modal-body" id="modalBody">
              <app-preview-video
                [input]="previewVideo"
                (selectedChange)="closePreviewVideo()"
                [readonly]="true"
              >
              </app-preview-video>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div
          class="faq-form float-left m-r-10 m-b-10 search-form"
          style="min-width: 260px; max-width: 400px"
        >
          <div class="form-group mb-0">
            <input
              class="form-control"
              type="text"
              [(ngModel)]="stateCache.search"
              name="textSearch"
              placeholder="{{ 'Lesson name' | translate }}"
              (keyup)="handleSearch()"
              maxlength="200"
            />
          </div>
          <!-- <app-feather-icons class="search-icon" [icon]="'search'"></app-feather-icons> -->
        </div>
        <div class="btn-group m-b-15 float-left m-r-10" role="group">
          <ng-select
            (change)="updateSelectedCategory($event)"
            [(ngModel)]="stateCache.category"
            [placeholder]="'Category name' | translate"
            [multiple]="true"
            [virtualScroll]="true"
            [items]="categories"
            bindLabel="name"
            bindValue="cid"
          >
          </ng-select>
        </div>
        <div class="btn-group m-b-15 float-left m-r-10" role="group">
          <ng-select
            (change)="updateSelectedAudioQuestion($event)"
            [(ngModel)]="stateCache.audio_question"
            [placeholder]="'Question name' | translate"
            [multiple]="true"
            [virtualScroll]="true"
            [items]="questionnerQuestions"
            bindLabel="name"
            bindValue="id"
          >
          </ng-select>
        </div>
        <div class="btn-group m-b-15 float-left m-r-10" role="group">
          <select
            class="form-control"
            [(ngModel)]="stateCache.lesson_age_group"
            (change)="updateSelectedAgeGroup($event.target['value'])"
          >
            <option value="0">{{ "All age groups" | translate }}</option>
            <option *ngFor="let ageGroup of lessonAgeGroups" [value]="ageGroup">
              {{ ageGroup }}
            </option>
          </select>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th style="min-width: 100px">
                {{ "Lesson Name" | translate }}
              </th>
              <th *ngIf="navService.screenWidth > 450">
                {{ "Category" | translate }}
              </th>
              <!-- <th *ngIf="navService.screenWidth > 1000">
                                {{'Selection Type'|translate}}
                            </th> -->
              <th>
                {{ "Age group" | translate }}
              </th>
              <th *ngIf="navService.screenWidth > 1000">
                {{ "Video list" | translate }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngFor="let item of getPaginationItems()">
            <tr>
              <td>
                <h6>{{ item.name }}</h6>
              </td>
              <td *ngIf="navService.screenWidth > 450">
                <h6>{{ getCategoryLabel(item.cid) }}</h6>
              </td>
              <!-- <td *ngIf="navService.screenWidth > 1000">
                                <h6>{{authService.getDisplayTypeLabels(item.display)}}</h6>
                            </td> -->
              <td>
                <h6>{{ item.ageGroup }}</h6>
              </td>
              <td *ngIf="navService.screenWidth > 1000">
                <ng-container *ngFor="let youtubeVideo of item.ylist">
                  <img
                    class="{{
                      navService.screenWidth > 450 ? 'img-100' : 'img-60'
                    }} m-r-5 m-b-5"
                    src="{{
                      youtubeVideo.customThumbnailUrl
                        ? youtubeVideo.customThumbnailUrl
                        : 'https://img.youtube.com/vi/' +
                          authService.getOriginalYID(youtubeVideo.yid) +
                          '/mqdefault.jpg'
                    }}"
                    alt="#"
                    (click)="openVideo(youtubeVideo, youtubePlayer)"
                    [style.opacity]="
                      youtubeVideo.customThumbnailUrl &&
                      youtubeVideo.customThumbnailUrl.includes('data:image')
                        ? 0.2
                        : 1
                    "
                    [style.border]="
                      youtubeVideo.customThumbnailUrl &&
                      youtubeVideo.customThumbnailUrl.includes('speaknplay')
                        ? 'solid 3px #000'
                        : ''
                    "
                  />
                </ng-container>
              </td>
              <td class="text-center" style="min-width: inherit !important">
                <div
                  class="btn-group float-right"
                  role="group"
                  aria-label="Button group with nested dropdown"
                >
                  <button
                    class="btn btn-primary"
                    [class.opacity-40]="authService.isNotTeacher()"
                    (click)="
                      authService.goToTeacherRoute([
                        '/library/edit-category',
                        item.vid
                      ])
                    "
                  >
                    {{ "Edit" | translate }}
                  </button>
                  <button
                    class="btn btn-secondary"
                    [class.opacity-40]="authService.isNotTeacher()"
                    (click)="copyLesson(item, copyModal)"
                  >
                    {{ "Copy" | translate }}
                  </button>
                  <button
                    class="btn btn-danger"
                    [class.opacity-40]="authService.isNotTeacher()"
                    (click)="withConfirmation(item)"
                  >
                    {{ "Delete" | translate }}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <div class="total-records float-left m-r-10">
            <p>
              {{ "Total records" | translate }}: {{ filteredItems?.length }}
            </p>
          </div>
          <ngb-pagination
            [collectionSize]="filteredItems.length"
            [(page)]="stateCache.page"
            [maxSize]="5"
            [rotate]="true"
            [boundaryLinks]="true"
            [pageSize]="pageSize"
            *ngIf="filteredItems.length > pageSize"
            (pageChange)="updateMediaState('page', $event)"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12">
    <div *ngIf="!filteredItems?.length">
      <div class="search-not-found text-center">
        <div>
          <img
            src="assets/images/search-not-found.png"
            alt=""
            class="second-search"
          />
          <p>Sorry, We didn't find any results matching this search</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
<ng-template #copyModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Copy</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-lesson
      (eventChange)="copyEventChange()"
      [editId]="copiedLesson.vid"
      [isCopy]="true"
    >
    </app-lesson>
    <!-- <textarea class="form-control" rows="10" [value]="copiedValue" (click)="copyToClipboard($event)"></textarea> -->
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-light"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
  </div>
</ng-template>
