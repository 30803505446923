import { NavService } from 'src/app/services/nav.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
// import 'firebase/auth';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/firebase/auth.service';
import {
  menu_words,
  QueueType,
  School,
  UserSetting,
} from 'src/app/services/constant.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LocalIndexedDBStorageService } from 'src/app/services/local-indexeddb-storage.service';
import { R2Service } from 'src/app/services/r2.service';
import { KvService } from 'src/app/services/kv.service';
declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
})
export class SettingComponent implements OnInit {
  public settingForm: FormGroup;
  public setting: UserSetting;
  public expiryDate: string;
  public currentUnixtime: any;
  public version: string;
  public defaultSystemRecognizedWords;
  public currentSystemRecognizedKey;
  public currentSystemRecognizedWord;
  public englishSystemRecognizedWords;
  public selectedSchool: School;
  public selectedSchoolId: string;
  public schools: any[] = [];

  items: Array<{ id: string; name: string }> = [];
  selectedItem: any;

  @Output() selectedChange = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    public authService: AuthService,
    public navService: NavService,
    public translate: TranslateService,
    public r2Service: R2Service,
    public kvService: KvService
  ) {
    this.setting = this.authService.userData.setting;
    this.defaultSystemRecognizedWords =
      this.setting?.defaultSystemRecognizedWords || {};
    this.englishSystemRecognizedWords = [].concat(...menu_words['en']);
    if (
      !this.defaultSystemRecognizedWords.hasOwnProperty(
        this.authService.currentLanguageCode
      )
    ) {
      this.defaultSystemRecognizedWords[this.authService.currentLanguageCode] =
        this.authService.getSystemWordsKeysValues();
    }
    this.version = environment.version;
    this.settingForm = this.fb.group({
      youtubeApiKey: [this.setting.youtubeApiKey],
      youtubePlaylist: [this.setting.youtubePlaylist],
      disableFullscreen: [this.setting.disableFullscreen],
      recordingVolume: [
        this.setting.recordingVolume ? this.setting.recordingVolume : 10,
      ],
      defaultSystemRecognizedWords: [this.defaultSystemRecognizedWords],
    });
  }

  emailToDisplayName(email: string): string {
    email = email.toLowerCase();
    let [username, domain] = email.split('@');
    username = username.replace(/\./g, ' ');
    username = username.toUpperCase();
    return username;
  }

  async ngOnInit(): Promise<void> {
    this.expiryDate = moment
      .unix(this.authService.userData.expiredDate)
      .format('YYYY/MM/DD HH:mm:ss');
    this.currentUnixtime = moment().format('X');
    this.selectedSchoolId = this.authService.schoolData.uid;
    this.selectedSchool = this.authService.selectedSchool;
    this.items = this.englishSystemRecognizedWords.map((key) => ({
      id: `${key}|${
        this.defaultSystemRecognizedWords[this.authService.currentLanguageCode][
          key
        ]
      }`,
      name: this.defaultSystemRecognizedWords[
        this.authService.currentLanguageCode
      ][key],
    }));
    this.schools = this.authService.userData.schools.map((school) => ({
      ...school,
      schoolName: this.emailToDisplayName(school.schoolName),
    }));
  }

  selectedSystemWordOptionChange(event) {
    let value = event.id.split('|');
    this.currentSystemRecognizedWord = value[1];
    this.currentSystemRecognizedKey = value[0];
  }

  changeSystemWord(value: string) {
    this.defaultSystemRecognizedWords[this.authService.currentLanguageCode][
      this.currentSystemRecognizedKey
    ] = value;
  }

  updateSetting() {
    console.log(this.settingForm.value);
    this.authService.userData.setting = this.settingForm.value as UserSetting;
    this.authService.PushUserDataChanges().then(() => {
      this.authService.updateStorage();
      this.toastr.success('Updated');
      this.selectedChange.emit(null);
      location.href = location.href;
    });
  }

  logOut() {
    this.selectedChange.emit('logout');
    this.authService.SignOut();
  }

  resetPassword() {
    this.selectedChange.emit('logout');
    this.authService.SignOut(false, true);
  }

  deleteConfirmation() {
    // Swal.fire({
    //   title: this.translate.instant('Are you sure?'),
    //   text: "You won't be able to revert this!",
    //   type: 'warning',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Yes, delete my account and data'
    // }).then((result) => {
    //   if (result.value) {
    //     this.authService.deleteUser();
    //   }
    // })

    // last login store as: userData['lastLogin'] = new Date().toISOString();
    // check if last login is within

    Swal.fire({
      title: this.translate.instant('Are you sure?'),
      text: this.translate.instant(
        "You won't be able to revert this. If you really want to delete your account, type `delete me` and click `delete account`"
      ),
      icon: 'warning',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        onkeydown:
          'if(event.keyCode == 13) {event.preventDefault(); event.stopPropagation();return false;}',
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === 'delete me') {
            resolve(null);
          } else {
            resolve(this.translate.instant('You need to type `delete me`'));
          }
        });
      },
      showCancelButton: true,
      confirmButtonText: this.translate.instant('Delete account'),
      confirmButtonColor: '#d33',
      showLoaderOnConfirm: true,
      allowEnterKey: false,
      customClass: {
        input: 'swal-input-custom',
      },
      // cancelButtonColor: '#3085d6',
      preConfirm: async (data) => {
        Swal.showLoading();
        // todo: 'delete user';
        if (data == 'delete me') {
          // if (this.authService.userData.type === 'school') {
          //   // delete user from school
          //   let students = await this.authService.getStudentsFromSchool(
          //     this.authService.userData.uid
          //   );
          //   for (let student of students) {
          //     const studentId = btoa(student.emailAddress);
          //     let schools = await this.authService.getSchoolsFromStudent(
          //       studentId
          //     );
          //     schools = schools.filter((item) => {
          //       return item.schoolId != this.authService.userData.uid;
          //     });
          //     const path = 'lessons/' + studentId + '/schools.json';
          //     await this.r2Service.uploadFile(
          //       this.authService.compressUserData(schools),
          //       path,
          //       1
          //     );
          //   }
          // }
          // if (this.authService.userData.type === 'student') {
          //   let schools = await this.authService.getSchoolsFromStudent(
          //     this.authService.userData.uid
          //   );
          //   for (let school of schools) {
          //     let students = await this.authService.getStudentsFromSchool(
          //       school.schoolId
          //     );
          //     students = students.filter((item) => {
          //       return item.emailAddress != this.authService.userData.email;
          //     });
          //     const path = 'lessons/' + school.schoolId + '/students.json';
          //     await this.r2Service.uploadFile(
          //       this.authService.compressUserData(students),
          //       path,
          //       1
          //     );
          //   }
          // }
          await this.kvService.delete(
            this.authService.userData.uid,
            'users',
            'delete_user'
          );
          Swal.close();
          this.authService.toster.success(
            this.translate.instant(
              'Your account and data have been deleted. Please note we do not have your back up data, if you need an account you will need to register a new one.'
            )
          );
          this.logOut();
          // let firstCB = async () => {
          //   await currentUser
          //     .delete()
          //     .then(() => {
          //       this.logOut();
          //       Swal.close();
          //       this.authService.toster.success(
          //         this.translate.instant(
          //           'Your account and data have been submitted to be deleted, it would takes up to a day to complete. Please note we do not have your back up data, if you need an account you will need to register a new one.'
          //         )
          //       );
          //     })
          //     .catch((error) => {
          //       Swal.close();
          //       if (error.hasOwnProperty('message')) {
          //         this.authService.toster.error(error.message);
          //       } else {
          //         this.authService.toster.error(JSON.stringify(error));
          //       }
          //     });
          // };
          // return this.authService.createQueueJob(
          //   QueueType.DeleteUser,
          //   {},
          //   firstCB
          // );
        } else {
          return data;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      console.log(result);
    });
  }

  async changeSchool(school: School) {
    if (school && school.schoolId) {
      await this.authService.setSchoolData(school.schoolId);
      await this.authService.setCurrentLearningLanguage();
    }
  }
}
