import { Subscription } from 'rxjs/internal/Subscription';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  Injectable,
  Input,
  ChangeDetectorRef,
  OnChanges,
  SimpleChange,
} from '@angular/core';
import 'rxjs/add/operator/map';
import { AuthService } from '../../services/firebase/auth.service';
import {
  NgbModal,
  NgbModalRef,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { truncate, rest, cloneDeep, isNumber } from 'lodash-es';
import * as moment from 'moment';
import { NumberSuffixPipe } from '../../pipe/number-suffix.pipe';
import { HttpClient } from '@angular/common/http';
import {
  YoutubeSearchResult,
  Lesson,
  InputPreviewVideo,
  Video,
} from 'src/app/services/constant.service';
import { YoutubeService } from 'src/app/services/youtube.service';
// import * as firebase from 'firebase/app';
// import 'firebase/storage';
// import 'firebase/firestore'
import { FormControl } from '@angular/forms';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/observable/fromEvent';
import { TranslateService } from '@ngx-translate/core';
import { LocalIndexedDBStorageService } from 'src/app/services/local-indexeddb-storage.service';
import { environment } from 'src/environments/environment';
declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-create-lesson-from-youtube-video',
  templateUrl: './create-lesson-from-youtube-video.component.html',
  styleUrls: ['./create-lesson-from-youtube-video.component.scss'],
})
export class CreateLessonFromYoutubeVideoComponent implements OnInit {
  @Output() selectedChange = new EventEmitter();
  @Output() previewChange = new EventEmitter();
  public youtubeSearchResults: YoutubeSearchResult[] = [];
  @Input() newOnPopup: boolean = true;
  @Input() ylist: Video[] = [];
  public selected: YoutubeSearchResult;
  public modal: NgbModalRef;
  public addModal: NgbModalRef;
  public player: YT.Player;
  public itemsInSelectedLang: Lesson[];
  searching = false;
  searchFailed = false;
  private maxSearchStateHistoryItems = 20;
  public publishedAfter: string = '';
  public publishedBefore: string = '';

  public type: string = '';
  public videoType: string = '';
  public videoDuration: string = '';
  public order: string = '';
  public query: string = '';
  public suggestQuery: string = '';

  public publishedDate: string = '';
  public videoChannelType: string = '';
  public caption: boolean = false;
  @Input() showSearch = true;
  public currentChannelId: string;
  public clickedChannels: { id: string; title: string }[] = [];
  public currentRelatedVideo: { id: string; title: string };
  public selectedHistoryIndex: number = -1;

  public selectBoxIndex = 1;
  public searchLang = null;
  public clickTo = 'play';
  private suggestionVideoUrl = '';

  public searchedResultType: 'List' | 'YoutubeId' = 'List';
  public suggestionVideos: YoutubeSearchResult[] = [];
  public suggestionChannelVideos: YoutubeSearchResult[] = [];
  public foundVideoByIds: YoutubeSearchResult[] = [];
  public foundSuggestionVideoByIds: YoutubeSearchResult[] = [];
  public tabName: string = 'suggestion';
  public suggestQueryControl = new FormControl();
  formCtrlSub: Subscription;

  public searchStateList: YoutubeSearchState[] = [];

  public currentSearchTabInfo: YoutubeSearchState = {
    query: '',
    videoId: '',
    channelId: '',
    playlistId: '',
    channelOrPlaylistTitle: '',
    publishedAfter: '',
    publishedBefore: '',
    publishedDate: '',
    type: '',
    videoType: '',
    videoChannelType: '',
    videoDuration: '',
    order: '',
    nextPageToken: '',
    searchCount: 0,
    stateDate: null,
    results: [],
    stateLabel: '',
  };

  public usedVideo: YoutubeSearchResult;
  public useItActionType: 'add' | 'edit' = null;
  public useItEditId: number;
  public hasYoutubeAPIKey = false;

  public suggestedChannels: { id: string; name: string }[] = [];
  public suggestedPage = 1;
  public flexSearchIndex: any;
  public currrentSelectedSuggestedChannel = 'all';
  public isLoadingData = true;

  constructor(
    public authService: AuthService,
    private el: ElementRef,
    private modalService: NgbModal,
    private toastService: ToastrService,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    public youtubeService: YoutubeService,
    public translate: TranslateService
  ) {}

  parseFoundVideoById(videoDetails) {
    const numberSuffix = new NumberSuffixPipe();
    let foundVideoById = new YoutubeSearchResult({});
    foundVideoById.duration = this.formatDuration(
      videoDetails.contentDetails.duration
    );
    foundVideoById.viewCount = numberSuffix.transform(
      videoDetails.statistics.viewCount
    );
    foundVideoById.viewCountUnformatted = videoDetails.statistics.viewCount;
    foundVideoById.type = 'video';
    foundVideoById.thumbnailUrl =
      typeof videoDetails.snippet.thumbnails !== 'undefined' &&
      typeof videoDetails.snippet.thumbnails.high !== 'undefined'
        ? videoDetails.snippet.thumbnails.high.url
        : '';
    foundVideoById.title = videoDetails.snippet.title;
    foundVideoById.publishedAt = videoDetails.snippet.publishedAt;
    foundVideoById.channelTitle = videoDetails.snippet.channelTitle;
    foundVideoById.desc = truncate(videoDetails.snippet.description, {
      length: 60,
    });
    foundVideoById.channelId = videoDetails.snippet.channelId
      ? videoDetails.snippet.channelId
      : null;
    return foundVideoById;
  }

  async ngOnInit(): Promise<void> {
    if (this.authService.teacherOnlyAlert()) {
      return;
    }
    let processSuggestionVideos = (data) => {
      this.isLoadingData = false;
      this.suggestionVideos = data as YoutubeSearchResult[];
      let count = 1;
      for (let item of this.suggestionVideos) {
        item.indexId = count;
        let exist = this.suggestedChannels.find((suggestedChannel) => {
          return suggestedChannel.id == item.channelId;
        });
        if (!exist) {
          this.suggestedChannels.push({
            id: item.channelId,
            name: item.channelTitle,
          });
        }
        count++;
      }
      this.suggestionChannelVideos = this.suggestionVideos;
      this.foundSuggestionVideoByIds = this.suggestionVideos.slice(0, 20);
      this.doIndexSearch();
    };
    if (
      this.authService.userData.uid !==
      this.authService.defaultPremiumSchool.schoolId
    ) {
      this.suggestionVideoUrl =
        environment.r2BaseURL +
        '/' +
        this.authService.defaultPremiumSchool.schoolId +
        '_suggestion_videos.json';
      this.http
        .get(this.suggestionVideoUrl)
        .subscribe(async (premiumData: YoutubeSearchResult[]) => {
          if (this.authService.userData.setting.youtubePlaylist) {
            // extract playlistId from url this.authService.userData.setting.youtubePlaylist
            const myPlaylistId =
              this.authService.userData.setting.youtubePlaylist.split(
                'list='
              )[1];
            let data = await this.youtubeService.getPlaylistVideos(
              myPlaylistId
            );
            let mergedData = premiumData.concat(data);
            processSuggestionVideos(mergedData);
          } else {
            processSuggestionVideos(premiumData);
          }
        });
    } else {
      let data = await this.youtubeService.getPlaylistVideos(
        'PLTxGZcMi2_K1Rxy9C12dFwr9QigjatH4y'
      );
      processSuggestionVideos(data);
    }
    // let cacheSuggestionVideos = await YoutubeService.getWithExpiry(
    //   'suggestion_videos'
    // );
    // if (cacheSuggestionVideos) {
    //   cacheSuggestionVideos = JSON.parse(cacheSuggestionVideos);
    //   processSuggestionVideos(cacheSuggestionVideos);
    // } else {

    //   // let storageRef = firebase.storage().ref();
    //   // storageRef
    //   //   .child('suggestion_channels/suggestion_videos.json')
    //   //   .getDownloadURL()
    //   //   .then((url) => {
    //   //     this.suggestionVideoUrl = url;
    //   //     this.http.get(this.suggestionVideoUrl).subscribe((data) => {
    //   //       YoutubeService.setWithExpiry(
    //   //         'suggestion_videos',
    //   //         JSON.stringify(data),
    //   //         60 * 60 * 1000
    //   //       );
    //   //       processSuggestionVideos(data);
    //   //     });
    //   //     console.log(url);
    //   //   });
    // }

    this.formCtrlSub = this.suggestQueryControl.valueChanges
      .debounceTime(1000)
      .subscribe((newValue) => {
        newValue = newValue.trim();
        if (newValue == '') {
          this.selectSuggestedChannel(this.currrentSelectedSuggestedChannel);
        } else {
          let returnResult = [];
          let youtubeId = this.youtube_parser(newValue.trim());
          if (youtubeId.length > 0) {
            var img = new Image();
            img.src =
              'http://img.youtube.com/vi/' + youtubeId + '/mqdefault.jpg';
            img.onload = async () => {
              if (img['width'] === 320) {
                // this is youtube video id
                let foundVideoById = new YoutubeSearchResult({});
                foundVideoById.id = youtubeId;
                if (this.hasYoutubeAPIKey) {
                  (await this.youtubeService.getLessons([youtubeId])).subscribe(
                    (result) => {
                      if (
                        result.items[0].status.license != 'creativeCommon' &&
                        !result.items[0].snippet.channelTitle
                          .toLowerCase()
                          .includes('speaknplay')
                      ) {
                        Swal.fire({
                          type: 'error',
                          title: 'Oops...',
                          allowOutsideClick: false,
                          showCancelButton: true,
                          showConfirmButton: false,
                          text: this.translate.instant(
                            'This video is not under common license, please use another video'
                          ),
                        });
                      } else {
                        foundVideoById = this.parseFoundVideoById(
                          result.items[0]
                        );
                        foundVideoById.id = youtubeId;
                        returnResult.push(foundVideoById);
                        this.suggestionChannelVideos = returnResult;
                        this.foundSuggestionVideoByIds =
                          this.suggestionChannelVideos.slice(
                            0,
                            this.suggestedPage * 20
                          );
                      }
                    }
                  );
                }
                // this.searchedResultType = 'YoutubeId';
              } else {
                alert('Your youtube video id is not exist');
              }
            };
          } else {
            this.selectSuggestedChannel(
              this.currrentSelectedSuggestedChannel,
              false
            );
            let searchResults = this.flexSearchIndex.search(newValue, 20, {
              suggest: true,
            });
            for (let item of this.suggestionChannelVideos) {
              if (searchResults.indexOf(item.indexId) > -1) {
                returnResult.push(item);
              }
            }
            this.suggestionChannelVideos = returnResult;
            this.foundSuggestionVideoByIds = this.suggestionChannelVideos.slice(
              0,
              this.suggestedPage * 20
            );
          }
        }
      });
    const numberSuffix = new NumberSuffixPipe();
    console.log(this.selected);
    if (this.authService.userData.setting.youtubeApiKey) {
      this.hasYoutubeAPIKey = true;
    }
    // Check if edit form pass videos
    if (this.ylist.length > 0) {
      let inputYoutubeIdList = this.ylist.map((item) => {
        return item.yid;
      });
      if (this.hasYoutubeAPIKey) {
        (
          await this.youtubeService.getLessons(inputYoutubeIdList, true)
        ).subscribe((result) => {
          for (let videoDetails of result.items) {
            let foundVideoById = new YoutubeSearchResult({});
            console.log(videoDetails);
            foundVideoById.id = videoDetails.id;
            foundVideoById.duration = this.formatDuration(
              videoDetails.contentDetails.duration
            );
            foundVideoById.viewCount = numberSuffix.transform(
              videoDetails.statistics.viewCount
            );
            foundVideoById.viewCountUnformatted =
              videoDetails.statistics.viewCount;
            foundVideoById.type = 'video';
            foundVideoById.thumbnailUrl =
              typeof videoDetails.snippet.thumbnails !== 'undefined' &&
              typeof videoDetails.snippet.thumbnails.high !== 'undefined'
                ? videoDetails.snippet.thumbnails.high.url
                : '';
            foundVideoById.title = videoDetails.snippet.title;
            foundVideoById.publishedAt = videoDetails.snippet.publishedAt;
            foundVideoById.channelTitle = videoDetails.snippet.channelTitle;
            foundVideoById.desc = truncate(videoDetails.snippet.description, {
              length: videoDetails.snippet.channelTitle
                .toLowerCase()
                .includes('speaknplay')
                ? 500
                : 60,
            });
            foundVideoById.channelId = videoDetails.snippet.channelId
              ? videoDetails.snippet.channelId
              : null;
            this.foundVideoByIds.push(foundVideoById);
            this.searchedResultType = 'YoutubeId';
          }
        });
      } else {
        for (let yid of inputYoutubeIdList) {
          let foundVideoById = new YoutubeSearchResult({});
          foundVideoById.id = yid;
          this.foundVideoByIds.push(foundVideoById);
        }
      }
    }
    if (this.hasYoutubeAPIKey) {
      const cacheState = await LocalIndexedDBStorageService.getItem(
        'search_state_list'
      );
      if (cacheState) {
        this.searchStateList = JSON.parse(cacheState);
        this.selectedHistoryIndex = this.searchStateList.length - 1;
        this.currentSearchTabInfo = cloneDeep(
          this.searchStateList[this.selectedHistoryIndex]
        );

        if (this.searchStateList.length > this.maxSearchStateHistoryItems) {
          this.searchStateList = this.searchStateList.slice(
            this.maxSearchStateHistoryItems * -1
          );
        }
        this.updateDateFilter(this.currentSearchTabInfo.publishedDate);
        this.updateType(this.currentSearchTabInfo.videoChannelType);
        this.updateDuration(this.currentSearchTabInfo.videoDuration);
        this.updateOrder(this.currentSearchTabInfo.order);
        this.query = this.currentSearchTabInfo.query;
      }
      console.log(this.currentSearchTabInfo);
      console.log(this.type);
    }
    setTimeout(() => {
      Swal.close();
    });
  }

  doIndexSearch() {
    this.flexSearchIndex = new window['FlexSearch'].Index({
      tokenize: 'reverse',
      cache: true,
    });
    for (let item of this.suggestionChannelVideos) {
      this.flexSearchIndex.add(item.indexId, item.title);
    }
  }

  selectSuggestedChannel(channelId, slice = true) {
    this.suggestedPage = 1;
    this.currrentSelectedSuggestedChannel = channelId;
    if (channelId == 'all') {
      this.suggestionChannelVideos = this.suggestionVideos;
    } else {
      this.suggestionChannelVideos = this.suggestionVideos.filter(
        (suggestionVideo) => {
          return suggestionVideo.channelId == channelId;
        }
      );
    }
    if (slice) {
      this.foundSuggestionVideoByIds = this.suggestionChannelVideos.slice(
        0,
        20
      );
    }
  }

  timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  youtube_parser(url) {
    if (url.length == 11) {
      return url;
    }
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : '';
  }

  logOut() {
    this.selectedChange.emit('logout');
    this.authService.SignOut();
  }

  search(
    query: string = '',
    relatedToVideoId: string = '',
    channelId: string = '',
    playlistId: string = '',
    channelOrPlaylistTitle: string = '',
    publishedAfter: string = '',
    publishedBefore: string = '',
    type: string = '',
    videoType: string = '',
    videoDuration: string = '',
    order: string = '',
    nextPageToken: string = '',
    searchCount: number = 0,
    append: boolean = false
  ) {
    const authCallback = () => {
      if (
        query.trim() == '' &&
        !relatedToVideoId &&
        !channelId &&
        !playlistId
      ) {
        return;
      }
      let performSearchFunc = () => {
        if (!this.hasYoutubeAPIKey) {
          return;
        }
        this.searchedResultType = 'List';
        if (this.videoChannelType != 'channel') {
          channelId = channelId ? channelId : this.currentChannelId;
        }
        if (!nextPageToken && !searchCount) {
          try {
            document
              .getElementById('search-results')
              .scroll({ top: 0, behavior: 'auto' });
          } catch (e) {}
        }
        setTimeout(async () => {
          (
            await this.youtubeService.search(
              query,
              relatedToVideoId,
              channelId,
              playlistId,
              publishedAfter,
              publishedBefore,
              type,
              videoType,
              videoDuration,
              order,
              this.caption,
              nextPageToken,
              searchCount
            )
          ).subscribe(
            (results: any) => {
              console.log(results);
              let ytSearchResults = this.parseSearchItems(results);
              if (append) {
                this.currentSearchTabInfo.results =
                  this.currentSearchTabInfo.results.concat(ytSearchResults);
                this.currentSearchTabInfo.nextPageToken =
                  typeof results['nextPageToken'] !== 'undefined'
                    ? results['nextPageToken']
                    : '';
              } else {
                this.searchStateList.push({
                  query: query,
                  videoId: relatedToVideoId,
                  channelId: channelId,
                  playlistId: playlistId,
                  channelOrPlaylistTitle: channelOrPlaylistTitle,
                  publishedAfter: this.publishedAfter,
                  publishedBefore: this.publishedBefore,
                  publishedDate: this.publishedDate,
                  type: this.type,
                  videoType: this.videoType,
                  videoChannelType: this.videoChannelType,
                  videoDuration: this.videoDuration,
                  order: this.order,
                  nextPageToken: results['nextPageToken'],
                  searchCount: 1,
                  stateDate: moment().unix(),
                  results: ytSearchResults,
                  stateLabel: '',
                });
                this.currentSearchTabInfo = cloneDeep(
                  this.searchStateList[this.searchStateList.length - 1]
                );
              }
              this.getCurrentTabItemsDetails(ytSearchResults);
              setTimeout(() => {
                this.selectBoxIndex = 1;
                this.animateSelectedLesson();
              }, 1000);
            },
            (err: any) => {
              try {
                console.log(err);
                const error_message = err.error.error.message;
                let footer_html = '';
                if (error_message.includes('API key not valid')) {
                  footer_html = `${this.translate.instant(
                    'Follow'
                  )}<a href="https://developers.google.com/youtube/v3/getting-started" target="_blank" style="margin:0 5px">${this.translate.instant(
                    'this link'
                  )}</a>
                      ${this.translate.instant(
                        'to get a free new key and place it under setting box'
                      )}}`;
                }
                if (error_message.includes('exceeded')) {
                  footer_html = `${this.translate.instant(
                    'You have exceeded 10,000 quotas per day, please come back tomorrow, to calculate quota usage please follow this'
                  )} <a href="https://developers.google.com/youtube/v3/determine_quota_cost" target="_blank" style="margin:0 5px">${this.translate.instant(
                    'link'
                  )}</a>`;
                }
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  allowOutsideClick: false,
                  showCancelButton: true,
                  showConfirmButton: false,
                  text: error_message,
                  footer: footer_html,
                });
              } catch (e) {}
            },
            () => {
              // on completion
              console.log('completion youtube search');
            }
          );
        });
      };

      let youtubeId = this.youtubeService.youtube_parser(query.trim());
      if (!this.hasYoutubeAPIKey && youtubeId.length !== 11) {
        alert(this.translate.instant('Your youtube video id is not valid'));
        return;
      }
      if (youtubeId.length > 0) {
        var img = new Image();
        img.src = 'http://img.youtube.com/vi/' + youtubeId + '/mqdefault.jpg';
        img.onload = async () => {
          if (img['width'] === 320) {
            // this is youtube video id
            let foundVideoById = new YoutubeSearchResult({});
            foundVideoById.id = youtubeId;
            if (this.hasYoutubeAPIKey) {
              (await this.youtubeService.getLessons([youtubeId])).subscribe(
                (result) => {
                  console.log(result);
                  //common license check
                  if (
                    result.items[0].status.license != 'creativeCommon' &&
                    !result.items[0].snippet.channelTitle
                      .toLowerCase()
                      .includes('speaknplay')
                  ) {
                    Swal.fire({
                      type: 'error',
                      title: 'Oops...',
                      allowOutsideClick: false,
                      showCancelButton: true,
                      showConfirmButton: false,
                      text: this.translate.instant(
                        'This video is not under common license, please use another video'
                      ),
                    });
                  } else {
                    foundVideoById = this.parseFoundVideoById(result.items[0]);
                    foundVideoById.id = youtubeId;
                    this.foundVideoByIds.push(foundVideoById);
                    this.searchedResultType = 'YoutubeId';
                  }
                }
              );
            } else {
              this.foundVideoByIds.push(foundVideoById);
              this.searchedResultType = 'YoutubeId';
            }
          } else {
            if (this.hasYoutubeAPIKey) {
              performSearchFunc();
            } else {
              alert(
                this.translate.instant('Your youtube video id is not exist')
              );
            }
          }
        };
      } else {
        performSearchFunc();
      }
    };
    authCallback();
  }

  async getCurrentTabItemsDetails(searchResults: any) {
    const numberSuffix = new NumberSuffixPipe();
    if (
      !this.currentSearchTabInfo.type ||
      this.currentSearchTabInfo.type == 'video'
    ) {
      let ids = this.currentSearchTabInfo.results
        .filter((item) => {
          return !item.duration;
        })
        .map((item) => {
          return item.id;
        });
      if (searchResults) {
        ids = searchResults
          .filter((item) => {
            return !item.duration;
          })
          .map((item) => {
            return item.id;
          });
      }
      (await this.youtubeService.getLessons(ids)).subscribe((result) => {
        this.currentSearchTabInfo.results =
          this.currentSearchTabInfo.results.map((item) => {
            for (let videoDetails of result.items) {
              if (item.id == videoDetails.id) {
                item.duration = this.formatDuration(
                  videoDetails.contentDetails.duration
                );
                item.favoriteCount = numberSuffix.transform(
                  videoDetails.statistics.favoriteCount
                );
                item.likeCount = numberSuffix.transform(
                  videoDetails.statistics.likeCount
                );
                item.dislikeCount = numberSuffix.transform(
                  videoDetails.statistics.dislikeCount
                );
                item.commentCount = numberSuffix.transform(
                  videoDetails.statistics.commentCount
                );
                item.viewCount = numberSuffix.transform(
                  videoDetails.statistics.viewCount
                );
                item.viewCountUnformatted = videoDetails.statistics.viewCount;
                break;
              }
            }
            return item;
          });
        console.log(this.currentSearchTabInfo);
        this.updateStorageFromCurrentSearch();
      });
    } else if (
      !this.currentSearchTabInfo.type ||
      this.currentSearchTabInfo.type == 'channel'
    ) {
      let ids = this.currentSearchTabInfo.results.map((item) => {
        return item.channelId;
      });
      (await this.youtubeService.getYoutubeChannels(ids)).subscribe(
        (result) => {
          console.log(result);
          this.currentSearchTabInfo.results =
            this.currentSearchTabInfo.results.map((item) => {
              for (let channelDetails of result.items) {
                if (item.channelId == channelDetails.id) {
                  item.viewCount = numberSuffix.transform(
                    channelDetails.statistics.viewCount
                  );
                  item.viewCountUnformatted =
                    channelDetails.statistics.viewCount;
                  item.videoCount = numberSuffix.transform(
                    channelDetails.statistics.videoCount
                  );
                  item.subscriberCount = numberSuffix.transform(
                    channelDetails.statistics.subscriberCount
                  );
                  break;
                }
              }
              return item;
            });
          console.log(this.currentSearchTabInfo.results);
          this.updateStorageFromCurrentSearch();
        }
      );
    } else if (
      !this.currentSearchTabInfo.type ||
      this.currentSearchTabInfo.type == 'playlist'
    ) {
      let ids = this.currentSearchTabInfo.results.map((item) => {
        return item.playlistId;
      });
      (await this.youtubeService.getYoutubePlaylists(ids)).subscribe(
        (result) => {
          console.log(result);
          this.currentSearchTabInfo.results =
            this.currentSearchTabInfo.results.map((item) => {
              for (let playlistDetails of result.items) {
                if (item.playlistId == playlistDetails.id) {
                  item.videoCount = numberSuffix.transform(
                    playlistDetails.contentDetails.itemCount
                  );
                  break;
                }
              }
              return item;
            });
          console.log(this.currentSearchTabInfo);
          this.updateStorageFromCurrentSearch();
        }
      );
    } else {
      this.updateStorageFromCurrentSearch();
    }
  }

  updateStorageFromCurrentSearch() {
    let stateItems = [];
    if (
      this.currentSearchTabInfo.videoId &&
      this.currentRelatedVideo &&
      this.currentRelatedVideo.id === this.currentSearchTabInfo.videoId
    ) {
      stateItems.push('related to: ' + this.currentRelatedVideo.title);
    }
    if (this.currentSearchTabInfo.query) {
      stateItems.push('query: ' + this.currentSearchTabInfo.query);
    }
    if (
      this.currentSearchTabInfo.videoChannelType &&
      this.currentSearchTabInfo.channelOrPlaylistTitle
    ) {
      stateItems.push(
        this.currentSearchTabInfo.videoChannelType +
          ': ' +
          this.currentSearchTabInfo.channelOrPlaylistTitle
      );
    }
    if (
      this.currentSearchTabInfo.videoChannelType &&
      !this.currentSearchTabInfo.channelOrPlaylistTitle
    ) {
      stateItems.push('type: ' + this.currentSearchTabInfo.videoChannelType);
    }
    // Related search
    if (
      !this.currentSearchTabInfo.videoChannelType &&
      this.currentSearchTabInfo.channelOrPlaylistTitle
    ) {
      if (this.currentSearchTabInfo.channelId) {
        stateItems.push(
          'from channel: ' + this.currentSearchTabInfo.channelOrPlaylistTitle
        );
      }
      if (this.currentSearchTabInfo.playlistId) {
        stateItems.push(
          'from playlist: ' + this.currentSearchTabInfo.channelOrPlaylistTitle
        );
      }
    }
    if (this.currentSearchTabInfo.order) {
      stateItems.push('order: ' + this.currentSearchTabInfo.order);
    }
    let stateLabel = stateItems.join(' • ');
    this.currentSearchTabInfo.stateLabel = stateLabel;
    this.searchStateList[this.searchStateList.length - 1] = cloneDeep(
      this.currentSearchTabInfo
    );
    this.searchStateList = this.searchStateList.slice(
      this.maxSearchStateHistoryItems * -1
    );
    console.log('current search history:', this.searchStateList);
    LocalIndexedDBStorageService.setItem(
      'search_state_list',
      JSON.stringify(this.searchStateList)
    );
  }

  parseSearchItems(response: any) {
    let parsedResponse: YoutubeSearchResult[] = response['items'].map(
      (item) => {
        let type = 'video';
        let id = '';
        let channelId = '';
        let playlistId = '';
        if (item.snippet.hasOwnProperty('resourceId')) {
          type = 'video';
          id = item.snippet.resourceId.videoId;
          channelId = item.snippet.channelId;
        } else {
          if (item.id.hasOwnProperty('videoId')) {
            type = 'video';
            id = item.id.videoId;
            if (item.id.hasOwnProperty('channelId')) {
              channelId = item.id.channelId;
            } else if (item.snippet.hasOwnProperty('channelId')) {
              channelId = item.snippet.channelId;
            }
          } else if (item.id.hasOwnProperty('channelId')) {
            type = 'channel';
            channelId = item.id.channelId;
          } else if (item.snippet.hasOwnProperty('channelId')) {
            type = 'channel';
            channelId = item.snippet.channelId;
          }
          if (item.id.hasOwnProperty('playlistId')) {
            type = 'playlist';
            playlistId = item.id.playlistId;
          }
        }

        return new YoutubeSearchResult({
          id: id,
          type: type,
          title: item.snippet.title,
          desc: truncate(item.snippet.description, {
            length: item.snippet.channelTitle != 'speaktplayvideo' ? 500 : 60,
          }),
          thumbnailUrl:
            typeof item.snippet.thumbnails !== 'undefined' &&
            typeof item.snippet.thumbnails.high !== 'undefined'
              ? item.snippet.thumbnails.high.url
              : '',
          channelId: channelId,
          channelTitle: item.snippet.channelTitle,
          playlistId: playlistId,
          publishedAt: item.snippet.publishedAt,
        });
      }
    );
    parsedResponse = parsedResponse.filter((item) => {
      return !!item.thumbnailUrl;
    });
    console.log(parsedResponse);
    return parsedResponse;
  }

  onScroll() {
    if (this.tabName == 'search') {
      if (!this.hasYoutubeAPIKey || !this.currentSearchTabInfo.nextPageToken) {
        return;
      }
      // temporary disable pagination for related video search, cause youtube return unrelated videos
      if (this.currentSearchTabInfo.videoId) {
        return;
      }
      this.search(
        this.currentSearchTabInfo.query,
        this.currentSearchTabInfo.videoId,
        this.currentSearchTabInfo.channelId,
        this.currentSearchTabInfo.playlistId,
        this.currentSearchTabInfo.channelOrPlaylistTitle,
        this.currentSearchTabInfo.publishedAfter,
        this.currentSearchTabInfo.publishedBefore,
        this.currentSearchTabInfo.type,
        this.currentSearchTabInfo.videoType,
        this.currentSearchTabInfo.videoDuration,
        this.currentSearchTabInfo.order,
        this.currentSearchTabInfo.nextPageToken,
        this.currentSearchTabInfo.searchCount,
        true
      );
    } else {
      this.suggestedPage++;
      this.foundSuggestionVideoByIds = this.suggestionChannelVideos.slice(
        0,
        this.suggestedPage * 20
      );
    }
  }

  ISODateString(d) {
    function pad(n) {
      return n < 10 ? '0' + n : n;
    }
    return (
      d.getUTCFullYear() +
      '-' +
      pad(d.getUTCMonth() + 1) +
      '-' +
      pad(d.getUTCDate()) +
      'T' +
      pad(d.getUTCHours()) +
      ':' +
      pad(d.getUTCMinutes()) +
      ':' +
      pad(d.getUTCSeconds()) +
      'Z'
    );
  }

  updateDateFilter(dateType: string) {
    this.publishedDate = dateType;
    switch (dateType) {
      case 'last_hour':
        this.publishedAfter = moment().startOf('hour').toISOString();
        this.publishedBefore = moment().endOf('hour').toISOString();
        break;
      case 'today':
        this.publishedAfter = moment().startOf('day').toISOString();
        this.publishedBefore = moment().endOf('day').toISOString();
        break;
      case 'this_week':
        this.publishedAfter = moment().startOf('week').toISOString();
        this.publishedBefore = moment().endOf('week').toISOString();
        break;
      case 'this_month':
        this.publishedAfter = moment().startOf('month').toISOString();
        this.publishedBefore = moment().endOf('month').toISOString();
        break;
      case 'this_year':
        this.publishedAfter = moment().startOf('year').toISOString();
        this.publishedBefore = moment().endOf('year').toISOString();
        break;
      default:
        this.publishedAfter = '';
        this.publishedBefore = '';
        break;
    }
    console.log(this.publishedAfter);
    console.log(this.publishedBefore);
  }

  updateType(type: string) {
    this.videoChannelType = type;
    if (type) {
      if (['channel', 'playlist', 'video'].includes(type)) {
        this.videoType = '';
        this.type = type;
      } else {
        this.type = type == 'episode' ? 'video' : '';
        this.videoType = type;
      }
    } else {
      this.videoType = '';
      this.type = '';
    }
  }

  updateDuration(duration: string) {
    if (duration) {
      this.videoDuration = duration;
    } else {
      this.videoDuration = '';
    }
  }

  updateOrder(order: string) {
    if (order) {
      this.order = order;
    } else {
      this.order = '';
    }
  }

  formatDate(date: string = null, unix: number = null) {
    if (date) {
      return moment(date).fromNow();
    }
    if (unix) {
      return moment.unix(unix).fromNow();
    }
  }

  // formatNumber(number: number){
  //   return moment(number).
  // }

  formatDuration(stringDuration: string) {
    if (stringDuration == 'P0D') {
      return 'Streaming';
    }
    let duration = moment.duration(stringDuration);

    // Use asSeconds() to get the total duration in seconds
    let seconds = duration.asSeconds();

    if (seconds < 60) {
      // If the duration is less than 60 seconds, display it as total seconds
      return seconds + ' ' + this.translate.instant('seconds');
    } else {
      // If the duration is more than 60 seconds, use humanize()
      return duration.humanize();
    }
  }

  formatDateTime(dateTime: string) {
    const formattedDate = moment(dateTime).format('MM/DD/YYYY'); //h:mm:ss A
    return formattedDate;
  }

  updateSelectedChannel(channelId: string) {}

  relatedSearch(
    relatedVideoId: string = '',
    channelId: string = '',
    playlistId: string = '',
    channelOrPlaylistTitle: string = '',
    relatedVideoTitle = ''
  ) {
    this.tabName = 'search';
    this.resetSearchForm();
    // if (relatedVideoId) {
    //   this.videoChannelType = 'video';
    // }
    if (relatedVideoTitle) {
      this.currentRelatedVideo = {
        id: relatedVideoId,
        title: relatedVideoTitle,
      };
    }
    if (channelId) {
      console.log({ id: channelId, title: channelOrPlaylistTitle });
      this.clickedChannels.push({
        id: channelId,
        title: channelOrPlaylistTitle,
      });
      this.currentChannelId = channelId;
      // this.videoChannelType = 'channel';
    }
    // if (playlistId) {
    //   this.videoChannelType = 'playlist';
    // }
    this.search(
      '',
      relatedVideoId,
      channelId,
      playlistId,
      channelOrPlaylistTitle
    );
  }

  resetSearchForm() {
    this.publishedAfter = '';
    this.publishedBefore = '';
    this.type = '';
    this.videoType = '';
    this.videoDuration = '';
    this.order = '';
    this.query = '';
    this.currentChannelId = '';

    this.publishedDate = '';
    this.videoChannelType = '';
  }

  setCurrentSearchTabInfo(historyIndex: number) {
    if (historyIndex >= 0) {
      this.selectedHistoryIndex = historyIndex;
      this.currentSearchTabInfo = cloneDeep(this.searchStateList[historyIndex]);
      console.log(this.currentSearchTabInfo);
    }
  }

  playLesson(content, youtubeSearchResult: YoutubeSearchResult) {
    this.selected = youtubeSearchResult;
    let size = 'lg';
    if (this.clickTo == 'zoomIn') {
      size = 'md';
    }
    this.modal = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: size,
      keyboard: false,
      centered: true,
      backdrop: 'static',
      windowClass: 'preview-video-modal',
    });
    this.previewChange.next('play');
  }

  animateSelectedLesson() {
    document.querySelectorAll('.selected-box').forEach((el) => {
      el.classList.remove('animated');
      el.classList.remove('rubberBand');
      el.classList.remove('infinite');
    });
    document.querySelectorAll('.box-' + this.selectBoxIndex).forEach((el) => {
      el.classList.add('animated');
      el.classList.add('rubberBand');
      el.classList.add('infinite');
    });
  }

  previewVideoSelectedChange($event: InputPreviewVideo, addForm) {
    if ($event) {
      if (typeof $event['startSeconds'] !== 'undefined') {
        this.selected.startSeconds = $event['startSeconds'];
      }
      if (typeof $event['endSeconds'] !== 'undefined') {
        this.selected.endSeconds = $event['endSeconds'];
      }
      if (typeof $event['questionAnswers'] !== 'undefined') {
        this.selected.questionAnswers = $event['questionAnswers'];
      }
      if (typeof $event['durationSkipBarsStatus'] !== 'undefined') {
        this.selected.durationSkipBarsStatus = $event['durationSkipBarsStatus'];
      }
      if (typeof $event['customThumbnailUrl'] !== 'undefined') {
        this.selected.customThumbnailUrl = $event['customThumbnailUrl'];
      }
      if (typeof $event['mute'] !== 'undefined') {
        this.selected.mute = $event['mute'];
      }
      if (typeof $event['volume'] !== 'undefined') {
        this.selected.volume = $event['volume'];
      }

      this.usedVideo = this.selected;
      this.useIt(this.selected, !this.newOnPopup ? null : addForm);
    } else {
      if (this.modal) {
        this.modal.close();
      }
    }
  }

  useIt(youtubeSearchResult: YoutubeSearchResult, addContent: any = null) {
    this.useItActionType = null;
    this.useItEditId = null;
    this.usedVideo = youtubeSearchResult;
    if (this.modal) {
      this.modal.close();
    }
    if (!this.newOnPopup) {
      this.selectedChange.emit(youtubeSearchResult);
    } else {
      this.addModal = this.modalService.open(addContent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'custom-modal-size',
      });
    }
  }

  eventChange($message) {
    this.useItActionType = null;
    if ($message) {
      this.toastService.error($message);
      setTimeout(() => {
        this.cd.detectChanges();
      });
    } else {
      this.usedVideo = null;
      this.useItEditId = null;
      this.addModal.close();
    }
  }

  selectedEditItem(payload: Lesson) {
    this.useItEditId = payload.vid;
    this.useItActionType = 'edit';
  }
}

export interface YoutubeSearchState {
  query: string;
  videoId: string;
  channelId: string;
  playlistId: string;
  channelOrPlaylistTitle: string;
  publishedAfter: string;
  publishedBefore: string;
  publishedDate: string;
  type: string;
  videoType: string;
  videoChannelType: string;
  videoDuration: string;
  order: string;
  nextPageToken: string;
  searchCount: number;
  stateDate: any;
  results: YoutubeSearchResult[];
  stateLabel: string;
}
