<!-- <app-breadcrumb></app-breadcrumb> -->
<ng-container *ngIf="!purchasedStatus">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body row flex-row">
            <div class="col-xl-3 col-sm-12 xl-50 mb-3">
              <div class="card text-center alert alert-info">
                <div class="card-body">
                  <h5>{{ "Student" | translate }}</h5>
                  <h3>{{ "$2/month" | translate }}</h3>
                  <h6 class="mb-0 plan-desc" *ngIf="!isExtend">
                    {{
                      "Full access to premium lessons for a month" | translate
                    }}
                  </h6>
                  <h6 class="mb-0 plan-desc" *ngIf="isExtend">
                    {{ "Extend your account" | translate }}
                    <strong>{{ "1 more month" | translate }}</strong>
                    {{
                      "to continue accessing all premium lessons" | translate
                    }}
                  </h6>
                </div>
                <a
                  class="btn btn-lg btn-warning btn-block"
                  href="javascript:void(0)"
                  (click)="stripeCheckout('studentmonth')"
                  *ngIf="userType == 'free' || userType == 'student'"
                >
                  <h5 class="mb-0">{{ buttonLabel }}</h5>
                </a>
                <h5 class="mb-0" *ngIf="userType == 'teacher'">
                  {{ "Unavailable for teachers" | translate }}
                </h5>
              </div>
            </div>
            <div class="col-xl-3 col-sm-12 xl-50 mb-3">
              <div class="card text-center alert alert-info">
                <div class="card-body">
                  <h5>{{ "Student" | translate }}</h5>
                  <h3>{{ "$20/year" | translate }}</h3>
                  <h6 class="mb-0 plan-desc" *ngIf="!isExtend">
                    {{
                      "Full access to premium lessons for a year" | translate
                    }}
                  </h6>
                  <h6 class="mb-0 plan-desc" *ngIf="isExtend">
                    {{ "Extend your account" | translate }}
                    <strong>{{ "1 more year" | translate }}</strong>
                    {{
                      "to continue accessing all premium lessons" | translate
                    }}
                  </h6>
                </div>
                <a
                  class="btn btn-lg btn-warning btn-block"
                  href="javascript:void(0)"
                  (click)="stripeCheckout('studentyear')"
                  *ngIf="userType == 'free' || userType == 'student'"
                >
                  <h5 class="mb-0">{{ buttonLabel }}</h5>
                </a>
                <h5 class="mb-0" *ngIf="userType == 'teacher'">
                  {{ "Unavailable for teachers" | translate }}
                </h5>
              </div>
            </div>
            <div class="col-xl-3 col-sm-12 xl-50 mb-3">
              <div class="card text-center alert alert-secondary">
                <div class="card-body">
                  <h5>
                    {{ "Teacher" | translate }}
                    <!-- <ng-container *ngIf="!authService.user || !authService.userData.expiredDate || (authService.userData.expiredDate > 0 && authService.userData.expiredDate < currentUnixtime)">
                      (<strong>{{ 'current' | translate }}</strong>)
                    </ng-container> -->
                  </h5>
                  <h3>{{ "$10/month" | translate }}</h3>
                  <h6 class="mb-0 plan-desc" *ngIf="!isExtend">
                    {{
                      "Full access to premium lessons and ability to create your own lessons from your YouTube playlist to share with students"
                        | translate
                    }}
                  </h6>
                  <h6 class="mb-0 plan-desc" *ngIf="isExtend">
                    {{ "Extend your account" | translate }}
                    <strong>{{ "1 more month" | translate }}</strong>
                    {{
                      "This plan is for teachers who want to create their own lessons from their YouTube playlist to share with students"
                        | translate
                    }}
                  </h6>
                </div>
                <!-- <a class="btn btn-lg btn-warning btn-block disabled" href="javascript:void(0)">
                  <h5 class="mb-0">{{ 'Free' | translate }}</h5>
                </a> -->
                <a
                  class="btn btn-lg btn-warning btn-block"
                  href="javascript:void(0)"
                  (click)="stripeCheckout('teachermonth')"
                  *ngIf="userType == 'free' || userType == 'teacher'"
                >
                  <h5 class="mb-0">{{ buttonLabel }}</h5>
                </a>
                <h5 class="mb-0" *ngIf="userType == 'student'">
                  {{ "Unavailable for students" | translate }}
                </h5>
              </div>
            </div>
            <div class="col-xl-3 col-sm-12 xl-50 mb-3">
              <div class="card text-center alert alert-secondary">
                <div class="card-body">
                  <h5>
                    {{ "Teacher" | translate }}
                    <!-- <ng-container *ngIf="!authService.user || !authService.userData.expiredDate || (authService.userData.expiredDate > 0 && authService.userData.expiredDate < currentUnixtime)">
                      (<strong>{{ 'current' | translate }}</strong>)
                    </ng-container> -->
                  </h5>
                  <h3>{{ "$100/year" | translate }}</h3>
                  <h6 class="mb-0 plan-desc" *ngIf="!isExtend">
                    {{
                      "Full access to premium lessons and ability to create your own lessons from your YouTube playlist to share with students"
                        | translate
                    }}
                  </h6>
                  <h6 class="mb-0 plan-desc" *ngIf="isExtend">
                    {{ "Extend your account" | translate }}
                    <strong>{{ "1 more year" | translate }}</strong>
                    {{
                      "This plan is for teachers who want to create their own lessons from their YouTube playlist to share with students"
                        | translate
                    }}
                  </h6>
                </div>
                <!-- <a class="btn btn-lg btn-warning btn-block disabled" href="javascript:void(0)">
                  <h5 class="mb-0">{{ 'Free' | translate }}</h5>
                </a> -->
                <a
                  class="btn btn-lg btn-warning btn-block"
                  href="javascript:void(0)"
                  (click)="stripeCheckout('teacheryear')"
                  *ngIf="userType == 'free' || userType == 'teacher'"
                >
                  <h5 class="mb-0">{{ buttonLabel }}</h5>
                </a>
                <h5 class="mb-0" *ngIf="userType == 'student'">
                  {{ "Unavailable for students" | translate }}
                </h5>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-xl-12 text-center">
              <!-- The license key will be sent to your email address that you provided in the payment form. <br /> -->
              <span *ngIf="!!expiryDate"
                >{{ "Your account" | translate }}
                <strong>{{ authService.userData.email }}</strong>
                {{ "will be" | translate }} {{ "expired at" | translate }}
                <strong>{{ expiryDate }}</strong
                ><br
              /></span>
              <strong>{{ "Note" | translate }}</strong
              >:
              {{
                "Each account allows only one device to be logged in at a time. When a new device signs in, the previous device will be automatically signed out."
                  | translate
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!!purchasedStatus && purchasedStatus == 'success'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card" *ngIf="isSuccess">
          <div class="card-header"></div>
          <div class="card-body text-center">
            <div class="row">
              <div class="col-lg-6 col-md-12 mb-3">
                <img
                  class="thank-you-img"
                  src="https://storage.speaknplay.app/internal%2Fthank_you.jpg?alt=media&token=f99dddc2-d7cc-48d1-bbf1-6f1e19affcef"
                />
              </div>
              <div class="col-lg-6 col-md-12 mb-3">
                <h1 class="display-3">{{ "Thank You!" | translate }}</h1>
                <p class="lead">
                  {{
                    "With every payment, you are not only gaining the excellent experience using our app. It also helps us to grow as a company, so that we can deliver more awesome work."
                      | translate
                  }}<br />
                  {{ "After this payment" | translate }}
                  <span *ngIf="userType == 'student'">{{
                    "Your student account" | translate
                  }}</span>
                  <span *ngIf="userType == 'teacher'">{{
                    "Your teacher account" | translate
                  }}</span
                  >&nbsp;
                  <strong>{{ authService.userData.email }}</strong>
                  {{ "will be expired at" | translate }}&nbsp;<strong
                    >{{ expiryDate }}.</strong
                  >
                  <span *ngIf="userType == 'teacher'">
                    {{ "Ready to add more videos? Go to" | translate }}
                    <a [routerLink]="['/library']">{{
                      "Library" | translate
                    }}</a>
                  </span>
                  <br />
                  <strong>{{ "Note" | translate }}</strong>

                  {{
                    "Each account allows only one device to be logged in at a time. When a new device signs in, the previous device will be automatically signed out."
                      | translate
                  }}
                </p>
                <hr />
                <p>
                  {{ "Having trouble?" | translate }}
                  <a href="mailto:support@speaknplay.app"
                    >support@speaknplay.app</a
                  >
                </p>
                <p class="lead">
                  <a
                    class="btn btn-primary btn-sm"
                    [routerLink]="['/']"
                    role="button"
                    >{{ "Continue to play videos" | translate }}</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="!isSuccess" style="margin-top: 30px">
          <div class="card-header">
            <h5>{{ "Validating..." | translate }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
