import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { APIResponse } from 'src/app/services/constant.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { NavService } from 'src/app/services/nav.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  public verifyStatus: 'success' | 'in-progress' | 'failed' = 'in-progress';
  public message: string = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    public navService: NavService,
    private http: HttpClient
  ) {
    this.route.params.subscribe((params) => {
      if (typeof params['ref'] != 'undefined') {
        console.log(params['ref']);
        const token = params['ref'];
        if (token) {
          const apiEndpoint = environment.apiEndpoint;
          const url = `${apiEndpoint}/verify-email`;
          this.http
            .post(url, {
              token: token,
            })
            .subscribe({
              next: (apiResponse: APIResponse) => {
                if (apiResponse.success) {
                  this.verifyStatus = 'success';
                } else {
                  this.verifyStatus = 'failed';
                  this.message = apiResponse.message;
                }
              },
              error: (apiResponse: APIResponse) => {
                this.verifyStatus = 'failed';
                this.message = apiResponse.message;
              },
            });
        } else {
          this.verifyStatus = 'failed';
          this.message = 'Invalid token';
        }
      }
    });
  }

  ngOnInit() {}
}
