<div
  id="imagecontainer"
  [class]="isPortrait ? 'portrait' : 'landscape'"
  (click)="playAudioQuestion(currentQuestionAnswer)"
>
  <img id="questioner_avatar" class="invisible" />
  <i class="icofont icofont-question-circle" id="question_ico_avatar"></i>
  <div
    class="questionAnswerDesc"
    *ngIf="currentQuestionAnswer"
    (click)="authService.playAnyKey(currentQuestionAnswer.answer)"
  >
    Answer: <span></span>{{ currentQuestionAnswer.answer }}<br />
    <i class="icofont icofont-mic"></i><span [innerHTML]="currentSpeech"></span>
  </div>
</div>
<div
  id="homeContainer"
  class="clearfix no_select"
  (contextmenu)="onRightClick($event)"
>
  <div
    class="videoWrapper"
    id="videoWrapper"
    (panmove)="showYoutubeCredit(10000)"
  >
    <div id="spaceCount"></div>
    <div
      id="youtubeCredit"
      *ngIf="currentVideo && currentVideo.title && currentVideo.channelTitle"
    >
      {{ "This video is based on" | translate }} "{{
        currentVideo?.originalTitle
          ? currentVideo.originalTitle
          : currentVideo?.title
      }}" {{ "by" | translate }} "{{
        currentVideo?.originalChannelTitle
          ? currentVideo.originalChannelTitle
          : currentVideo?.channelTitle
      }}", {{ "used under" | translate }}
      <a href="https://creativecommons.org/licenses/by/3.0" target="_blank"
        >CC BY 3.0</a
      >. {{ "Available on" | translate }}
      <a
        href="https://www.youtube.com/watch?v={{
          currentVideo?.originalYid
            ? currentVideo.originalYid
            : currentVideo?.yid
        }}"
        target="_blank"
        >YouTube</a
      >.
      <!-- {{'In our application, we use parameters of Youtube player to customize the player controls and use player functions to direct our users to specific parts of the video for educational purposes.' | translate}} -->
    </div>
    <youtube-player
      *ngIf="ytReady"
      videoId="KvgufisIBt4"
      suggestedQuality="highres"
      [height]="250"
      [width]="500"
      (ready)="onPlayerReady($event)"
      (stateChange)="onPlayerStateChange($event)"
      [playerVars]="{
        autoplay: 0,
        autohide: 1,
        controls: 0,
        disablekb: 1,
        loop: 1,
        modestbranding: 1,
        rel: 0,
        showinfo: 0,
        origin: currentOrigin
      }"
    >
    </youtube-player>
  </div>
  <div id="toolbar" *ngIf="!!items && !!categories">
    <div id="result">
      <div id="recordIconContainer" class="d-inline-block">
        <i class="icofont icofont-mic" style="visibility: hidden"></i>
        <i class="icofont icofont-mic-mute"></i>
      </div>
      <div
        id="currentSpeech"
        class="d-inline-block"
        [innerHTML]="currentSpeech | translate"
      ></div>
    </div>
    <div style="border-bottom: solid 1px #fff" class="text-center">
      <ng-container *ngFor="let cat of categories; let i = index">
        <span
          [ngClass]="{
            'selected-category': cat.cid === currentSelectedCategory
          }"
          class="category-item"
          *ngIf="cat.cid != 10000"
          (click)="authService.playAnyKey(cat.name)"
          >{{ cat.name }}</span
        >
      </ng-container>
    </div>
  </div>
  <div
    class="container-fluid position-relative"
    id="photoContainer"
    *ngIf="!!targetYoutube && !!items"
  >
    <div class="row photo-container">
      <ng-container
        *ngFor="let playItem of selectedCategoryItems; let i = index"
      >
        <div class="photo-item">
          <div
            class="position-relative thumbnail-container m{{
              playItem.ylist[0].yid
            }} vid{{ playItem.vid }}"
            [style.width]="
              thumbnailContainerWidth === 'auto'
                ? 'auto'
                : thumbnailContainerWidth + 'px'
            "
          >
            <img
              title="{{ playItem.ylist[0].yid }}"
              src="{{
                playItem.ylist[0].customThumbnailUrl
                  ? playItem.ylist[0].customThumbnailUrl
                  : 'https://img.youtube.com/vi/' +
                    authService.getOriginalYID(playItem.ylist[0].yid) +
                    '/mqdefault.jpg'
              }}"
              [title]="playItem.name"
            />
            <div class="position-absolute video-name">
              <span (click)="authService.playAnyKey(playItem.name)">{{
                playItem.name
              }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #myModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title text-center" style="width: 100%">
        <ng-container style="font-size: 1rem; margin-top: 0.5rem">
          <!-- <a href="javascript:void(0)" class="logout-link guide-menu-item mr-2" (click)="navService.showInfo(navService.getInfoText('intro'), 'swal-wide')">{{'What is it'| translate }}</a> -->
          <a
            *ngIf="
              authService.userData &&
              authService.userData.expiredDate &&
              authService.userData.expiredDate > currentUnixtime &&
              authService.userData.type == 'teacher'
            "
            href="javascript:void(0)"
            (click)="goToMedia()"
            class="mr-2 video-management-link guide-menu-item"
          >
            {{ "Library" | translate }}</a
          >
          <a
            *ngIf="authService.isLoggedIn()"
            href="javascript:void(0)"
            (click)="openSettingPopup(settingContent)"
            class="mr-2 setting-link guide-menu-item"
            >{{ "Settings" | translate }}</a
          >
          <a
            *ngIf="!authService.isLoggedIn()"
            href="javascript:void(0)"
            [routerLink]="['login']"
            (click)="closePopups()"
            class="video-management-link guide-menu-item mr-2"
            >{{ "login" | translate }}</a
          >
          <a
            href="javascript:void(0)"
            (click)="openPurchasePopup(purchaseContent)"
            class="logout-link guide-menu-item"
            >{{ purchaseHeader | translate }} {{ expiredAt }}</a
          >
        </ng-container>
      </h4>
    </div>
    <div class="modal-body">
      <ul>
        <li>
          <app-languages [isPickingLearningLanguage]="false" [showLabel]="true">
          </app-languages>
        </li>
        <li>
          <app-languages
            (selectedLanguageChange)="changeLanguage($event)"
            [isPickingLearningLanguage]="true"
            [showLabel]="true"
          >
          </app-languages>
        </li>
        <li>
          <div class="form-group row">
            <label class="col-sm-3 col-md-2 col-form-label text-left m-b-10"
              >{{ "Mode" | translate }}
              <app-info name="mode" color="white"></app-info
            ></label>
            <div class="col-sm-9 col-md-4 text-left m-b-10">
              <select
                class="form-control"
                [(ngModel)]="homeMode"
                (change)="rememberHomeMode($event.target['value'])"
              >
                <option value="BEGINNER">
                  {{ "Beginner mode" | translate }}
                </option>
                <option value="ADVANCE">
                  {{ "Advance mode" | translate }}
                </option>
              </select>
            </div>
          </div>
        </li>
        <li>
          <div class="form-group row">
            <label class="col-sm-3 col-md-2 col-form-label text-left m-b-10"
              >{{ "Age group" | translate }}
              <app-info name="age_group" color="white"></app-info
            ></label>
            <div class="col-sm-9 col-md-2 text-left m-b-10">
              <select
                class="form-control"
                [(ngModel)]="selectedLessonAgeGroup"
                (change)="
                  rememberSelectedLessonAgeGroup($event.target['value'])
                "
              >
                <option
                  *ngFor="let ageGroup of lessonAgeGroups"
                  [value]="ageGroup"
                >
                  {{ ageGroup }}
                </option>
              </select>
            </div>
          </div>
        </li>
        <!-- <li class="mt-3">
          <h5><strong>{{'Tips after you press `Start` button'| translate}}</strong></h5>
        </li>
        <li>- {{'If you want to play video\'s name'| translate }} "<strong>{{'whale' | translate}}</strong>", {{'you can
          speak'| translate
          }}: "<strong>{{'whale' | translate}}</strong>", {{'or'| translate }} "<strong>{{'a' | translate}} {{'whale' |
            translate}}</strong>", {{'or'| translate }}
          "<strong>{{'whale animal' | translate}}</strong>". {{'Sometimes longer speech helps speech recognition does
          the job better.'|
          translate }}</li>
        <li *ngIf="!isHomeModeBeginner">- {{'Press space key from your keyboard to switch between video and menu'|
          translate }}</li>
        <li>- {{'Press ESC to exit full screen mode'| translate }}</li> -->
      </ul>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        id="startButton"
        (click)="openFullscreen()"
      >
        {{ "Start" | translate }}
      </button>
    </div>
  </ng-template>
  <button
    id="btnFullScreen"
    type="button"
    class="btn btn-light btn-black active"
    (click)="openFullscreen()"
  >
    <h1 class="animated pulse infinite">
      <i class="icofont icofont-animal-{{ randomIcon }}"></i>
    </h1>
  </button>
</div>
<!-- <div id="homePageCover" (click)="playClickSound()" (mousedown)="spaceOrClickKeyDownEvent($event)"
  (mouseup)="spaceOrClickKeyUpEvent($event)" (contextmenu)="onRightClick($event)"
  (press)="spaceOrClickKeyDownEvent($event)" (pressup)="spaceOrClickKeyUpEvent($event)"
  (panstart)="spaceOrClickKeyDownEvent($event)" (panend)="spaceOrClickKeyUpEvent($event)" class="fullScreen">
</div> -->
<audio id="audio_click" autoplay="false"></audio>
<audio id="recording_start" autoplay="false"></audio>
<audio id="audio_ding" autoplay="false">
  <!-- <source id="audio_ding_source"/> -->
</audio>
<audio id="audio_question" autoplay="false"></audio>
<ng-template #ylistModal let-modal>
  <div class="modal-body" (click)="playClickSound(); closeYlilstMenu()">
    <app-menu-generator
      *ngIf="currentLesson.display"
      [listDisplayType]="currentLesson.display"
      [selected]="matchedMenuVideo"
      [calculateSize]="true"
      [yList]="currentLesson.ylist"
    >
    </app-menu-generator>
  </div>
</ng-template>
<ng-template #purchaseContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ "Upgrade" | translate }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-purchase (purchaseClickEvent)="closePopups()"></app-purchase>
  </div>
</ng-template>
<ng-template #settingContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ "Global settings" | translate }} {{ "version" | translate }}
      {{ version }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-setting (selectedChange)="closeSettingPopup($event)"></app-setting>
  </div>
</ng-template>
<audio id="preload_audio" autoplay="false"></audio>
