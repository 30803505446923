<!-- Page Header Start-->
<div
  class="page-main-header"
  [ngClass]="{ open: navService.collapseSidebar }"
  id="page-main-header"
>
  <div class="main-header-right row">
    <div class="main-header-left">
      <app-breadcrumb [showBreadcrumb]="showBreadcrumb"></app-breadcrumb>
    </div>
    <div class="nav-right col p-r-0">
      <ul class="nav-menus">
        <li *ngIf="authService.isLoggedIn()">
          <a href="javascript:void(0)" (click)="openLg(purchaseContent)">
            {{
              navService.screenWidth < 500 && isExpiredIn1Week
                ? ("Expire Soon" | translate)
                : (purchaseHeader | translate)
            }}
            {{ expiredAt }}</a
          >
        </li>
        <li *ngIf="authService.isLoggedIn()">
          <a href="javascript:void(0)" (click)="openLg(content)">
            <span *ngIf="navService.screenWidth < 600"
              ><i class="fa fa-gear" style="font-size: 16px"></i
            ></span>
            <span *ngIf="navService.screenWidth >= 600">{{
              "Settings" | translate
            }}</span>
          </a>
        </li>
        <li class="onhover-dropdown">
          <!-- (selectedLanguageChange)="changeLanguage($event)" -->
          <app-languages
            [isPickingLearningLanguage]="false"
            [showLabel]="false"
            style="display: block; position: relative; top: 10px"
            *ngIf="
              showLanguageSelection &&
              navService.screenWidth >= 600 &&
              showLanguages
            "
          >
          </app-languages>
          <a
            href="javascript:void(0)"
            (click)="openLg(languageContent)"
            *ngIf="showLanguageSelection && navService.screenWidth < 600"
          >
            <i class="fa fa-language"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
<ng-template #languageContent let-modal>
  <!-- (selectedLanguageChange)="changeLanguage($event)" -->
  <app-languages
    [isPickingLearningLanguage]="false"
    [showLabel]="false"
    style="display: block; position: relative; top: 10px"
    *ngIf="showLanguageSelection && showLanguages"
  >
  </app-languages>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ "Global settings" | translate }} {{ "version" | translate }}
      {{ version }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-setting (selectedChange)="modal.close()"></app-setting>
  </div>
</ng-template>

<ng-template #purchaseContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ purchaseHeader | translate }} {{ expiredAt }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-purchase></app-purchase>
  </div>
</ng-template>
