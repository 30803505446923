import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../services/analytics.service';
import { compress, decompress } from 'compress-json';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/firebase/auth.service';
import {
  NgbPaginationConfig,
  NgbModalRef,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash-es';
import {
  Lesson,
  Category,
  ImportLesson,
  QuestionerAudioQuestion,
  Video,
  LessonAgeGroups,
} from 'src/app/services/constant.service';
import { NavService } from 'src/app/services/nav.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var require;
const Swal = require('sweetalert2');
declare var require;
const jsonpack = require('jsonpack/main');

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
  providers: [NgbPaginationConfig],
})
export class LibraryComponent implements OnInit, OnDestroy {
  public modal: NgbModalRef;
  public items: Lesson[];
  public questionnerQuestions: QuestionerAudioQuestion[];
  public filteredItems: Lesson[];
  public categories: Category[];
  public media: any;
  public pageSize = 20;
  public allCategoriesLabel: string = 'All Categories';
  public stateCache: any = {
    search: '',
    category: [],
    audioQuestion: [],
    page: 1,
    lang: null,
    lesson_age_group: 2,
  };
  public player: YT.Player;
  public copiedValue: string = '';
  public copiedLesson: Lesson;
  public previewVideo: Video;
  public lessonAgeGroups: number[];

  constructor(
    private toastr: ToastrService,
    public authService: AuthService,
    config: NgbPaginationConfig,
    private modalService: NgbModal,
    public router: Router,
    private analyticsService: AnalyticsService,
    public navService: NavService,
    public translate: TranslateService,
    private route: ActivatedRoute
  ) {
    config.size = 'sm';
    config.boundaryLinks = true;
  }

  ngOnInit() {
    this.lessonAgeGroups = LessonAgeGroups;
    this.allCategoriesLabel = this.translate.instant('All Categories');
    let lastStateCache = localStorage.getItem('media_state');
    if (lastStateCache) {
      try {
        lastStateCache = JSON.parse(lastStateCache);
        if (lastStateCache) {
          this.stateCache = lastStateCache;
        }
      } catch (e) {}
    }
    this.getData();
    this.handleSearch(false);
    // this.analyticsService.analytics.logEvent("page_view", {
    //   page_title: "media",
    // });
  }

  ngOnDestroy(): void {}

  showDelete() {
    this.toastr.error(this.translate.instant('User Deleted !'));
  }

  // A warning message, with a function attached to the "Confirm"-button...
  withConfirmation(item: Lesson) {
    if (!this.authService.teacherOnlyAlert()) {
      Swal.fire({
        title: this.translate.instant('Are you sure?'),
        text: this.translate.instant("You won't be able to revert this!"),
        type: 'warning',
        showCancelButton: true,
        // confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: this.translate.instant('Yes, delete it!'),
        cancelButtonText: this.translate.instant('Cancel'),
      }).then((result) => {
        if (result.value) {
          this.delete(item.vid);
        }
      });
    }
  }

  updateSelectedCategory(category: Category[]) {
    const categoryIds = category.map((cat) => {
      return cat.cid;
    });
    this.stateCache.category = categoryIds;
    this.handleSearch();
  }

  updateSelectedAgeGroup(ageGroup: number) {
    this.stateCache.lesson_age_group = ageGroup;
    this.handleSearch();
  }

  updateSelectedAudioQuestion(audioQuestion: QuestionerAudioQuestion[]) {
    const audioQuestionIds = audioQuestion.map((aq) => {
      return aq.id;
    });
    this.stateCache.audioQuestion = audioQuestionIds;
    this.handleSearch();
  }

  handleSearch(updateState: boolean = true) {
    const normalizedSearchValue = this.stateCache.search.trim().toLowerCase();
    this.filteredItems = this.items.filter((media) => {
      try {
        const matchedName = normalizedSearchValue
          ? media.name.toLowerCase().includes(normalizedSearchValue)
          : true;
        const matchedCategory =
          !this.stateCache.category || this.stateCache.category.length == 0
            ? true
            : this.stateCache.category.some((cat) => {
                return media.cid.includes(cat);
              });
        const matchedAgeGroup =
          !this.stateCache.lesson_age_group ||
          this.stateCache.lesson_age_group == 0
            ? true
            : media.ageGroup == this.stateCache.lesson_age_group;
        const matchedAudioQuestion =
          !this.stateCache.audioQuestion ||
          this.stateCache.audioQuestion.length == 0
            ? true
            : this.stateCache.audioQuestion.some((qa) => {
                return media.ylist.some((ylist) => {
                  return ylist.questionAnswers.some((ylist_qa) => {
                    return qa == ylist_qa.question;
                  });
                });
              });
        return (
          matchedName &&
          matchedCategory &&
          matchedAudioQuestion &&
          matchedAgeGroup
        );
      } catch (e) {
        console.log(media);
        return false;
      }
    });
    if (updateState) {
      this.updateMediaState('page', 1);
      this.updateMediaState('search', normalizedSearchValue);
      this.updateMediaState('category', this.stateCache.category);
      this.updateMediaState('audioQuestion', this.stateCache.audioQuestion);
    }
    this.getPaginationItems();
  }

  delete(vId) {
    let submit = () => {
      this.items = this.items.filter((media) => {
        return media.vid !== vId;
      });
      this.filteredItems = this.filteredItems.filter((media) => {
        return media.vid !== vId;
      });
      this.authService.schoolData.data.lessons = this.items;
      this.authService.PushUserDataChanges().then(() => {
        this.getData();
        this.handleSearch();
        this.toastr.success(this.translate.instant('Deleted.'));
      });
    };
    submit();
  }

  getData() {
    // for deleting
    const lessons = cloneDeep(this.authService.schoolData.data.lessons);
    this.items = lessons || [];
    this.questionnerQuestions = cloneDeep(
      this.authService.schoolData.questionerAudioQuestions
    );
    this.questionnerQuestions = this.questionnerQuestions.map((question) => {
      question.disabled = false;
      return question;
    });
    this.filteredItems = this.items;
    this.categories =
      cloneDeep(this.authService.schoolData.data.categories) || [];
  }

  getCategoryLabel(cids) {
    cids = Array.isArray(cids) ? cids : [cids];
    let catNames = cids.map((cid) => {
      if (!cid) {
        return null;
      }
      if (cid === 10000) {
        return this.translate.instant(this.authService.unsetCategoryLabel);
      }
      const category = this.categories.find((category) => {
        return category.cid == cid;
      });
      return category?.name;
    });
    return catNames.join(', ');
  }

  getPaginationItems() {
    return this.filteredItems.length > 0
      ? this.filteredItems.slice(
          (this.stateCache.page - 1) * this.pageSize,
          this.stateCache.page * this.pageSize
        )
      : [];
  }

  public downloadJsonHref: string = '';

  async clone() {
    Swal.fire({
      title: this.translate.instant('Are you sure?'),
      text: this.translate.instant(
        'Your school lessons will be overwritten with this data.'
      ),
      type: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',
      confirmButtonText: this.translate.instant('Yes, clone and overwrite!'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then(async (result) => {
      if (result.value) {
        await this.authService.cloneCurrentSchool();
      }
    });
  }

  downloadJson() {
    let exportedItems: Lesson[] = cloneDeep(this.filteredItems);
    exportedItems = exportedItems.map((item) => {
      item['cid'] = Array.isArray(item['cid']) ? item['cid'] : [item['cid']];
      item['cname'] = item['cid'].map((cidValue) => {
        if (Number(cidValue) == 10000) {
          return cidValue;
        } else {
          return this.authService.getCategoryLabel(cidValue);
        }
      });
      item.ylist =
        item.ylist && item.ylist.length > 0
          ? item.ylist.map((ylist) => {
              if (ylist.questionAnswers && ylist.questionAnswers.length > 0) {
                ylist.questionAnswers = ylist.questionAnswers.filter((qa) => {
                  return qa.question && typeof qa.question === 'number';
                });
                ylist.questionAnswers =
                  ylist.questionAnswers && ylist.questionAnswers.length > 0
                    ? ylist.questionAnswers.map((qa) => {
                        const questionerAudioQuestion: QuestionerAudioQuestion =
                          this.authService.getQuestionerAudioQuestion(
                            qa.question
                          );
                        qa.exportQuestionName = questionerAudioQuestion.name;
                        qa.exportAudioURL = questionerAudioQuestion.audioURL;
                        qa.exportAvatarName = this.authService.getAvatarName(
                          questionerAudioQuestion.questionerAvatarId
                        );
                        qa.exportAvatarURL = this.authService.getJpgURL(
                          questionerAudioQuestion.questionerAvatarId
                        );
                        qa.exportAudioDuration =
                          questionerAudioQuestion.questionDuration;
                        return qa;
                      })
                    : ylist.questionAnswers;
              }
              return ylist;
            })
          : item.ylist;
      delete item['cid'];
      delete item['vid'];
      return item;
    });
    // var sJson = exportedItems;
    var sJson = jsonpack.pack(exportedItems);
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/json;charset=UTF-8,' +
        encodeURIComponent(JSON.stringify(sJson))
    );
    element.setAttribute('download', 'data.json');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }
  copyLesson(playItem: Lesson, modalContent) {
    if (!this.authService.teacherOnlyAlert()) {
      let clonedLesson = cloneDeep(playItem);
      clonedLesson['cname'] = this.authService.getCategoryLabel(
        clonedLesson.cid
      );
      this.copiedLesson = cloneDeep(clonedLesson);
      delete clonedLesson.vid;
      this.copiedValue = JSON.stringify([clonedLesson]);
      this.modal = this.modalService.open(modalContent, {
        size: 'lg',
        scrollable: true,
      });
    }
  }

  copyToClipboard($event) {
    $event.target.select();
    document.execCommand('copy');
    this.toastr.success(this.translate.instant('Copied'));
  }

  file: any;
  importFileChanged(e) {
    this.file = e.target.files[0];
  }

  importJson(importedJsonData) {
    let importFunc = (jsonData) => {
      const importedData = jsonpack.unpack(jsonData) as ImportLesson[];
      // const importedData = JSON.parse(jsonData) as ImportLesson[];

      this.addCategoriesFromImportedDataToLocal(importedData);
      this.authService.generateCategoryMap();
      const categoryMapFlipped = this.authService.categoryMapFlipped;
      for (let importedItem of importedData) {
        // importedItem['yid'] = importedItem['media'];
        let cidArray =
          typeof importedItem.cname == 'undefined'
            ? [10000]
            : importedItem.cname.map((cname) => {
                if (parseInt(cname) == 10000) {
                  return parseInt(cname);
                } else {
                  return parseInt(categoryMapFlipped[cname]);
                }
              });
        cidArray = cidArray.filter((cid) => !!cid);
        // during import if in QuestionAnswer, the quesiton is text then find if any QuestionerAudioQuestion has same name
        // if yes, use that id from found question, if not create new QuestionerAudioQuestion with audioURL is empty
        importedItem.ylist.map((youtubeVideo) => {
          if (
            youtubeVideo.questionAnswers &&
            youtubeVideo.questionAnswers.length > 0
          ) {
            youtubeVideo.questionAnswers.map((qa) => {
              // avatar
              const foundAvatar =
                this.authService.schoolData.questionerAvatars.find((avatar) => {
                  return avatar.name == qa.exportAvatarName + '';
                });
              if (foundAvatar) {
                qa.questionerAvatar = foundAvatar.id;
              } else {
                // create new one
                if (qa.exportAvatarName && qa.exportAvatarURL) {
                  let questionerAvatarId = 0;
                  if (
                    this.authService.schoolData.questionerAvatars.length > 0
                  ) {
                    questionerAvatarId = Math.max(
                      ...this.authService.schoolData.questionerAvatars.map(
                        (question) => question.id
                      )
                    );
                  }
                  questionerAvatarId++;
                  this.authService.schoolData.questionerAvatars.push({
                    id: questionerAvatarId,
                    name: qa.exportAvatarName,
                    jpgURL: qa.exportAvatarURL,
                  });
                  qa.questionerAvatar = questionerAvatarId;
                } else {
                  qa.questionerAvatar = 999;
                }
              }

              // audio
              const foundAudioQuestion =
                this.authService.schoolData.questionerAudioQuestions.find(
                  (qaq) => {
                    return qaq.name == qa.exportQuestionName;
                  }
                );
              if (foundAudioQuestion) {
                qa.question = foundAudioQuestion.id;
              } else {
                // create new one
                if (qa.exportQuestionName && qa.exportAudioURL) {
                  let questionerAudioQuestionId = 0;
                  if (
                    this.authService.schoolData.questionerAudioQuestions
                      .length > 0
                  ) {
                    questionerAudioQuestionId = Math.max(
                      ...this.authService.schoolData.questionerAudioQuestions.map(
                        (question) => question.id
                      )
                    );
                  }
                  questionerAudioQuestionId++;
                  this.authService.schoolData.questionerAudioQuestions.push({
                    id: questionerAudioQuestionId,
                    name: qa.exportQuestionName,
                    audioURL: qa.exportAudioURL,
                    questionerAvatarId: qa.questionerAvatar,
                    questionDuration: qa.exportAudioDuration,
                  });
                  qa.question = questionerAudioQuestionId;
                } else {
                  qa.question = null;
                }
              }
              delete qa.exportAvatarName;
              delete qa.exportAvatarURL;
              delete qa.exportQuestionName;
              delete qa.exportAudioURL;
              return qa;
            });
          }

          return youtubeVideo;
        });
        // change keys in importedItem, to remove leading and trailing spaces and single/double quotes
        for (let key in importedItem) {
          let newKey = key
            .replace(/(^\s+|\s+$)/g, '')
            .replace(/(^"|"$)/g, '')
            .replace(/(^'|'$)/g, '');
          if (newKey !== key) {
            importedItem[newKey] = importedItem[key];
            delete importedItem[key];
          }
        }

        this.authService.addNewVideoToLocal(
          importedItem.name,
          cidArray,
          importedItem.ylist,
          importedItem.display,
          importedItem.ageGroup
        );
      }
      this.authService.PushUserDataChanges().then(() => {
        this.toastr.success(this.translate.instant('Done'));
        this.modal.close();
        setTimeout(() => {
          window.location.href = window.location.href;
        });
      });
    };

    if (this.file) {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        console.log(fileReader.result);
        importFunc(fileReader.result);
      };
      fileReader.readAsText(this.file);
    } else if (importedJsonData && importedJsonData.trim().length > 0) {
      importFunc(importedJsonData);
    }
  }

  addCategoriesFromImportedDataToLocal(importedData: ImportLesson[]) {
    let importedCategories = [];
    for (let importedItem of importedData) {
      if (typeof importedItem.cname !== 'undefined') {
        for (let cnameValue of importedItem.cname) {
          if (parseInt(cnameValue) == 10000 || !cnameValue) {
            continue;
          }
          const cat = cnameValue.trim();
          if (cat && !importedCategories.includes(cnameValue)) {
            importedCategories.push(cat);
          }
        }
      }
    }

    for (let importedCategory of importedCategories) {
      this.authService.addNewCategoryToLocal(importedCategory);
    }
  }

  openLg(content) {
    this.modal = this.modalService.open(content, {
      size: 'xl',
      scrollable: true,
    });
  }

  openVideo(video, content) {
    this.previewVideo = video;
    this.modal = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'preview-video-modal',
    });
  }

  closePreviewVideo() {
    this.modal.close();
  }

  playerReady(playerEvent: YT.PlayerEvent) {
    this.player = playerEvent.target;
    const mediaWrapper = document.getElementById('mediaWrapper');
    const modalBody = document.getElementById('modalBody');
    const styles = getComputedStyle(modalBody);
    const containerWidth =
      modalBody.offsetWidth -
      parseInt(styles.paddingLeft) -
      parseInt(styles.paddingRight);
    const playerHeight = Math.floor((containerWidth * 9) / 16);
    // const playerSize = this.authService.calculatePlayerSize(containerWidth, mediaWrapper.offsetHeight);
    // document.querySelector('#mediaWrapper')['style'].width =containerWidth + 'px';
    document.querySelector('#mediaWrapper iframe')['style'].height =
      playerHeight + 'px';
    document.querySelector('#mediaWrapper iframe')['style'].width =
      containerWidth + 'px';
    document.querySelector('#mediaWrapper iframe')['style'].marginLeft = 'auto';
    document.querySelector('#mediaWrapper iframe')['style'].marginRight =
      'auto';
    mediaWrapper.classList.remove('invisible');
  }

  updateMediaState(
    type: 'search' | 'category' | 'page' | 'audioQuestion',
    payload
  ) {
    if (type == 'page') {
      this.stateCache.page = payload;
    }
    localStorage.setItem('media_state', JSON.stringify(this.stateCache));
  }

  copyEventChange() {
    this.modal.close();
  }
  goToSearch() {
    this.authService.goToTeacherRoute([
      '/library/create-lesson-from-youtube-video',
    ]);
  }
}
