import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Lesson, Category, Student } from 'src/app/services/constant.service';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-edit-student',
  templateUrl: './edit-student.component.html',
  styleUrls: ['./edit-student.component.scss'],
})
export class EditStudentComponent implements OnInit {
  @Input() type: 'add' | 'edit' = null;
  @Input() editStudentId: string = null;
  @Input() passedHiddenLang: string = null;
  @Output() eventChange = new EventEmitter();
  public form: FormGroup;
  public sidebaron: any;
  public editStudentIdEncrypted: string;

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private authService: AuthService,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    if (this.type == 'add') {
      this.form = this.fb.group({
        emailAddress: ['', Validators.required],
        studentName: ['', Validators.required],
      });
    } else {
      let student = this.authService.userData.students.find((item) => {
        return item.studentId == this.editStudentId;
      });
      this.form = this.fb.group({
        studentName: [student.studentName, Validators.required],
      });
    }
  }

  async add(formValue) {
    this.authService.toastSubmit();
    const studentId = await this.authService.encryptText(
      formValue.emailAddress
    );
    let student = {
      studentId: studentId,
      studentName: formValue.studentName,
    };

    let exist = this.authService.userData.students.find((item) => {
      return item.studentId == studentId;
    });

    if (exist) {
      this.toastrService.error(
        this.translate.instant('This student already exists')
      );
    } else {
      this.authService.userData.students.push(student);
      this.eventChange.next({
        emailAddress: formValue.emailAddress,
        actionType: 'add',
      });
    }
  }

  update(formValue) {
    this.authService.toastSubmit();
    this.authService.userData.students = this.authService.userData.students.map(
      (student) => {
        if (student.studentId == this.editStudentId) {
          student.studentName = formValue.studentName;
        }
        return student;
      }
    );
    this.eventChange.next({
      emailAddress: this.editStudentId,
      actionType: 'edit',
    });
  }

  cancel() {
    this.eventChange.next('');
  }
}
