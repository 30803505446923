import { Component, OnInit, Inject, Input } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/firebase/auth.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

var body = document.getElementsByTagName('body')[0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() public showBreadcrumb: boolean = true;
  @Input() public showLanguages: boolean = true;
  public modal: NgbModalRef;
  public menuItems: Menu[];
  public items: Menu[];
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public text: string;
  public elem;
  public isOpenMobile: boolean = false;
  // public selectedLanguage: string = 'en';
  public purchaseHeader = 'Upgrade';
  public expiredAt = '';
  public isExpiredIn1Week = false;
  public showLanguageSelection = true;
  public version: string;

  constructor(
    private translate: TranslateService,
    public navService: NavService,
    @Inject(DOCUMENT) private document: any,
    public authService: AuthService,
    public router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    if (
      this.router.url.includes('auth/') ||
      this.router.url.includes('privacy-policy') ||
      this.router.url.includes('terms-of-service') ||
      this.router.url.includes('data-deletion')
    ) {
      this.showLanguageSelection = false;
    }
    // this.selectedLanguage = localStorage.getItem('language');
    this.elem = document.documentElement;
    this.navService.items.subscribe((menuItems) => {
      this.items = menuItems;
    });
    const nowUnix = parseInt(moment().format('X'));
    this.version = environment.version;
    const oneWeekLaterUnix = parseInt(moment().add('1', 'week').format('X'));
    if (this.authService.userData && this.authService.userData.expiredDate) {
      const expiredDateUnix = this.authService.userData.expiredDate;
      if (expiredDateUnix <= oneWeekLaterUnix && expiredDateUnix > nowUnix) {
        this.purchaseHeader = 'Expire date:';
        this.expiredAt = moment
          .unix(expiredDateUnix)
          .format('YYYY/MM/DD HH:mm:ss');
        this.isExpiredIn1Week = true;
      } else if (expiredDateUnix > nowUnix) {
        this.purchaseHeader = 'Extend';
      }
    } else {
      this.purchaseHeader = 'Upgrade';
    }
  }

  ngOnDestroy() {
    this.removeFix();
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return (this.menuItems = []);
    let items = [];
    term = term.toLowerCase();
    this.items.filter((menuItems) => {
      if (
        menuItems.title.toLowerCase().includes(term) &&
        menuItems.type === 'link'
      ) {
        items.push(menuItems);
      }
      if (!menuItems.children) return false;
      menuItems.children.filter((subItems) => {
        if (
          subItems.title.toLowerCase().includes(term) &&
          subItems.type === 'link'
        ) {
          subItems.icon = menuItems.icon;
          items.push(subItems);
        }
        if (!subItems.children) return false;
        subItems.children.filter((suSubItems) => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon;
            items.push(suSubItems);
          }
        });
      });
      this.checkSearchResultEmpty(items);
      this.menuItems = items;
    });
  }

  checkSearchResultEmpty(items) {
    if (!items.length) this.searchResultEmpty = true;
    else this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    body.classList.add('offcanvas');
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove('offcanvas');
    this.text = '';
  }

  toggleFullScreen() {
    this.navService.fullScreen = !this.navService.fullScreen;
    if (this.navService.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  openLg(content) {
    this.modal = this.modalService.open(content, {
      size: 'xl',
      scrollable: true,
    });
  }
}
