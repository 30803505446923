<div class="page-wrapper">
  <app-header [showBreadcrumb]="false" [showLanguages]="true"></app-header>
  <div class="auth-bg">
    <div class="authentication-box">
      <div class="text-center"></div>
      <div class="card mt-4">
        <div class="card-body">
          <ng-container>
            <div class="text-center" *ngIf="verifyStatus == 'in-progress'">
              <h4>Please wait while we verify your email address.</h4>
              <br />
              <div class="loader">
                <div class="line bg-info"></div>
                <div class="line bg-info"></div>
                <div class="line bg-info"></div>
                <div class="line bg-info"></div>
              </div>
            </div>
            <div class="text-center" *ngIf="verifyStatus == 'success'">
              <h4>Your email address has been verified successfully.</h4>
              <br />
              <a [routerLink]="['/login']" class="btn btn-primary"> Login </a>
            </div>
            <div class="text-center" *ngIf="verifyStatus == 'failed'">
              <h4>There was an error verifying your email address.</h4>
              <p>{{ message }}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="footer">
  <app-footer></app-footer>
</footer>
