import { AnalyticsService } from './../../services/analytics.service';
import { SpeechService } from './../../services/speech.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../services/firebase/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { APIResponse, User } from 'src/app/services/constant.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };
declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  public user: User;
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    email: '',
    password: '',
  };
  public errorMessage: any;
  public browserSupport = true;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    public speechService: SpeechService,
    private translate: TranslateService,
    private http: HttpClient,
    private toster: ToastrService,
    private router: Router // private analyticsService: AnalyticsService
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.browserSupport =
      !this.speechService.useCloudSpeech &&
      typeof window['speechSynthesis'] != 'undefined';
    if (this.authService.userData) {
      this.authService.SignOut();
    }
    // this.analyticsService.analytics.logEvent('page_view', {page_title: this.translate.instant('login')});
  }

  // Simple Login
  login() {
    // this.analyticsService.analytics.logEvent('login_page_clicked_password_login');
    const apiEndpoint = environment.apiEndpoint;
    const url = `${apiEndpoint}/login`;
    Swal.showLoading();
    this.http
      .post(url, {
        email: this.loginForm.value['email'],
        password: this.loginForm.value['password'],
      })
      .subscribe({
        next: (apiResponse: APIResponse) => {
          this.toster.clear();
          if (apiResponse.success && apiResponse.token) {
            // go to home page
            localStorage.setItem('session_token', apiResponse.token);
            this.authService.authenticationCallback(apiResponse.user);
            setTimeout(() => {
              this.router.navigate(['/']);
            }, 1000);
          } else {
            this.toster.error(
              this.translate.instant(
                'Something went wrong: ' + apiResponse.message
              ),
              '',
              {
                timeOut: 60000,
              }
            );
          }
          Swal.close();
        },
        error: (apiResponse: APIResponse) => {
          Swal.close();
          this.toster.error(apiResponse.message);
        },
      });
  }

  enableTest() {
    localStorage.setItem('access_key', '123456');
  }
}
