<div class="page-wrapper">
  <app-header></app-header>
  <div class="auth-bg">
    <div class="authentication-box">
      <div class="card mt-4">
        <div class="card-body">
          <ng-container *ngIf="browserSupport">
            <div class="text-center">
              <h4>{{ "LOGIN" | translate }}</h4>
              <h6>{{ "Using any option below to login" | translate }}</h6>
            </div>
            <form class="theme-form" [formGroup]="loginForm" *ngIf="!newUser">
              <div class="form-group">
                <label class="col-form-label pt-0">{{
                  "Email" | translate
                }}</label>
                <input
                  class="form-control"
                  formControlName="email"
                  type="email"
                  required=""
                />
                <div
                  *ngIf="
                    loginForm.controls.email.touched &&
                    loginForm.controls.email.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  {{ "Email is required" | translate }}
                </div>
                <div
                  *ngIf="
                    loginForm.controls.email.touched &&
                    loginForm.controls.email.errors?.email
                  "
                  class="text text-danger mt-1"
                >
                  {{ "Invalid Email" | translate }}
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">{{
                  "Password" | translate
                }}</label>
                <input
                  class="form-control"
                  type="password"
                  formControlName="password"
                  required=""
                />
                <div
                  *ngIf="
                    loginForm.controls.password.touched &&
                    loginForm.controls.password.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  {{ "Password is required" | translate }}
                </div>
              </div>
              <div class="checkbox p-0">
                <input id="checkbox1" type="checkbox" />
                <label for="checkbox1">{{ "Remember me" | translate }}</label>
              </div>
              <div class="form-row">
                <div class="col-sm-4">
                  <button
                    class="btn btn-primary btn-block btn-lg"
                    [class.loader--text]="authService.showLoader"
                    [disabled]="!loginForm.valid || authService.showLoader"
                    (click)="login()"
                    type="submit"
                  >
                    <span>{{
                      authService.showLoader ? "" : ("Login" | translate)
                    }}</span>
                  </button>
                </div>
                <div class="col-sm-8">
                  <div class="text-left mt-2 m-l-10">
                    <span (click)="enableTest()">{{
                      "Maybe?" | translate
                    }}</span>
                    <a
                      class="btn-link text-capitalize"
                      [routerLink]="['/register']"
                      >{{ "Register" | translate }}</a
                    >
                    {{ "or" | translate }}
                    <a
                      class="btn-link text-capitalize"
                      [routerLink]="['/reset-password']"
                      >{{ "Reset Password" | translate }}</a
                    >
                  </div>
                </div>
              </div>
              <div class="login-divider"></div>
            </form>
          </ng-container>
          <ng-container *ngIf="!browserSupport">
            <div class="text-center">
              <h4>{{ "Browser is not supported" | translate }}</h4>
              <h6>
                {{ "Please use latest" | translate }}
                <a target="_blank" href="https://www.google.com/chrome"
                  >Google Chrome</a
                >,
                <a target="_blank" href="https://www.microsoft.com/edge"
                  >Microsoft Edge</a
                >
                {{ "or" | translate }}
                <a target="_blank" href="https://www.apple.com/safari"
                  >Apple Safari</a
                >
                {{ "browser" | translate }}.
              </h6>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="footer">
  <app-footer></app-footer>
</footer>
