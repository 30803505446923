import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';
import { LocalIndexedDBStorageService } from '../services/local-indexeddb-storage.service';

@Injectable({
    providedIn: 'root'
})

export class SecureInnerPagesGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }
    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        try {
            let user = JSON.parse(await LocalIndexedDBStorageService.getItem('user'));
            if (await this.authService.isLoggedIn()) {
                window.alert("You are not allowed to access this URL!");
                this.router.navigate(['/dashboard/default']);
                return false;
            }
            return true;
        } catch (error) {
            console.error("Error in canActivate:", error);
            return false;
        }
    }
}