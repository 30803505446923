<div class="social mt-3">
    <div class="form-group btn-showcase d-flex">
        <button class="btn social-btn btn-fb d-inline-block" (click)="loginFacebook()">
            <i class="fa fa-2x fa-facebook"></i>
        </button>
        <button class="btn social-btn btn-twitter d-inline-block" (click)="loginTwitter()">
            <i class="fa fa-2x fa-twitter"></i>
        </button>
        <button class="btn social-btn btn-google d-inline-block" (click)="loginGoogle()">
            <i class="fa fa-2x fa-google"></i>
        </button>
        <!-- <button class="btn social-btn btn-google d-inline-block" (click)="loginYoutube()">
            <i class="fa fa-2x fa-youtube"></i>
        </button> -->
    </div>
</div>