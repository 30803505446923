import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Lesson,
  Video,
  QuestionAnswer,
  QuestionerAvatar,
  QuestionerAudioQuestion,
} from '../../services/constant.service';
import { AuthService } from '../../services/firebase/auth.service';
import {
  Component,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';

declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-questioner-avatar-management',
  templateUrl: './questioner-avatar-management.component.html',
  styleUrls: ['./questioner-avatar-management.component.scss'],
})
export class QuestionerAvatarManagementComponent implements OnInit {
  items: QuestionerAvatar[] = [];
  avatarShotModal = null;
  selectedEditAvatar: QuestionerAvatar = null;
  @ViewChild('avatarShotForm') avatarShotForm: TemplateRef<any>;
  @Output() avatarChange = new EventEmitter();
  public saveButtonRef = { label: 'Save' };

  constructor(
    public authService: AuthService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.items = cloneDeep(this.authService.schoolData.questionerAvatars);
  }

  avatarSelectedChange(avatar: QuestionerAvatar) {
    if (avatar) {
      this.selectedEditAvatar = null;

      this.authService.toastSubmit();
      this.authService.PushUserDataChanges().then(() => {
        this.items = cloneDeep(this.authService.schoolData.questionerAvatars);
        this.authService.toastSaved(this.saveButtonRef);
        if (this.avatarShotModal) {
          this.avatarShotModal.close();
        }
      });
    } else {
      if (this.avatarShotModal) {
        this.avatarShotModal.close();
      }
    }
  }

  addAvatar() {
    this.avatarShotModal = this.modalService.open(this.avatarShotForm, {
      size: 'md',
    });
  }

  editAvatar(avatar: QuestionerAvatar) {
    this.selectedEditAvatar = avatar;
    this.avatarShotModal = this.modalService.open(this.avatarShotForm, {
      size: 'md',
    });
  }

  withConfirmation(avatarId) {
    Swal.fire({
      title: this.translate.instant('Are you sure?'),
      text: this.translate.instant(
        "You won't be able to revert this! All audio questions using this questioner avatar will be set to use default avatar"
      ),
      type: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',
      confirmButtonText: this.translate.instant('Yes, delete it!'),
    }).then((result) => {
      if (result.value) {
        this.deleteAvatar(avatarId);
      }
    });
  }

  deleteAvatar(avatarId) {
    this.authService.toastSubmit();
    let deletingAvatar = this.authService.schoolData.questionerAvatars.find(
      (item) => {
        return item.id == avatarId;
      }
    );
    this.items = this.items.filter((item) => {
      return item.id != avatarId;
    });
    this.authService.schoolData.questionerAvatars =
      this.authService.schoolData.questionerAvatars.filter((item) => {
        return item.id != avatarId;
      });
    let submitted = false;
    let submit = () => {
      if (!submitted) {
        submitted = true;
        this.authService.schoolData.questionerAudioQuestions =
          this.authService.schoolData.questionerAudioQuestions.map(
            (questionAudio: QuestionerAudioQuestion) => {
              if (
                questionAudio.questionerAvatarId &&
                questionAudio.questionerAvatarId == avatarId
              ) {
                questionAudio.questionerAvatarId = 999;
              }
              return questionAudio;
            }
          );
        this.authService.PushUserDataChanges().then(() => {
          this.items = cloneDeep(this.authService.schoolData.questionerAvatars);
          this.authService.toastSaved(this.saveButtonRef);
        });
      }
    };
    if (
      deletingAvatar &&
      deletingAvatar.jpgURL.includes(this.authService.userData.uid)
    ) {
      this.authService.deleteFromURL(deletingAvatar.jpgURL, submit);
    } else {
      submit();
    }
  }

  cancel() {
    this.router.navigate(['/library/audio-question']);
  }
}
