<div class="dark-body-only" id="outer-container">
  <!-- page-wrapper Start-->
  <div class="page-wrapper" id="canvas-bookmark">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper">
      <div class="page-body">
        <div
          class="container"
          style="background-color: #fff; margin-top: 80px; padding: 3rem 2rem"
        >
          <div class="col-xs-12">
            <h3>
              {{
                "What is Youtube API key? And why you need to get one"
                  | translate
              }}
            </h3>
            <p>
              {{
                "Youtube API key works like a username/password to allow you to send search requests to Youtube, without it Youtube does not know who you are."
                  | translate
              }}<br />
              {{
                "Each API key can send maximum 100 search requests per day, this is also the reason why we cannot have just one API key to serve all our users."
                  | translate
              }}<br />
              {{
                "API key is only being used for public Youtube search. There is no access to your personal data in Youtube."
                  | translate
              }}
            </p>
            <h3>{{ "How to Get a Youtube API Key" | translate }}</h3>
            <ol>
              <li>
                {{
                  "If you don't have a Google account, you need to go to"
                    | translate
                }}
                <a href="https://accounts.google.com/signup" _target="_blank"
                  >https://accounts.google.com/signup</a
                >
                {{ "and create one first." | translate }}
              </li>
              <li>
                {{ "Log in to" | translate }}
                <a href="https://console.cloud.google.com/apis/dashboard"
                  >Google Console</a
                >
                {{ "using your Google account." | translate }}
              </li>
              <li>
                {{
                  "Once you log in, you'll automatically be redirected to Google Console Dashboard. If you never been there before, it should look like this"
                    | translate
                }}:<br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fget-yt-key%2Fgoogle_console_dashboard.JPG?alt=media&token=b4cc4bb1-86d8-48d5-ba7d-2cfa4ab1985d"
                />
              </li>
              <li>
                {{
                  "Tick agree to 'Terms of Service', then hit button 'AGREE AND CONTINUE'"
                    | translate
                }}:<br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fget-yt-key%2Fagree.JPG?alt=media&token=75987a23-356c-40e1-9db0-def06db216d6"
                />
              </li>
              <li>
                {{ "Click 'CREATE PROJECT'" | translate }}: <br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fget-yt-key%2Fcreate_project.JPG?alt=media&token=25958d3c-1393-4925-b3af-27abca8ee1f4"
                />
              </li>
              <li>
                {{
                  "Fill up the 'New Project' form as below and hit button 'CREATE'"
                    | translate
                }}:<br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fget-yt-key%2Fnew_project_form.JPG?alt=media&token=122d07cb-96c7-4d0d-ae3a-f94e4bf41851"
                />
              </li>
              <li>
                {{
                  "Once you created a project, you'll be transfer to project Dashboard. In search box at the top type 'YouTube Data API v3', then click to select the item showing the the below suggestion dropdown"
                    | translate
                }}:<br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fget-yt-key%2Fsearch_yt_api_v3.JPG?alt=media&token=f4361ea7-cbe9-4b30-8729-33561d3a6c16"
                />
              </li>
              <li>
                {{ "Hit button 'ENABLE'" | translate }}:<br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fget-yt-key%2Fenable_yt_v3.JPG?alt=media&token=51da2fc5-3193-4f22-88f7-1c1d289847d2"
                />
              </li>
              <li>
                {{ "Hit button 'CREATE CREENTIALS'" | translate }}:<br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fget-yt-key%2Fcreate_credential.JPG?alt=media&token=1afe5004-2830-4607-80e7-a424a9e6d041"
                />
              </li>
              <li>
                {{
                  "When 'Create credentials' form showing, please complete the form as below, and hit button 'Next'"
                    | translate
                }}:<br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fget-yt-key%2Fdone.JPG?alt=media&token=7fe054c3-6ab8-4a6f-ba2f-5eabdefd9661"
                />
              </li>
              <li>
                {{
                  "You'll see the API key right after you click next, copy this key"
                    | translate
                }}:<br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fget-yt-key%2Fkey_showing.JPG?alt=media&token=3340359b-9e0a-4c11-b545-3e0ff85a9f6e"
                />
              </li>
              <li>
                {{ "Go to our web application" | translate }}
                <a href="https://speaknplay.app/">https://speaknplay.app/</a>,
                {{ "login and open settings form" | translate }}.<br />
                {{
                  "You'll see the text box's name 'Youtube API Key' to enter the API Key you just got from Youtube. When it's done hit button 'Save'"
                    | translate
                }}.<br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fget-yt-key%2Fsave_api_key.JPG?alt=media&token=b2263264-6348-439a-a8dc-1b24e5e55220"
                />
              </li>
              <li>
                {{
                  "All done, now you can use our Youtube custom search"
                    | translate
                }}:<br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fget-yt-key%2Fcustom_search.JPG?alt=media&token=90ecd765-6536-4a8f-a3d0-b37e421b6ebd"
                />
              </li>
            </ol>
            <p>
              {{
                "Thank you for reading this, We are all ear to hear from you, if you have any questions please do not hestite to send us an email to"
                  | translate
              }}
              <a href="mailto:support@speaknplay.app">support@speaknplay.app</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Page Body End-->
  </div>
  <!-- page-wrapper End-->
</div>
<footer class="footer">
  <app-footer></app-footer>
</footer>
