import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalIndexedDBStorageService {
  private static dbName = 'speaknplay';
  private static storeName = 'data';
  private static db: IDBDatabase | null = null;

  constructor() {
    LocalIndexedDBStorageService.initDB();
  }

  private static initDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      if (LocalIndexedDBStorageService.db) {
        resolve(LocalIndexedDBStorageService.db);
        return;
      }

      const openRequest = indexedDB.open(
        LocalIndexedDBStorageService.dbName,
        1
      );

      openRequest.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        db.createObjectStore(LocalIndexedDBStorageService.storeName);
      };

      openRequest.onsuccess = (event) => {
        LocalIndexedDBStorageService.db = (
          event.target as IDBOpenDBRequest
        ).result;
        resolve(LocalIndexedDBStorageService.db);
      };

      openRequest.onerror = (event) => {
        reject((event.target as IDBOpenDBRequest).error);
      };
    });
  }

  private static getObjectStore(mode: IDBTransactionMode): IDBObjectStore {
    if (!LocalIndexedDBStorageService.db) {
      throw new Error('IndexedDB not initialized');
    }
    const transaction = LocalIndexedDBStorageService.db.transaction(
      LocalIndexedDBStorageService.storeName,
      mode
    );
    return transaction.objectStore(LocalIndexedDBStorageService.storeName);
  }

  static async setItem(key: string, value: any): Promise<void> {
    await LocalIndexedDBStorageService.initDB();
    return new Promise((resolve, reject) => {
      const objectStore =
        LocalIndexedDBStorageService.getObjectStore('readwrite');
      const request = objectStore.put(value, key);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  static async getItem(key: string): Promise<any> {
    await LocalIndexedDBStorageService.initDB();
    return new Promise((resolve, reject) => {
      const objectStore =
        LocalIndexedDBStorageService.getObjectStore('readonly');
      const request = objectStore.get(key);

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  static async removeItem(key: string): Promise<void> {
    await LocalIndexedDBStorageService.initDB();
    return new Promise((resolve, reject) => {
      const objectStore =
        LocalIndexedDBStorageService.getObjectStore('readwrite');
      const request = objectStore.delete(key);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }
}
