import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/firebase/auth.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  public show: boolean = true;
  private intCallback;

  constructor(private authService: AuthService) {
    // setTimeout(() => {
    //   this.show = false;
    // }, 3000);
    this.authService.observerAuthentication.subscribe((e) => {
      console.log('loader received push');
      this.show = false;
    });
    // this.intCallback = setInterval(() => {
    //   if (this.authService.receivedFirstCallbackFromFirebase) {
    //     setTimeout(() => {
    //       clearInterval(this.intCallback);
    //       this.show = false;
    //     }, 500)
    //   }
    // }, 1000);
  }

  ngOnInit() { }

  ngOnDestroy() { }

}
