<div class="dark-body-only" id="outer-container">
  <!-- page-wrapper Start-->
  <div class="page-wrapper" id="canvas-bookmark">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper">
      <div class="page-body">
        <div
          class="container"
          style="background-color: #fff; margin-top: 80px; padding: 3rem 2rem"
        >
          <div class="col-xs-12">
            <h2>{{ "Data Deletion" | translate }}</h2>
            <br />
            <h5 style="color: red">
              {{ "Please note data deletion cannot be reverted!" | translate }}
            </h5>
            <p>
              {{
                "After you delete your data, everything related to your account included your Explorer account membership will be permanent deleted. If you wish to proceed to delete your data, please follow below steps:"
                  | translate
              }}
            </p>
            <ol>
              <li>
                <strong>{{ "Login and click `Settings`" | translate }}</strong
                ><br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fclick_setting.PNG?alt=media&token=047c3be2-6717-4f86-bc67-4179349f4b0d"
                />
                <br /><br />
                <strong>{{
                  "If you are currently in management page, click `Settings' on the top right of that page:"
                    | translate
                }}</strong
                ><br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fmanagement_setting.PNG?alt=media&token=cb83de9a-53c8-4b19-be0f-1d94317f8296"
                />
              </li>
              <li>
                <strong>{{
                  "When setting pop-up showing, you will see the delete button at the end of the pop-up:"
                    | translate
                }}</strong
                ><br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fsetting_popup.PNG?alt=media&token=fe62ce09-b84c-4330-8e5f-ccff233ad7d8"
                />
              </li>
              <li>
                <strong>{{
                  "Click `Delete account`, then you will see a confirmation box:"
                    | translate
                }}</strong
                ><br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fdelete_account_popup.PNG?alt=media&token=34769fc0-ef49-44bf-bdbd-1b0f18d2f2f3"
                />
              </li>
              <li>
                <strong>{{
                  "Type `delete me` in the text input to confirm and click button `Delete account`"
                    | translate
                }}</strong
                ><br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Ftype_delete_me.PNG?alt=media&token=83ce93d4-29f8-4507-8889-e6dc9f3bb220"
                />
              </li>
              <li>
                <strong>{{
                  "After clicked `Delete account` button you will see this:"
                    | translate
                }}</strong
                ><br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fdelete_success.PNG?alt=media&token=2523213a-514e-412b-97b6-75c7d7341b2c"
                />
                <br /><br />
                <strong>{{
                  "Unless your last login is not within 5 minutes, you will see this error:"
                    | translate
                }}</strong
                ><br /><br />
                <img
                  src="https://storage.speaknplay.app/internal%2Fdelete_account_failed.PNG?alt=media&token=036172eb-c4c1-45c3-bcc5-9351e9d7a4c7"
                />
                <br /><br />
                <strong>{{
                  "You will need to logout and login again then following the above steps."
                    | translate
                }}</strong>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <!-- footer start-->
      <!-- footer End-->
    </div>
    <!-- Page Body End-->
  </div>
  <!-- page-wrapper End-->
</div>
<footer class="footer">
  <app-footer></app-footer>
</footer>
