import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import * as firebase from "firebase";
import * as moment from 'moment';
import {
  QuestionerAudioQuestion,
  QuestionerAvatar,
} from 'src/app/services/constant.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { NavService } from 'src/app/services/nav.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { R2Service } from 'src/app/services/r2.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-questioner-audio-question',
  templateUrl: './questioner-audio-question.component.html',
  styleUrls: ['./questioner-audio-question.component.scss'],
})
export class NewQuestionerAudioQuestionComponent implements OnInit {
  @Input() questionerAudioQuestion: QuestionerAudioQuestion = null;
  @Output() questionerAudioQuestionUpdate = new EventEmitter();
  public dataURL: string = null;
  public audioDownloadURL;
  public isRecording = false;
  public isPlayingRecord = false;
  public hasRecordAudio = false;
  public questionDuration = null;
  public selectedAvatarId = 999;
  public form: FormGroup;
  public avatarShotForm;
  avatarShotModal = null;

  constructor(
    public navService: NavService,
    public authService: AuthService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private r2Service: R2Service
  ) {}

  ngOnInit(): void {
    if (this.questionerAudioQuestion) {
      this.audioDownloadURL = this.questionerAudioQuestion.audioURL;
      this.hasRecordAudio = true;
      this.dataURL = this.questionerAudioQuestion.audioURL;
      this.form = this.fb.group({
        name: [this.questionerAudioQuestion.name, Validators.required],
        questionerAvatar: [this.questionerAudioQuestion.questionerAvatarId],
      });
    } else {
      this.form = this.fb.group({
        name: ['', Validators.required],
        questionerAvatar: ['999'],
      });
    }
  }

  clearStopRecordingInt = 0;

  playPauseClick(elementClass) {
    let audio: any = document.getElementById('testQuestionerAudioQuestion');
    let playPauseElement = document.querySelector('.' + elementClass);
    if (this.isPlayingRecord) {
      this.isPlayingRecord = false;
      playPauseElement.classList.remove('pause');
      playPauseElement.classList.add('play');
      audio.pause();
    } else {
      this.isPlayingRecord = true;
      playPauseElement.classList.remove('play');
      playPauseElement.classList.add('pause');
      audio.src = this.dataURL;
      audio.onended = () => {
        playPauseElement.classList.remove('pause');
        playPauseElement.classList.add('play');
      };
      audio.play();
    }
  }

  playDataURL() {
    if (!window['recorder'] || !window['recorder'].getBlob()) return;

    window['recorder'].getDataURL((dataURL) => {
      let audio: any = document.getElementById('testQuestionerAudioQuestion');
      audio.src = dataURL;
      this.dataURL = dataURL;
      audio.play();
      audio.onplay = () => {
        this.questionDuration = new Date().getTime();
      };
      audio.onended = () => {
        this.questionDuration =
          (new Date().getTime() - this.questionDuration) / 1000;
        document.querySelector('.form-play-pause').classList.add('play');
        this.hasRecordAudio = true;
      };
      if (window['microphone']) {
        window['microphone'].stop();
        window['microphone'] = null;
      }
    });
    return;
  }

  stopRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      document.getElementById('record-button').classList.remove('active');
      window['recorder'].stopRecording(() => {
        this.hasRecordAudio = true;
        this.playDataURL();
      });
    }
    // if (window['microphone']) {
    //   window['microphone'].getTracks().forEach((track) => {
    //     track.stop();
    //   });
    //   window['microphone'] = null;
    // }
    if (window['ajustedVolumeMediaStream']) {
      window['ajustedVolumeMediaStream'].getTracks().forEach((track) => {
        track.stop();
      });
      window['ajustedVolumeMediaStream'] = null;
    }
  }

  /**
   * It record and save it to window['recorder']
   */
  recordButtonClick() {
    if (this.isRecording) {
      clearTimeout(this.clearStopRecordingInt);
      this.stopRecording();
    } else {
      this.isRecording = true;
      this.hasRecordAudio = false;
      document.getElementById('record-button').classList.add('active');
      if (!window['microphone']) {
        window['captureMicrophone']((mic) => {
          window['microphone'] = mic;
          if (window['isSafari']) {
            this.recordButtonClick();
            alert(
              this.translate.instant(
                'Please click startRecording button again. First time we tried to access your microphone. Now we will record it.'
              )
            );
            return;
          }
          var options = {
            type: 'audio',
            numberOfAudioChannels: window['isEdge'] ? 1 : 2,
            checkForInactiveTracks: true,
            bufferSize: 16384,
          };

          if (window['isSafari'] || window['isEdge']) {
            options['recorderType'] = window['StereoAudioRecorder'];
          }

          if (
            navigator.platform &&
            navigator.platform.toString().toLowerCase().indexOf('win') === -1
          ) {
            options['sampleRate'] = 48000; // or 44100 or remove this line for default
          }

          if (window['isSafari']) {
            options['sampleRate'] = 44100;
            options['bufferSize'] = 4096;
            options.numberOfAudioChannels = 2;
          }

          if (window['recorder']) {
            window['recorder'].destroy();
            window['recorder'] = null;
          }

          window['recorder'] = window['RecordRTC'](
            window['microphone'],
            options
          );
          window['recorder'].onStateChanged((state) => {
            console.log(state);
          });
          window['recorder'].startRecording();
          this.clearStopRecordingInt = setTimeout(() => {
            if (this.isRecording) {
              this.stopRecording();
              this.navService.showMessage(
                this.translate.instant('Maximum 3 seconds reached')
              );
            }
          }, 3000);
          return;
        }, this.authService.userData.setting.recordingVolume);
      }
    }
  }

  closeIt() {
    this.questionerAudioQuestionUpdate.emit(null);
  }

  async useIt() {
    // check for existing
    if (
      this.authService.schoolData.questionerAudioQuestions.filter((qa) => {
        // remove ending spaces and question marks for comparison
        const qaName = qa.name
          .replace(/\?+$/, '')
          .replace(/\s+$/, '')
          .toLowerCase();
        const formName = this.form.value.name
          .replace(/\?+$/, '')
          .replace(/\s+$/, '')
          .toLowerCase();
        return qaName === formName;
      }).length > 0
    ) {
      this.navService.showMessage(
        this.translate.instant('This question is already exists')
      );
      return;
    }
    if (this.dataURL.includes('https')) {
      this.authService.toastSubmit();
      this.authService.schoolData.questionerAudioQuestions.map((qa) => {
        if (qa.id == this.questionerAudioQuestion.id) {
          qa.name = this.form.value.name;
          qa.questionerAvatarId = Number(this.selectedAvatarId);
        }
        return qa;
      });
      this.authService.PushUserDataChanges().then(() => {
        this.authService.toastSaved();
        this.questionerAudioQuestionUpdate.emit(null);
      });
    } else {
      let path =
        'audio/' +
        this.authService.userData.uid +
        '/' +
        moment().valueOf() +
        '.mp3';
      let downloadURL = environment.r2BaseURL + '/' + path;
      await this.r2Service.uploadFile(this.dataURL, path, 1);
      let progressionToast = this.authService.toastSubmit();
      let questionerAudioQuestionId = 0;
      if (this.questionerAudioQuestion) {
        questionerAudioQuestionId = this.questionerAudioQuestion.id;
      } else {
        if (this.authService.schoolData.questionerAudioQuestions.length > 0) {
          questionerAudioQuestionId = Math.max(
            ...this.authService.schoolData.questionerAudioQuestions.map(
              (question) => question.id
            )
          );
        }
        questionerAudioQuestionId++;
      }

      let emitAudioQuestion = null;
      let deleteURL = null;
      if (this.questionerAudioQuestion) {
        deleteURL = this.questionerAudioQuestion.audioURL;
        this.questionerAudioQuestion.audioURL = downloadURL;
        this.questionerAudioQuestion.name = this.form.value.name;
        this.authService.schoolData.questionerAudioQuestions.map((qa) => {
          if (qa.id == this.questionerAudioQuestion.id) {
            qa.audioURL = downloadURL;
            qa.name = this.form.value.name;
            qa.questionerAvatarId = Number(this.selectedAvatarId);
          }
          return qa;
        });
        emitAudioQuestion = this.questionerAudioQuestion;
      } else {
        let newQuestionerAudioQuestion: QuestionerAudioQuestion = {
          id: questionerAudioQuestionId + 1,
          audioURL: downloadURL,
          name: this.form.value.name,
          questionerAvatarId: Number(this.selectedAvatarId),
          questionDuration: this.questionDuration,
          disabled: false,
        };
        emitAudioQuestion = newQuestionerAudioQuestion;
        this.authService.schoolData.questionerAudioQuestions.push(
          newQuestionerAudioQuestion
        );
      }

      this.authService.PushUserDataChanges().then(async () => {
        this.questionerAudioQuestionUpdate.emit(emitAudioQuestion);
        this.toastrService.clear();
        this.toastrService.success(this.translate.instant('Done'), '', {
          extendedTimeOut: 3000,
          timeOut: 5000,
          enableHtml: true,
          tapToDismiss: false,
        });

        if (this.questionerAudioQuestion) {
          await this.authService.deleteFromURL(deleteURL);
        }
      });
      // var storageRef = firebase.storage().ref();

      // var metadata = {
      //   cacheControl: 'public, max-age=31536000', // Cache for one year
      // };
      // let uploadTask = storageRef
      //   .child('audio/' + path)
      //   .putString(this.dataURL, 'data_url', metadata);
      // let progress = 0;
      // uploadTask.on(
      //   firebase.storage.TaskEvent.STATE_CHANGED,
      //   (snapshot) => {
      //     progress = Math.floor(
      //       (snapshot.bytesTransferred / snapshot.totalBytes) * 50
      //     );
      //     console.log('Upload is ' + progress + '% done');
      //     if (progress > 0) {
      //       (<any>progressionToast).toastRef.componentInstance.updateProgress =
      //         () => {
      //           (<any>progressionToast).toastRef.componentInstance.width =
      //             progress;
      //         };
      //     }
      //   },
      //   (error) => {
      //     console.log('error: ', error);
      //   },
      //   () => {
      //     // Upload completed successfully, now we can get the download URL
      //     uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      //       (<any>progressionToast).toastRef.componentInstance.updateProgress =
      //         () => {
      //           (<any>progressionToast).toastRef.componentInstance.width = 70;
      //         };

      //       let questionerAudioQuestionId = 0;
      //       if (this.questionerAudioQuestion) {
      //         questionerAudioQuestionId = this.questionerAudioQuestion.id;
      //       } else {
      //         if (
      //           this.authService.schoolData.questionerAudioQuestions.length > 0
      //         ) {
      //           questionerAudioQuestionId = Math.max(
      //             ...this.authService.schoolData.questionerAudioQuestions.map(
      //               (question) => question.id
      //             )
      //           );
      //         }
      //         questionerAudioQuestionId++;
      //       }

      //       let emitAudioQuestion = null;
      //       let deleteURL = null;
      //       if (this.questionerAudioQuestion) {
      //         deleteURL = this.questionerAudioQuestion.audioURL;
      //         this.questionerAudioQuestion.audioURL = downloadURL;
      //         this.questionerAudioQuestion.name = this.form.value.name;
      //         this.authService.schoolData.questionerAudioQuestions.map((qa) => {
      //           if (qa.id == this.questionerAudioQuestion.id) {
      //             qa.audioURL = downloadURL;
      //             qa.name = this.form.value.name;
      //             qa.questionerAvatarId = Number(this.selectedAvatarId);
      //           }
      //           return qa;
      //         });
      //         emitAudioQuestion = this.questionerAudioQuestion;
      //       } else {
      //         let newQuestionerAudioQuestion: QuestionerAudioQuestion = {
      //           id: questionerAudioQuestionId + 1,
      //           audioURL: downloadURL,
      //           name: this.form.value.name,
      //           questionerAvatarId: Number(this.selectedAvatarId),
      //           questionDuration: this.questionDuration,
      //           disabled: false,
      //         };
      //         emitAudioQuestion = newQuestionerAudioQuestion;
      //         this.authService.schoolData.questionerAudioQuestions.push(
      //           newQuestionerAudioQuestion
      //         );
      //       }

      //       this.authService.PushUserDataChanges().then(() => {
      //         (<any>(
      //           progressionToast
      //         )).toastRef.componentInstance.updateProgress = () => {
      //           (<any>progressionToast).toastRef.componentInstance.width = 90;
      //         };
      //         let cb = () => {
      //           (<any>(
      //             progressionToast
      //           )).toastRef.componentInstance.updateProgress = () => {
      //             (<any>(
      //               progressionToast
      //             )).toastRef.componentInstance.width = 100;
      //           };
      //           this.questionerAudioQuestionUpdate.emit(emitAudioQuestion);
      //           this.toastrService.clear();
      //           this.toastrService.success(this.translate.instant('Done'), '', {
      //             extendedTimeOut: 3000,
      //             timeOut: 5000,
      //             enableHtml: true,
      //             tapToDismiss: false,
      //           });
      //         };
      //         if (this.questionerAudioQuestion) {
      //           this.authService.deleteFromURL(deleteURL, () => {
      //             cb();
      //           });
      //         } else {
      //           cb();
      //         }
      //       });
      //     });
      //   }
      // );
    }
  }

  openAvatarShotModal(content) {
    this.avatarShotModal = this.modalService.open(content, { size: 'md' });
  }

  questionnerAvatarUpdate(questionerAvatar: QuestionerAvatar) {
    if (questionerAvatar) {
      this.form.controls['questionerAvatar'].setValue(questionerAvatar.id);
      this.selectedAvatarId = questionerAvatar.id;
    }
    if (this.avatarShotModal) {
      this.avatarShotModal.close();
    }
  }

  pickedQuestionerAvatar(questionerAvatar: any) {
    this.selectedAvatarId = questionerAvatar;
  }
}
