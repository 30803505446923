import { Component, EventEmitter, OnInit, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit, OnChanges {
  public thumbnailFile: File;
  @Input() customThumbnailUrl: string = null;
  @Input() expectedWidth: number = null;
  @Input() expectedHeight: number = null;
  @Input() showPreview: boolean = true;
  @Input() resetSelection: boolean = false;
  @Output() thumbnailChanged = new EventEmitter<{ type: string, url: string, origin?: string }>();
  @Input() aspectRatio = 16 / 9;
  @Input() resizeToWidth = 320;
  @Input() readonly = false;
  public file: File;
  constructor(private toastr: ToastrService,
    private translate: TranslateService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    // console.log(this.customThumbnailUrl);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.showPreview) {
      if (changes.customThumbnailUrl && changes.customThumbnailUrl.currentValue) {
        this.customThumbnailUrl = changes.customThumbnailUrl.currentValue;
      }

      if (changes.resetSelection && changes.resetSelection.currentValue) {
        this.thumbnailFile = null;
        this.file = null;
      }
    }
  }

  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    const file: File | null = target.files?.[0] || null;
    this.thumbnailFile = file;
  }

  loadImageFailed() {
    this.toastr.error(this.translate.instant('Failed to load image'));
  }
  imageCroppedBase64(base64Image: string) {
    if (this.showPreview) {
      this.customThumbnailUrl = base64Image;
    }
    this.thumbnailChanged.emit({ type: 'CROP', url: base64Image, origin: null });
  }

  removeThumbnail() {
    if (this.showPreview && this.customThumbnailUrl && !this.customThumbnailUrl.includes('data:image')) {
      this.thumbnailChanged.emit({ type: 'DELETE', url: this.customThumbnailUrl, origin: null });
      this.thumbnailFile = null;
      this.customThumbnailUrl = null;
      this.file = null;
    } else if (this.customThumbnailUrl.includes('data:image')) {
      this.customThumbnailUrl = null;
      this.thumbnailFile = null;
      this.file = null;
    }
  }

}
