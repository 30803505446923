<div class="mediaWrapper" id="mediaWrapper" class="invisible">
  <youtube-player
    [videoId]="authService.getOriginalYID(localInput.yid)"
    suggestedQuality="highres"
    [playerVars]="
      showToolbar
        ? {
            autoplay: 1,
            loop: 1,
            rel: 0,
            showinfo: 0,
            disablekb: 1,
            modestbranding: 1
          }
        : {
            autoplay: 1,
            autohide: 1,
            controls: 0,
            disablekb: 1,
            loop: 1,
            modestbranding: 1,
            rel: 0,
            showinfo: 0
          }
    "
    (ready)="playerReady($event)"
    (stateChange)="onPlayerStateChange($event)"
    (apiChange)="onPlayerStateChange($event)"
    (playbackRateChange)="onPlayerStateChange($event)"
    (playbackQualityChange)="onPlayerStateChange($event)"
  >
  </youtube-player>
  <div
    class="bar-container"
    *ngIf="
      localDurationSkipBarsStatus && localDurationSkipBarsStatus.length > 0
    "
  >
    <ng-container
      *ngFor="let i of localDurationSkipBarsStatus; let idx = index"
    >
      <!-- if supported mobile add following to the following line (panstart)="onPanStart($event);" (panmove)="onPanMove($event)" (panend)="onPanEnd($event)" -->
      <div
        class="bar-{{ idx }} bar noselect {{
          localDurationSkipBarsStatus[idx].disabled ? 'muted-bar' : ''
        }} {{
          currentPlayTime >= localDurationSkipBarsStatus[idx].from &&
          currentPlayTime < localDurationSkipBarsStatus[idx].to
            ? 'playing-bar'
            : ''
        }}"
        [style.width]="barWidth + '%'"
        (mousedown)="onMouseDown(idx, $event)"
        (mousemove)="onMouseMove(idx, $event)"
        (mouseup)="onMouseUp(idx, $event)"
        [title]="
          youtubeService.formatTime(localDurationSkipBarsStatus[idx].from) +
          '-' +
          youtubeService.formatTime(localDurationSkipBarsStatus[idx].to)
        "
        [style.cursor]="readonly ? 'inherit' : 'pointer'"
      >
        &nbsp;
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="showToolbar">
    <div class="row m-t-10 m-b-10">
      <div class="col-12 text-left">
        {{ "Volume:" | translate }} <strong>{{ localInput.volume }}</strong
        >, {{ "Muted:" | translate }}
        <strong>{{
          localInput.mute ? ("Yes" | translate) : ("No" | translate)
        }}</strong>
        <app-info
          name="preview_sound"
          class="mt-2 ml-1 d-inline-block"
        ></app-info>
      </div>
    </div>
    <hr />
    <div class="row m-t-10 m-b-10" *ngIf="!readonly">
      <div class="col-12 text-center">
        <button
          type="button"
          class="btn btn-danger m-b-10"
          (click)="openQuestionAnswerForm(formContent, true)"
        >
          {{ askQuestion }}
        </button>
      </div>
    </div>
    <div class="question-answer-list">
      <table class="table table-responsive-sm">
        <thead>
          <tr>
            <th scope="col">
              {{ "Time" | translate }} <app-info name="question_at"></app-info>
            </th>
            <th scope="col">
              {{ "Audio" | translate }}
              <app-info name="answer_question"></app-info>
            </th>
            <th>
              {{ "Question Name" | translate }}
            </th>
            <th scope="col">
              {{ "Answer" | translate }}
              <app-info name="question_answer"></app-info>
            </th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let questionAnswer of questionAnswersFormArray.controls;
              let i = index
            "
          >
            <tr
              [formGroup]="getQuestionAnswer(questionAnswer)"
              class="{{ isDisabledQuestionAnswer(i) ? 'disabled-row' : '' }}"
            >
              <th scope="row">
                <input
                  class="form-control d-inline-block"
                  formControlName="at"
                  style="max-width: 80px; width: 80%"
                  [readonly]="readonly"
                />
                <app-info
                  [infoText]="
                    explainAtInfo(questionAnswer['controls'].at.value)
                  "
                ></app-info>
              </th>
              <td>
                <div class="player-container" style="margin-top: -5px">
                  <div
                    class="play play-pause question-list-item question{{ i }}"
                    (click)="
                      playAudioQuestion(
                        'question' + i,
                        questionAnswer['controls'].question.value
                      )
                    "
                  >
                    Play
                  </div>
                </div>
              </td>
              <td style="padding-top: 18px">
                {{
                  authService.getAudioName(
                    questionAnswer["controls"].question.value
                  )
                }}
              </td>

              <td>
                <input
                  class="form-control answer-list-item"
                  formControlName="answer"
                  [readonly]="readonly"
                />
              </td>
              <td style="font-size: 20px">
                <ng-container *ngIf="!readonly">
                  <i
                    class="fa fa-edit mr-2"
                    style="cursor: pointer"
                    (click)="editQuestion(i)"
                  ></i>
                  <i
                    class="fa fa-times mr-2"
                    style="cursor: pointer"
                    (click)="deleteQuestionAnswer(i)"
                  ></i>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <hr />
    <div class="row m-t-10 m-b-10" *ngIf="!readonly">
      <div class="col-12 text-left">
        <strong
          >{{ "Custom Video Thumbnail" | translate }}
          <app-info
            name="custom_video_thumbnail"
            class="mt-2 ml-1 d-inline-block"
          ></app-info
        ></strong>
      </div>
      <div class="col-12">
        <app-upload-image
          (thumbnailChanged)="handleThumbnailChanged($event)"
          [resetSelection]="resetSelectedThumbnailFile"
          [customThumbnailUrl]="localInput.customThumbnailUrl"
        ></app-upload-image>
      </div>
    </div>
  </ng-container>
</div>
<div class="container-fluid" *ngIf="showToolbar">
  <div class="row position-relative" style="height: 30px">
    <div style="right: 0" class="position-absolute">
      <button
        type="button"
        class="btn btn-primary m-r-5 pl-3 pr-3"
        (click)="save()"
        *ngIf="!readonly"
      >
        {{
          isPreviewForAdding
            ? ("Use it" | translate)
            : ("Quick save" | translate)
        }}
      </button>
      <button
        *ngIf="!isPreviewForAdding && !readonly"
        type="button"
        class="btn btn-warning m-r-5 pl-3 pr-3"
        (click)="save(true)"
      >
        {{ "Save and close" | translate }}
      </button>
      <button type="button" class="btn btn-light pl-3 pr-3" (click)="closeIt()">
        {{ "Close" | translate }}
      </button>
    </div>
  </div>
</div>
<audio id="testaudio" autoplay="false" #playPause></audio>
<!-- Ask question form -->
<ng-template #formContent let-modal>
  <div class="modal-body" id="modalBody">
    <form class="theme-form" [formGroup]="form">
      <div class="form-group">
        <label class="col-form-label pt-0"
          >{{ "Select an audio question." | translate }}
          <app-info name="questioner_audio"></app-info
        ></label>
        <button
          class="btn btn-danger btn-xs float-right active"
          type="button"
          placement="top"
          (click)="openQuestionerAudioQuestionModal(questionerAudioForm)"
          style="position: relative; z-index: 999"
        >
          {{ "Create" | translate }}
        </button>

        <!-- <label class="col-form-label pt-0">Questioner audio question <app-info name="questioner_audio"></app-info>
        </label>

        <button class="btn btn-light btn-xs float-right active" type="button" placement="top"
          (click)="openQuestionerAudioQuestionModal(questionerAudioForm)" style="position:relative;z-index:999">New questioner audio</button> -->
        <ng-select
          formControlName="question"
          (change)="pickedQuestionerAudioQuestion($event)"
          [placeholder]="'Select a question' | translate"
          [multiple]="false"
          [virtualScroll]="true"
          [items]="localQuestionnerQuestions"
          bindLabel="name"
          bindValue="id"
        >
        </ng-select>
        <!-- <select class="form-select mt-1" formControlName="questionerAvatar"
          (change)="pickedQuestionerAvatar($event.target['value'])">
          <option value="999" selected>Default (A question mark)</option>
          <ng-container *ngFor="let questionerAudioQuestion of authService.questionnerQuestions">
            <option [value]="questionerAudioQuestion.id">{{questionerAudioQuestion.name}}</option>
          </ng-container>
        </select> -->
      </div>
      <div class="form-group">
        <label class="col-form-label pt-0"
          >{{ "Expecting Answer" | translate }}
          <app-info name="question_answer"></app-info
        ></label>
        <input
          class="form-control"
          formControlName="answer"
          type="text"
          required=""
          maxlength="50"
        />
        <div
          *ngIf="
            form.controls.answer.touched &&
            form.controls.answer.errors?.required
          "
          class="text text-danger mt-1"
        >
          {{ "Answer is required" | translate }}
        </div>
        <div
          *ngIf="
            form.controls.answer.touched && form.controls.answer.errors?.answer
          "
          class="text text-danger mt-1"
        >
          {{ "Invalid answer" | translate }}
        </div>
      </div>

      <div class="form-group form-row mt-3 mb-0">
        <button
          class="btn btn-primary mr-2"
          type="button"
          placement="top"
          (click)="add(form.value)"
          type="submit"
          *ngIf="!editQuestionAnswer"
          [disabled]="!form.value.question || !form.value.answer"
        >
          {{ "Add" | translate }}
        </button>
        <button
          class="btn btn-primary mr-2"
          type="button"
          placement="top"
          (click)="edit(form.value)"
          type="submit"
          *ngIf="editQuestionAnswer"
          [disabled]="!form.value.question || !form.value.answer"
        >
          {{ "Save" | translate }}
        </button>
        <button
          class="btn btn-light"
          type="button"
          placement="top"
          (click)="cancel()"
          title=""
        >
          {{ "Cancel" | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #questionerAudioForm>
  <div class="modal-body">
    <app-questioner-audio-question
      (questionerAudioQuestionUpdate)="questionnerAudioQuestionUpdate($event)"
    >
    </app-questioner-audio-question>
  </div>
</ng-template>
