<div
  class="container-fluid position-relative"
  style="height: 100%; background-color: #fff; position: relative"
  [style.top]="ylist && ylist.length > 0 ? '-10px' : '30px'"
  [ngClass]="{ 'page-search': newOnPopup }"
>
  <div class="row mb-3">
    <div class="col-sm-12 col-md-6">
      <!-- <app-breadcrumb></app-breadcrumb> -->
    </div>
    <div class="col-sm-12 col-md-6">
      <!-- <div
        class="btn-group btn-group-toggle tab-menu"
        ngbRadioGroup
        name="radioBasic"
        [(ngModel)]="tabName"
      >
        <label
          ngbButtonLabel
          class="btn-light btn-square"
          style="height: 2.2rem"
        >
          <input ngbButton type="radio" [value]="'suggestion'" />
          {{ "Suggestions Videos" | translate }}
        </label>
        <label
          ngbButtonLabel
          class="btn-light btn-square"
          style="height: 2.2rem"
        >
          <input ngbButton type="radio" [value]="'search'" />
          {{ "Youtube Search" | translate }}
        </label>
      </div> -->
    </div>
  </div>
  <div class="row pl-3 pr-3 mb-2">
    <ng-container *ngIf="tabName == 'search'">
      <!-- <div class="faq-form float-left  m-r-5 m-b-5 suggest-search-form" *ngIf="!hasYoutubeAPIKey && showSearch"
        style="width:100%;max-width:800px">
        <input type="text" class="form-control"
          placeholder="Enter youtube video id, example: U7zARByAu1c or https://www.youtube.com/watch?v=0jmXcfRXjDI"
          [(ngModel)]="query" />
      </div> -->
      <div
        class="faq-form float-left m-r-5 m-b-5 suggest-search-form"
        *ngIf="hasYoutubeAPIKey && showSearch"
        style="min-width: 200px; max-width: 400px"
      >
        <div class="form-group mb-0">
          <input
            id="typeahead-http"
            type="text"
            class="form-control"
            [class.is-invalid]="searchFailed"
            [(ngModel)]="query"
            [ngbTypeahead]="youtubeService.searchYoutube"
            placeholder="{{ 'Eg:' | translate }} Dinosaur, U7zARByAu1c"
            maxlength="200"
          />
          <!-- (selectItem)="search($event.item)" -->
        </div>
      </div>
      <ng-container *ngIf="hasYoutubeAPIKey">
        <select
          class="form-control m-r-5 m-b-5"
          *ngIf="showSearch"
          style="width: 110px"
          (change)="updateType($event.target['value'])"
          [(ngModel)]="videoChannelType"
        >
          <option value="">{{ "Type" | translate }}</option>
          <option value="channel">{{ "Channel" | translate }}</option>
          <option value="video">{{ "Video" | translate }}</option>
          <option value="playlist">{{ "Playlist" | translate }}</option>
          <option value="movie">{{ "Movie" | translate }}</option>
          <option value="episode">{{ "Show" | translate }}</option>
        </select>
        <select
          class="form-control m-r-5 m-b-5"
          *ngIf="
            showSearch &&
            clickedChannels.length > 0 &&
            videoChannelType != 'channel'
          "
          style="width: 150px"
          [(ngModel)]="currentChannelId"
        >
          <option value="">{{ "Any channels" | translate }}</option>
          <ng-container *ngFor="let clickedChannel of clickedChannels">
            <option [value]="clickedChannel.id">
              {{ clickedChannel.title }}
            </option>
          </ng-container>
        </select>
        <select
          class="form-control m-r-5 m-b-5"
          *ngIf="showSearch"
          style="width: 140px"
          (change)="updateDateFilter($event.target['value'])"
          [(ngModel)]="publishedDate"
        >
          <option value="">{{ "Upload date" | translate }}</option>
          <option value="last_hour">{{ "Last hour" | translate }}</option>
          <option value="today">{{ "Today" | translate }}</option>
          <option value="this_week">{{ "This week" | translate }}</option>
          <option value="this_month">{{ "This month" | translate }}</option>
          <option value="this_year">{{ "This year" | translate }}</option>
        </select>
        <select
          class="form-control m-r-5 m-b-5"
          *ngIf="showSearch"
          style="width: 170px"
          (change)="updateDuration($event.target['value'])"
          [(ngModel)]="videoDuration"
        >
          <option value="">{{ "Video duration" | translate }}</option>
          <option value="long">{{ "Long (>20 mins)" | translate }}</option>
          <option value="medium">
            {{ "Medium (4 - 20 mins)" | translate }}
          </option>
          <option value="short">{{ "Short (<4 mins)" | translate }}</option>
        </select>
        <select
          class="form-control m-r-5 m-b-5"
          *ngIf="showSearch"
          style="width: 130px"
          (change)="updateOrder($event.target['value'])"
          [(ngModel)]="order"
        >
          <option value="">{{ "Sort by" | translate }}</option>
          <option value="date">{{ "Date" | translate }}</option>
          <option value="rating">{{ "Rating" | translate }}</option>
          <option value="relevance">{{ "Relevance" | translate }}</option>
          <option value="videoCount">{{ "Video count" | translate }}</option>
          <option value="viewCount">{{ "View count" | translate }}</option>
        </select>
        <div
          class="checkbox m-r-10 m-l-10"
          *ngIf="
            showSearch &&
            videoChannelType != 'channel' &&
            videoChannelType != 'playlist'
          "
        >
          <input
            type="checkbox"
            id="videoCaption"
            (change)="caption = $event.target['checked']"
          />
          <label for="videoCaption">{{ "Has subtitle" | translate }}</label>
        </div>
      </ng-container>

      <button
        class="btn btn-primary m-r-5 m-b-5"
        *ngIf="showSearch && hasYoutubeAPIKey"
        type="button"
        (click)="
          search(
            query,
            '',
            '',
            '',
            '',
            publishedAfter,
            publishedBefore,
            type,
            videoType,
            videoDuration,
            order
          )
        "
      >
        {{ "Find Creative Commons Videos" | translate }}
      </button>
      <app-info class="mr-2 mt-2" name="creative_commons_videos"></app-info>
      <select
        class="form-control m-b-5 m-r-5"
        *ngIf="hasYoutubeAPIKey && showSearch"
        (change)="setCurrentSearchTabInfo($event.target['value'])"
        style="max-width: 250px"
        [(ngModel)]="selectedHistoryIndex"
      >
        <option value="-1">
          {{ "Or select from search history" | translate }}
        </option>
        <ng-container
          *ngFor="let searchState of searchStateList; let i = index"
        >
          <option [value]="i">
            {{ searchState.stateLabel }}
            ({{ formatDate(null, searchState.stateDate) }})
          </option>
        </ng-container>
      </select>
    </ng-container>
    <ng-container *ngIf="tabName == 'suggestion'">
      <div
        class="faq-form float-left m-r-5 m-b-5 suggest-search-form"
        *ngIf="showSearch"
        style="width: 100%; max-width: 800px"
      >
        <input
          type="text"
          class="form-control"
          placeholder="{{
            'Enter:' | translate
          }} Dinasour, Shark or https://youtube.com/watch?v=0jmXcfRXjDI or U7zARByAu1c"
          [formControl]="suggestQueryControl"
          maxlength="200"
        />
      </div>
      <select
        class="form-control m-b-5"
        *ngIf="showSearch"
        (change)="selectSuggestedChannel($event.target['value'])"
        style="max-width: 300px"
      >
        <option value="all">{{ "All videos" | translate }}</option>
        <ng-container
          *ngFor="let suggestedChannel of suggestedChannels; let i = index"
        >
          <option [value]="suggestedChannel.id">
            {{ suggestedChannel.name }}
          </option>
        </ng-container>
      </select>
    </ng-container>
  </div>
  <div class="row" *ngIf="isLoadingData">
    <!-- <div class="loader-box"></div> -->
    <div class="col-12 mt-3">
      <div class="loader">
        <div class="line bg-primary"></div>
        <div class="line bg-primary"></div>
        <div class="line bg-primary"></div>
        <div class="line bg-primary"></div>
      </div>
    </div>
  </div>
  <div
    class="my-gallery row gallery-with-description m-b-5 pl-2 pr-2"
    style="height: 100%; min-height: 95vh"
  >
    <strong
      class="col-form-label"
      *ngIf="!hasYoutubeAPIKey && tabName == 'search'"
    >
      {{
        "You currently not yet configure the Youtube API Key. To search by video name, channel name etc,. you will need to configure the Youtube API Key."
          | translate
      }}<br />Please follow this {{ "instruction" | translate }}
      <a href="https://speaknplay.app/how-to-get-youtube-api-key" target="blank"
        >https://speaknplay.app/how-to-get-youtube-api-key</a
      ></strong
    >
    <div
      id="search-results"
      class="my-app-custom-plain-container-with-desc row search-results"
      style="width: 100%; height: 85%; overflow-y: auto"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="newOnPopup"
      (scrolled)="onScroll()"
    >
      <ng-container *ngIf="foundVideoByIds.length > 0">
        <!-- Input ylist result -->
        <figure
          class="col-xl-2 col-sm-3 col-xs-4 col-6 ylist-input-item"
          *ngFor="let foundVideoById of foundVideoByIds"
        >
          <div class="position-relative">
            <div
              class="position-absolute video-type"
              *ngIf="foundVideoById.type"
            >
              <span class="d-none d-md-inline">{{ foundVideoById.type }} </span>
            </div>
            <div
              class="position-absolute video-name"
              *ngIf="foundVideoById.duration && foundVideoById.viewCount"
            >
              <span
                >{{ foundVideoById.duration }} •
                {{ foundVideoById.viewCount }} views</span
              >
            </div>
            <img
              [src]="
                'https://img.youtube.com/vi/' +
                foundVideoById.id +
                '/mqdefault.jpg'
              "
              class="thumbnail"
              (click)="playLesson(content, foundVideoById)"
            />
          </div>

          <div class="caption">
            <ng-container
              *ngIf="foundVideoById.title && foundVideoById.publishedAt"
            >
              <h6>{{ foundVideoById.title }}</h6>
              {{ formatDateTime(foundVideoById.publishedAt) }} by
              <a
                href="javascript:void(0)"
                *ngIf="foundVideoById.channelId && foundVideoById.channelTitle"
                (click)="
                  relatedSearch(
                    '',
                    foundVideoById.channelId,
                    '',
                    foundVideoById.channelTitle
                  )
                "
                >{{ foundVideoById.channelTitle }}</a
              >
              <a
                href="https://www.youtube.com/channel/{{
                  foundVideoById.channelId
                }}"
                target="_blank"
                style="position: relative; top: 2px; left: 3px"
                ><i class="fa fa-external-link"></i
              ></a>
            </ng-container>
            <p>
              <span class="d-none d-md-block" *ngIf="foundVideoById.desc"
                >{{ foundVideoById.desc }}<br
              /></span>

              <button
                type="button"
                class="btn btn-xs btn-light m-r-5"
                (click)="
                  usedVideo = foundVideoById;
                  useIt(foundVideoById, !newOnPopup ? null : addForm)
                "
              >
                {{ "Use it" | translate }}
              </button>
              <button
                type="button"
                class="btn btn-xs btn-light m-r-5"
                (click)="
                  relatedSearch(
                    foundVideoById.id,
                    '',
                    '',
                    '',
                    foundVideoById.title
                  )
                "
                *ngIf="hasYoutubeAPIKey"
              >
                {{ "More like this" | translate }}
              </button>
              <a
                href="https://www.youtube.com/watch?v={{ foundVideoById.id }}"
                target="_blank"
                style="position: relative; top: 2px"
                ><i class="fa fa-external-link"></i
              ></a>
            </p>
          </div>
        </figure>
        <hr style="width: 100%; margin-top: 0" *ngIf="hasYoutubeAPIKey" />
      </ng-container>

      <ng-container
        *ngIf="foundSuggestionVideoByIds.length > 0 && tabName == 'suggestion'"
      >
        <!-- Suggestion result -->
        <figure
          class="col-xl-2 col-sm-3 col-xs-4 col-6 suggestion-item"
          *ngFor="let foundSuggestionVideoById of foundSuggestionVideoByIds"
        >
          <div class="position-relative">
            <div
              class="position-absolute video-type"
              *ngIf="foundSuggestionVideoById.type"
            >
              <span class="d-none d-md-inline"
                >{{ foundSuggestionVideoById.type }}
              </span>
            </div>
            <div
              class="position-absolute video-name"
              *ngIf="
                foundSuggestionVideoById.duration &&
                foundSuggestionVideoById.viewCount
              "
            >
              <span
                >{{ foundSuggestionVideoById.duration }} •
                {{ foundSuggestionVideoById.viewCount }}
                {{ "views" | translate }}</span
              >
            </div>
            <img
              [src]="
                'https://img.youtube.com/vi/' +
                foundSuggestionVideoById.id +
                '/mqdefault.jpg'
              "
              class="thumbnail"
              (click)="playLesson(content, foundSuggestionVideoById)"
            />
          </div>

          <div class="caption">
            <ng-container
              *ngIf="
                foundSuggestionVideoById.title &&
                foundSuggestionVideoById.publishedAt
              "
            >
              <h6>{{ foundSuggestionVideoById.title }}</h6>
              <!-- {{formatDateTime(foundSuggestionVideoById.publishedAt)}}  -->
              <!-- by <a href="javascript:void(0)" *ngIf="foundSuggestionVideoById.channelId && foundSuggestionVideoById.channelTitle" (click)="relatedSearch('', foundSuggestionVideoById.channelId, '', foundSuggestionVideoById.channelTitle)">{{foundSuggestionVideoById.channelTitle}}</a>
              <a href="https://www.youtube.com/channel/{{foundSuggestionVideoById.channelId}}" target="_blank" style="position:relative;top:2px;left:3px"><i class="fa fa-external-link"></i></a> -->
            </ng-container>
            <p>
              <span
                class="d-none d-md-block"
                *ngIf="foundSuggestionVideoById.desc"
                >{{ foundSuggestionVideoById.desc }}<br
              /></span>

              <button
                type="button"
                class="btn btn-xs btn-light m-r-5"
                (click)="
                  usedVideo = foundSuggestionVideoById;
                  useIt(foundSuggestionVideoById, !newOnPopup ? null : addForm)
                "
              >
                {{ "Use it" | translate }}
              </button>
              <button
                type="button"
                class="btn btn-xs btn-light m-r-5"
                (click)="
                  relatedSearch(
                    foundSuggestionVideoById.id,
                    '',
                    '',
                    '',
                    foundSuggestionVideoById.title
                  )
                "
                *ngIf="hasYoutubeAPIKey"
              >
                {{ "More like this" | translate }}
              </button>
              <a
                href="https://www.youtube.com/watch?v={{
                  foundSuggestionVideoById.id
                }}"
                target="_blank"
                style="position: relative; top: 2px"
                ><i class="fa fa-external-link"></i
              ></a>
            </p>
          </div>
        </figure>
        <hr style="width: 100%; margin-top: 0" *ngIf="hasYoutubeAPIKey" />
      </ng-container>

      <ng-container *ngIf="hasYoutubeAPIKey && tabName == 'search'">
        <!-- Search result -->
        <figure
          class="col-xl-2 col-sm-3 col-xs-4 col-6 search-item"
          *ngFor="let result of currentSearchTabInfo.results"
        >
          <div class="position-relative">
            <div class="position-absolute video-type">
              <span class="d-none d-md-inline">{{ result.type }} </span>
            </div>
            <div
              class="position-absolute video-name"
              *ngIf="result.type == 'video'"
            >
              <span
                >{{ result.duration }} • {{ result.viewCount }}
                {{ "views" | translate }}</span
              >
            </div>
            <div
              class="position-absolute video-name"
              *ngIf="result.type == 'channel'"
            >
              <span
                >{{ result.subscriberCount }} subscribers •
                {{ result.videoCount }} {{ "videos" | translate }}</span
              >
            </div>
            <div
              class="position-absolute video-name"
              *ngIf="result.type == 'playlist'"
            >
              <span>{{ result.videoCount }} {{ "videos" | translate }}</span>
            </div>
            <img
              [src]="
                'https://img.youtube.com/vi/' + result.id + '/mqdefault.jpg'
              "
              class="thumbnail"
              (click)="playLesson(content, result)"
              *ngIf="result.type == 'video'"
            />
            <img
              [src]="result.thumbnailUrl"
              class="thumbnail"
              *ngIf="result.type !== 'video' && !!result.thumbnailUrl"
            />
          </div>

          <div class="caption">
            <h6>{{ result.title }}</h6>
            {{ formatDateTime(result.publishedAt) }} by
            <a
              href="javascript:void(0)"
              (click)="
                relatedSearch('', result.channelId, '', result.channelTitle)
              "
              *ngIf="result.type !== 'playlist'"
              >{{ result.channelTitle }}</a
            >
            <a
              href="javascript:void(0)"
              (click)="relatedSearch('', '', result.playlistId, result.title)"
              *ngIf="result.type == 'playlist'"
              >{{ result.title }}</a
            >
            <p>
              <span class="d-none d-md-block">{{ result.desc }}<br /></span>

              <button
                type="button"
                class="btn btn-xs btn-light m-r-5"
                (click)="
                  usedVideo = result;
                  useIt(result, !newOnPopup ? null : addForm)
                "
                *ngIf="result.type == 'video'"
              >
                {{ "Use it" | translate }}
              </button>
              <button
                type="button"
                class="btn btn-xs btn-light m-r-5"
                (click)="relatedSearch(result.id, '', '', '', result.title)"
                *ngIf="result.type == 'video'"
              >
                {{ "More like this" | translate }}
              </button>
              <a
                href="https://www.youtube.com/watch?v={{ result.id }}"
                target="_blank"
                style="position: relative; top: 2px"
                *ngIf="result.type == 'video'"
                ><i class="fa fa-external-link"></i
              ></a>
            </p>
          </div>
        </figure>
      </ng-container>
    </div>

    <!-- <div id="search-results" class="my-app-custom-plain-container-with-desc row search-results"
      style="width:100%;height:100%;overflow-y:auto" >
      
    </div> -->
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-body" id="modalBody">
    <app-preview-video
      [input]="selected"
      [isPreviewForAdding]="true"
      (selectedChange)="previewVideoSelectedChange($event, addForm)"
      *ngIf="clickTo == 'play'"
    >
    </app-preview-video>
    <img
      [src]="'https://img.youtube.com/vi/' + selected.id + '/hqdefault.jpg'"
      class="img-responsive"
      *ngIf="clickTo == 'zoomIn'"
      style="position: relative; left: -7px"
    />
  </div>
</ng-template>
<ng-template #addForm let-addModal>
  <div class="modal-header">
    <h4 class="modal-title">{{ "Create/Update lesson" | translate }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="modalBody">
    <div
      class="text-center"
      style="padding-left: 20px; padding-right: 30px"
      *ngIf="!useItActionType"
    >
      <button
        class="btn btn-primary btn-lg m-b-15"
        type="button"
        placement="top"
        (click)="useItActionType = 'add'"
      >
        {{ "Add new" | translate }}
      </button>
      <h2 class="m-b-15">Or</h2>
    </div>
    <div
      style="padding-left: 30px; padding-right: 30px"
      *ngIf="!useItActionType"
    >
      <ng-select
        [items]="authService.schoolData.data.lessons"
        bindLabel="name"
        (change)="selectedEditItem($event)"
        [placeholder]="
          'Select exist lesson to add to this category' | translate
        "
      >
      </ng-select>
    </div>
    <app-lesson
      *ngIf="!!useItActionType"
      [usedVideo]="usedVideo"
      (eventChange)="eventChange($event)"
      [type]="useItActionType"
      [editId]="useItEditId"
    ></app-lesson>
  </div>
</ng-template>
