import { SpeechService } from './../../services/speech.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { APIResponse, User } from 'src/app/services/constant.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

type UserFields = 'email';
type FormErrors = { [u in UserFields]: string };
declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public newUser = false;
  public user: User;
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    email: '',
  };
  public errorMessage: any;
  public browserSupport = true;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    public speechService: SpeechService,
    private translate: TranslateService,
    private http: HttpClient,
    private toster: ToastrService,
    private router: Router
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
    this.browserSupport = !this.speechService.useCloudSpeech;
  }

  // Simple Login
  forgotPassword() {
    // this.authService.ForgotPassword(this.loginForm.value['email']);
    const apiEndpoint = environment.apiEndpoint;
    const url = `${apiEndpoint}/send-reset-password-email`;
    Swal.showLoading();
    this.http
      .post(url, {
        email: this.loginForm.value['email'],
      })
      .subscribe({
        next: (apiResponse: APIResponse) => {
          this.toster.clear();
          if (apiResponse.success) {
            this.toster.success(
              this.translate.instant('Reset password email sent successfully')
            );
          } else {
            this.toster.error(
              this.translate.instant(
                'Something went wrong: ' + apiResponse.message
              ),
              '',
              {
                timeOut: 60000,
              }
            );
          }
          Swal.close();
        },
        error: (apiResponse: APIResponse) => {
          Swal.close();
          this.toster.error(apiResponse.message);
        },
      });
  }
}
